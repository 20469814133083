import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Location, Book } from 'epubjs';
import { useSwipeable } from 'react-swipeable';
import { useEpubReaderStore } from './EpubReaderStoreProvider';
import { useDebounceCallback, useDidMountEffect, useMobileDetect } from '../../../../hooks';
import { clutch } from './number';

export type Ispread = 'none' | 'auto';

export type ILocationData = {
  chapterName: string;
  currentPage?: globalThis.Location;
  totalPage: number;
  percentage: number;
};

export interface IUseEpubReaderActions {
  spread: Ispread;
  isOpenToc: boolean;
  isDisableBtnGoNextPage: boolean;
  isDisableBtnGoPrevPage: boolean;
  locationData: ILocationData;
  handleNextPageChange: () => void;
  handlePrevPageChange: () => void;
  handleSlideChange: (sliderValue?: number) => void;
  onResizePaneReader: (width?: number) => void;
  handleTocOpenChange: (isOpenToc?: boolean) => void;
  handleSpreadChange: (spread?: Ispread) => void;
}

export const EpubReaderActionsContext = createContext<IUseEpubReaderActions | undefined>(undefined);
EpubReaderActionsContext.displayName = 'EpubReaderActionsContext';

export const useEpubReaderActions = () => {
  const context = useContext(EpubReaderActionsContext);
  if (context === undefined) {
    throw new Error('useEpubReaderActionsContext must be used within EpubReaderActionsProvider');
  }
  return context;
};

export const EpubReaderActionsProvider = ({ children }) => {
  const { bookRef } = useEpubReaderStore();
  const { isDesktop } = useMobileDetect();
  const [isOpenToc, setOpenToc] = useState(isDesktop());
  const [spread, setSpread] = useState<Ispread>(isDesktop() ? 'auto' : 'none');
  const [locationData, setLocationData] = useState<ILocationData>({
    chapterName: '',
    currentPage: undefined,
    totalPage: 0,
    percentage: 0,
  });
  const [isDisableBtnGoPrevPage, setIsDisableBtnGoPrevPage] = useState(false);
  const [isDisableBtnGoNextPage, setIsDisableBtnGoNextPage] = useState(false);

  const updatePageButtonsDisableStatuses = useDebounceCallback(() => {
    if (bookRef.current?.rendition?.location) {
      setIsDisableBtnGoPrevPage(bookRef.current?.rendition?.location?.atStart);
      setIsDisableBtnGoNextPage(bookRef.current?.rendition.location.atEnd);
    }
  }, [100, bookRef.current?.rendition?.location]);

  const handleTocOpenChange = (isOpenToc?: boolean) => {
    setOpenToc((prevState) => {
      if (typeof isOpenToc === 'boolean') {
        return isOpenToc;
      }
      return !prevState;
    });
  };

  const handleSpreadChange = (spread?: Ispread) => {
    setSpread((prevState) => {
      if (spread) {
        return spread;
      }
      return prevState === 'none' ? 'auto' : 'none';
    });
  };

  const getLocationData = (loc: Location, book: Book): ILocationData => {
    const { locations } = book;
    const percent = locations.percentageFromCfi(loc.start.cfi);
    const percentage = Math.floor(percent * 100);
    const currentPage = locations.locationFromCfi(loc.start.cfi);
    const spineItem = book.spine.get(loc.start.cfi);
    const navItem = book.navigation.get(spineItem.href);
    const chapterName = navItem && navItem.label.trim();
    // @ts-ignore
    const totalPage = locations?.total;
    // console.log({
    //   chapterName,
    //   currentPage,
    //   totalPage,
    //   percentage,
    //   loc,
    //   book,
    // });
    return {
      chapterName,
      currentPage,
      totalPage,
      percentage,
    };
  };

  const onLocationChange = useCallback(
    (loc: Location) => {
      if (bookRef.current?.book?.locations && loc) {
        setLocationData(getLocationData(loc, bookRef.current.book));
      }
    },
    [bookRef],
  );

  const handlers = useSwipeable({
    onSwiped: (e) => {
      if (e.absX > 75 && bookRef.current) {
        if (e.dir === 'Left') {
          // @ts-ignore
          bookRef.current?.nextPage();
        }

        if (e.dir === 'Right') {
          // @ts-ignore
          bookRef.current?.prevPage();
        }
      }
    },
  });

  useEffect(() => {
    let listenerContent;
    if (bookRef.current?.rendition) {
      listenerContent = (contents) => {
        updatePageButtonsDisableStatuses();

        const el = contents.document.documentElement;
        handlers.ref(el);
      };
      bookRef.current?.rendition.hooks.content.register(listenerContent);
      bookRef.current?.rendition.on('relocated', onLocationChange);
    }

    return () => {
      if (bookRef.current?.rendition && listenerContent) {
        bookRef.current?.rendition.hooks.content.deregister(listenerContent);
      }
    };
  }, [bookRef.current?.rendition]);

  const handlePrevPageChange = useCallback(() => {
    if (bookRef.current) {
      // @ts-ignore
      bookRef.current?.prevPage();
      updatePageButtonsDisableStatuses();
    }
  }, [bookRef.current?.rendition?.location]);

  const handleNextPageChange = useCallback(() => {
    if (bookRef.current) {
      // @ts-ignore
      bookRef.current?.nextPage();
      updatePageButtonsDisableStatuses();
    }
  }, [bookRef.current?.rendition?.location]);

  const handleSlideChange = useCallback((sliderValue?: number) => {
    if (sliderValue && bookRef.current?.book && bookRef.current?.rendition) {
      const cfi = bookRef.current.book.locations.cfiFromPercentage(sliderValue / 100);
      bookRef.current?.rendition.display(cfi);
      updatePageButtonsDisableStatuses();
    }
  }, []);

  const onResizePaneReader = useCallback(
    (width?: number) => {
      if (!width || !bookRef.current?.state.isLoaded || !bookRef.current?.rendition) {
        return;
      }
      // @ts-ignore
      bookRef.current?.rendition?.resize(width);
      updatePageButtonsDisableStatuses();
      if (width && bookRef.current?.rendition?.settings?.minSpreadWidth) {
        setSpread(width < bookRef.current.rendition.settings.minSpreadWidth ? 'none' : 'auto');
      }
    },
    [bookRef.current?.rendition, bookRef.current?.state.isLoaded, updatePageButtonsDisableStatuses],
  );

  useDidMountEffect(() => {
    bookRef.current?.rendition?.spread(spread);
  }, [spread]);

  const epubReaderActionsContextObject: IUseEpubReaderActions = useMemo(
    () => ({
      spread,
      isOpenToc,
      isDisableBtnGoNextPage,
      isDisableBtnGoPrevPage,
      locationData,
      handleNextPageChange,
      handlePrevPageChange,
      handleTocOpenChange,
      handleSpreadChange,
      handleSlideChange,
      onResizePaneReader,
    }),
    [isOpenToc, isDisableBtnGoNextPage, isDisableBtnGoPrevPage, spread, locationData],
  );

  return (
    <EpubReaderActionsContext.Provider value={epubReaderActionsContextObject}>
      {children}
    </EpubReaderActionsContext.Provider>
  );
};
