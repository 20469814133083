export interface IUsePublicationStoreResult {
  publicationStore: Record<string, any>;
}

export const usePublicationStore = (): IUsePublicationStoreResult => {
  // const location = useLocation();
  // const [publicationId] = useState(location.pathname.split('/')[2]);

  const publicationStore = {};

  return {
    publicationStore,
  };
};
