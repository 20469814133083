import { useActive, useCommands } from '@remirror/react';
import React, { FC, useCallback } from 'react';
import { HardBreakExtension } from 'remirror/extensions';

import { WFormatUnderlineExtension } from '../../index';
import { CommandButton, ICommandButtonProps } from '../../../components';
import { WHardBreakIcon } from './index';

export type IToggleLineBreakButtonProps = Omit<
  ICommandButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'
>;

export const ToggleWHardBreakButton: FC<IToggleLineBreakButtonProps> = (props) => {
  const { toggleHardBreak: command, focus } = useCommands<HardBreakExtension>();
  const active = useActive<WFormatUnderlineExtension>().wFormatUnderline();
  const enabled = command.enabled();

  const handleClick = useCallback(() => {
    if (enabled) {
      command();
      focus();
    }
  }, [command]);

  return (
    <CommandButton
      {...props}
      commandName="toggleHardBreak"
      active={active}
      enabled={enabled}
      onSelect={handleClick}
      icon={<WHardBreakIcon style={{ height: '1rem', width: '1rem' }} />}
    />
  );
};
