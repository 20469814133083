import { useCallback } from 'react';
import { TreeProps } from 'antd/lib';
import { throttle } from 'remirror';
import { useDebounce } from '../../../../../hooks';
import { ManagerFolderTreeNodeType } from '../useFoldersTreeStore';
import { useFoldersTreeStoreContext } from '../FoldersTreeStoreProvider';
import { useDebounceExpandFolder } from '../FolderActions/useDebounceExpandFolder';
import { MoveFolderInput } from '../../../../../services/apolloGraphqlAppApiClient/models/types';
import { IMoveFolderActionOptions, useMoveFolderAction } from './useMoveFolderAction';

export interface IUseFolderTreeDndActionsProps {
  data?: any;
}

export type IUseFolderTreeDndActionsResult = {
  onDragStart: TreeProps<ManagerFolderTreeNodeType>['onDragEnd'];
  onDragOver: TreeProps<ManagerFolderTreeNodeType>['onDragOver'];
  onDragEnter: TreeProps<ManagerFolderTreeNodeType>['onDragEnter'];
  onDragEnd: TreeProps<ManagerFolderTreeNodeType>['onDragEnd'];
  onDrop: TreeProps<ManagerFolderTreeNodeType>['onDrop'];
  allowDrop: TreeProps<ManagerFolderTreeNodeType>['allowDrop'];
};

export const useFolderTreeDndActions = (props?: IUseFolderTreeDndActionsProps): IUseFolderTreeDndActionsResult => {
  const { draggedFolder, setDraggedFolder, treeData, toggleExpandNode, expandOptions, onExpand, loadData, loadedKeys } =
    useFoldersTreeStoreContext();
  const { moveFolderAction } = useMoveFolderAction();

  const onDragStart = (info) => {
    setDraggedFolder(info.node);
  };

  const onDragEnd: TreeProps<ManagerFolderTreeNodeType>['onDragEnd'] = (info) => {
    setDraggedFolder(null);
  };
  const onDragOver: TreeProps<ManagerFolderTreeNodeType>['onDragOver'] = (info) => {
    if (info.event.target) {
      scrollTo(info.event.target);
    }
    // const { dragOver } = info.node;
    // console.log('dragOver', { dragOver });
    // console.log({ onDragOver: info });
  };

  const onDragEnter = useDebounce<TreeProps<ManagerFolderTreeNodeType>['onDragEnter']>((info) => {
    const { dragOver, dragOverGapBottom } = info.node;
    // console.log({ onDragEnter: info });
    // if (draggedFolder?.key !== info.node.key && !dragOverGapBottom) {
    //   toggleExpandNode(info.node.key, false);
    // }
  }, 2000);

  const handleExpandNode = useDebounceExpandFolder<typeof toggleExpandNode>((key, collapsed = false) => {
    toggleExpandNode(key, collapsed);
  }, 2000);

  const onDrop: TreeProps<ManagerFolderTreeNodeType>['onDrop'] = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const dragTitle = info.dragNode.title;
    const dropTitle = info.node.title;

    if (info.node.id === 0) {
      const newPosition: MoveFolderInput = {
        id: info.dragNode.id,
        newParent: null,
        newPosition: 1,
      };
      const options: IMoveFolderActionOptions = {
        dragTitle,
        dropTitle: '1st position',
        position: 'on',
      };
      moveFolderAction(newPosition, options);
      return;
    }

    const newPosition: MoveFolderInput = {
      id: info.dragNode.id,
      newParent: dropPosition === 0 ? info.node.id : info.node.parentId!,
      newPosition: dropPosition === 0 ? 1 : info.dropPosition,
    };
    const options: IMoveFolderActionOptions = {
      dragTitle,
      dropTitle,
      position: info.dropPosition === -1 ? 'before' : 'after',
    };

    console.log({ info, newPosition, options, dragKey, dropKey, dropPosition, dropPos });
    moveFolderAction(newPosition, options);
  };

  const scrollTo = useCallback(
    throttle(100, (target) => {
      const { bottom: currentBottom, top: currentTop } = target.getBoundingClientRect();
      const { bottom: boxBottom, top: boxTop } = document
        .getElementsByClassName('ant-tree-list-holder')[0]
        .getBoundingClientRect();
      if (currentTop > boxBottom - 48) {
        document.getElementsByClassName('ant-tree-list-holder')[0].scrollTop =
          document.getElementsByClassName('ant-tree-list-holder')[0].scrollTop + 32;
      }

      if (boxTop + 48 > currentBottom) {
        document.getElementsByClassName('ant-tree-list-holder')[0].scrollTop =
          document.getElementsByClassName('ant-tree-list-holder')[0].scrollTop - 32;
      }
    }),
    [],
  );

  const allowDrop: TreeProps<ManagerFolderTreeNodeType>['allowDrop'] = (options) => {
    if (options.dropNode.id === options.dragNode.id) {
      return false;
    }
    if (options.dropNode.id === options.dragNode.parentId && options.dragNode.order === 1) {
      return false;
    }
    if (options.dropNode.parentId === null && options.dragNode.parentId === null && options.dropPosition === 0) {
      return false;
    }
    if (options.dropNode.childPublicationCount > 0 && options.dropPosition === 0) {
      return false;
    }
    if (options.dropNode.parentId !== null && options.dragNode.parentId === null) {
      return false;
    }
    if (options.dropNode.parentId === null && options.dragNode.parentId !== null && options.dropPosition !== 0) {
      return false;
    }

    if (options.dragNode.id !== options.dropNode.id && options.dropPosition === 0) {
      handleExpandNode(options.dropNode.id);
    }
    return true;
  };

  return {
    onDragStart,
    onDragOver,
    onDragEnter,
    onDragEnd,
    onDrop,
    allowDrop,
  };
};
