import { CommandsExtension } from '@remirror/core';
import { useCommands, useCurrentSelection } from '@remirror/react-core';
import React, { FC, useCallback } from 'react';

import { CommandButton, ICommandButtonProps } from './CommandButton';

export type IToggleCopyButtonProps = Omit<
  ICommandButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'
>;

export const ToggleCopyButton: FC<IToggleCopyButtonProps> = (props) => {
  const { copy } = useCommands<CommandsExtension>();
  // Force component update on selection change
  useCurrentSelection();

  const handleSelect = useCallback(() => {
    if (copy.enabled()) {
      copy();
    }
  }, [copy]);

  const enabled = copy.enabled();

  return <CommandButton {...props} commandName="copy" active={false} enabled={enabled} onSelect={handleSelect} />;
};
