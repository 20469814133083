import { App, Button, ModalFuncProps } from 'antd';
import React from 'react';
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';
import {
  ManagerPublicationEpubListItemFragment,
  useSetPublicationMainEpubMutation,
} from '../../../services/apolloGraphqlAppApiClient/models/types';
import { usePublicationEpubListManagerStore } from '../PublicationEpubListManagerStoreProvider';

export const SetPublicationMainEpubActionButton = (record: ManagerPublicationEpubListItemFragment) => {
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();

  const { getPublicationEpubListLazy } = usePublicationEpubListManagerStore();
  const infoKey = uuidV4();

  const [setPublicationMainEpub] = useSetPublicationMainEpubMutation({
    onCompleted: (result) => {
      getPublicationEpubListLazy();
      info({
        placement: 'topRight',
        message: `Set main Epub status: true`,
        key: infoKey,
        duration: 7,
      });
    },
    onError: (e) => {
      return e;
    },
  });

  const handleOk: ModalFuncProps['onOk'] = () => {
    setPublicationMainEpub({
      variables: {
        input: { id: record.id, publicationId: record.publicationId, type: record.type },
      },
    });
  };

  return (
    <Button
      type="link"
      icon={<CheckOutlined />}
      disabled={record.isMain}
      onClick={() => {
        confirm({
          title: `Are you sure to set this epub as main?`,
          icon: <ExclamationCircleOutlined />,
          okText: 'Set main',
          onOk: handleOk,
          closable: true,
        });
      }}
    >
      Set Main
    </Button>
  );
};
