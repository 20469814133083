import { App, ModalFuncProps } from 'antd';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { FlexListTemplate } from '../../../../../components';
import {
  useUnpublishPublicationMutation,
  UnpublishPublicationInput,
} from '../../../../../services/apolloGraphqlAppApiClient/models/types';
import { TManagerPublicationListItem } from '../usePublicationListStore';
import { usePublicationListStoreContext } from '../PublicationListStoreProvider';

export interface IUseUnpublishPublicationActionResult {
  unpublishPublicationAction: (...args: [UnpublishPublicationInput, IUnpublishPublicationActionOptions]) => void;
}

export type IUnpublishPublicationActionOptions = {
  dragTitle: TManagerPublicationListItem['title'];
};

export const useUnpublishPublicationAction = (): IUseUnpublishPublicationActionResult => {
  const { refetch } = usePublicationListStoreContext();
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();

  const [unpublishPublication] = useUnpublishPublicationMutation({
    onCompleted: (result) => {
      info({
        placement: 'topRight',
        message: `Unpublish publication status: true`,
        description: (
          <FlexListTemplate gap={5} alignItems="left" justifyContent="left" style={{ flexDirection: 'column' }}>
            ¦{/* info */}
          </FlexListTemplate>
        ),
        key: uuidV4(),
        duration: 7,
      });
      refetch(
        result.unpublishPublication.publicationDto?.order
          ? result.unpublishPublication.publicationDto.order - 1
          : undefined,
      );
    },
    onError: (e) => {
      return e;
    },
  });

  const handleOk: ModalFuncProps['onOk'] = async (values: UnpublishPublicationInput) => {
    await unpublishPublication({
      variables: {
        input: { ...values },
      },
    });
  };

  const unpublishPublicationAction: IUseUnpublishPublicationActionResult['unpublishPublicationAction'] = (
    values,
    options,
  ) => {
    const { dragTitle } = options;
    confirm({
      title: `Unpublish ${dragTitle} Publication!`,
      onOk: () => handleOk(values),
      okText: 'Unpublish',
      content: `Are you sure you want to unpublish "${dragTitle}" publication ?`,
    });
  };

  return {
    unpublishPublicationAction,
  };
};
