import { TextField } from '@mui/material';
import React from 'react';

import { CommandForm, ICommandFormProps } from '../../../components';

interface IToggleWAnchorFormProps extends Omit<ICommandFormProps, 'children' | 'fields'> {
  initialOption?: string;
  initialValues?: any;
  onRemove?: () => void;
}

export const ToggleWAnchorForm = (props: IToggleWAnchorFormProps) => {
  const { ...rest } = props;

  // const handleRemove = () => {
  //   onRemove && onRemove();
  // };
  //
  // const handleSubmit = (values) => {
  //   onSubmit && onSubmit(values);
  // };

  return (
    <CommandForm name="wAnchor" {...rest}>
      <TextField
        name="id"
        label="Id"
        // rules={[{ required: true, message: 'Id is required' }]}
        size="small"
        variant="standard"
        id="outlined-start-adornment"
      />
    </CommandForm>
  );
};
