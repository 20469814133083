import { App, Form } from 'antd';
import React, { useRef } from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { EditOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';

import {
  AddParagraphInput,
  PublicationChaptersReaderParagraphListItemFragment,
  useAddPublicationChaptersParagraphMutation,
} from '../../../services/apolloGraphqlAppApiClient/models/types';
import { PublicationParagraphEditor } from './PublicationParagraphEditor';
import { usePublicationChaptersReaderStore } from '../PublicationChaptersReaderStoreProvider';
import { IModalContentFormTemplateProps, ModalContentFormTemplate } from '../../../components';

export interface IUseAddPublicationChaptersEndParagraphActionProps {
  paragraphData: PublicationChaptersReaderParagraphListItemFragment;
}

export interface IUseAddPublicationChaptersEndParagraphActionResult {
  addPublicationChaptersEndParagraphAction: ItemType;
}

export const useAddPublicationChaptersEndParagraphAction = (
  props: IUseAddPublicationChaptersEndParagraphActionProps,
): IUseAddPublicationChaptersEndParagraphActionResult => {
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();
  const { paragraphData } = props;

  const menuItemKey = `AddPublicationChaptersEndParagraphAction-${paragraphData.paraId}`;
  const infoKey = uuidV4();
  const modalRef = useRef<ReturnType<typeof confirm>>();

  const visible = true;

  const { setActiveChapterOrder, fetchParagraphsLazy, publicationChaptersReaderStore, historyParams, setScrollToItem } =
    usePublicationChaptersReaderStore();

  const [AddPublicationChaptersEndParagraphAction] = useAddPublicationChaptersParagraphMutation({
    onCompleted: (result) => {
      info({
        placement: 'topRight',
        message: `AddPublicationChaptersEndParagraph status: true`,
        description: (
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={{ __html: result.addParagraph.paragraphDto?.content || '' }} />
        ),
        key: infoKey,
        duration: 7,
      });
      publicationChaptersReaderStore
        .refetch({ publicationId: historyParams?.publicationId })
        .then((res) => {
          const lastChapterOrder = res.data?.publication?.chapters?.slice(-1)[0].order;
          const resultParagraphOrder = result?.addParagraph?.paragraphDto?.order;
          if (typeof lastChapterOrder === 'number' && typeof resultParagraphOrder === 'number') {
            const nextOrder = resultParagraphOrder - 19;
            fetchParagraphsLazy({
              order: nextOrder,
              isRefetch: true,
            })
              .then(() => {
                if (lastChapterOrder) {
                  setActiveChapterOrder(lastChapterOrder);
                  setScrollToItem(resultParagraphOrder);
                }
              })
              .catch((e) => {
                console.log({ e });
              });
          }
        })
        .catch((e) => {
          console.log('[fetchReaderParagraphError]', { e });
        });
    },
    onError: (e) => {
      return e;
    },
  });

  const handleOk: IModalContentFormTemplateProps<AddParagraphInput>['onFinish'] = async (values, form) => {
    const r = await AddPublicationChaptersEndParagraphAction({
      variables: {
        input: {
          before: null,
          content: values.content,
          publicationId: paragraphData.publicationId,
        },
      },
    });
    if (r.errors) {
      return Promise.reject(r.errors);
    }
    if (modalRef.current) {
      modalRef.current.destroy();
    }
    return r;
  };

  const handleCancel: IModalContentFormTemplateProps<AddParagraphInput>['onCancel'] = async (form) => {
    if (modalRef.current) {
      modalRef.current.destroy();
    }
    form.resetFields();
  };
  return {
    addPublicationChaptersEndParagraphAction: visible
      ? {
          key: menuItemKey,
          icon: <EditOutlined />,
          label: 'Add paragraph at the end',
          onClick: (menuInfo) => {
            menuInfo.domEvent.preventDefault();
            menuInfo.domEvent.stopPropagation();
            modalRef.current = confirm({
              title: `Add paragraph at the end of publication!`,
              width: '75%',
              footer: null,
              content: (
                <ModalContentFormTemplate<AddParagraphInput>
                  initialValues={{
                    content: '',
                  }}
                  layout="vertical"
                  name={`addPublicationChaptersEndParagraphForm-${paragraphData.paraId}`}
                  onFinish={handleOk}
                  onCancel={handleCancel}
                  buttonProps={{ submit: { label: 'Add paragraph' } }}
                >
                  <Form.Item
                    name="content"
                    valuePropName="value"
                    label="WEML Content Editor"
                    rules={[
                      {
                        required: false,
                        message: `Please input paragraph content!`,
                      },
                    ]}
                  >
                    <PublicationParagraphEditor initialContent="" />
                  </Form.Item>
                </ModalContentFormTemplate>
              ),
            });
          },
        }
      : null,
  };
};
