import { Autocomplete, Box, TextField } from '@mui/material';
import React from 'react';

import { W_HEADING_PROPERTIES } from '../../index';
import { CommandForm, ICommandFormProps } from '../../../components';

export interface IToggleWHeadingNodeFormProps extends Omit<ICommandFormProps, 'children' | 'fields'> {
  initialOption?: string;
  initialValues?: any;
  onRemove?: () => void;
}

export const ToggleWHeadingForm = (props: IToggleWHeadingNodeFormProps) => {
  const { ...rest } = props;

  return (
    <CommandForm name="wHeading" {...rest}>
      <Autocomplete
        id="level"
        size="small"
        options={W_HEADING_PROPERTIES.levelList}
        autoHighlight
        getOptionLabel={(option: any) => option}
        renderOption={(props, option) => {
          props['data-name'] = 'level';
          return (
            <Box component="li" {...props}>
              {option}
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="level"
            label="Level"
            size="small"
            variant="standard"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
      <TextField name="skip" size="small" label="Skip" variant="standard" id="outlined-start-skip" />
    </CommandForm>
  );
};
