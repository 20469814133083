import React, { FC, useCallback } from 'react';

import { useActive, useCommands } from '@remirror/react-core';
import { useAttrs } from '@remirror/react';
import { CommandPopoverButton, ICommandPopoverButtonProps } from '../../../components';
import { WPageBlockExtension } from '../../index';
import { ToggleWPageBlockForm } from './ToggleWPageBlockForm';

export type IToggleWPageBlockButtonProps = Omit<
  ICommandPopoverButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect' | 'popoverContent'
>;

export const ToggleWPageBlockButton: FC<IToggleWPageBlockButtonProps> = (props) => {
  const { toggleWPageBlock, updateWPageBlock } = useCommands<WPageBlockExtension>();
  const attrs = useAttrs<WPageBlockExtension>().wPageBlock();
  const active = useActive<WPageBlockExtension>().wPageBlock();
  const enabled = toggleWPageBlock.enabled();
  const handleSubmit = useCallback(
    (values) => {
      if (toggleWPageBlock.enabled()) {
        if (active) {
          return updateWPageBlock(values);
        }
        toggleWPageBlock(values);
      }
    },
    [toggleWPageBlock, updateWPageBlock],
  );

  const handleRemove = useCallback(() => {
    if (toggleWPageBlock.enabled()) {
      toggleWPageBlock();
    }
  }, [toggleWPageBlock]);

  return (
    <CommandPopoverButton
      {...props}
      commandName="toggleWPageBlock"
      active={active}
      enabled={enabled}
      popoverContent={
        <ToggleWPageBlockForm
          onRemove={active ? handleRemove : undefined}
          initialValues={attrs}
          onSubmit={handleSubmit}
        />
      }
    />
  );
};
