import { EditorComponent, Remirror, useRemirror } from '@remirror/react';
import { DocExtension, DropCursorExtension, GapCursorExtension, TrailingNodeExtension } from 'remirror/extensions';
import { useCallback } from 'react';
import { EditorState } from '@remirror/core';
import { prosemirrorNodeToHtml } from 'remirror';
import {
  CommandButtonGroup,
  ControlledEditorToolbar,
  EditorContentActionType,
  EditorThemeProvider,
  IControlledEditorProps,
  IUseEditorContentStoreProps,
  ToggleCopyButton,
  ToggleCutButton,
  TogglePasteButton,
  ToggleRedoButton,
  ToggleResetButton,
  ToggleUndoButton,
  ToggleWFormatAllCapsButton,
  ToggleWFormatBoldButton,
  ToggleWFormatItalicButton,
  ToggleWFormatSmallCapsButton,
  ToggleWFormatSubscriptButton,
  ToggleWFormatSuperscriptButton,
  ToggleWFormatUnderlineButton,
  useDebounce,
  WFormatAllCapsExtension,
  WFormatBoldExtension,
  WFormatItalicExtension,
  WFormatSmallCapsExtension,
  WFormatSubscriptExtension,
  WFormatSuperscriptExtension,
  WFormatUnderlineExtension,
  WHardBreakExtension,
  WTextBlockExtension,
} from '../../../../../../components';
import { useAppThemeContext } from '../../../../../../themes';
import './PublicationDescriptionEditor.scss';

export interface IPublicationDescriptionEditorProps {
  onChange?: (value: string) => void;
  value?: string;
  initialContent: string;
  placeholder?: string;
}

export const PublicationDescriptionEditor = (props: IPublicationDescriptionEditorProps) => {
  const {
    placeholder = 'please input description text',
    onChange: onControlledChange,
    initialContent,
    value,
    ...rest
  } = props;
  const { theme, isSystemDarkModeTheme } = useAppThemeContext();

  const { manager, state, setState } = useRemirror({
    extensions: () => [
      new DocExtension({ content: 'wTextBlock{1}' }),
      new WTextBlockExtension(),
      // Base Marks
      new WFormatBoldExtension(),
      new WFormatItalicExtension(),
      new WFormatUnderlineExtension(),
      new WFormatSuperscriptExtension(),
      new WFormatSubscriptExtension(),
      new WFormatSmallCapsExtension(),
      new WFormatAllCapsExtension(),
      new WHardBreakExtension(),

      new GapCursorExtension(),
      new DropCursorExtension({ color: 'gray' }),
      new TrailingNodeExtension(),
    ],
    stringHandler: 'html',
    content: `<doc>${initialContent}</doc>`,
  });

  const handleEditorStateChange = useDebounce(
    (state: EditorState) => {
      const html = prosemirrorNodeToHtml(state.doc);
      console.log({ html });
      if (onControlledChange) {
        onControlledChange(html);
      }
    },
    [100],
  );

  const onEditorChange = useCallback(
    (props: any) => {
      const { state, tr } = props;
      handleEditorStateChange(state);
      setState(state);
    },
    [setState],
  );

  return (
    <>
      <EditorThemeProvider
        themeMode={
          theme === 'system'
            ? isSystemDarkModeTheme
              ? 'dark'
              : 'light'
            : (theme as IControlledEditorProps['themeMode'])
        }
        className="publicationDescription--editor"
      >
        <Remirror placeholder={placeholder} manager={manager} state={state} onChange={onEditorChange}>
          <ControlledEditorToolbar className="remirror-toolbar">
            <CommandButtonGroup>
              <ToggleWFormatBoldButton />
              <ToggleWFormatItalicButton />
              <ToggleWFormatUnderlineButton />
              <ToggleWFormatSmallCapsButton />
              <ToggleWFormatAllCapsButton />
              <ToggleWFormatSuperscriptButton />
              <ToggleWFormatSubscriptButton />
            </CommandButtonGroup>
            <CommandButtonGroup>
              <ToggleRedoButton />
              <ToggleUndoButton />
              <TogglePasteButton />
              <ToggleCopyButton />
              <ToggleCutButton />
            </CommandButtonGroup>
            <CommandButtonGroup>
              <ToggleResetButton label="Reset" type="reset" value={initialContent} />
              <ToggleResetButton
                label="Clear"
                // value={'<w-para indent="1"><w-text-block type="paragraph"></w-text-block></w-para>'}
              />
            </CommandButtonGroup>
          </ControlledEditorToolbar>
          <EditorComponent />
        </Remirror>
      </EditorThemeProvider>
    </>
  );
};
