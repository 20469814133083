import React, { createContext, Dispatch, Reducer, useContext, useMemo, useReducer, useRef } from 'react';
import { NavItem, Rendition } from 'epubjs';
import { EpubView } from 'react-reader';

export enum IEpubReaderStateActionType {
  SET_LOCATION = 'SET_LOCATION',
  SET_TOC = 'SET_TOC',
  SET_RENDITION = 'SET_RENDITION',
}

export type IEpubReaderStateAction =
  | {
      type: IEpubReaderStateActionType.SET_LOCATION;
      payload: IEpubReaderState['location'];
    }
  | {
      type: IEpubReaderStateActionType.SET_TOC;
      payload: IEpubReaderState['toc'];
    }
  | {
      type: IEpubReaderStateActionType.SET_RENDITION;
      payload: IEpubReaderState['rendition'];
    };

export interface IEpubReaderState {
  location: string | number;
  toc: NavItem[];
  rendition: Rendition | undefined;
}

const epubReaderStateReducer: Reducer<IEpubReaderState, IEpubReaderStateAction> = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_LOCATION':
      return { ...state, location: payload };
    case 'SET_TOC':
      return { ...state, toc: payload };
    case 'SET_RENDITION':
      return { ...state, rendition: payload };
    default:
      throw new Error();
  }
};

export interface IUseEpubReaderStoreResult {
  epubReaderState: IEpubReaderState;
  setEpubReaderState: Dispatch<IEpubReaderStateAction>;
  bookRef: React.RefObject<EpubView>;
}

export const EpubReaderStoreContext = createContext<IUseEpubReaderStoreResult | undefined>(undefined);
EpubReaderStoreContext.displayName = 'EpubReaderStoreContext';

export const useEpubReaderStore = () => {
  const context = useContext(EpubReaderStoreContext);
  if (context === undefined) {
    throw new Error('useEpubReaderStoreContext must be used within EpubReaderStoreProvider');
  }
  return context;
};

export const EpubReaderStoreProvider = ({ children }) => {
  const bookRef = useRef<EpubView>(null);
  const initialState = {
    location: 0,
    rendition: undefined,
    toc: [],
  };
  const [epubReaderState, setEpubReaderState] = useReducer(epubReaderStateReducer, initialState);

  const epubReaderStoreContextObject: IUseEpubReaderStoreResult = useMemo(
    () => ({
      epubReaderState,
      setEpubReaderState,
      bookRef,
    }),
    [epubReaderState, setEpubReaderState, bookRef],
  );

  return (
    <EpubReaderStoreContext.Provider value={epubReaderStoreContextObject}>{children}</EpubReaderStoreContext.Provider>
  );
};
