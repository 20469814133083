import { CommandsExtension } from '@remirror/core';
import { useCommands, useCurrentSelection } from '@remirror/react-core';
import React, { FC, useCallback } from 'react';

import { CommandButton, ICommandButtonProps } from './CommandButton';

export type IToggleCutButtonProps = Omit<
  ICommandButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'
>;

export const ToggleCutButton: FC<IToggleCutButtonProps> = (props) => {
  const { cut } = useCommands<CommandsExtension>();
  // Force component update on selection change
  useCurrentSelection();

  const handleSelect = useCallback(() => {
    if (cut.enabled()) {
      cut();
    }
  }, [cut]);

  const enabled = cut.enabled();

  return <CommandButton {...props} commandName="cut" active={false} enabled={enabled} onSelect={handleSelect} />;
};
