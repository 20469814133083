import React, { useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  InMemoryCache,
  ServerError,
  concat,
  createHttpLink,
} from '@apollo/client';
import { App } from 'antd';
import { useAuth } from 'react-oidc-context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { RetryLink } from '@apollo/client/link/retry';
import CONSTANTS from '../config/CONSTANTS';
import { apolloErrorsHandler } from '../utils';

const parseBearerToken = (token?: string) => {
  return `Bearer ${token}`;
};
export const ApolloGraphqlAppApiClientProvider = ({ children }) => {
  const { notification } = App.useApp();
  // const { user, ...rest } = useAuth();
  // const storage = JSON.parse(
  //   sessionStorage.getItem(`oidc.user:${CONSTANTS.APP_API_SESSION_ACCESS_URL}:${CONSTANTS.APP_API_CLIENT_ID}`) || '',
  // );
  // console.log({ storage, user, ...rest });

  const httpLink = createUploadLink({
    uri: `${CONSTANTS.APP_API_URL}/graphql`,
  });

  // const restLink = new RestLink({
  //   uri: `${CONSTANTS.APP_API_SESSION_ACCESS_URL}`,
  //   endpoints: {
  //     legacyUserInfo: {
  //       uri: `/connect/legacy-userinfo`,
  //       responseTransformer: async (response) => response.json().then(({data}) => data),
  //     },
  //   },
  //   // headers: {
  //   //   authorization: token ? `Bearer ${token}` : '',
  //   // },
  // },);
  //
  // const authLink = setContext((_, { headers, ...rest }) => {
  //   console.log({ headers, rest, r: _, toc: user?.access_token });
  //   return {
  //     headers: {
  //       ...headers,
  //       authorization: user?.access_token ? `Bearer ${user?.access_token}` : '',
  //       'GraphQL-Preflight': 1,
  //     },
  //     ...rest,
  //   };
  // });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: sessionStorage.getItem(
          `oidc.user:${CONSTANTS.APP_API_SESSION_ACCESS_URL}:${CONSTANTS.APP_API_CLIENT_ID}`,
        )
          ? `Bearer ${
              JSON.parse(
                sessionStorage.getItem(
                  `oidc.user:${CONSTANTS.APP_API_SESSION_ACCESS_URL}:${CONSTANTS.APP_API_CLIENT_ID}`,
                ) || '',
              )?.access_token
            }`
          : '',
        'GraphQL-Preflight': 1,
      },
    }));

    return forward(operation);
  });

  const retryLink = new RetryLink({
    attempts: (count, operation, error) => {
      return !!error && operation.operationName !== 'specialCase';
    },
    delay: (count, operation, error) => {
      return count * 1000 * Math.random();
    },
  });

  const errorLink = onError((error: ErrorResponse) => {
    const { graphQLErrors, networkError, operation, forward } = error;
    // if (error?.operation?.operationName === 'GetLegacyUserInfo') {
    //   return;
    // }
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        // authorization: user?.access_token ? `Bearer ${user?.access_token}` : '',
        authorization: sessionStorage.getItem(
          `oidc.user:${CONSTANTS.APP_API_SESSION_ACCESS_URL}:${CONSTANTS.APP_API_CLIENT_ID}`,
        )
          ? `Bearer ${
              JSON.parse(
                sessionStorage.getItem(
                  `oidc.user:${CONSTANTS.APP_API_SESSION_ACCESS_URL}:${CONSTANTS.APP_API_CLIENT_ID}`,
                ) || '',
              )?.access_token
            }`
          : '',
        'GraphQL-Preflight': 1,
      },
    }));
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const { message, locations, path, extensions } = err;
        switch (extensions?.code) {
          // Apollo Server sets code to UNAUTHENTICATED
          // when an AuthenticationError is thrown in a resolver
          case 'AUTH_NOT_AUTHORIZED':
            // Retry the request, returning the new observable
            forward(operation);
            break;
          default:
            apolloErrorsHandler(graphQLErrors, { notification });
        }
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      // const { result } = networkError as ServerError;
      if ((networkError as ServerError)?.result) {
        apolloErrorsHandler(networkError, { notification });
      }
    }
  });

  const client = new ApolloClient({
    link: from([errorLink, concat(authMiddleware, httpLink)]),
    name: 'PublicationsManagementUI',
    cache: new InMemoryCache(),
    connectToDevTools: process.env.NODE_ENV === 'development',
    queryDeduplication: true,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
