import { App, ModalFuncProps } from 'antd';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { FlexListTemplate } from '../../../../../components';
import {
  MoveFolderInput,
  ManagerFolderTreeListItemFragment,
  useMoveFolderMutation,
} from '../../../../../services/apolloGraphqlAppApiClient/models/types';
import { useFoldersTreeStoreContext } from '../FoldersTreeStoreProvider';
import { ManagerFolderTreeNodeType } from '../useFoldersTreeStore';

export interface IUseMoveFolderActionProps {
  folderData: ManagerFolderTreeListItemFragment;
}

export interface IUseMoveFolderActionResult {
  moveFolderAction: (...args: [MoveFolderInput, IMoveFolderActionOptions]) => void;
}

export type IMoveFolderActionOptions = {
  dragTitle: ManagerFolderTreeNodeType['title'];
  dropTitle: ManagerFolderTreeNodeType['title'];
  position: 'before' | 'after' | 'on';
};

export const useMoveFolderAction = (): IUseMoveFolderActionResult => {
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();
  const { refetchFolders } = useFoldersTreeStoreContext();
  const infoKey = uuidV4();

  const [moveFolder] = useMoveFolderMutation({
    onCompleted: (result) => {
      refetchFolders();
      info({
        placement: 'topRight',
        message: `Move Folder status: true`,
        description: (
          <FlexListTemplate gap={5} alignItems="left" justifyContent="left" style={{ flexDirection: 'column' }}>
            {/* info */}
          </FlexListTemplate>
        ),
        key: infoKey,
        duration: 7,
      });
    },
    onError: (e) => {
      return e;
    },
  });

  const handleOk: ModalFuncProps['onOk'] = async (values: MoveFolderInput) => {
    await moveFolder({
      variables: {
        input: { ...values },
      },
    });
  };

  const moveFolderAction: IUseMoveFolderActionResult['moveFolderAction'] = (values, options) => {
    const { dragTitle, dropTitle, position } = options;
    confirm({
      title: `Move ${dragTitle} Folder!`,
      onOk: () => handleOk(values),
      okText: 'Move Folder',
      content: `Are you sure you want to move "${dragTitle}" ${position} "${dropTitle}"`,
    });
  };

  return {
    moveFolderAction,
  };
};
