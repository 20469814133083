import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TablePaginationConfig } from 'antd';
import { IUseHistorySearchPropsResult, useDidMountEffect, useHistorySearchProps } from './index';
import { CONSTANTS } from '../config';

export interface IOrderOptions {
  asc: string;
  desc: string;
}

// export const getSortOrder = (
//   field: string,
//   sort: any,
//   orderOptions: IOrderOptions,
// ): IOrderOptions['asc'] | IOrderOptions['desc'] | undefined => {
//   const FIELD = field
//     .split(/(?=[A-Z])/)
//     .join('_')
//     .toUpperCase();
//   const newOrder = sort?.find((item) => item.field.toUpperCase() === FIELD)?.order;
//   if (newOrder === SortEnumType.Desc) {
//     return orderOptions.desc;
//   }
//   if (newOrder === SortEnumType.Asc) {
//     return orderOptions.asc;
//   }
//   return undefined;
// };
//
// export const getSortOrderBy4OpenApi = (sort, ascOrderName: string) => {
//   return sort
//     ?.map((item) => {
//       if (item?.order) {
//         return item.order === ascOrderName ? item.field : `-${item.field}`;
//       }
//       return null;
//     })
//     .filter((item) => item !== null || true);
// };

export type TBasePageSearchPagination = {
  skip?: number;
  take?: number;
};

export type TPageSearchFilter<F> = F | undefined;
export type TPageSearchSort<S> = S | undefined;
export type TPageSearchPagination<P> = P | undefined;

export type TPageHistorySearchProps<
  F extends Record<string, any> | undefined = Record<string, any>,
  S extends Record<string, any> | Record<string, any>[] | undefined = Record<string, any>[],
  P extends Record<string, any> | undefined = TBasePageSearchPagination,
> = {
  filter?: TPageSearchFilter<F>;
  sort?: TPageSearchSort<S>;
  pagination?: TPageSearchPagination<P>;
};

export interface IUsePageSearchPropsResult<
  F extends Record<string, any> | undefined = Record<string, any>,
  S extends Record<string, any> | Record<string, any>[] | undefined = Record<string, any>[],
  P extends Record<string, any> | undefined = TBasePageSearchPagination,
> extends IUseHistorySearchPropsResult<TPageHistorySearchProps<F, S, P>> {
  handlePageSearchPropsChange: (
    historySearchProps: TPageHistorySearchProps<F, S, P>,
    settings?: { withPagination?: boolean; cleanFilter?: boolean; cleanSort?: boolean; cleanPagination?: boolean },
  ) => void;
  antdPagination: TablePaginationConfig;
  setAntdPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>;
}

export const usePageSearchProps = <
  F extends Record<string, any> | undefined = Record<string, any>,
  S extends Record<string, any> | Record<string, any>[] | undefined = Record<string, any>[],
  P extends Record<string, any> | undefined = TBasePageSearchPagination,
>(
  initPagination = true,
): IUsePageSearchPropsResult<F, S, P> => {
  const { historySearchProps, setHistorySearchProps, ...rest } =
    useHistorySearchProps<TPageHistorySearchProps<F, S, P>>();
  const [antdPagination, setAntdPagination] = useState<TablePaginationConfig>({
    current:
      historySearchProps?.pagination?.skip && historySearchProps?.pagination?.take
        ? Math.round(historySearchProps.pagination.skip / historySearchProps.pagination.take + 1)
        : 1,
    pageSize: historySearchProps?.pagination?.take || CONSTANTS.APP_DEFAULT_LIST_SIZE,
  });

  const handlePageSearchPropsChange: IUsePageSearchPropsResult<F, S, P>['handlePageSearchPropsChange'] = (
    historySearchProps,
    settings,
  ) => {
    const { filter: newFilter, sort: newSort, pagination: newPagination } = historySearchProps;
    const withPagination = settings?.withPagination === undefined ? initPagination : settings.withPagination;
    const cleanPagination = settings?.cleanPagination === undefined ? false : settings.cleanPagination;
    const cleanFilter = settings?.cleanFilter === undefined ? false : settings.cleanFilter;
    const cleanSort = settings?.cleanSort === undefined ? false : settings.cleanSort;
    if (newFilter || newSort || newPagination) {
      // @ts-ignore
      setHistorySearchProps((currentData) => {
        const initialData = { ...currentData };
        return {
          ...initialData,
          ...(cleanFilter
            ? { filter: undefined }
            : newFilter
            ? {
                filter: {
                  ...initialData?.filter,
                  ...newFilter,
                },
              }
            : {}),
          ...(!newPagination && newFilter && withPagination
            ? {
                pagination: {
                  ...initialData?.pagination,
                  skip: 0,
                  // take: initialData?.pagination?.pageSize,
                },
              }
            : {}),
          ...(newPagination
            ? {
                pagination: {
                  ...initialData?.pagination,
                  skip: newPagination.skip,
                  take: newPagination.take,
                },
              }
            : {}),
          ...(cleanSort
            ? { sort: undefined }
            : newSort
            ? {
                sort: {
                  ...initialData?.sort,
                  ...newSort,
                },
              }
            : {}),
        };
      });
    }
  };

  useEffect(() => {
    if (initPagination) {
      setHistorySearchProps((currentData) => ({
        ...currentData,
        pagination: {
          take: historySearchProps?.pagination?.take || CONSTANTS.APP_DEFAULT_LIST_SIZE,
          skip: historySearchProps?.pagination?.skip || 0,
        } as unknown as P,
      }));
    }
  }, []);

  useDidMountEffect(() => {
    setAntdPagination({
      current:
        historySearchProps?.pagination?.skip && historySearchProps?.pagination?.take
          ? Math.round(historySearchProps.pagination.skip / historySearchProps.pagination.take + 1)
          : 1,
      pageSize: historySearchProps?.pagination?.take || CONSTANTS.APP_DEFAULT_LIST_SIZE,
      ...historySearchProps?.pagination,
    });
  }, [historySearchProps.pagination]);

  return useMemo(
    () => ({
      handlePageSearchPropsChange,
      historySearchProps,
      setHistorySearchProps,
      antdPagination,
      setAntdPagination,
      ...rest,
    }),
    [antdPagination, historySearchProps, rest.historyParams],
  );
};
