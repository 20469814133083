import { App, Button, ModalFuncProps } from 'antd';
import React from 'react';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';
import {
  ManagerPublicationCoverTypeListItemFragment,
  useDeletePublicationCoverTypeMutation,
} from '../../../../services/apolloGraphqlAppApiClient/models/types';
import { usePublicationCoverTypesManagerStore } from '../../PublicationCoverTypesManagerStoreProvider';

export const DeletePublicationCoverTypeActionButton = (record: ManagerPublicationCoverTypeListItemFragment) => {
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();

  const { refetchCoverTypes } = usePublicationCoverTypesManagerStore();
  const infoKey = uuidV4();

  const [deletePublicationCoverType] = useDeletePublicationCoverTypeMutation({
    onCompleted: (result) => {
      refetchCoverTypes();
      info({
        placement: 'topRight',
        message: `Delete Cover Type ${result.deleteCoverType.coverTypeDto?.code} status: true`,
        key: infoKey,
        duration: 7,
      });
    },
    onError: (e) => {
      return e;
    },
  });

  const handleOk: ModalFuncProps['onOk'] = () => {
    deletePublicationCoverType({
      variables: {
        input: { code: record.code },
      },
    });
  };

  return (
    <Button
      icon={<DeleteOutlined />}
      danger
      disabled={record.coverCount > 0}
      onClick={() => {
        confirm({
          title: `Are you sure to delete cover type?`,
          content: `${record?.code} will bee deleted`,
          icon: <ExclamationCircleOutlined />,
          okText: 'Delete',
          onOk: handleOk,
          closable: true,
        });
      }}
    />
  );
};
