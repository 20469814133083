import { App, Form, Input, UploadProps } from 'antd';
import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';
import { FlexListTemplate, IModalFormTemplateProps, ModalFormTemplate } from '../../../components';
import { usePublicationEpubListManagerStore } from '../PublicationEpubListManagerStoreProvider';
import {
  ExportEpubInput,
  SortEnumType,
  useGenerateNewPublicationEpubMutation,
} from '../../../services/apolloGraphqlAppApiClient/models/types';

export const GenerateNewPublicationEpubActionButton = () => {
  const {
    notification: { info },
  } = App.useApp();
  const { historyParams, getPublicationEpubListLazy } = usePublicationEpubListManagerStore();

  const infoKey = uuidV4();
  const [GenerateNewPublicationEpub, { loading }] = useGenerateNewPublicationEpubMutation({
    onCompleted: (result) => {
      getPublicationEpubListLazy({
        variables: {
          where: {
            type: { eq: historyParams?.typeId },
            publicationId: { eq: historyParams?.publicationId },
          },
          order: { createdAt: SortEnumType.Desc },
        },
      });
      info({
        placement: 'topRight',
        message: `Generate new epub status: true`,
        key: infoKey,
        duration: 7,
      });
    },
    onError: (e) => {
      return e;
    },
  });

  const handleFinish: IModalFormTemplateProps<ExportEpubInput>['onFinish'] = async (values, form) => {
    const r = await GenerateNewPublicationEpub({
      variables: {
        input: {
          publicationId: historyParams?.publicationId as unknown as number,
        },
      },
    });
    if (r.errors) {
      return Promise.reject(r.errors);
    }
    // form.resetFields();
    return r;
  };

  const dummyRequest: UploadProps['customRequest'] = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess && onSuccess('ok');
    }, 0);
  };

  return (
    <ModalFormTemplate<ExportEpubInput>
      title="Generate new epub"
      okText="Generate"
      onFinish={handleFinish}
      triggerProps={{
        icon: <UploadOutlined />,
        // type: 'primary',
        children: 'Generate New',
        className: 'tabs-extra-button',
      }}
      formProps={{
        initialValues: { publicationId: historyParams?.publicationId },
        layout: 'vertical',
        name: `generateNewPublicationEpubForm-${historyParams?.typeId}`,
      }}
      confirmLoading={loading}
    >
      <FlexListTemplate justifyContent="space-between" alignItems="start">
        <Form.Item name="publicationId" label="PublicationId" hidden>
          <Input disabled />
        </Form.Item>
      </FlexListTemplate>
    </ModalFormTemplate>
  );
};
