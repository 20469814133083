import { useActive, useAttrs, useCommands, useCurrentSelection, useExtensionEvent } from '@remirror/react';
import { useHelpers } from '@remirror/react-core';
import { useCallback, useMemo, useState } from 'react';

import { WSentExtension, WSentViewClickHandlerProps } from '../WSentExtension';

function useWSentClick() {
  const [wSentClickHandlerProps, setWSentClickHandlerProps] = useState<WSentViewClickHandlerProps | undefined>();
  const [isEditing, setIsEditing] = useState(false);

  useExtensionEvent(
    WSentExtension,
    'onViewClick',
    useCallback(
      (props) => {
        if (!isEditing && props?.markWithPosition) {
          setIsEditing(true);
        }

        setWSentClickHandlerProps(props);
      },
      [isEditing],
    ),
  );

  return { isEditing, wSentClickHandlerProps, setIsEditing };
}

export function useWSentState() {
  const { toggleWSent, removeWSent, selectWSent, focus } = useCommands();
  const { isEditing, wSentClickHandlerProps, setIsEditing } = useWSentClick();
  const { to, empty } = useCurrentSelection();
  const { isSelectionEmpty } = useHelpers();

  const attrs = useAttrs<WSentExtension>().wSent();

  const active = useActive<WSentExtension>().wSent();
  const enabled = wSentClickHandlerProps?.markWithPosition ? true : !isSelectionEmpty() && toggleWSent.enabled();

  // console.log({ wSent: wSentClickHandlerProps?.markWithPosition, enabled });
  const handleRemove = useCallback(() => {
    removeWSent();
    focus();
  }, [removeWSent]);

  // const updateReason = useUpdateReason();

  // useLayoutEffect(() => {
  //   if (!isEditing) {
  //     return;
  //   }
  //
  //   // if (updateReason.doc || updateReason.selection) {
  //   //   setIsEditing(false);
  //   // }
  // }, [isEditing, setIsEditing, updateReason.doc, updateReason.selection]);

  const handleSubmit = useCallback(() => {
    setIsEditing(false);
    const range = wSentClickHandlerProps?.markWithPosition;
    if (range) {
      handleClickEdit();
      handleRemove();
      return;
    }
    toggleWSent();
    focus();
  }, [setIsEditing, wSentClickHandlerProps, toggleWSent, attrs]);

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    focus();
  }, [setIsEditing]);

  const handleClickEdit = useCallback(() => {
    if (empty) {
      selectWSent();
    }
    setIsEditing(true);
  }, [selectWSent, empty, setIsEditing]);

  return useMemo(
    () => ({
      attrs,
      wSentClickHandlerProps,
      isEditing,
      handleRemove,
      handleSubmit,
      handleCancel,
      active,
      enabled,
      handleClickEdit,
    }),
    [
      attrs,
      active,
      enabled,
      wSentClickHandlerProps,
      isEditing,
      handleRemove,
      handleSubmit,
      handleCancel,
      handleClickEdit,
    ],
  );
}
