import { useActive, useAttrs, useCommands, useCurrentSelection, useExtensionEvent } from '@remirror/react';
import { useHelpers } from '@remirror/react-core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isNodeSelection } from 'remirror';

import { WPageAttributes, WPageExtension, WPageViewClickHandlerProps } from '../WPageExtension';

function useWPageViewClick() {
  const [wPageViewClickHandlerProps, setWPageViewClickHandlerProps] = useState<
    WPageViewClickHandlerProps | undefined
  >();
  const [isEditing, setIsEditing] = useState(false);

  useExtensionEvent(
    WPageExtension,
    'onViewClick',
    useCallback(
      (props: WPageViewClickHandlerProps) => {
        if (!isEditing && props?.nodeWithPosition) {
          setIsEditing(true);
        }
        setWPageViewClickHandlerProps(props);
      },
      [isEditing],
    ),
  );

  return { isEditing, wPageViewClickHandlerProps, setIsEditing };
}

export function useWPageState() {
  const { toggleWPage, removeWPage, focus } = useCommands<WPageExtension>();
  const { isEditing, wPageViewClickHandlerProps, setIsEditing } = useWPageViewClick();
  const { isSelectionEmpty } = useHelpers();
  const selection = useCurrentSelection();
  const { to, empty } = selection;

  const attrs = useAttrs<WPageExtension>().wPage();

  const active = useActive<WPageExtension>().wPage();
  const enabled =
    (isNodeSelection(selection) && selection?.node?.type?.name === 'wPage') ||
    (isSelectionEmpty() && toggleWPage.enabled());

  const handleRemove = useCallback(() => {
    setIsEditing(false);
    removeWPage();
    focus();
  }, [removeWPage]);

  // const updateReason = useUpdateReason();

  // useLayoutEffect(() => {
  //   if (!isEditing) {
  //     return;
  //   }
  //
  //   // if (updateReason.doc || updateReason.selection) {
  //   //   setIsEditing(false);
  //   // }
  // }, [isEditing, setIsEditing, updateReason.doc, updateReason.selection]);

  const handleSubmit = useCallback(
    (values) => {
      setIsEditing(false);
      if (values?.number) {
        toggleWPage({ ...values });
      }
      focus();
    },
    [setIsEditing, wPageViewClickHandlerProps, toggleWPage, attrs],
  );

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    focus();
  }, [setIsEditing]);

  // useEffect(() => {
  //   if (isEditing) {
  //     selectWPage();
  //   }
  // }, [isEditing]);

  const handleClickEdit = useCallback(() => {
    // if (empty) {
    //   selectWPage();
    // }
    setIsEditing(true);
  }, [empty, setIsEditing]);

  return useMemo(
    () => ({
      attrs,
      wPageViewClickHandlerProps,
      isEditing,
      handleRemove,
      handleSubmit,
      handleCancel,
      handleClickEdit,
      active,
      enabled,
    }),
    [
      attrs,
      active,
      enabled,
      wPageViewClickHandlerProps,
      handleClickEdit,
      isEditing,
      handleRemove,
      handleSubmit,
      handleCancel,
    ],
  );
}
