import React, { createContext, useContext, useMemo } from 'react';
import { App, ConfigProvider as AntConfigProvider } from 'antd';
import { getThemes } from './models/Themes';
import { useTheme } from './hooks';
import { TMenu } from '../config';
import { IThemes } from './models/_interfaces';
import '../assets/styles/index.scss';

export interface IAppThemeContextProps {
  theme: string;
  setTheme: React.Dispatch<React.SetStateAction<string>>;
  isSystemDarkModeTheme: boolean;
  menuItems: TMenu[];
  themes: IThemes;
}

export const AppThemeContext = createContext<IAppThemeContextProps | undefined>(undefined);
AppThemeContext.displayName = 'AppThemeContext';

export const useAppThemeContext = (): IAppThemeContextProps => {
  const context = useContext(AppThemeContext);
  if (context === undefined) {
    throw new Error('useAppThemeContext must be used within AppThemeProvider');
  }
  return context;
};

export const AppThemeProvider = ({ children }) => {
  const { theme, setTheme, isSystemDarkModeTheme } = useTheme();
  const themes = getThemes(isSystemDarkModeTheme);

  const menuItems: TMenu[] = [
    {
      key: 'themeLabel',
      label: `${themes[theme].label} Theme`,
      icon: themes[theme].icon,
      children: Object.keys(themes).map((key, index) => {
        return {
          label: themes[key].label,
          key: themes[key].id,
          icon: themes[key].icon,
          onClick: ({ item, key, keyPath, domEvent }) => {
            setTheme(key);
          },
        };
      }),
    },
  ];

  // const theme = lazy(() => import(`./${themes.find((t) => t.id === theme)?.component || 'LightTheme'}`));

  const AppThemeContextObject: IAppThemeContextProps = useMemo(
    () => ({
      theme,
      setTheme,
      isSystemDarkModeTheme,
      menuItems,
      themes,
    }),
    [theme, setTheme, isSystemDarkModeTheme, menuItems, themes],
  );

  return (
    // <Suspense fallback={<span />}>
    <AppThemeContext.Provider value={AppThemeContextObject}>
      <AntConfigProvider componentSize="small" theme={getThemes(isSystemDarkModeTheme)[theme].antdTheme}>
        <App>{children}</App>
      </AntConfigProvider>
    </AppThemeContext.Provider>
    // </Suspense>
  );
};
