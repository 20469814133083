import React, { FC, useCallback } from 'react';
import { useAttrs } from '@remirror/react';
import { useActive, useCommands } from '@remirror/react-core';
import { ProsemirrorAttributes } from '@remirror/core';
import { CommandPopoverButton, ICommandPopoverButtonProps } from '../../../components';
import { ToggleWParaForm, WParaAttributes, WParaExtension } from '../../index';

export type IToggleWParaButtonProps = Omit<
  ICommandPopoverButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'popoverContent' | 'onSelect'
>;

export const ToggleWParaButton: FC<IToggleWParaButtonProps> = (props) => {
  const { toggleWPara } = useCommands<WParaExtension>();
  const attrs = useAttrs<WParaExtension>(true).wPara() as WParaAttributes;

  const active = useActive<WParaExtension>().wPara();
  const enabled = toggleWPara.enabled();

  const handleSubmit = useCallback(
    (values) => {
      if (toggleWPara.enabled()) {
        toggleWPara(values);
      }
    },
    [toggleWPara, active],
  );

  const handleRemove = useCallback(() => {
    if (toggleWPara.enabled()) {
      toggleWPara();
    }
  }, [toggleWPara]);

  return (
    <CommandPopoverButton
      {...props}
      commandName="toggleWPara"
      active={active}
      enabled={enabled}
      popoverContent={
        <ToggleWParaForm
          onRemove={active ? handleRemove : undefined}
          initialValues={
            attrs
              ? {
                  ...(attrs?.skip ? { skip: attrs.skip } : {}),
                  ...(attrs?.role ? { role: attrs.role } : {}),
                  ...(attrs?.indent ? { indent: attrs.indent } : {}),
                  ...(attrs?.align ? { align: attrs.align } : {}),
                }
              : {}
          }
          onSubmit={handleSubmit}
        />
      }
    />
  );
};
