import React from 'react';
import { PageTemplate } from '../../components';
import { PublicationCoverTypesManagerStoreProvider } from './PublicationCoverTypesManagerStoreProvider';
import { PublicationCoverTypesList } from './Components';

export const PublicationCoverTypesManagerPageComponent = (props) => {
  return (
    <PageTemplate title="Publications Cover Types Manager">
      <PublicationCoverTypesList />
    </PageTemplate>
  );
};

export const PublicationCoverTypesManagerPage = (props) => {
  return (
    <PublicationCoverTypesManagerStoreProvider>
      <PublicationCoverTypesManagerPageComponent />
    </PublicationCoverTypesManagerStoreProvider>
  );
};
