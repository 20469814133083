import React from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { FileImageOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { Button } from 'antd';
import { ManagerPublicationListItemFragment } from '../../../../../services/apolloGraphqlAppApiClient/models/types';

export interface IUseManagePublicationCoversActionProps {
  publicationData: ManagerPublicationListItemFragment;
}

export interface IUseManagePublicationCoversActionResult {
  managePublicationCoversAction: ItemType;
}

export const useManagePublicationCoversAction = (
  props: IUseManagePublicationCoversActionProps,
): IUseManagePublicationCoversActionResult => {
  const { publicationData } = props;

  const menuItemKey = `useManagePublicationCoversAction-${publicationData.publicationId}`;

  const visible = true;

  return {
    managePublicationCoversAction: visible
      ? {
          key: menuItemKey,
          style: {
            padding: 0,
          },
          label: (
            <NavLink to={`/publications/${publicationData.publicationId}/covers`}>
              <Button
                type="text"
                icon={<FileImageOutlined style={{ fontSize: 12 }} />}
                style={{ padding: '5px 12px', borderRadius: 4, height: '100%' }}
              >
                Manage covers
              </Button>
            </NavLink>
          ),
          onClick: (menuInfo) => {
            // menuInfo.domEvent.preventDefault();
            menuInfo.domEvent.stopPropagation();
          },
        }
      : null,
  };
};
