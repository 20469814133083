import { Stack, StackProps } from '@mui/material';
import React, { createContext, FC, ReactNode, useContext, useMemo } from 'react';

import { useDebounce } from '../../hooks';

export type IToolbarProps = StackProps;

export const Toolbar: FC<IToolbarProps> = (props) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={
        {
          // backgroundColor: 'background',
          // overflowX: 'auto'
        }
      }
      {...props}
    />
  );
};

export interface IEditorToolbarContextProps<U = null> {
  size?: 'large' | 'middle' | 'small';
}

export const EditorToolbarContext = createContext<IEditorToolbarContextProps | undefined>(undefined);
EditorToolbarContext.displayName = 'EditorToolbarContext';

export const useEditorToolbarContext = () => {
  const context = useContext(EditorToolbarContext);
  if (context === undefined) {
    throw new Error('useEditorToolbarContext must be used within EditorToolbar');
  }
  return context;
};

export interface IEditorToolbarProps extends IToolbarProps {
  children?: ReactNode | ReactNode[];
  size?: 'large' | 'middle' | 'small';
}

export const EditorToolbar: React.FC<IEditorToolbarProps> = (props) => {
  const { children, ...rest } = props;
  const toolbarContextObject: IEditorToolbarContextProps = useMemo(
    () => ({
      size: rest.size,
    }),
    [rest.size],
  );

  return (
    <EditorToolbarContext.Provider value={toolbarContextObject}>
      <Toolbar {...rest}>{children}</Toolbar>
    </EditorToolbarContext.Provider>
  );
};

EditorToolbar.displayName = 'EditorToolbar';

function areEqual(prevProps, nextProps) {
  /*
              return true if passing nextProps to render would return
              the same result as passing prevProps to render,
              otherwise return false
              */

  if (prevProps === nextProps) {
    console.log({ prevProps, nextProps });
    return false;
  }
  return false;
}

export const ControlledEditorToolbar = React.memo(EditorToolbar);
