import { NodeWithPosition } from '@remirror/core';
import { TextSelection } from '@remirror/pm/state';
import { NodeViewComponentProps, useExtensionEvent } from '@remirror/react';
import React, { ComponentType, useCallback, useEffect, useState } from 'react';

import { EditorSubField, useWNoteState, WNoteExtension } from '../index';

import '../assets/styles/view_w-note-body.scss';

export const WNoteBodyView: ComponentType<NodeViewComponentProps> = (props) => {
  const { node, forwardRef, view, selected } = props;
  // const { wNoteClickProps, isEditing } = useWNoteState();

  const [viewNodeWithPosition, setViewNodeWithPosition] = useState<NodeWithPosition | undefined>(undefined);

  useExtensionEvent(
    WNoteExtension,
    'onViewClick',
    useCallback(
      (props) => {
        const lastChild = props?.nodeWithPosition?.node?.lastChild;
        if (viewNodeWithPosition || props?.nodeWithPosition?.node?.lastChild !== node) {
          setViewNodeWithPosition(undefined);
          return;
        }
        setViewNodeWithPosition(props.nodeWithPosition);
      },
      [viewNodeWithPosition, node, props],
    ),
  );

  useEffect(() => {
    if (viewNodeWithPosition?.node.nodeSize) {
      const tr = view.state.tr
        .setSelection(
          TextSelection.create(
            view.state.tr.doc,
            // @ts-ignore
            viewNodeWithPosition.pos + viewNodeWithPosition.node.nodeSize - 3,
          ),
        )
        .scrollIntoView();
      view?.dispatch?.(tr);
    }
  }, [viewNodeWithPosition]);

  return (
    <EditorSubField style={{ display: viewNodeWithPosition ? 'block' : 'none' }} label="Body Content">
      <div ref={forwardRef} />
    </EditorSubField>
  );
};
