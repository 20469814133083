import { useEffect, useMemo, useState } from 'react';
import { useDidMountEffect, useMobileDetect, useWindowDimensions } from '../../hooks';
import { useSiderSwipeEffect } from './useSiderSwipeEffect';

export interface IUseAppLayoutProps {
  withSwipeEffect?: boolean;
  minCollapseWidth?: number;
  minSiderWidth?: number;
  maxSiderWidth?: number;
  headerHeight?: number;
  footerHeight?: number;
}

export interface IUseAppLayoutResult {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  manualCollapsed: boolean;
  setManualCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  margin: { marginLeft?: number };
  setMargin: React.Dispatch<React.SetStateAction<{ marginLeft?: number | undefined }>>;
  minSiderWidth: number;
  maxSiderWidth: number;
  headerHeight: number;
  footerHeight?: number;
  minPageContentHeight: number;
  setMinPageContentHeight: React.Dispatch<React.SetStateAction<number>>;
}

export const useAppLayout = (props: IUseAppLayoutProps): IUseAppLayoutResult => {
  const { isMobile, isDesktop } = useMobileDetect();
  const {
    withSwipeEffect = true,
    minCollapseWidth = 480,
    minSiderWidth = isMobile() ? 0 : 50,
    maxSiderWidth = isMobile() ? window.innerWidth : 200,
    headerHeight = 50,
    footerHeight,
  } = props;
  const { width } = useWindowDimensions();
  const { isSiderSwiped } = useSiderSwipeEffect({
    minSwipeStartWidth: minSiderWidth,
    maxSwipeStartWidth: maxSiderWidth,
  });
  const [collapsed, setCollapsed] = useState(false);
  const [manualCollapsed, setManualCollapsed] = useState(false);
  const [margin, setMargin] = useState<{ marginLeft?: number }>({});
  const [minPageContentHeight, setMinPageContentHeight] = useState<number>(0);

  const widthChangeHandler = () => {
    if (!manualCollapsed) {
      if (width > minCollapseWidth) {
        return setCollapsed(false);
      }
      if (width <= minCollapseWidth) {
        setCollapsed(true);
      }
    }
  };

  const manualCollapsedChangeHandler = () => {
    setCollapsed(manualCollapsed);
  };

  useDidMountEffect(() => {
    manualCollapsedChangeHandler();
  }, [manualCollapsed]);

  useDidMountEffect(() => {
    widthChangeHandler();
  }, [width]);

  useDidMountEffect(() => {
    if (withSwipeEffect) {
      setManualCollapsed(!isSiderSwiped);
    }
  }, [isSiderSwiped]);

  useEffect(() => {
    if (isDesktop()) {
      if (collapsed) {
        setMargin({ marginLeft: minSiderWidth });
      }
      if (!collapsed) {
        setMargin({ marginLeft: maxSiderWidth });
      }
      return;
    }
    if (isMobile()) {
      setMargin({});
    }
  }, [collapsed, isMobile, isDesktop]);

  return useMemo(
    () => ({
      collapsed,
      setCollapsed,
      manualCollapsed,
      setManualCollapsed,
      margin,
      setMargin,
      maxSiderWidth,
      minSiderWidth,
      headerHeight,
      footerHeight,
      minPageContentHeight,
      setMinPageContentHeight,
    }),
    [
      collapsed,
      manualCollapsed,
      margin,
      maxSiderWidth,
      minSiderWidth,
      headerHeight,
      footerHeight,
      minPageContentHeight,
    ],
  );
};
