import { App, Form, Input, ModalFuncProps, Select } from 'antd';
import React, { useRef } from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { EditOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';
import { FlexListTemplate } from '../../../../../components';
import {
  ManagerPublicationListItemFragment,
  PublicationPermissionEnum,
  SetPublicationPermissionInput,
  useSetPublicationPermissionMutation,
} from '../../../../../services/apolloGraphqlAppApiClient/models/types';
import { usePublicationListStoreContext } from '../PublicationListStoreProvider';

export interface IUseSetPublicationPermissionActionProps {
  publicationData: ManagerPublicationListItemFragment;
}

export interface IUseSetPublicationPermissionActionResult {
  setPublicationPermissionAction: ItemType;
}

export const useSetPublicationPermissionAction = (
  props: IUseSetPublicationPermissionActionProps,
): IUseSetPublicationPermissionActionResult => {
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();
  const { publicationData } = props;
  const { refetch } = usePublicationListStoreContext();

  const menuItemKey = `SetPublicationPermissionAction-${publicationData.publicationId}`;
  const infoKey = uuidV4();
  const modalRef = useRef<ReturnType<typeof confirm>>();

  const visible = true;

  const [setPublicationPermissionForm] = Form.useForm<SetPublicationPermissionInput>();

  const [setPublicationPermissionAction] = useSetPublicationPermissionMutation({
    onCompleted: (result) => {
      // refetch();
      info({
        placement: 'topRight',
        message: `SetPublicationPermission status: true`,
        description: (
          <FlexListTemplate gap={5} alignItems="left" justifyContent="left" style={{ flexDirection: 'column' }}>
            {/* info */}
          </FlexListTemplate>
        ),
        key: infoKey,
        duration: 7,
      });
      refetch(
        result.setPublicationPermission.publicationDto?.order
          ? result.setPublicationPermission.publicationDto.order - 1
          : undefined,
      );
    },
    onError: (e) => {
      return e;
    },
  });

  const handleOk: ModalFuncProps['onOk'] = () => {
    setPublicationPermissionForm
      .validateFields()
      .then((values) => {
        return setPublicationPermissionAction({
          variables: {
            input: { ...values },
          },
        });
      })
      .then((res) => {
        // setPublicationPermissionForm.resetFields();
        if (modalRef.current) {
          modalRef.current.destroy();
        }
      })
      .catch((e) => {
        return e;
      });
  };

  const handleCancel = () => {
    setPublicationPermissionForm.resetFields();
    if (modalRef.current) {
      modalRef.current.destroy();
    }
  };

  return {
    setPublicationPermissionAction: visible
      ? {
          key: menuItemKey,
          icon: <EditOutlined />,
          label: 'Set Permission',
          onClick: (menuInfo) => {
            menuInfo.domEvent.preventDefault();
            menuInfo.domEvent.stopPropagation();
            modalRef.current = confirm({
              title: `Set Permission for "${publicationData.title}" Publication!`,
              onOk: handleOk,
              onCancel: handleCancel,
              okText: 'Set Permission',
              content: (
                <Form
                  initialValues={{
                    publicationId: publicationData.publicationId,
                    permission: publicationData.permission,
                  }}
                  layout="vertical"
                  form={setPublicationPermissionForm}
                  name="setPublicationPermissionForm"
                >
                  <Form.Item name="publicationId" label="Id" hidden>
                    <Input type="textarea" />
                  </Form.Item>
                  <Form.Item
                    name="permission"
                    label="Permission"
                    valuePropName="value"
                    rules={[
                      {
                        required: true,
                        message: `Please select language!`,
                      },
                    ]}
                  >
                    <Select
                      options={Object.values(PublicationPermissionEnum).map((k) => {
                        return { value: k, label: k };
                      })}
                    />
                  </Form.Item>
                </Form>
              ),
            });
          },
        }
      : null,
  };
};
