import { Box } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';

import { useCommandOptionValues } from '../../hooks';
import { ClickableRichTooltip } from '../tooltips';
import { CommandButton, ICommandButtonProps } from './index';

export interface ICommandPopoverButtonProps extends Omit<ICommandButtonProps, 'onSelect'> {
  popoverContent: React.ReactNode;
  onSelect?: () => null;
}

const PopoverButton: FC<ICommandPopoverButtonProps> = ({ popoverContent, onSelect = () => null, ...rest }) => {
  const commandOptions = useCommandOptionValues({
    commandName: rest?.commandName,
    active: rest?.active || false,
    enabled: rest?.enabled,
    attrs: rest?.attrs,
  });

  const labelText = rest['aria-Label'] ?? commandOptions.label ?? '';
  const tooltipText = commandOptions.description ?? rest?.label ?? labelText;
  const shortcutText = rest?.displayShortcut && commandOptions.shortcut ? ` (${commandOptions.shortcut})` : '';

  return rest?.enabled ? (
    <ClickableRichTooltip content={<>{popoverContent}</>} placement="bottom" title={`${tooltipText}${shortcutText}`}>
      <Box component="div">
        <CommandButton {...rest} onSelect={onSelect} sx={{}} />
      </Box>
    </ClickableRichTooltip>
  ) : (
    <CommandButton {...rest} onSelect={onSelect} sx={{}} />
  );
};

export const CommandPopoverButton = React.memo(PopoverButton);

function areEqual(prevProps, nextProps) {
  /*
                return true if passing nextProps to render would return
                the same result as passing prevProps to render,
                otherwise return false
                */

  if (prevProps === nextProps) {
    return false;
  }
  return true;
}
