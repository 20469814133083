import { useActive, useCommands } from '@remirror/react';
import React, { FC, useCallback } from 'react';
import { CommandButton, ICommandButtonProps } from '../../../components';
import { WFormatSmallCapsExtension } from '../WFormatSmallCapsExtension';
import { WFormatSmallcupsIcon } from './WFormatSmallcupsIcon';

export type IToggleWFormatSmallCapsButtonProps = Omit<
  ICommandButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'
>;

export const ToggleWFormatSmallCapsButton: FC<IToggleWFormatSmallCapsButtonProps> = (props) => {
  const type = 'small-caps';
  const { toggleWFormatSmallCaps: command, focus } = useCommands<WFormatSmallCapsExtension>();
  const active = useActive<WFormatSmallCapsExtension>().wFormatSmallCaps({
    type,
  });
  const enabled = command.enabled({ type });

  const handleClick = useCallback(() => {
    if (enabled) {
      command({ type });
      focus();
    }
  }, [command]);

  return (
    <CommandButton
      {...props}
      commandName="toggleWFormatSmallCaps"
      active={active}
      enabled={enabled}
      onSelect={handleClick}
      icon={<WFormatSmallcupsIcon />}
    />
  );
};
