import { theme as antdTheme, ThemeConfig } from 'antd';
import { IThemes } from './_interfaces';

const antdThemeDark: ThemeConfig = {
  algorithm: [antdTheme.darkAlgorithm],
  // components: {
  //   App: { style: { colorScheme: 'dark' } },
  // },
  // token: {
  //   size: 'small',
  // },
};

const antdThemeLight: ThemeConfig = {
  algorithm: [antdTheme.compactAlgorithm, antdTheme.defaultAlgorithm],
  // token: {
  //   colorPrimary: '#00b96b',
  // },
};

export const getThemes = (isDarkMode?: boolean): IThemes => {
  return {
    system: {
      id: 'system',
      label: 'System',
      icon: isDarkMode ? <span>🌜</span> : <span>🌞</span>,
      antdTheme: isDarkMode ? antdThemeDark : antdThemeLight,
      component: 'SystemTheme',
      isDarkMode,
    },
    light: {
      id: 'light',
      label: 'Light',
      icon: <span>🌞</span>,
      antdTheme: antdThemeLight,
      component: 'LightTheme',
    },
    dark: {
      id: 'dark',
      label: 'Dark',
      icon: <span>🌜</span>,
      antdTheme: antdThemeDark,
      component: 'DarkTheme',
    },
  };
};
