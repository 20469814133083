import React from 'react';
import { Spin, Tree } from 'antd';
import { useResizeDetector } from 'react-resize-detector';
import { createStyles } from 'antd-style';
import { useFoldersTreeStoreContext } from './FoldersTreeStoreProvider';
import { FolderTreeItemTitleRender } from './FolderTreeItemTitleRender';
import { useFolderTreeDndActions } from './FoldersTreeDndActions';
import { usePublicationsManagerStoreContext } from '../Manager';
import { ManagerFolderTreeNodeType } from './useFoldersTreeStore';

export interface IFoldersTreeProps {
  width?: number;
}

const useFolderTreeStyles = createStyles((props) => {
  return {
    folderTree: {
      '.ant-tree-switcher': {
        display: 'none',
      },
      '.ant-tree-node-content-wrapper': {
        // overflow: 'hidden !important',
        display: 'inline-flex',
        flaxWrap: 'nowrap',
      },
      '.ant-tree-title': {
        display: 'inline',
        width: '100%',
        textAlign: 'start',
        textWrap: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '.custom-ant-tree-title--text': {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          alignItems: 'start',
        },
      },
      // '.ant-tree-indent-unit': {
      //   width: 10,
      // },
    },
  };
});

const Component = (props: IFoldersTreeProps) => {
  const { width } = props;
  const { styles } = useFolderTreeStyles();
  const { foldersStore, loadData, loadedKeys, treeData, expandOptions, onExpand } = useFoldersTreeStoreContext();
  const { setParams, historyParams, selectedFolder } = usePublicationsManagerStoreContext();
  const { onDragStart, onDragOver, onDragEnter, onDragEnd, onDrop, allowDrop } = useFolderTreeDndActions();
  const { width: treeWrapperWidth, height: treeWrapperHeight, ref: treeWrapperRef } = useResizeDetector();
  return (
    <div
      ref={treeWrapperRef}
      style={{
        height: '100%',
      }}
    >
      <Spin tip="Loading..." style={{ height: treeWrapperHeight }} spinning={foldersStore.loading}>
        <Tree<ManagerFolderTreeNodeType>
          className={styles.folderTree}
          height={treeWrapperHeight || 400}
          titleRender={FolderTreeItemTitleRender}
          fieldNames={{
            key: 'id',
            title: 'title',
            children: 'children',
          }}
          // showLine={{ showLeafIcon: false }}
          // focusable
          allowDrop={allowDrop}
          switcherIcon={false}
          showIcon={false}
          blockNode
          draggable={{
            icon: false,
            nodeDraggable: (node) => node?.title !== 'Unpublished',
          }}
          onDragStart={onDragStart}
          // // onDragEnter={onDragEnter}
          onDragOver={onDragOver}
          onDragEnd={onDragEnd}
          onDrop={onDrop}
          selectedKeys={[selectedFolder?.id]}
          // defaultSelectedKeys={[0]}
          onSelect={(selectedKeys, info) => {
            if (historyParams?.selectedFolderId !== info.node.key) {
              if (setParams) {
                setParams(`/publications/manager/${info?.node.id}`);
              }
            }
          }}
          expandAction="click"
          expandedKeys={[
            ...(selectedFolder?.id ? [selectedFolder.id] : []),
            ...(expandOptions?.expandedKeys ? expandOptions.expandedKeys : []),
          ]}
          autoExpandParent={expandOptions?.autoExpandParent}
          // autoExpandParent={false}
          onExpand={onExpand}
          // loadData={loadData}
          // loadedKeys={loadedKeys}
          treeData={treeData}
        />
      </Spin>
    </div>
  );
};

export const FoldersTree = (props: IFoldersTreeProps) => {
  return (
    // <FoldersTreeStoreProvider>
    <Component {...props} />
    // </FoldersTreeStoreProvider>
  );
};
