import { TableProps } from 'antd';
import React from 'react';
import { ManagerPublicationCoverTypeListItemFragment } from '../../../services/apolloGraphqlAppApiClient/models/types';
import { PublicationCoverTypesListFiltersForm } from './PublicationCoverTypeListFiltersForm';
import { FlexListTemplate } from '../../../components';
import { CreatePublicationCoverTypeActionButton } from './PublicationCoverTypeActions';

export const PublicationCoverTypeListTitle: TableProps<ManagerPublicationCoverTypeListItemFragment>['title'] = (
  data,
) => {
  return (
    <FlexListTemplate justifyContent="space-between">
      <CreatePublicationCoverTypeActionButton />
      <PublicationCoverTypesListFiltersForm />
    </FlexListTemplate>
  );
};
