import { ApplySchemaAttributes, NodeExtensionSpec, NodeSpecOverride } from 'remirror';
import { ExtensionTag, NodeExtension } from '@remirror/core';

import { createWFigureSchema } from './wFigure-utils';

export class WFigcaptionExtension extends NodeExtension {
  get name() {
    return 'wFigcaption' as const;
  }

  createTags() {
    return [ExtensionTag.Block];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      content: 'wTextBlock{1}',
      group: 'wFigure',
      draggable: true,
      selectable: true,
      marks: '',
      attrs: extra.defaults(),
      ...override,
      parseDOM: [{ tag: 'figcaption' }],
      toDOM: (node) => ['figcaption', 0],
    };
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wFigcaption: WFigcaptionExtension;
    }
  }
}
