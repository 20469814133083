import { Autocomplete, Box, TextField } from '@mui/material';
import React from 'react';
import { CommandForm, ICommandFormProps } from '../../../components';
import { W_TEXT_BLOCK_PROPERTIES, WTextBlockAttributes } from '../WTextBlockExtension';

export interface IToggleWTextBlockNodeFormProps extends Omit<ICommandFormProps, 'children' | 'fields'> {
  initialOption?: string;
  initialValues?: WTextBlockAttributes;
  onRemove?: () => void;
}

export const ToggleWTextBlockForm = (props: IToggleWTextBlockNodeFormProps) => {
  const { ...rest } = props;

  return (
    <CommandForm name="wTextBlock" {...rest}>
      <Autocomplete
        id="type"
        size="small"
        options={W_TEXT_BLOCK_PROPERTIES.typeList}
        autoHighlight
        getOptionLabel={(option: any) => option}
        renderOption={(props, option) => {
          props['data-name'] = 'type';
          return (
            <Box component="li" {...props}>
              {option}
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="type"
            label="Type"
            size="small"
            variant="standard"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
    </CommandForm>
  );
};
