import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Button, Dropdown, Menu, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { MENUS_MAP } from '../../config';
import { useAppServices } from '../../services';

export interface IProfileSectionProps {
  type?: 'dropdown' | 'menu';
}

export const ProfileSection = (props: IProfileSectionProps): React.ReactElement => {
  const { type = 'dropdown' } = props;
  const authService = useAuth();
  const { authProfile } = useAppServices();

  const profileMenu = MENUS_MAP.PROFILE_MENU(authProfile, { authService })[0];
  if (type === 'dropdown') {
    return (
      <Dropdown
        menu={{
          items: profileMenu.children,
        }}
        placement="bottom"
        arrow={{ pointAtCenter: true }}
      >
        <Button
          icon={profileMenu.icon}
          type="text"
          style={{
            display: 'inline-flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'nowrap',
            height: 'auto',
          }}
        >
          <Space wrap={false} align="center">
            {profileMenu.label}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  }
  return <Menu items={MENUS_MAP.PROFILE_MENU(authProfile, { logoSize: 18, authService })} theme="dark" mode="inline" />;
};
