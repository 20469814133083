import { Button, List, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import {
  ExportTypeEnum,
  ManagerPublicationEpubListItemFragment,
} from '../../../services/apolloGraphqlAppApiClient/models/types';
import { usePublicationEpubListStyles } from './usePublicationEpubListStyles';
import { PublicationEpubListItem } from './PublicationEpubListItem';
import { EpubReader } from '../Components';
import { usePublicationEpubListManagerStore } from '../PublicationEpubListManagerStoreProvider';

export interface IPublicationEpubListProps {
  typeId?: ExportTypeEnum;
  publicationId?: number;
}

const defaultEpubPreviewState = {
  open: false,
  url: '',
};

export const PublicationEpubList: React.FC<IPublicationEpubListProps> = (props) => {
  const { styles } = usePublicationEpubListStyles();
  const { typeId, publicationId } = props;
  const [epubPreviewState, setEpubPreviewState] = useState<{
    open: boolean;
    url: ManagerPublicationEpubListItemFragment['uri'];
  }>(defaultEpubPreviewState);
  const { publicationEpubListManagerStore } = usePublicationEpubListManagerStore();

  return (
    <>
      <List<ManagerPublicationEpubListItemFragment>
        loading={publicationEpubListManagerStore.loading}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
          xxl: 5,
        }}
        className={styles.list}
        style={{
          height: '100%',
        }}
        dataSource={publicationEpubListManagerStore.data?.exportedFiles?.items || []}
        renderItem={(listItem) => (
          <PublicationEpubListItem listItem={listItem} setEpubPreviewState={setEpubPreviewState} />
        )}
      />
      <Modal
        open={epubPreviewState.open}
        className={styles.modalWrapper}
        onCancel={() => {
          setEpubPreviewState(defaultEpubPreviewState);
        }}
        width="100%"
        style={{
          top: 5,
          bottom: 5,
          width: '100%',
          height: 'calc(100% - 10px)',
          paddingBottom: 0,
        }}
        styles={{
          content: {
            padding: 0,
            // background: 'none',
            height: '100%',
          },
          body: {
            height: '100%',
          },
          footer: {
            display: 'none',
          },
          header: {
            display: 'none',
          },
        }}
        footer={<></>}
        maskClosable={false}
        destroyOnClose
      >
        <EpubReader
          url={epubPreviewState.url}
          height="100%"
          extra={
            <Button type="text" icon={<CloseOutlined />} onClick={() => setEpubPreviewState(defaultEpubPreviewState)} />
          }
        />
      </Modal>
    </>
  );
};
