import { useActive, useAttrs, useCommands, useCurrentSelection, useExtensionEvent } from '@remirror/react';
import { useHelpers } from '@remirror/react-core';
import { useCallback, useMemo, useState } from 'react';
import { isNodeSelection } from 'remirror';

import { WPageExtension } from '../../WPageExtension';
import { WAnchorExtension, WAnchorViewClickHandlerProps } from '../WAnchorExtension';

function useWAnchorClick() {
  const [wAnchorClickProps, setWAnchorClickProps] = useState<WAnchorViewClickHandlerProps | undefined>();
  const [isEditing, setIsEditing] = useState(false);

  useExtensionEvent(
    WAnchorExtension,
    'onViewClick',
    useCallback(
      (props: WAnchorViewClickHandlerProps) => {
        if (!isEditing && props?.nodeWithPosition) {
          setIsEditing(true);
        }

        setWAnchorClickProps(props);
      },
      [isEditing],
    ),
  );

  return { isEditing, wAnchorClickProps, setIsEditing };
}

export function useWAnchorState() {
  const { toggleWAnchor, removeWAnchor, focus } = useCommands();
  const { isEditing, wAnchorClickProps, setIsEditing } = useWAnchorClick();
  const { isSelectionEmpty } = useHelpers();
  const selection = useCurrentSelection();
  const { to, empty } = selection;

  const attrs = useAttrs<WAnchorExtension>().wAnchor();

  const active = useActive<WAnchorExtension>().wAnchor();
  const enabled =
    (isNodeSelection(selection) && selection?.node?.type?.name === 'wAnchor') ||
    (isSelectionEmpty() && toggleWAnchor.enabled());

  const handleRemove = useCallback(() => {
    setIsEditing(false);
    removeWAnchor();
    focus();
  }, [removeWAnchor]);

  // const updateReason = useUpdateReason();

  // useLayoutEffect(() => {
  //   if (!isEditing) {
  //     return;
  //   }
  //
  //   // if (updateReason.doc || updateReason.selection) {
  //   //   setIsEditing(false);
  //   // }
  // }, [isEditing, setIsEditing, updateReason.doc, updateReason.selection]);

  const handleSubmit = useCallback(
    (values) => {
      setIsEditing(false);
      if (values?.id) {
        toggleWAnchor({ ...values });
      }
      focus();
    },
    [setIsEditing, wAnchorClickProps, toggleWAnchor, attrs],
  );

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    focus();
  }, [setIsEditing]);

  // useEffect(() => {
  //   if (isEditing) {
  //     selectWPage();
  //   }
  // }, [isEditing]);

  const handleClickEdit = useCallback(() => {
    // if (empty) {
    //   selectWPage();
    // }
    setIsEditing(true);
  }, [empty, setIsEditing]);

  return useMemo(
    () => ({
      attrs,
      wAnchorClickProps,
      isEditing,
      handleRemove,
      handleSubmit,
      handleCancel,
      handleClickEdit,
      active,
      enabled,
    }),
    [attrs, active, enabled, wAnchorClickProps, isEditing, handleClickEdit, handleRemove, handleSubmit, handleCancel],
  );
}
