import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { LazyQueryExecFunction, QueryResult } from '@apollo/client/react/types/types';
import { IUsePageSearchPropsResult, usePageSearchProps } from '../../hooks';
import {
  ExportTypeEnum,
  GetManagerPublicationEpubListLazyQueryHookResult,
  GetManagerPublicationEpubListQuery,
  GetManagerPublicationEpubListQueryVariables,
  SortEnumType,
  useGetManagerPublicationEpubListLazyQuery,
} from '../../services/apolloGraphqlAppApiClient/models/types';

export interface IUsePublicationEpubListManagerStoreResult
  extends Omit<IUsePageSearchPropsResult, 'setHistorySearchProps' | 'antdPagination' | 'setAntdPagination'> {
  publicationEpubListManagerStore: QueryResult<
    GetManagerPublicationEpubListQuery,
    GetManagerPublicationEpubListQueryVariables
  >;
  getPublicationEpubListLazy: LazyQueryExecFunction<
    GetManagerPublicationEpubListQuery,
    GetManagerPublicationEpubListQueryVariables
  >;
  publicationEpubTypes: [ExportTypeEnum.Epub, ExportTypeEnum.Pdf];
}

export const PublicationEpubListManagerStoreContext = createContext<
  IUsePublicationEpubListManagerStoreResult | undefined
>(undefined);
PublicationEpubListManagerStoreContext.displayName = 'PublicationEpubListManagerStoreContext';

export const usePublicationEpubListManagerStore = () => {
  const context = useContext(PublicationEpubListManagerStoreContext);
  if (context === undefined) {
    throw new Error('usePublicationEpubsManagerStoreContext must be used within PublicationEpubsManagerStoreProvider');
  }
  return context;
};

export const PublicationEpubListManagerStoreProvider = ({ children }) => {
  const { historyParams, handlePageSearchPropsChange, historySearchProps, setParams } = usePageSearchProps(false);

  const publicationEpubTypes: [ExportTypeEnum.Epub, ExportTypeEnum.Pdf] = [ExportTypeEnum.Epub, ExportTypeEnum.Pdf];

  const [
    getPublicationEpubListLazy,
    publicationEpubListManagerStore,
  ]: GetManagerPublicationEpubListLazyQueryHookResult = useGetManagerPublicationEpubListLazyQuery({
    // variables: {
    //   where: {
    //     type: { eq: historyParams?.typeId },
    //     publicationId: { eq: historyParams?.publicationId },
    //   },
    //   order: { createdAt: SortEnumType.Desc },
    // },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (historyParams?.typeId && publicationEpubTypes.find((i) => i === historyParams?.typeId)) {
      getPublicationEpubListLazy({
        variables: {
          where: {
            type: { eq: historyParams?.typeId },
            publicationId: { eq: historyParams?.publicationId },
          },
          order: { createdAt: SortEnumType.Desc },
        },
      });
    } else if (setParams) {
      setParams(`/publications/${historyParams?.publicationId}/epubs/${publicationEpubTypes[0]}`, { replace: true });
    }
  }, [historyParams?.typeId]);

  const publicationEpubListManagerStoreContextObject: IUsePublicationEpubListManagerStoreResult = useMemo(
    () => ({
      publicationEpubListManagerStore,
      getPublicationEpubListLazy,
      publicationEpubTypes,
      historyParams,
      historySearchProps,
      handlePageSearchPropsChange,
      setParams,
    }),
    [publicationEpubListManagerStore, publicationEpubTypes, historyParams, historySearchProps],
  );

  return (
    <PublicationEpubListManagerStoreContext.Provider value={publicationEpubListManagerStoreContextObject}>
      {children}
    </PublicationEpubListManagerStoreContext.Provider>
  );
};
