import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownProps, Image, List, Spin, theme } from 'antd';
import TinyVirtualList, { Props as TinyVirtualListProps } from 'react-tiny-virtual-list';
// import VirtualList, {ListProps as VirtualListProps} from 'rc-virtual-list';
import { useResizeDetector, useResizeDetectorProps } from 'react-resize-detector';
import Scrollbars, { positionValues, ScrollbarProps } from 'react-custom-scrollbars';
import { createStyles } from 'antd-style';
import { getParaPosition, usePublicationChaptersReaderStore } from './PublicationChaptersReaderStoreProvider';
import {
  PublicationChaptersReaderChapterListItemFragment,
  PublicationChaptersReaderParagraphListItemFragment,
} from '../../services/apolloGraphqlAppApiClient/models/types';
import paraLoaderImg from '../../assets/images/loader-dy.gif';
import { useContextPublicationChaptersParagraphActions } from './PublicationParagraphActions';

const useChaptersParagraphListStyles = createStyles((props) => {
  return {
    // listRender: {
    //   'div:last-child': {
    //     '&::after': {
    //       height: 900,
    //       display: 'block',
    //     },
    //   },
    // },
    withStartChapterIndicator: {
      position: 'relative',
      '&::before': {
        position: 'absolute',
        top: 0,
        left: 0,
        color: 'red',
        content: '">"',
      },
    },
  };
});

const ChapterParagraphListComponent = (props) => {
  const {
    fetchParagraphsLazy,
    activeChapterOrder,
    publicationChaptersReaderStore,
    chapterParagraphListStore,
    setActiveChapterOrder,
    setScrollToItem,
  } = usePublicationChaptersReaderStore();
  const { height: listWrapperHeight, ref: listWrapperRef } = useResizeDetector();
  const { ref: scrollWrapperRef } = useResizeDetector();
  const [listLoadingType, setListLoadingType] = useState<'prev' | 'next' | 'initial'>('initial');
  const { styles } = useChaptersParagraphListStyles();
  const handleFetchMore = async (viewParaOrder: number, loadingType: 'prev' | 'next' | 'initial' = 'initial') => {
    setListLoadingType(loadingType);
    let fetrchArgs = {};
    switch (loadingType) {
      case 'prev':
        fetrchArgs =
          viewParaOrder > 20
            ? { order: viewParaOrder - 20, take: 20, skip: 0 }
            : {
                order: 1,
                take: 20,
                skip: 0,
              };
        break;
      default:
        fetrchArgs = { order: viewParaOrder, take: 20, skip: 0 };
    }
    return fetchParagraphsLazy(fetrchArgs)
      .then(() => {
        setListLoadingType('initial');
      })
      .catch((e) => {
        setListLoadingType('initial');
        console.log('[fetchReaderParagraphError]', { e });
      });
  };

  const handleParagraphListScroll: ScrollbarProps['onScroll'] = (e) => {
    const target = e.target as HTMLDivElement;
    if (target && target?.childNodes instanceof NodeList) {
      (target.childNodes as NodeListOf<HTMLDivElement>).forEach((item, i, arr) => {
        const itemChapterOrder = item?.attributes.getNamedItem('data-chapterorder')?.value;
        const itemOrder = item?.attributes.getNamedItem('data-order')?.value;
        const itemIsChapterStart = item?.attributes.getNamedItem('data-ischapterstart')?.value;

        if (itemOrder && itemChapterOrder) {
          const viewParaOrder = parseInt(itemOrder, 10);
          const viewChapterOrder = parseInt(itemChapterOrder, 10);
          if (
            itemIsChapterStart === 'true' &&
            item.offsetTop >= target.scrollTop &&
            item.offsetTop < target.scrollTop + 100
          ) {
            if (viewChapterOrder !== activeChapterOrder) {
              setActiveChapterOrder(viewChapterOrder);
            }
          }
          if (i === 0 && viewParaOrder > 1 && target.scrollTop === 0) {
            handleFetchMore(viewParaOrder, 'prev')
              .then(() => {
                setScrollToItem(viewParaOrder);
              })
              .catch((e) => {
                console.log('[fetchReaderParagraphError]', { e });
              });
          }
          if (
            i === arr.length - 1 &&
            target?.scrollHeight &&
            target?.scrollTop &&
            target.scrollHeight - target.scrollTop === listWrapperHeight &&
            chapterParagraphListStore?.data?.paragraphs?.pageInfo.hasNextPage
          ) {
            handleFetchMore(viewParaOrder, 'next');
          }
        }
      });
    }
  };

  return (
    <div
      ref={listWrapperRef}
      style={{
        height: '100%',
      }}
    >
      <Spin
        indicator={<Image src={paraLoaderImg} alt="Loading..." width={50} />}
        spinning={chapterParagraphListStore.loading}
        style={{
          height: 'max-content',
          ...(listLoadingType === 'next'
            ? {
                top: 'unset',
                bottom: 50,
              }
            : listLoadingType === 'prev'
            ? { top: 0 }
            : { top: '50%' }),
        }}
      >
        <List
          style={{
            height: listWrapperHeight || 400,
          }}
        >
          <Scrollbars
            autoHide
            style={{ height: listWrapperHeight || 400, width: '100%' }}
            onScroll={handleParagraphListScroll}
            renderTrackVertical={({ style, ...props }) => {
              return (
                <div
                  {...props}
                  style={{
                    ...style,
                    borderRadius: 4,
                    width: 8,
                    right: 0,
                    bottom: 2,
                    top: 2,
                  }}
                />
              );
            }}
            renderThumbVertical={({ style, ...props }) => {
              return (
                <div
                  {...props}
                  style={{
                    ...style,
                    borderRadius: 4,
                    background: 'rgba(0, 0, 0, 0.5)',
                  }}
                />
              );
            }}
            renderView={({ ...props }) => {
              return <div {...props} ref={scrollWrapperRef} />;
            }}
          >
            {chapterParagraphListStore.data?.paragraphs?.items?.map((item, i, arr) => {
              const chapter = getParaPosition(publicationChaptersReaderStore.data?.publication?.chapters, item);
              return (
                <ChapterParagraphListItem
                  key={`${i}-${item.paragraphId}-${item.order}`}
                  id={`ChapterParagraphListItem-${item.order}`}
                  item={item}
                  chapter={chapter}
                />
              );
            })}
          </Scrollbars>
        </List>
      </Spin>
    </div>
  );
};

export const ChapterParagraphListItem = (props: {
  item: PublicationChaptersReaderParagraphListItemFragment;
  chapter?: PublicationChaptersReaderChapterListItemFragment;
  id: string;
}) => {
  const { item, chapter, id } = props;
  const { scrollToItem, setScrollToItem } = usePublicationChaptersReaderStore();
  const { ref: listItemWrapperRef } = useResizeDetector<HTMLDivElement>();
  const { styles } = useChaptersParagraphListStyles();
  const paragraphContextActions = useContextPublicationChaptersParagraphActions({ paragraphData: item });
  const [disabled, setDisabled] = useState<DropdownProps['disabled']>(false);
  const [open, setOpen] = useState<DropdownProps['open']>(false);
  useEffect(() => {
    if (scrollToItem === item.order && listItemWrapperRef.current) {
      listItemWrapperRef.current?.scrollIntoView();
      setScrollToItem(undefined);
    }
  }, [scrollToItem]);

  const handleOpenChange: NonNullable<DropdownProps['onOpenChange']> = (open) => {
    setOpen(open);
  };

  return (
    <Dropdown
      menu={{ items: paragraphContextActions }}
      trigger={['contextMenu']}
      destroyPopupOnHide
      forceRender={false}
      disabled={disabled}
      open={disabled ? false : open}
      onOpenChange={handleOpenChange}
    >
      <div
        // className={item.paraId === chapter.paraId ? styles.withStartChapterIndicator : ''}
        className="contextMenuCursor weml-editor"
        ref={listItemWrapperRef}
        id={id}
        data-paraid={item.paraId}
        data-ischapterstart={item.paraId === chapter?.paraId}
        data-chapterorder={chapter?.order}
        data-order={item.order}
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: item.content }}
        onMouseDown={(e) => {
          const selection = window.getSelection();
          const isSelected =
            selection &&
            !selection.isCollapsed &&
            selection.anchorNode !== null &&
            selection.anchorNode.parentNode === e.target;
          if (typeof isSelected === 'boolean' && isSelected !== disabled) {
            setDisabled(isSelected);
          }
        }}
        onMouseUp={(e) => {
          const selection = window.getSelection();
          const isSelected =
            selection &&
            !selection.isCollapsed &&
            selection.anchorNode !== null &&
            selection.anchorNode.parentNode === e.target;
          if (typeof isSelected === 'boolean' && isSelected !== disabled) {
            setDisabled(isSelected);
          }
        }}
        onContextMenu={(e) => {
          const selection = window.getSelection();
          const isSelected =
            selection &&
            !selection.isCollapsed &&
            selection.anchorNode !== null &&
            selection.anchorNode.parentNode === e.target;
          if (typeof isSelected === 'boolean' && isSelected !== disabled) {
            setDisabled(isSelected);
            e.stopPropagation();
          }
        }}
      />
    </Dropdown>
  );
};
export const ChapterParagraphList = (props) => {
  return (
    // <PublicationListStoreProvider>
    <ChapterParagraphListComponent {...props} />
    // </PublicationListStoreProvider>
  );
};
