import { mutateTag } from 'remirror';

export * from './WParaExtension';
export * from './WTextBlockExtension';
export * from './WPageExtension';
export * from './WNoteExtension';
export * from './WAnchorExtension';
export * from './WHardBreakExtension';
export * from './WFormatExtension';
export * from './WLangExtension';
export * from './WNonEgwExtension';
export * from './WSentExtension';
export * from './WEntityExtension';
export * from './WLinkExtension';
export * from './WPageBlockExtension';
export * from './WHeadingExtension';
export * from './WListExtension';
export * from './WImageExtension';

mutateTag((tag) => {
  tag.WemlContainer = 'wemlContainer';
  tag.WParaContent = 'wParaContent';
  tag.WemlBlock = 'wemlBlock';
});

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface ExtensionTags {
      WemlContainer: 'wemlContainer';
      WParaContent: 'wParaContent';
      WemlBlock: 'wemlBlock';
    }
  }
}
