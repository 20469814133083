import React, { CSSProperties, FC, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Card, theme } from 'antd';
import { useResizeDetector } from 'react-resize-detector';
import { createStyles } from 'antd-style';
import { PageTemplate, ResizableLayout } from '../../components';
import {
  PublicationChaptersReaderStoreProvider,
  usePublicationChaptersReaderStore,
} from './PublicationChaptersReaderStoreProvider';

import { useAppLayoutContext } from '../../layout';
import { ChaptersReaderToc } from './ChaptersReaderToc';
import { ChapterParagraphList } from './ChapterParagraphList';
import { PublicationPageHoc } from '../PublicationPageHoc';

const usePublicationChaptersReaderPageStyles = createStyles(({ token }) => ({
  pageBodyContent: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    // alignItems: 'start',
    // justifyContent: 'start',
    // width: '100%',
  },
}));
export const PublicationChaptersReaderPageComponent = (props) => {
  const { minPageContentHeight } = useAppLayoutContext();
  const { ref: bodyWrapperRef } = useResizeDetector();

  return (
    <Card
      bodyStyle={{
        padding: 0,
        flexWrap: 'nowrap',
      }}
      headStyle={{ minHeight: 0, padding: 8 }}
    >
      <div
        ref={bodyWrapperRef}
        style={{
          height:
            bodyWrapperRef.current && minPageContentHeight
              ? minPageContentHeight - bodyWrapperRef.current.offsetTop - 3
              : 'auto',
        }}
      >
        <ResizableLayout
          axis="x"
          initial={200}
          startContent={<ChaptersReaderToc />}
          endContent={<ChapterParagraphList />}
          virtual
          styles={{
            wrapperStyles: {
              height:
                bodyWrapperRef.current && minPageContentHeight
                  ? minPageContentHeight - bodyWrapperRef.current.offsetTop - 3
                  : 'auto',
            },
          }}
        />
      </div>
    </Card>
  );
};

export const PublicationChaptersReaderPage = (props) => {
  return (
    <PublicationPageHoc title="chapters reader">
      <PublicationChaptersReaderStoreProvider>
        <PublicationChaptersReaderPageComponent />
      </PublicationChaptersReaderStoreProvider>
    </PublicationPageHoc>
  );
};
