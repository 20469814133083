import { ProsemirrorAttributes } from '@remirror/core';
import { useActive, useAttrs, useCommands, useCurrentSelection, useExtensionEvent } from '@remirror/react';
import { useHelpers } from '@remirror/react-core';
import { useCallback, useMemo, useState } from 'react';

import { WNonEgwAttributes, WNonEgwExtension, WNonEgwViewClickHandlerProps } from '../WNonEgwExtension';

function useWNonEgwClick() {
  const [wNonEgwClickHandlerProps, setWNonEgwClickHandlerProps] = useState<WNonEgwViewClickHandlerProps | undefined>();
  const [isEditing, setIsEditing] = useState(false);

  useExtensionEvent(
    WNonEgwExtension,
    'onViewClick',
    useCallback(
      (props) => {
        if (!isEditing && props?.markWithPosition) {
          setIsEditing(true);
        }
        setWNonEgwClickHandlerProps(props);
      },
      [isEditing],
    ),
  );

  return { isEditing, wNonEgwClickHandlerProps, setIsEditing };
}

export interface IUseWNonEgwState {
  wNonEgwClickHandlerProps: WNonEgwViewClickHandlerProps | undefined;
  handleSubmit: (values: any) => void;
  handleCancel: () => void;
  isEditing: boolean;
  handleClickEdit: () => void;
  active: (attrs?: ProsemirrorAttributes<WNonEgwAttributes>) => boolean;
  handleRemove: () => void;
  enabled: boolean;
  attrs: (Record<string, unknown> & Remirror.Attributes & object & { class?: string }) | undefined;
}

export function useWNonEgwState(): IUseWNonEgwState {
  const { toggleWNonEgw, removeWNonEgw, selectWNonEgw, focus } = useCommands();
  const { isEditing, wNonEgwClickHandlerProps, setIsEditing } = useWNonEgwClick();
  const { to, empty } = useCurrentSelection();
  const { isSelectionEmpty } = useHelpers();

  const attrs = useAttrs<WNonEgwExtension>().wNonEgw();

  const { wNonEgw: active } = useActive<WNonEgwExtension>();
  const enabled = (!isSelectionEmpty() && toggleWNonEgw.enabled()) || isEditing;
  const handleRemove = useCallback(() => {
    removeWNonEgw();
    focus();
  }, [removeWNonEgw]);

  // const updateReason = useUpdateReason();

  // useLayoutEffect(() => {
  //   if (!isEditing) {
  //     return;
  //   }
  //
  //   // if (updateReason.doc || updateReason.selection) {
  //   //   setIsEditing(false);
  //   // }
  // }, [isEditing, setIsEditing, updateReason.doc, updateReason.selection]);

  const handleSubmit = useCallback(
    (values) => {
      setIsEditing(false);
      const range = wNonEgwClickHandlerProps?.markWithPosition;
      if (range) {
        handleClickEdit();
      }
      if (values?.type === attrs?.type) {
        handleRemove();
        return;
      }
      if (values?.type) {
        toggleWNonEgw({ ...values }, range);
      }
      focus();
    },
    [setIsEditing, wNonEgwClickHandlerProps, toggleWNonEgw, attrs],
  );

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    focus();
  }, [setIsEditing]);

  const handleClickEdit = useCallback(() => {
    if (empty) {
      selectWNonEgw();
    }
    if (!isEditing) {
      setIsEditing(true);
    }
  }, [selectWNonEgw, empty, setIsEditing]);

  return useMemo<IUseWNonEgwState>(
    () => ({
      attrs,
      wNonEgwClickHandlerProps,
      isEditing,
      handleRemove,
      handleSubmit,
      handleCancel,
      active,
      enabled,
      handleClickEdit,
    }),
    [
      attrs,
      active,
      enabled,
      wNonEgwClickHandlerProps,
      isEditing,
      handleRemove,
      handleSubmit,
      handleCancel,
      handleClickEdit,
    ],
  );
}
