import React, { FC } from 'react';
import { Badge, Card, Descriptions, Image, List, Space, Tooltip } from 'antd';
import { createStyles } from 'antd-style';
import dayjs from 'dayjs';
import { filesize } from 'filesize';
import { RibbonProps } from 'antd/es/badge/Ribbon';
import { useResizeDetector } from 'react-resize-detector';
import {
  ManagerPublicationCoverListItemFragment,
  ManagerPublicationCoverTypeListItemFragment,
} from '../../../services/apolloGraphqlAppApiClient/models/types';
import { CONSTANTS } from '../../../config';
import { usePublicationCoversManagerStore } from '../PublicationCoversManagerStoreProvider';
import { timeSince } from '../../../utils';
import { SetPublicationMainCoverActionButton } from './PublicationCoverActions';
import { useAppLayoutContext } from '../../../layout';

const usePublicationCoverTypeCoversListStyles = createStyles(({ token }) => ({
  item: {
    '.ant-ribbon-wrapper': {
      paddingTop: 22,
    },
  },
  itemCard: {
    '.ant-card-head': {
      all: 'unset',
      minHeight: 22,
    },
    '.ant-card-actions': {
      li: {
        margin: 0,
      },
    },
    '.ant-image-img': {
      borderRadius: 8,
    },
    '.ant-card-meta': {
      margin: 0,
    },
    '.ant-card-body': {
      padding: 3,
    },
  },
  itemBadge: {
    button: {
      padding: '0 !important',
      '.ant-btn-icon': {
        marginInlineEnd: '0 !important',
      },
    },
  },
  itemDescription: {
    // width: 100,
    '.ant-descriptions-view': {
      table: {
        width: 'unset',
        maxWidth: 200,
      },
    },
    '.ant-descriptions-item-label': {
      padding: '0 2px !important',
      fontSize: '0.75em',
      textOverflow: 'ellipsis',
      textWrap: 'nowrap',
    },
    '.ant-descriptions-header': {
      marginBottom: 2,
    },
    '.ant-descriptions-item-content': {
      padding: '0 2px !important',
      textOverflow: 'ellipsis',
      fontSize: '0.9em',
      textWrap: 'nowrap',
    },
    '.ant-descriptions-item': {
      padding: '0 !important',
      textOverflow: 'ellipsis',
      textWrap: 'nowrap',
    },
    '.cover-upload-date': {
      '&:hover': {
        cursor: 'help',
      },
    },
  },
}));

const BadgeListItem: FC<{ withBadge?: boolean } & RibbonProps> = (props) => {
  const { children, withBadge = true, text = 'Main', ...rest } = props;

  if (withBadge) {
    return (
      <Badge.Ribbon text={text} {...rest}>
        {children}
      </Badge.Ribbon>
    );
  }
  return <>{children}</>;
};
export const PublicationCoverTypeCoversList = (coverType: ManagerPublicationCoverTypeListItemFragment) => {
  const { publicationCoversManagerStore } = usePublicationCoversManagerStore();
  const { styles } = usePublicationCoverTypeCoversListStyles();

  return (
    <Image.PreviewGroup>
      <List<ManagerPublicationCoverListItemFragment>
        loading={publicationCoversManagerStore.loading}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
          xxl: 5,
        }}
        style={{
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
        dataSource={publicationCoversManagerStore.data?.covers?.items || []}
        renderItem={(listItem) => (
          <List.Item style={{ maxWidth: 250 }} className={styles.item}>
            <BadgeListItem
              // withBadge={listItem.isMain}
              style={{ top: 0 }}
              className={styles.itemBadge}
              text={listItem.isMain ? 'main' : <SetPublicationMainCoverActionButton {...listItem} />}
              color={listItem.isMain ? 'green' : 'lightGray'}
            >
              <Card
                className={styles.itemCard}
                size="small"
                bordered={false}
                // title={<div style={{ height: 22 }} />}
                actions={
                  [
                    // <Space key={`${listItem.id}-set-main-action`}>
                    //   <SetPublicationMainCoverActionButton {...listItem} />
                    // </Space>,
                  ]
                }
              >
                <Card.Meta
                  description={
                    <Space align="end">
                      <Image
                        height={100 / (listItem.type!.minWidth / listItem.type!.minHeight)}
                        width={100}
                        // src={GET_PUBLICATION_COVER_SRC(listItem.publicationId)}
                        src={listItem?.uri}
                        alt={listItem.publicationId.toString()}
                        fallback={CONSTANTS.NOT_FOUND_IMG_EGW}
                      />
                      <Descriptions
                        className={styles.itemDescription}
                        // bordered
                        colon={false}
                        layout="vertical"
                        column={1}
                        items={[
                          {
                            key: '1',
                            label: 'Dimensions',
                            children: `${listItem.width}x${listItem.height} px.`,
                          },
                          {
                            key: '2',
                            label: 'File size',
                            children: filesize(listItem.size),
                          },
                          {
                            key: '3',
                            label: 'Format',
                            children: listItem.format,
                          },
                          {
                            key: '4',
                            label: 'Upload date',
                            children: (
                              <Tooltip
                                placement="bottom"
                                title={dayjs(listItem.createdAt).format('dddd, MMMM D, YYYY h:mm A')}
                              >
                                <div className="cover-upload-date">{timeSince(listItem.createdAt) || ''}</div>
                              </Tooltip>
                            ),
                          },
                        ]}
                      />
                    </Space>
                  }
                />
              </Card>
            </BadgeListItem>
          </List.Item>
        )}
      />
    </Image.PreviewGroup>
  );
};
