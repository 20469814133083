import { Box, BoxProps, Button, ButtonGroup, Stack } from '@mui/material';
import * as React from 'react';
import { FC, ReactNode, useState } from 'react';

import { usePopoverContext } from '../tooltips/PopoverProvider';

export interface ICommandFormProps extends Omit<BoxProps<'form'>, 'onSubmit'> {
  fields?: any;
  initialValues?: { [key: string]: any };
  children: any;
  onRemove?: () => void;
  onSubmit?: (values: { [key: string]: any }) => void;
}

export const CommandForm: FC<ICommandFormProps> = (props) => {
  const { name, initialValues, children, onSubmit, onReset, onRemove, ...rest } = props;
  const [state, setState] = useState(initialValues || {});
  const { setOpen } = usePopoverContext();

  const handleInputChange = (event, newValue, reason, details, name) => {
    const target = event?.target;
    const value = newValue || (target.type === 'checkbox' ? target.checked : target.value);
    const fieldName = target.name || target.getAttribute('data-name') || name;
    setState({
      ...state,
      [fieldName]: value,
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (onSubmit) {
      setOpen(false);
      onSubmit(state);
    }
  };

  const handleRemove = () => {
    setOpen(false);
    onRemove && onRemove();
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1 },
      }}
      noValidate
      autoComplete="off"
      name={`${name}CommandForm`}
      onSubmit={handleSubmit}
      onReset={(values) => {
        // wLinkForm.resetFields();
      }}
      {...rest}
    >
      <Stack spacing={1} direction="column">
        {React.Children.map<ReactNode, ReactNode>(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              ...child.props,
              value: state[child.props.name || child.props?.id],
              onChange: (event, newValue, reason, details) =>
                handleInputChange(event, newValue, reason, details, child.props.name || child.props?.id),
            });
          }
          return child;
        })}
        <ButtonGroup
          size="small"
          sx={{
            display: 'flex !important',
            justifyContent: 'right !important',
          }}
        >
          <Button type="submit" variant="contained">
            {`Toggle ${name}`}
          </Button>
          <Button type="reset" variant="outlined">
            Reset Form
          </Button>
          {onRemove && (
            <Button variant="outlined" onClick={handleRemove} color="error">
              {`Remove ${name}`}
            </Button>
          )}
        </ButtonGroup>
      </Stack>
    </Box>
  );
};
