import { TextField } from '@mui/material';
import React from 'react';

import { CommandForm, ICommandFormProps } from '../../../components';

export interface IToggleWPageFormProps extends Omit<ICommandFormProps, 'children' | 'fields'> {
  initialOption?: string;
  initialValues?: any;
  onRemove?: () => void;
}

export const ToggleWPageForm = (props: IToggleWPageFormProps) => {
  const { ...rest } = props;

  return (
    <CommandForm name="wPage" {...rest}>
      <TextField
        name="number"
        type="number"
        label="Number"
        size="small"
        variant="standard"
        // id="outlined-start-adornment"
        // rules={[{ required: true, message: 'Number is required' }]}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
      />
    </CommandForm>
  );
};
