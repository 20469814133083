import { Icon, useRemirrorContext } from '@remirror/react';
import React, { FC, useCallback } from 'react';
import { htmlToProsemirrorNode } from 'remirror';

import { CommandButton, ICommandButtonProps } from './CommandButton';

export type IToggleResetButtonProps = Omit<
  ICommandButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'
> & {
  value?: string;
  type?: 'reset' | 'clear';
};

export const ToggleResetButton: FC<IToggleResetButtonProps> = (props) => {
  const { value, type = 'clear' } = props;
  const { setContent, schema } = useRemirrorContext();

  const handleSubmit = useCallback(() => {
    const doc = htmlToProsemirrorNode({
      content: value || '',
      schema,
    });
    setContent(doc, { triggerChange: true });
  }, [setContent]);

  return (
    <CommandButton
      {...props}
      commandName=""
      enabled
      onSelect={handleSubmit}
      icon={<Icon name={type === 'reset' ? 'asterisk' : 'deleteBinLine'} size="1rem" />}
    />
  );
};
