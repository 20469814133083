import React from 'react';
import { PageTemplate } from '../../components';
import { Manager } from './components';

export const PublicationsManagerPage = (props) => {
  return (
    <PageTemplate title="Publication Management">
      <Manager />
    </PageTemplate>
  );
};
