import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionPriority,
  ExtensionTag,
  findParentNodeOfType,
  isEqual,
  keyBinding,
  KeyBindingProps,
  NamedShortcut,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
  PrimitiveSelection,
  ProsemirrorAttributes,
  Static,
} from '@remirror/core';
import { ExtensionParagraphMessages } from '@remirror/messages';
import { isElementDomNode } from 'remirror';

export type WTextBlockTypeProperty = 'blockquote' | 'paragraph' | 'poem';

export interface WTextBlockProperties {
  typeList: WTextBlockTypeProperty[];
}

export interface WTextBlockAttributes extends ProsemirrorAttributes {
  type?: Static<WTextBlockTypeProperty>;
}

export const W_TEXT_BLOCK_PROPERTIES: Static<WTextBlockProperties> = {
  typeList: ['blockquote', 'paragraph', 'poem'],
};

const W_TEXT_BLOCK_ATTRIBUTES: Static<WTextBlockAttributes> = {};

const insertWTextBlockOptions: Remirror.CommandDecoratorOptions = {
  icon: 'paragraph',
  label: ({ t }) => t(ExtensionParagraphMessages.INSERT_LABEL),
  description: ({ t }) => t(ExtensionParagraphMessages.INSERT_DESCRIPTION),
};

const TOGGLE_COMMAND_LABEL = 'Toggle wTextBlock';
const TOGGLE_COMMAND_DESCRIPTION = 'Toggle wTextBlock Attributes';

const toggleWTextBlockOptions: Remirror.CommandDecoratorOptions = {
  icon: 'paragraph',
  label: ({ t }) => t(TOGGLE_COMMAND_LABEL),
  description: ({ t }) => t(TOGGLE_COMMAND_DESCRIPTION),
};

const convertWTextBlockOptions: Remirror.CommandDecoratorOptions = {
  icon: 'paragraph',
  label: ({ t }) => t(ExtensionParagraphMessages.CONVERT_LABEL),
  description: ({ t }) => t(ExtensionParagraphMessages.CONVERT_DESCRIPTION),
};

export interface WTextBlockOptions {
  properties?: Static<WTextBlockProperties>;
  attributes?: Static<WTextBlockAttributes>;
}

/**
 * The paragraph is one of the essential building blocks for a prosemirror
 * editor and by default it is provided to all editors.
 *
 * @core
 */
@extension<WTextBlockOptions>({
  defaultOptions: {
    properties: W_TEXT_BLOCK_PROPERTIES,
    attributes: W_TEXT_BLOCK_ATTRIBUTES,
  },
  staticKeys: ['properties', 'attributes'],
  defaultPriority: ExtensionPriority.Medium,
})
export class WTextBlockExtension extends NodeExtension<WTextBlockOptions> {
  get name() {
    return 'wTextBlock' as const;
  }

  createTags() {
    return [
      ExtensionTag.WemlBlock,
      ExtensionTag.LastNodeCompatible,
      ExtensionTag.TextBlock,
      ExtensionTag.Block,
      ExtensionTag.FormattingNode,
    ];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      content: 'inline*',
      marks: '_',
      draggable: false,
      ...override,
      attrs: {
        ...extra.defaults(),
        type: { default: this.options.attributes.type },
      },
      parseDOM: [
        {
          tag: 'w-text-block',
          getAttrs: (node) => {
            return isElementDomNode(node)
              ? {
                  type: node.getAttribute('type') as string,
                }
              : false;
          },
        },
        ...(override.parseDOM ?? []),
      ],

      toDOM: (node) => {
        if (node?.attrs) {
          return ['w-text-block', { ...(node.attrs?.type ? { type: node.attrs.type.toString() } : {}) }, 0];
        }
        return ['w-text-block', { type: 'paragraph' }, 0];
      },
    };
  }

  /**
   * Convert the current node to a paragraph.
   */
  @command(convertWTextBlockOptions)
  convertWTextBlock(options: WTextBlockCommandOptions = {}): CommandFunction {
    const { attrs, selection, preserveAttrs } = options;

    return this.store.commands.setBlockNodeType.original(this.type, attrs, selection, preserveAttrs);
  }

  @command(toggleWTextBlockOptions)
  toggleWTextBlock(attrs?: WTextBlockAttributes, pos?: number): CommandFunction {
    // return updateNodeAttributes(this.type)(attrs, pos);
    return ({ state: { tr, selection, doc }, dispatch }) => {
      // if (!isValidCalloutExtensionAttributes(attributes)) {
      //   throw new Error('Invalid attrs passed to the updateAttributes method');
      // }

      const parent = findParentNodeOfType({
        types: this.type,
        selection: pos ? doc.resolve(pos) : selection,
      });

      if (!parent || isEqual(attrs, parent.node.attrs)) {
        // Do nothing since the attrs are the same
        return false;
      }

      tr.setNodeMarkup(parent.pos, this.type, {
        ...parent.node.attrs,
        ...attrs,
      });

      if (dispatch) {
        dispatch(tr);
      }

      return true;
    };
  }

  /**
   * Inserts a paragraph into the editor at the current selection.
   */
  @command(insertWTextBlockOptions)
  insertWTextBlock(content: string, options: WTextBlockCommandOptions = {}): CommandFunction {
    const { selection, attrs } = options;
    return this.store.commands.insertNode.original(this.type, { content, selection, attrs });
  }

  /**
   * Add the paragraph shortcut to the editor. This makes a paragraph into a
   */
  @keyBinding({ shortcut: NamedShortcut.Paragraph, command: 'convertWTextBlock' })
  shortcut(props: KeyBindingProps): boolean {
    return this.convertWTextBlock()(props);
  }
}

interface WTextBlockCommandOptions {
  attrs?: ProsemirrorAttributes;
  selection?: PrimitiveSelection;
  preserveAttrs?: boolean;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wTextBlock: WTextBlockExtension;
    }
  }
}
