import { App, Form, Input, ModalFuncProps } from 'antd';
import React, { useRef } from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { FolderAddOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';
import { useApolloClient } from '@apollo/client';
import { DebounceSelect, FlexListTemplate } from '../../../../../components';
import {
  CreateFolderInput,
  FolderTypeDtoFilterInput,
  GetManagerFolderTypeListDocument,
  GetManagerFolderTypeListQuery,
  ManagerFolderTreeListItemFragment,
  useCreateFolderMutation,
} from '../../../../../services/apolloGraphqlAppApiClient/models/types';
import { useFoldersTreeStoreContext } from '../FoldersTreeStoreProvider';

export interface IUseCreateSubFolderActionProps {
  folderData: ManagerFolderTreeListItemFragment;
}

export interface IUseCreateSubFolderActionResult {
  createSubFolderAction: ItemType;
}

export const useCreateSubFolderAction = (props: IUseCreateSubFolderActionProps): IUseCreateSubFolderActionResult => {
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();
  const { folderData } = props;
  const apolloGraphqlAppClient = useApolloClient();
  const { refetchFolders } = useFoldersTreeStoreContext();
  const menuItemKey = `CreateFolderAction-${folderData.id}`;
  const infoKey = uuidV4();
  const modalRef = useRef<ReturnType<typeof confirm>>();

  const visible = folderData.title !== 'Unpublished' && folderData.childPublicationCount === 0;

  const [createSubFolderForm] = Form.useForm<CreateFolderInput>();

  const [createFolder] = useCreateFolderMutation({
    onCompleted: (result) => {
      refetchFolders();
      info({
        placement: 'topRight',
        message: `New sub Folder create status: ${result.createFolder.boolean}`,
        description: (
          <FlexListTemplate gap={5} alignItems="left" justifyContent="left" style={{ flexDirection: 'column' }}>
            {/* info */}
          </FlexListTemplate>
        ),
        key: infoKey,
        duration: 7,
      });
    },
    onError: (e) => {
      return e;
    },
  });

  const handleOk: ModalFuncProps['onOk'] = () => {
    createSubFolderForm
      .validateFields()
      .then((values) => {
        return createFolder({
          variables: {
            input: { ...values },
          },
        });
      })
      .then((res) => {
        // createSubFolderForm.resetFields();
        if (modalRef.current) {
          modalRef.current.destroy();
        }
      })
      .catch((e) => {
        return e;
      });
  };

  const hadleCancel = () => {
    createSubFolderForm.resetFields();
    if (modalRef.current) {
      modalRef.current.destroy();
    }
  };

  return {
    createSubFolderAction: visible
      ? {
          key: menuItemKey,
          icon: <FolderAddOutlined />,
          label: 'Create Folder',
          onClick: (menuInfo) => {
            menuInfo.domEvent.preventDefault();
            menuInfo.domEvent.stopPropagation();
            modalRef.current = confirm({
              title: `Create New ${folderData.title} sub Folder!`,
              onOk: handleOk,
              onCancel: hadleCancel,
              okText: 'Create Folder',
              content: (
                <Form<CreateFolderInput>
                  layout="vertical"
                  name="createSubFolderForm"
                  form={createSubFolderForm}
                  initialValues={{ parentId: folderData.id }}
                >
                  <Form.Item name="parentId" label="ParentId" hidden>
                    <Input type="textarea" />
                  </Form.Item>
                  <Form.Item
                    name="title"
                    label="Title"
                    rules={[
                      {
                        required: true,
                        message: 'Please input the title of wrapper!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="type"
                    label="Type"
                    valuePropName="value"
                    rules={[
                      {
                        required: true,
                        message: `Please select folder type!`,
                      },
                    ]}
                  >
                    <DebounceSelect
                      style={{ width: '100%' }}
                      placeholder="Select folder type"
                      fetchCallback={async (value) => {
                        try {
                          const response = await apolloGraphqlAppClient.query<
                            GetManagerFolderTypeListQuery,
                            { where: FolderTypeDtoFilterInput }
                          >({
                            query: GetManagerFolderTypeListDocument,
                            variables: {
                              where: {
                                id: { neq: 'root' },
                                ...(value
                                  ? {
                                      or: [{ title: { contains: value } }],
                                    }
                                  : {}),
                              },
                            },
                          });
                          const {
                            data: { folderTypes },
                          } = response;
                          return folderTypes.map((type) => ({
                            label: `${type.title}${
                              type.allowedTypes.length > 0 ? ` - [ ${type.allowedTypes.join(', ')} ]` : ''
                            }`,
                            value: type.id,
                          }));
                        } catch (e) {
                          return [];
                        }
                      }}
                    />
                  </Form.Item>
                </Form>
              ),
            });
          },
        }
      : null,
  };
};
