import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  GetPublicationPageHocDataQueryHookResult,
  useGetPublicationPageHocDataQuery,
} from '../../services/apolloGraphqlAppApiClient/models/types';
import { PageTemplate } from '../../components';

export interface IUsePublicationPageHocStoreResult {
  publicationPageHocStore: GetPublicationPageHocDataQueryHookResult;
}

const filterInt = (value?: string): number | typeof NaN => {
  if (value && /^(\\-|\+)?([0-9]+|Infinity)$/.test(value)) {
    return Number(value);
  }
  return NaN;
};

export const PublicationPageHocStoreContext = createContext<IUsePublicationPageHocStoreResult | undefined>(undefined);
PublicationPageHocStoreContext.displayName = 'PublicationPageHocStoreContext';

export const usePublicationPageHocStore = () => {
  const context = useContext(PublicationPageHocStoreContext);
  if (context === undefined) {
    throw new Error('usePublicationPageHocStore must be used within PublicationPageHocStoreProvider');
  }
  return context;
};

export interface IPublicationPageHocProps {
  children?: React.ReactNode;
  title?: React.ReactNode;
}

export interface IPublicationPageHocContextProviderProps {
  children?: React.ReactNode;
  title?: React.ReactNode;
  publicationId: number;
}

export const PublicationPageHoc: React.FC<IPublicationPageHocProps> = ({ children, title }) => {
  const { publicationId } = useParams();

  if (!publicationId || (publicationId && Number.isNaN(filterInt(publicationId)))) {
    return <Navigate to="/publications" />;
  }
  return (
    <PublicationPageHocContextProvider title={title} publicationId={parseInt(publicationId, 10)}>
      {children}
    </PublicationPageHocContextProvider>
  );
};

export const PublicationPageHocContextProvider: React.FC<IPublicationPageHocContextProviderProps> = ({
  children,
  title,
  publicationId,
}) => {
  const navigate = useNavigate();

  const publicationPageHocStore: GetPublicationPageHocDataQueryHookResult = useGetPublicationPageHocDataQuery({
    variables: { publicationId },
    fetchPolicy: 'cache-first',
    onCompleted: (newData) => {
      if (!newData.publication) {
        navigate('/publications');
      }
    },
    onError: (error) => {
      console.log({ error });
      navigate('/publications');
    },
  });

  const publicationPageHocStoreContextObject: IUsePublicationPageHocStoreResult = useMemo(
    () => ({
      publicationPageHocStore,
    }),
    [publicationPageHocStore],
  );

  return (
    <PublicationPageHocStoreContext.Provider value={publicationPageHocStoreContextObject}>
      <PageTemplate
        loading={publicationPageHocStore.loading}
        contentLoading={publicationPageHocStore.loading || !publicationPageHocStore.data?.publication}
        title={
          <>
            {publicationPageHocStore.data?.publication?.title} {title}
          </>
        }
      >
        {children}
      </PageTemplate>
    </PublicationPageHocStoreContext.Provider>
  );
};
