import { useCommands, useHelpers } from '@remirror/react-core';
import React, { FC, useCallback } from 'react';
import { HistoryExtension } from 'remirror/extensions';

import { CommandButton, ICommandButtonProps } from './CommandButton';

export type IToggleRedoButtonProps = Omit<
  ICommandButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'
>;

export const ToggleRedoButton: FC<IToggleRedoButtonProps> = (props) => {
  const { redo } = useCommands<HistoryExtension>();
  const { redoDepth } = useHelpers<HistoryExtension>(true);

  const handleSelect = useCallback(() => {
    if (redo.enabled()) {
      redo();
    }
  }, [redo]);

  const enabled = redoDepth() > 0;

  return <CommandButton {...props} commandName="redo" active={false} enabled={enabled} onSelect={handleSelect} />;
};
