import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useTitle = (title = '') => {
  const location = useLocation();
  useEffect(() => {
    const oldTitle = document.title;
    if (title && oldTitle !== title) {
      document.title = title;
    } else {
      const path = location.pathname.replace(/\//g, ' ');
      const title = `${process.env.REACT_APP_TITLE}${path && path !== ' ' ? ` - ${path}` : ''}`;
      if (oldTitle !== title) {
        document.title = title;
      }
    }
    return () => {
      // document.title = oldTitle;
    };
  }, [title, location.pathname]);
};
