import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionPriority,
  ExtensionTag,
  getTextSelection,
  Handler,
  isMarkActive,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
  NodeWithPosition,
  PrimitiveSelection,
  ProsemirrorAttributes,
  Static,
} from '@remirror/core';
import { CreateEventHandlers } from '@remirror/extension-events';
import { TextSelection } from '@remirror/pm/state';
import React from 'react';
import { isElementDomNode } from 'remirror';

import './assets/styles/view_w-page.scss';

import { MouseEventHandler } from '@remirror/extension-events/dist-types/events-extension';

const CREATE_COMMAND_LABEL = 'Create WPage';
const CREATE_COMMAND_DESCRIPTION = 'Insert WPage Attributes';
const CREATE_COMMAND_SHORTCUT = 'Mod-P';

const toggleWPageOptions: Remirror.CommandDecoratorOptions = {
  icon: 'pageSeparator',
  label: ({ t }) => t(CREATE_COMMAND_LABEL),
  description: ({ t }) => t(CREATE_COMMAND_DESCRIPTION),
  // shortcut: CREATE_COMMAND_SHORTCUT,
};

export type WPageNumberProperty = string;

export interface WPageProperties {
  number: Static<WPageNumberProperty>;
}

export interface WPageAttributes {
  number: Static<WPageNumberProperty>;
}

export const W_PAGE_PROPERTIES: Static<WPageProperties> = {
  number: 'X',
};

const W_PAGE_ATTRIBUTES: Static<WPageAttributes> = {
  number: '',
};

export interface WPageViewClickHandlerProps {
  event: MouseEvent;
  nodeWithPosition?: NodeWithPosition;
}

export interface WPageOptions {
  properties?: Static<WPageProperties>;
  attributes?: Static<WPageAttributes>;

  onViewClick?: Handler<(props: WPageViewClickHandlerProps) => boolean | undefined | void>;
}

@extension<WPageOptions>({
  defaultOptions: {
    properties: W_PAGE_PROPERTIES,
    attributes: W_PAGE_ATTRIBUTES,
  },
  handlerKeys: ['onViewClick'],
  defaultPriority: ExtensionPriority.Medium,
  staticKeys: ['properties', 'attributes'],
})
export class WPageExtension extends NodeExtension<WPageOptions> {
  get name() {
    return 'wPage' as const;
  }

  createTags() {
    return [ExtensionTag.InlineNode, ExtensionTag.Behavior];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      inline: true,
      draggable: true,
      selectable: true,
      content: 'inline*',
      marks: '',
      atom: true,
      isolating: true,
      ...override,
      attrs: {
        ...extra.defaults(),
        number: { default: this.options.attributes.number },
      },
      parseDOM: [
        {
          tag: `w-page`,
          getAttrs: (node) => {
            return isElementDomNode(node)
              ? {
                  ...extra.parse(node),
                  number: node.getAttribute('number') as string,
                }
              : false;
          },
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (mark) => {
        return ['w-page', { number: mark.attrs.number.toString() }];
      },
    };
  }

  createEventHandlers(): CreateEventHandlers {
    return {
      click: (event, clickState) => {
        // Check if this is a direct click which must be the case for atom
        if (!clickState.direct) {
          return;
        }

        const nodeWithPosition = clickState.getNode(this.type);
        // console.log({ nodeWithPosition, clickState });

        if (!nodeWithPosition || nodeWithPosition.node.type !== this.type) {
          return this.options.onViewClick({ event });
        }

        return this.options.onViewClick({ event, nodeWithPosition });
      },
      // mousedown: (event) => {
      //   const { tr } = this.store.view.state;
      //   // @ts-ignore
      //   if (event?.target?.pmViewDesc) {
      //     // @ts-ignore
      //     // const { node, posAfter, posAtStart, posBefore, size } = event.target.pmViewDesc;
      //     // if (node && node.type === this.type) {
      //     //   // console.log({ tr, node, posAfter, posAtStart, posBefore, size });
      //     //   this.store.views.dispatch?.(
      //     //     tr.setSelection(TextSelection.create(tr.doc, posAtStart, posAfter)).scrollIntoView(),
      //     //   );
      //     // }
      //   }
      // },
    };
  }

  /**
   * Inserts a `<w-page number="X"></w-page>` tag into the editor.
   */
  @command(toggleWPageOptions)
  toggleWPage(attrs?: ProsemirrorAttributes, selection?: PrimitiveSelection): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc);
      const node = this.type.create(attrs);

      // @ts-ignore
      dispatch?.(
        tr
          .replaceRangeWith(from, to, node)
          .setSelection(TextSelection.create(tr.doc, from + 1))
          .scrollIntoView(),
      );

      return true;
    };
  }

  @command()
  removeWPage(): CommandFunction {
    return (props) => {
      const { tr, dispatch, state } = props;

      const { from, to } = tr.selection;

      // @ts-ignore
      if (tr.selection?.node.type === this.type) {
        if (dispatch) {
          dispatch(tr.delete(from, to).scrollIntoView());
        }
        return true;
      }
      return false;
    };
  }

  // @command()
  // selectWPage(): CommandFunction {
  //   //   return !isNodeActive({
  //   //     state: tr,
  //   //     type: this.type,
  //   //   });
  //   this.store.commands.selectNode(this.type);
  // }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wPage: WPageExtension;
    }
  }
}
