import React from 'react';
import { Card, theme } from 'antd';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import { useResizeDetector } from 'react-resize-detector';
import { useGlobalCursorStyle, useMobileDetect } from '../../../../hooks';
import '../../assets/styles/Manager.scss';
import { FoldersTree, FoldersTreeStoreProvider } from '../Folders';
import { PublicationList, PublicationListStoreProvider } from '../Publications';
import { ManagerHeader } from './ManagerHeader';
import { PublicationsManagerStoreProvider } from './PublicationsManagerStoreProvider';
import { PublicationsManagerDndProvider } from './PublicationsManagerDndStore';
import { ManagerFooter } from './ManagerFooter';
import { useAppLayoutContext } from '../../../../layout';
import { ResizableLayout } from '../../../../components';

interface ICardGridState {
  gridWidth: ICardGrid;
  setGridWidth: React.Dispatch<React.SetStateAction<ICardGrid>>;
}

interface ICardGrid {
  [key: string]: number;
}

export const Component = (props) => {
  const { minPageContentHeight } = useAppLayoutContext();
  const { ref: bodyWrapperRef } = useResizeDetector();
  const { height: headerWrapperHeight, ref: headerWrapperRef } = useResizeDetector();
  const { height: footerWrapperHeight, ref: footerWrapperRef } = useResizeDetector();
  const { isDesktop } = useMobileDetect();

  const bodyContentStyle: React.CSSProperties = {
    position: 'relative',
    overflow: 'hidden',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'start',
    justifyContent: 'start',
    width: '100%',
  };

  return (
    <Card
      style={{ height: minPageContentHeight || 600 }}
      title={<ManagerHeader ref={headerWrapperRef} />}
      bodyStyle={{
        padding: 0,
        flexWrap: 'nowrap',
      }}
      headStyle={{ minHeight: 0, padding: 8 }}
    >
      <div
        ref={bodyWrapperRef}
        className="managerContentWrapper"
        style={{
          height:
            bodyWrapperRef.current && minPageContentHeight && minPageContentHeight
              ? minPageContentHeight - bodyWrapperRef.current.offsetTop
              : 'auto',
        }}
      >
        <ResizableLayout
          axis="x"
          initial={200}
          startContent={<FoldersTree />}
          endContent={<PublicationList />}
          virtual
          styles={{
            wrapperStyles: {
              ...bodyContentStyle,
              height:
                bodyWrapperRef.current && minPageContentHeight && footerWrapperHeight
                  ? minPageContentHeight - bodyWrapperRef.current.offsetTop - footerWrapperHeight
                  : 'auto',
            },
            endContentStyles: {
              padding: 0,
            },
          }}
        />
        <ManagerFooter ref={footerWrapperRef} />
      </div>
    </Card>
  );
};

export const Manager = (props) => {
  return (
    <PublicationsManagerStoreProvider>
      <FoldersTreeStoreProvider>
        <PublicationListStoreProvider>
          <PublicationsManagerDndProvider>
            <Component {...props} />
          </PublicationsManagerDndProvider>
        </PublicationListStoreProvider>
      </FoldersTreeStoreProvider>
    </PublicationsManagerStoreProvider>
  );
};
