import { Autocomplete, Box, TextField } from '@mui/material';
import React from 'react';

import { W_LANG_PROPERTIES } from '../../extensions';
import { CommandForm, ICommandFormProps } from './CommandForm';

export interface IToggleWLangFormProps extends Omit<ICommandFormProps, 'children' | 'fields'> {
  initialOption?: string;
  initialValues?: any;
  onRemove?: () => void;
}

export const ToggleWLangForm = (props: IToggleWLangFormProps) => {
  const { ...rest } = props;

  return (
    <CommandForm name="wLang" {...rest}>
      {[W_LANG_PROPERTIES.dirList, W_LANG_PROPERTIES.langList].map((list, index) => {
        const name = index === 0 ? 'dir' : 'lang';
        const label = index === 0 ? 'Dir' : 'Lang';
        return (
          <Autocomplete
            key={`wLang-${name}-select-${index}`}
            id={name}
            size="small"
            options={list}
            autoHighlight
            getOptionLabel={(option: any) => option}
            renderOption={(props, option) => {
              props['data-name'] = name;
              return (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {name === 'lang' && (
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.toLowerCase()}.png 2x`}
                      alt=""
                    />
                  )}
                  {option}
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                label={label}
                size="small"
                variant="standard"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
        );
      })}
    </CommandForm>
  );
};
