import { NodeWithPosition } from '@remirror/core';
import { TextSelection } from '@remirror/pm/state';
import { NodeViewComponentProps, useExtensionEvent } from '@remirror/react';
import React, { ComponentType, useCallback, useEffect, useState } from 'react';

import { EditorSubField, useWNoteState, WNoteExtension } from '../index';

export const WNoteHeaderView: ComponentType<NodeViewComponentProps> = (props) => {
  const { node, forwardRef, view, selected } = props;

  const [viewNodeWithPosition, setViewNodeWithPosition] = useState<NodeWithPosition | undefined>(undefined);

  useExtensionEvent(
    WNoteExtension,
    'onViewClick',
    useCallback(
      (props) => {
        const firstChild = props?.nodeWithPosition?.node?.firstChild;
        if (viewNodeWithPosition || firstChild !== node) {
          setViewNodeWithPosition(undefined);
          return;
        }
        setViewNodeWithPosition(props.nodeWithPosition);
      },
      [viewNodeWithPosition, node, props],
    ),
  );

  return (
    <EditorSubField style={{ display: viewNodeWithPosition ? 'block' : 'none' }} label="Header Content">
      <div ref={forwardRef} className="w-note-body" />
    </EditorSubField>
  );
};
