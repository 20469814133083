import React, { Key, useEffect, useState } from 'react';
import { theme, Tree } from 'antd';
import debounce from 'lodash/debounce';
import RcTree from 'rc-tree';
import { createStyles } from 'antd-style';
import { useResizeDetector } from 'react-resize-detector';
import { TreeProps } from 'antd/lib';
import { DirectoryTreeProps } from 'antd/es/tree';
import { ChaptersTreeNodeType, usePublicationChaptersReaderStore } from './PublicationChaptersReaderStoreProvider';

const useChaptersReaderTocStyles = createStyles((props) => {
  return {
    ChaptersReaderTocTree: {
      '.ant-tree-node-content-wrapper': {
        overflow: 'hidden !important',
      },
      '.ant-tree-title': {
        display: 'block',
        width: '100%',
        textAlign: 'start',
        textWrap: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '.ant-tree-indent-unit': {
        width: 10,
      },
    },
  };
});

export type IChaptersReaderTocProps = React.PropsWithChildren;
const ChaptersReaderTocComponent: React.ForwardRefRenderFunction<RcTree, DirectoryTreeProps> = (props, ref) => {
  const { height: resizeHeight, ref: resizeRef } = useResizeDetector();
  const treeRef = React.createRef<RcTree>();
  React.useImperativeHandle(ref, () => treeRef.current!);
  const {
    fetchParagraphsLazy,
    publicationChaptersReaderStore,
    chaptersTree,
    activeChapterOrder,
    setScrollToItem,
    setActiveChapterOrder,
  } = usePublicationChaptersReaderStore();
  const { styles } = useChaptersReaderTocStyles();
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);

  const onDebounceExpand = debounce(
    (event: React.MouseEvent<HTMLElement>, node: any) => {
      const { isLeaf, expanded, children } = node;

      if (isLeaf || children?.length === 0 || event.shiftKey || event.metaKey || event.ctrlKey) {
        return;
      }
      // Call internal rc-tree expand function
      // https://github.com/ant-design/ant-design/issues/12567
      treeRef.current?.onNodeExpand(event as any, node);
    },
    200,
    {
      leading: true,
    },
  );

  const onExpand: TreeProps<ChaptersTreeNodeType>['onExpand'] = (keys, info) => {
    const newKeys = keys.filter((item) => {
      if (info.node?.children && info.node.children.find((j) => j.order === item)) {
        return false;
      }
      return true;
    });
    setExpandedKeys(newKeys);

    return undefined;
  };

  const onClick: TreeProps<ChaptersTreeNodeType>['onClick'] = (event, node) => {
    onDebounceExpand(event, node);
  };

  return (
    <div
      ref={resizeRef}
      style={{
        height: '100%',
      }}
    >
      <Tree<ChaptersTreeNodeType>
        height={resizeHeight || undefined}
        className={styles.ChaptersReaderTocTree}
        fieldNames={{
          key: 'order',
          title: 'title',
          children: 'children',
        }}
        ref={treeRef}
        selectedKeys={[activeChapterOrder || 1]}
        onSelect={(selectedKeys, info) => {
          if (info.node?.order !== activeChapterOrder) {
            const lastChapter = publicationChaptersReaderStore.data?.publication?.chapters?.slice(-1)[0];
            const nextOrder =
              lastChapter?.order && lastChapter.order - info.node.order < 10
                ? info.node.order > 10
                  ? info.node.order - 10
                  : info.node.order
                : info.node.order > 1
                ? info.node.order - 1
                : info.node.order;
            const nextTake = lastChapter?.order === info.node.order ? 10 : 20;
            fetchParagraphsLazy({
              order: nextOrder,
              take: nextTake,
              isRefetch: true,
            })
              .then(() => {
                setActiveChapterOrder(info.node.order);
                setScrollToItem(info.node.order);
              })
              .catch((e) => {
                console.log({ e });
              });
          }
        }}
        treeData={chaptersTree}
        blockNode
        expandedKeys={[...expandedKeys, activeChapterOrder]}
        onExpand={onExpand}
        onClick={onClick}
        autoExpandParent
      />
    </div>
  );
};

export const ChaptersReaderToc = React.forwardRef(ChaptersReaderTocComponent);
