import { createContext, useContext, useMemo } from 'react';
import { IUsePublicationsManagerStoreResult, usePublicationsManagerStore } from './usePublicationsManagerStore';

export const PublicationsManagerStoreContext = createContext<IUsePublicationsManagerStoreResult | undefined>(undefined);
PublicationsManagerStoreContext.displayName = 'PublicationsManagerStoreContext';

export const usePublicationsManagerStoreContext = () => {
  const context = useContext(PublicationsManagerStoreContext);
  if (context === undefined) {
    throw new Error('usePublicationsManagerStoreContext must be used within PublicationsManagerStoreProvider');
  }
  return context;
};

export const PublicationsManagerStoreProvider = ({ children }) => {
  const publicationsManagerStore = usePublicationsManagerStore();

  const publicationsManagerStoreContextObject: IUsePublicationsManagerStoreResult = useMemo(
    () => publicationsManagerStore,
    [publicationsManagerStore],
  );

  return (
    <>
      {publicationsManagerStoreContextObject && (
        <PublicationsManagerStoreContext.Provider value={publicationsManagerStoreContextObject}>
          {children}
        </PublicationsManagerStoreContext.Provider>
      )}
    </>
  );
};
