import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  EditorState,
  EditorView,
  extension,
  ExtensionPriority,
  ExtensionTag,
  Handler,
  NodeExtension,
  NodeSpecOverride,
  NodeWithPosition,
  ProsemirrorNode,
  Static,
  uniqueId,
} from '@remirror/core';
import { CreateEventHandlers } from '@remirror/extension-events';
import { TextSelection } from '@remirror/pm/state';
import { isNodeSelection, NodeViewMethod } from 'remirror';

import { createWNote, CreateWNoteCommand, createWNoteSchema, WNoteSchemaSpec } from './wNote-utils';
import { WNoteBodyExtension } from './WNoteBodyExtension';
import { WNoteHeaderExtension } from './WNoteHeaderExtension';

const CREATE_COMMAND_LABEL = 'Toggle wNote';
const CREATE_COMMAND_DESCRIPTION = 'Create wNote Attributes';
const CREATE_COMMAND_SHORTCUT = 'Mod-N';

const toggleWNoteOptions: Remirror.CommandDecoratorOptions = {
  label: ({ t }) => t(CREATE_COMMAND_LABEL),
  description: ({ t }) => t(CREATE_COMMAND_DESCRIPTION),
  shortcut: CREATE_COMMAND_SHORTCUT,
};

export type WNoteTypeProperty = 'footnote' | 'chapter-endnote' | 'book-endnote';

export interface WNoteProperties {
  typeList: Static<WNoteTypeProperty>[];
}

export interface WNoteAttributes {
  type?: Static<WNoteTypeProperty>;
}

export const W_NOTE_PROPERTIES: Static<WNoteProperties> = {
  typeList: ['footnote', 'chapter-endnote', 'book-endnote'],
};

const W_NOTE_ATTRIBUTES: Static<WNoteAttributes> = {};

export interface WNoteViewClickHandlerProps {
  event: MouseEvent;
  nodeWithPosition?: NodeWithPosition;
}

export interface WNoteOptions {
  properties?: Static<WNoteProperties>;
  attributes?: Static<WNoteAttributes>;
  onViewClick?: Handler<(props: WNoteViewClickHandlerProps) => boolean | undefined | void>;
}

@extension<WNoteOptions>({
  defaultOptions: {
    properties: W_NOTE_PROPERTIES,
    attributes: W_NOTE_ATTRIBUTES,
    extraAttributes: {
      id: () => uniqueId(),
    },
  },
  handlerKeys: ['onViewClick'],
  staticKeys: ['properties', 'attributes'],
  defaultPriority: ExtensionPriority.Low,
})
export class WNoteExtension extends NodeExtension<WNoteOptions> {
  private lastGoodState?: EditorState = undefined;

  get name() {
    return 'wNote' as const;
  }

  createNodeViews(): NodeViewMethod {
    // @ts-ignore
    return (node: ProsemirrorNode, view: EditorView, getPos: boolean | (() => number)) => {
      const { type } = node.attrs;

      const dom = document.createElement('span');
      const contentDOM = document.createElement('w-note');
      contentDOM.setAttribute('type', type);
      const text = node.firstChild?.textContent || '*';
      contentDOM.setAttribute('data-header', text);
      // const wNoteContent = document.createElement('span');
      // wNoteContent.style.display = 'none';
      //
      //
      //
      // contentDOM.append(wNoteContent);
      dom.append(contentDOM);

      const update = (newNode: ProsemirrorNode): boolean => {
        if (newNode.type !== node.type) {
          return false;
        }
        const text = newNode.firstChild?.textContent || '*';
        contentDOM.setAttribute('data-header', text);
        return true;
      };

      update(node);
      return { dom, contentDOM, update };
    };
  }

  createTags() {
    return [ExtensionTag.InlineNode];
  }

  /**
   * The last known good state that didn't need fixing. This helps make the fix
   * command more effective.
   */

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): WNoteSchemaSpec {
    return createWNoteSchema(extra, override).wNote;
  }

  createExtensions(): Array<WNoteHeaderExtension | WNoteBodyExtension> {
    return [
      new WNoteHeaderExtension({ priority: ExtensionPriority.Low }),
      new WNoteBodyExtension({ priority: ExtensionPriority.Low }),
    ];
  }

  createEventHandlers(): CreateEventHandlers {
    return {
      click: (event, clickState) => {
        // Check if this is a direct click which must be the case for atom
        // nodes.
        // event.preventDefault();
        // event.stopPropagation();
        if (!clickState.direct) {
          return;
        }

        const nodeWithPosition = clickState.getNode(this.type);

        if (!nodeWithPosition || nodeWithPosition.node.type !== this.type) {
          return;
        }

        return this.options.onViewClick({ event, nodeWithPosition });
      },
      // mousedown: (event) => {
      //   // event.preventDefault();
      //   const { tr } = this.store.views.state;
      //   // @ts-ignore
      //   if (event?.target?.firstChild?.pmViewDesc?.parent) {
      //     // @ts-ignore
      //     const { node, posAfter, posAtStart, posBefore, size } = event.target.firstChild.pmViewDesc.parent;
      //     if (node && node.type === this.type && posAfter && posAtStart && posBefore) {
      //       console.log({ tr, node, posAfter, posAtStart, posBefore, size });
      //       this.store.views.dispatch?.(
      //         tr.setSelection(TextSelection.create(tr.doc, posAtStart, posAfter)).scrollIntoView(),
      //       );
      //     }
      //   }
      // },
    };
  }

  @command(toggleWNoteOptions)
  toggleWNote(options: CreateWNoteCommand = {}): CommandFunction {
    return (props) => {
      const { tr, dispatch, state } = props;

      if (!tr.selection.empty) {
        return false;
      }

      const $pos = state.selection.$anchor;
      for (let d = $pos.depth; d > 0; d--) {
        const node = $pos.node(d);
        if (node.type.spec.wNoteRole === 'wNote') {
          if (dispatch) {
            dispatch(
              state.tr.setNodeMarkup($pos.before(d), undefined, options.attrs || { type: undefined }).scrollIntoView(),
            );
          }
          return true;
        }
      }

      const offset = tr.selection.anchor + 1;
      const nodes = createWNote({ schema: state.schema, ...options });

      dispatch?.(
        tr
          .replaceSelectionWith(nodes)
          .setSelection(TextSelection.near(tr.doc.resolve(offset)))
          .scrollIntoView(),
      );

      return true;
    };
  }

  @command()
  removeWNote(): CommandFunction {
    return (props) => {
      const { tr, dispatch, state } = props;

      const { from, to, $from } = tr.selection;
      const $pos = state.selection.$anchor;

      if (isNodeSelection(tr.selection) && tr.selection?.node?.type?.name === 'wNote') {
        if (dispatch) dispatch(state.tr.delete(from, to).scrollIntoView());
        return true;
      }

      for (let d = $pos.depth; d > 0; d--) {
        const node = $pos.node(d);
        if (node.type.spec.wNoteRole === 'wNote') {
          if (dispatch) dispatch(state.tr.delete($pos.before(d), $pos.after(d)).scrollIntoView());
          return true;
        }
      }
      return false;

      // @ts-ignore
      // if (tr.selection?.node.type === this.type) {
      //   if (dispatch) {
      //     dispatch(tr.delete(from, to).scrollIntoView());
      //   }
      //   return true;
      // }
      // return false;
    };
  }

  // @command()
  // selectWNote(): CommandFunction {
  //   return !isNodeActive({
  //     state: tr,
  //     type: this.type,
  //   });
  //   return this.store.commands.selectNode(this.type);
  // }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wNote: WNoteExtension;
    }
  }
}
