import React, { FC } from 'react';
import { Badge, Card, Descriptions, List, Space, Tooltip } from 'antd';

import dayjs from 'dayjs';
import { filesize } from 'filesize';
import { RibbonProps } from 'antd/es/badge/Ribbon';
import { ManagerPublicationEpubListItemFragment } from '../../../services/apolloGraphqlAppApiClient/models/types';
import { timeSince } from '../../../utils';
import {
  DeletePublicationEpubActionButton,
  DownloadPublicationEpubActionButton,
  PreviewPublicationEpubActionButton,
  SetPublicationMainEpubActionButton,
} from '../PublicationEpubActions';
import { usePublicationEpubListStyles } from './usePublicationEpubListStyles';

const BadgeListItem: FC<{ withBadge?: boolean } & RibbonProps> = (props) => {
  const { children, withBadge = true, text = 'Main', ...rest } = props;

  if (withBadge) {
    return (
      <Badge.Ribbon text={text} {...rest}>
        {children}
      </Badge.Ribbon>
    );
  }
  return <>{children}</>;
};

export interface IPublicationEpubListItemProps {
  listItem: ManagerPublicationEpubListItemFragment;
  setEpubPreviewState: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      url: ManagerPublicationEpubListItemFragment['uri'];
    }>
  >;
}

export const PublicationEpubListItem: React.FC<IPublicationEpubListItemProps> = (props) => {
  const { listItem, setEpubPreviewState } = props;

  const { styles } = usePublicationEpubListStyles();

  return (
    <List.Item style={{ maxWidth: 250 }} className={styles.item}>
      <BadgeListItem
        style={{ top: 0 }}
        className={styles.itemBadge}
        text={listItem.isMain ? 'main' : <SetPublicationMainEpubActionButton {...listItem} />}
        color={listItem.isMain ? 'green' : 'lightGray'}
      >
        <Card
          className={styles.itemCard}
          size="small"
          bordered={false}
          cover={<PreviewPublicationEpubActionButton record={listItem} setEpubPreviewState={setEpubPreviewState} />}
          actions={[
            <DownloadPublicationEpubActionButton key={`${listItem.id}-download-action`} {...listItem} />,
            <DeletePublicationEpubActionButton key={`${listItem.id}-delete-action`} {...listItem} />,
          ]}
        >
          <Card.Meta
            description={
              <Space align="end">
                <Descriptions
                  className={styles.itemDescription}
                  // bordered
                  colon={false}
                  layout="vertical"
                  column={2}
                  items={[
                    {
                      key: '2',
                      label: 'File size',
                      children: filesize(listItem.size),
                    },
                    {
                      key: '4',
                      label: 'Upload date',
                      children: (
                        <Tooltip
                          placement="bottom"
                          title={dayjs(listItem.createdAt).format('dddd, MMMM D, YYYY h:mm A')}
                        >
                          <div className="epub-upload-date">{timeSince(listItem.createdAt) || ''}</div>
                        </Tooltip>
                      ),
                    },
                  ]}
                />
              </Space>
            }
          />
        </Card>
      </BadgeListItem>
    </List.Item>
  );
};
