import React from 'react';
import { Table, TableProps } from 'antd';
import { CONSTANTS } from '../../../config';
import { useDebounce } from '../../../hooks';
import { ManagerPublicationCoverTypeListItemFragment } from '../../../services/apolloGraphqlAppApiClient/models/types';
import { PublicationCoverTypeListTitle } from './PublicationCoverTypeListTitle';
import { PublicationCoverTypesListItemActionsRender } from './PublicationCoverTypeActions';
import {
  TPublicationCoverTypesManagerFilter,
  usePublicationCoverTypesManagerStore,
} from '../PublicationCoverTypesManagerStoreProvider';

export const PublicationCoverTypesList = (props) => {
  const { publicationCoverTypesManagerStore, handlePageSearchPropsChange, antdPagination } =
    usePublicationCoverTypesManagerStore();

  const handleSearch = useDebounce((values: TPublicationCoverTypesManagerFilter) => {
    handlePageSearchPropsChange({ filter: values });
  }, 500);

  const columns: TableProps<ManagerPublicationCoverTypeListItemFragment>['columns'] = [
    {
      key: 'code',
      dataIndex: 'code',
      title: 'Code',
      width: 70,
      // defaultSortOrder: sortParsers.getTableFieldOrder('code', sort),
      // sorter: {
      //     multiple: 1,
      // },
    },
    {
      key: 'coverCount',
      dataIndex: 'coverCount',
      title: 'Cover Count',
      width: 40,
      // defaultSortOrder: sortParsers.getTableFieldOrder('name', sort),
      // sorter: {
      //     multiple: 2,
      // },
    },
    {
      key: 'minWidth',
      dataIndex: 'minWidth',
      title: 'Min Width',
      width: 40,
      // defaultSortOrder: sortParsers.getTableFieldOrder('name', sort),
      // sorter: {
      //     multiple: 2,
      // },
    },
    {
      key: 'minHeight',
      dataIndex: 'minHeight',
      title: 'Min Height',
      width: 40,
      // defaultSortOrder: sortParsers.getTableFieldOrder('name', sort),
      // sorter: {
      //     multiple: 2,
      // },
    },
    {
      key: 'aspectRatio',
      dataIndex: 'aspectRatio',
      title: 'Aspect Ratio',
      width: 40,
      render: (value, record, index) => {
        return (record.minWidth / record.minHeight).toFixed(3);
      },
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'Description',
      width: 200,
      // defaultSortOrder: sortParsers.getTableFieldOrder('name', sort),
      // sorter: {
      //     multiple: 2,
      // },
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      title: 'Actions',
      width: 40,
      render: PublicationCoverTypesListItemActionsRender,
    },
  ];

  const showTotal = (total: number) => `Total ${total} cover types`;

  return (
    <Table<ManagerPublicationCoverTypeListItemFragment>
      bordered
      title={PublicationCoverTypeListTitle}
      size="small"
      rowKey="code"
      loading={publicationCoverTypesManagerStore.loading}
      pagination={{
        total: publicationCoverTypesManagerStore.data?.coverTypes?.totalCount,
        showTotal,
        position: ['bottomRight', 'topRight'],
        style: { margin: '5px 0' },
        showSizeChanger: true,
        showQuickJumper: true,
        pageSize: antdPagination?.pageSize,
        defaultPageSize: antdPagination?.pageSize,
        pageSizeOptions: ['1', `${CONSTANTS.APP_DEFAULT_LIST_SIZE}`, '50', '100'],
        defaultCurrent: antdPagination?.current,
        current: antdPagination?.current,
        hideOnSinglePage: false,
        showTitle: false,
      }}
      onChange={(pagination, filters, sorter) => {
        // @ts-ignore
        const { current, pageSize } = pagination;
        if (current && pageSize) {
          handlePageSearchPropsChange({
            pagination: { skip: ((current || 1) - 1) * (pageSize || 20), take: pageSize },
          });
        }
      }}
      columns={columns}
      dataSource={publicationCoverTypesManagerStore.data?.coverTypes?.items || []}
      scroll={{ x: 1000 }}
    />
  );
};
