import { Space, Typography } from 'antd';
import React, { CSSProperties } from 'react';
import { ManagerPublicationListItemFragment } from '../../../../services/apolloGraphqlAppApiClient/models/types';
import { PublicationListItemContextActionsRender } from './PublicationListItemContextActionsRender';
import { ManagerPublicationTypeIcon } from '../Manager';
import { PreventOnClickParentsEvent } from '../../../../components';

export interface IPublicationListItemTitleRenderProps {
  value?: any;
  record: ManagerPublicationListItemFragment;
  index?: number;
  style?: CSSProperties;
  propRef?: React.Ref<HTMLDivElement> | ((instance: HTMLDivElement | null) => void);
  isEllipsis?: boolean;
  withActions?: boolean;
}

export const PublicationListItemTitleRender = (props: IPublicationListItemTitleRenderProps) => {
  const { withActions = true, isEllipsis = false, value, record, index, propRef, ...restProps } = props;
  if (withActions) {
    return (
      <Space>
        <PreventOnClickParentsEvent>
          <ManagerPublicationTypeIcon type={record?.type} ref={propRef} {...restProps} />
        </PreventOnClickParentsEvent>
        <PublicationListItemContextActionsRender publicationData={record}>
          <Typography.Text
            italic
            className="contextMenuCursor"
            style={{
              ...(isEllipsis ? { maxWidth: 100, display: 'inline-block' } : {}),
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {record?.title}
          </Typography.Text>
        </PublicationListItemContextActionsRender>
      </Space>
    );
  }
  return (
    <Space>
      <ManagerPublicationTypeIcon type={record?.type} ref={propRef} {...restProps} />
      <Typography.Text
        italic
        style={{
          ...(isEllipsis ? { maxWidth: 100, display: 'inline-block' } : {}),
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {record?.title}
      </Typography.Text>
    </Space>
  );
};
