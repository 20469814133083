import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Long: { input: any; output: any; }
  Mp3FileName: { input: any; output: any; }
  ParaId: { input: any; output: any; }
  URL: { input: any; output: any; }
  UUID: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

/** Add a paragraph */
export type AddParagraphInput = {
  /** Element before that added new paragraph */
  before?: InputMaybe<Scalars['Int']['input']>;
  /** Content of new paragraph */
  content: Scalars['String']['input'];
  /** ID of publication for paragraph */
  publicationId: Scalars['Int']['input'];
};

export type AddParagraphPayload = {
  __typename?: 'AddParagraphPayload';
  paragraphDto?: Maybe<ParagraphDto>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

/** A segment of a collection. */
export type ArchiveCollectionSegment = {
  __typename?: 'ArchiveCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<ArchivedPublicationDto>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ArchivedPublicationDto = {
  __typename?: 'ArchivedPublicationDto';
  /** Archived publication version */
  archivedAt: Scalars['DateTime']['output'];
  /** Unique identifier */
  id: Scalars['UUID']['output'];
  /** Publication identifier */
  publicationId: Scalars['Int']['output'];
};

export type ArchivedPublicationDtoFilterInput = {
  and?: InputMaybe<Array<ArchivedPublicationDtoFilterInput>>;
  /** Archived publication version */
  archivedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Unique identifier */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ArchivedPublicationDtoFilterInput>>;
  /** Publication identifier */
  publicationId?: InputMaybe<IntOperationFilterInput>;
};

export type ArchivedPublicationDtoSortInput = {
  /** Archived publication version */
  archivedAt?: InputMaybe<SortEnumType>;
  /** Unique identifier */
  id?: InputMaybe<SortEnumType>;
  /** Publication identifier */
  publicationId?: InputMaybe<SortEnumType>;
};

/** Mp3 file item */
export type AssignedMp3FileInput = {
  /** Mp3 file name */
  file: Scalars['Mp3FileName']['input'];
  /** Paragraph Id */
  paraId: Scalars['ParaId']['input'];
};

/** A segment of a collection. */
export type AuthorsCollectionSegment = {
  __typename?: 'AuthorsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PublicationAuthorDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** Background task */
export type BackgroundTask = {
  __typename?: 'BackgroundTask';
  /** Task end date */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Error message */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** Task ID */
  id: Scalars['UUID']['output'];
  /** Task start date */
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Task status */
  status: BackgroundTaskStatus;
  /** Task title */
  title: Scalars['String']['output'];
};

/** Background task status */
export enum BackgroundTaskStatus {
  /** Task is completed successfully */
  Completed = 'COMPLETED',
  /** Task is failed */
  Failed = 'FAILED',
  /** Task is */
  Pending = 'PENDING',
  /** Task is started */
  Started = 'STARTED'
}

/** Bible metadata */
export type BibleMetadata = {
  __typename?: 'BibleMetadata';
  /** Bible book */
  book: Scalars['String']['output'];
  /** Bible chapter */
  chapter: Scalars['Int']['output'];
  /** Bible verse */
  verses: Array<Scalars['Int']['output']>;
};

/** Bible metadata */
export type BibleMetadataFilterInput = {
  and?: InputMaybe<Array<BibleMetadataFilterInput>>;
  /** Bible book */
  book?: InputMaybe<StringOperationFilterInput>;
  /** Bible chapter */
  chapter?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<BibleMetadataFilterInput>>;
  /** Bible verse */
  verses?: InputMaybe<ListIntOperationFilterInput>;
};

/** Bible metadata */
export type BibleMetadataSortInput = {
  /** Bible book */
  book?: InputMaybe<SortEnumType>;
  /** Bible chapter */
  chapter?: InputMaybe<SortEnumType>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Changes folder type */
export type ChangeFolderTypeInput = {
  /** Folder ID */
  id: Scalars['Int']['input'];
  /** New folder Type */
  type: Scalars['String']['input'];
};

export type ChangeFolderTypePayload = {
  __typename?: 'ChangeFolderTypePayload';
  folderDto?: Maybe<FolderDto>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

/** Cover DTO */
export type CoverDto = {
  __typename?: 'CoverDto';
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** Media format */
  format: MediaFormatEnum;
  /** Original height */
  height: Scalars['Int']['output'];
  /** Cover ID */
  id: Scalars['UUID']['output'];
  /** Is main cover for a publication/type */
  isMain: Scalars['Boolean']['output'];
  /** Publication identifier */
  publicationId: Scalars['Int']['output'];
  /** Original file size */
  size: Scalars['Long']['output'];
  thumbnail?: Maybe<Scalars['URL']['output']>;
  /** Cover type */
  type?: Maybe<CoverTypeDto>;
  /** Cover type code */
  typeId: Scalars['String']['output'];
  /** Image URI */
  uri?: Maybe<Scalars['URL']['output']>;
  /** Original width */
  width: Scalars['Int']['output'];
};

/** Cover DTO */
export type CoverDtoThumbnailArgs = {
  height: Scalars['Int']['input'];
  resize?: InputMaybe<ResizeTypeEnum>;
  width: Scalars['Int']['input'];
};

/** Cover DTO */
export type CoverDtoFilterInput = {
  and?: InputMaybe<Array<CoverDtoFilterInput>>;
  /** Created at */
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Media format */
  format?: InputMaybe<MediaFormatEnumOperationFilterInput>;
  /** Original height */
  height?: InputMaybe<IntOperationFilterInput>;
  /** Cover ID */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Is main cover for a publication/type */
  isMain?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<CoverDtoFilterInput>>;
  /** Publication identifier */
  publicationId?: InputMaybe<IntOperationFilterInput>;
  /** Original file size */
  size?: InputMaybe<LongOperationFilterInput>;
  /** Cover type code */
  typeId?: InputMaybe<StringOperationFilterInput>;
  /** Image URI */
  uri?: InputMaybe<UrlOperationFilterInput>;
  /** Original width */
  width?: InputMaybe<IntOperationFilterInput>;
};

/** Cover DTO */
export type CoverDtoSortInput = {
  /** Created at */
  createdAt?: InputMaybe<SortEnumType>;
  /** Media format */
  format?: InputMaybe<SortEnumType>;
  /** Original height */
  height?: InputMaybe<SortEnumType>;
  /** Cover ID */
  id?: InputMaybe<SortEnumType>;
  /** Is main cover for a publication/type */
  isMain?: InputMaybe<SortEnumType>;
  /** Publication identifier */
  publicationId?: InputMaybe<SortEnumType>;
  /** Original file size */
  size?: InputMaybe<SortEnumType>;
  /** Cover type code */
  typeId?: InputMaybe<SortEnumType>;
  /** Image URI */
  uri?: InputMaybe<UriSortInput>;
  /** Original width */
  width?: InputMaybe<SortEnumType>;
};

/** Cover type */
export type CoverTypeDto = {
  __typename?: 'CoverTypeDto';
  /** Cover type code */
  code: Scalars['String']['output'];
  /** Number of uploaded covers of this type. */
  coverCount: Scalars['Int']['output'];
  /** Cover type description. */
  description?: Maybe<Scalars['String']['output']>;
  /** Minimum width of the cover type. If null, minimal width is not specified. */
  minHeight: Scalars['Int']['output'];
  /** Minimum width of the cover type. If null, minimal width is not specified. */
  minWidth: Scalars['Int']['output'];
};

/** Cover type */
export type CoverTypeDtoFilterInput = {
  and?: InputMaybe<Array<CoverTypeDtoFilterInput>>;
  /** Cover type code */
  code?: InputMaybe<StringOperationFilterInput>;
  /** Number of uploaded covers of this type. */
  coverCount?: InputMaybe<IntOperationFilterInput>;
  /** Cover type description. */
  description?: InputMaybe<StringOperationFilterInput>;
  /** Minimum width of the cover type. If null, minimal width is not specified. */
  minHeight?: InputMaybe<IntOperationFilterInput>;
  /** Minimum width of the cover type. If null, minimal width is not specified. */
  minWidth?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<CoverTypeDtoFilterInput>>;
};

/** Cover type */
export type CoverTypeDtoSortInput = {
  /** Cover type code */
  code?: InputMaybe<SortEnumType>;
  /** Number of uploaded covers of this type. */
  coverCount?: InputMaybe<SortEnumType>;
  /** Cover type description. */
  description?: InputMaybe<SortEnumType>;
  /** Minimum width of the cover type. If null, minimal width is not specified. */
  minHeight?: InputMaybe<SortEnumType>;
  /** Minimum width of the cover type. If null, minimal width is not specified. */
  minWidth?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type CoverTypesCollectionSegment = {
  __typename?: 'CoverTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<CoverTypeDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** A segment of a collection. */
export type CoversCollectionSegment = {
  __typename?: 'CoversCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<CoverDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** Create cover type input */
export type CreateCoverTypeInput = {
  /** Cover type code */
  code: Scalars['String']['input'];
  /** Cover type description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Publication required int MinHeight */
  minHeight: Scalars['Int']['input'];
  /** Publication required int MinWidth */
  minWidth: Scalars['Int']['input'];
};

export type CreateCoverTypePayload = {
  __typename?: 'CreateCoverTypePayload';
  coverTypeDto?: Maybe<CoverTypeDto>;
};

/** Creates a new folder at the end of specified parent folder */
export type CreateFolderInput = {
  /** Parent folder ID */
  parentId: Scalars['Int']['input'];
  /** Title */
  title: Scalars['String']['input'];
  /** Type */
  type: Scalars['String']['input'];
};

export type CreateFolderPayload = {
  __typename?: 'CreateFolderPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

/** Creates a new language */
export type CreateLanguageInput = {
  /** BCP47 language code */
  bcp47Code: Scalars['String']['input'];
  /** Language code */
  code: Scalars['String']['input'];
  /** Language direction */
  direction: WemlTextDirection;
  /** EGW language code */
  egwCode: Scalars['String']['input'];
  /** Language title */
  title: Scalars['String']['input'];
};

export type CreateLanguagePayload = {
  __typename?: 'CreateLanguagePayload';
  languageDto?: Maybe<LanguageDto>;
};

/** Create folder for language */
export type CreateRootFolderForLanguageInput = {
  /** Language code */
  code: Scalars['String']['input'];
};

export type CreateRootFolderForLanguagePayload = {
  __typename?: 'CreateRootFolderForLanguagePayload';
  languageDto?: Maybe<LanguageDto>;
};

export type DateOperationFilterInput = {
  eq?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  neq?: InputMaybe<Scalars['Date']['input']>;
  ngt?: InputMaybe<Scalars['Date']['input']>;
  ngte?: InputMaybe<Scalars['Date']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  nlt?: InputMaybe<Scalars['Date']['input']>;
  nlte?: InputMaybe<Scalars['Date']['input']>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Delete cover type input */
export type DeleteCoverTypeInput = {
  /** Cover type code */
  code: Scalars['String']['input'];
};

export type DeleteCoverTypePayload = {
  __typename?: 'DeleteCoverTypePayload';
  coverTypeDto?: Maybe<CoverTypeDto>;
};

/** Delete publication from archive */
export type DeleteEntryFromArchiveInput = {
  /** Archive entity ID */
  id: Scalars['UUID']['input'];
  /** Publication ID */
  publicationId: Scalars['Int']['input'];
};

export type DeleteEntryFromArchivePayload = {
  __typename?: 'DeleteEntryFromArchivePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

/** Delete export file */
export type DeleteExportFileInput = {
  /** File ID */
  id: Scalars['UUID']['input'];
};

export type DeleteExportFilePayload = {
  __typename?: 'DeleteExportFilePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

/** Delete a folder by ID */
export type DeleteFolderInput = {
  id: Scalars['Int']['input'];
};

export type DeleteFolderPayload = {
  __typename?: 'DeleteFolderPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

/** Deletes a language */
export type DeleteLanguageInput = {
  /** Language code */
  code: Scalars['String']['input'];
};

export type DeleteLanguagePayload = {
  __typename?: 'DeleteLanguagePayload';
  languageDto?: Maybe<LanguageDto>;
};

/** Deletes a paragraph */
export type DeleteParagraphInput = {
  /** Paragraph to deleting */
  paraId: Scalars['ParaId']['input'];
};

export type DeleteParagraphPayload = {
  __typename?: 'DeleteParagraphPayload';
  paraId?: Maybe<Scalars['ParaId']['output']>;
};

/** Export epub */
export type ExportEpubInput = {
  /** Publication id */
  publicationId: Scalars['Int']['input'];
};

export type ExportEpubPayload = {
  __typename?: 'ExportEpubPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

/** Export files enum */
export enum ExportTypeEnum {
  /** EPub file */
  Epub = 'EPUB',
  /** Kindle Mobi file */
  Mobi = 'MOBI',
  /** Mp3 file */
  Mp3 = 'MP3',
  /** PDF File */
  Pdf = 'PDF'
}

export type ExportTypeEnumOperationFilterInput = {
  eq?: InputMaybe<ExportTypeEnum>;
  in?: InputMaybe<Array<ExportTypeEnum>>;
  neq?: InputMaybe<ExportTypeEnum>;
  nin?: InputMaybe<Array<ExportTypeEnum>>;
};

/**
 * Exported file
 * ExportedFile
 */
export type ExportedFileDto = {
  __typename?: 'ExportedFileDto';
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** Unique file identifier */
  id: Scalars['UUID']['output'];
  /** Is main export */
  isMain: Scalars['Boolean']['output'];
  /** Publication ID */
  publicationId: Scalars['Int']['output'];
  /** File size */
  size: Scalars['Long']['output'];
  /** Exported type enum */
  type: ExportTypeEnum;
  /** Updated at */
  updatedAt: Scalars['DateTime']['output'];
  /** File URI */
  uri?: Maybe<Scalars['URL']['output']>;
};

/**
 * Exported file
 * ExportedFile
 */
export type ExportedFileDtoFilterInput = {
  and?: InputMaybe<Array<ExportedFileDtoFilterInput>>;
  /** Created at */
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Unique file identifier */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Is main export */
  isMain?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ExportedFileDtoFilterInput>>;
  /** Publication ID */
  publicationId?: InputMaybe<IntOperationFilterInput>;
  /** File size */
  size?: InputMaybe<LongOperationFilterInput>;
  /** Exported type enum */
  type?: InputMaybe<ExportTypeEnumOperationFilterInput>;
  /** Updated at */
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** File URI */
  uri?: InputMaybe<UrlOperationFilterInput>;
};

/**
 * Exported file
 * ExportedFile
 */
export type ExportedFileDtoSortInput = {
  /** Created at */
  createdAt?: InputMaybe<SortEnumType>;
  /** Unique file identifier */
  id?: InputMaybe<SortEnumType>;
  /** Is main export */
  isMain?: InputMaybe<SortEnumType>;
  /** Publication ID */
  publicationId?: InputMaybe<SortEnumType>;
  /** File size */
  size?: InputMaybe<SortEnumType>;
  /** Exported type enum */
  type?: InputMaybe<SortEnumType>;
  /** Updated at */
  updatedAt?: InputMaybe<SortEnumType>;
  /** File URI */
  uri?: InputMaybe<UriSortInput>;
};

/** A segment of a collection. */
export type ExportedFilesCollectionSegment = {
  __typename?: 'ExportedFilesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ExportedFileDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** DTO for Folder */
export type FolderDto = {
  __typename?: 'FolderDto';
  /** Child folder count */
  childFolderCount: Scalars['Int']['output'];
  /** Child publication count */
  childPublicationCount: Scalars['Int']['output'];
  childPublicationCountRecursive: Scalars['Int']['output'];
  /** Date of creation */
  createdAt: Scalars['DateTime']['output'];
  /** Unique folder id */
  id: Scalars['Int']['output'];
  /** Language */
  language?: Maybe<LanguageDto>;
  /** Order */
  order: Scalars['Int']['output'];
  /** Folder parent ID */
  parentId?: Maybe<Scalars['Int']['output']>;
  /** Folder type */
  parents: Array<FolderDto>;
  /** Folder type ID */
  path: Array<Scalars['Int']['output']>;
  /** Child publications */
  publications: Array<PublicationDto>;
  /** Title */
  title: Scalars['String']['output'];
  /** Folder type */
  type?: Maybe<FolderTypeDto>;
  /** Folder type ID */
  typeId: Scalars['String']['output'];
  /** Date of last modification */
  updatedAt: Scalars['DateTime']['output'];
};

/** DTO for Folder */
export type FolderDtoFilterInput = {
  and?: InputMaybe<Array<FolderDtoFilterInput>>;
  /** Child folder count */
  childFolderCount?: InputMaybe<IntOperationFilterInput>;
  /** Child publication count */
  childPublicationCount?: InputMaybe<IntOperationFilterInput>;
  /** Date of creation */
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Unique folder id */
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<FolderDtoFilterInput>>;
  /** Order */
  order?: InputMaybe<IntOperationFilterInput>;
  /** Folder parent ID */
  parentId?: InputMaybe<IntOperationFilterInput>;
  /** Folder type ID */
  path?: InputMaybe<ListIntOperationFilterInput>;
  /** Title */
  title?: InputMaybe<StringOperationFilterInput>;
  /** Folder type ID */
  typeId?: InputMaybe<StringOperationFilterInput>;
  /** Date of last modification */
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

/** DTO for Folder */
export type FolderDtoSortInput = {
  /** Child folder count */
  childFolderCount?: InputMaybe<SortEnumType>;
  /** Child publication count */
  childPublicationCount?: InputMaybe<SortEnumType>;
  /** Date of creation */
  createdAt?: InputMaybe<SortEnumType>;
  /** Unique folder id */
  id?: InputMaybe<SortEnumType>;
  /** Order */
  order?: InputMaybe<SortEnumType>;
  /** Folder parent ID */
  parentId?: InputMaybe<SortEnumType>;
  /** Title */
  title?: InputMaybe<SortEnumType>;
  /** Folder type ID */
  typeId?: InputMaybe<SortEnumType>;
  /** Date of last modification */
  updatedAt?: InputMaybe<SortEnumType>;
};

/** DTO For FolderType */
export type FolderTypeDto = {
  __typename?: 'FolderTypeDto';
  /** Allowed publication types */
  allowedTypes: Array<PublicationType>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** Folder type Id */
  id: Scalars['String']['output'];
  /** Folder type name */
  title: Scalars['String']['output'];
  /** Updated at */
  updatedAt: Scalars['DateTime']['output'];
};

/** DTO For FolderType */
export type FolderTypeDtoFilterInput = {
  /** Allowed publication types */
  allowedTypes?: InputMaybe<ListPublicationTypeOperationFilterInput>;
  and?: InputMaybe<Array<FolderTypeDtoFilterInput>>;
  /** Created at */
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Folder type Id */
  id?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FolderTypeDtoFilterInput>>;
  /** Folder type name */
  title?: InputMaybe<StringOperationFilterInput>;
  /** Updated at */
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

/** DTO For FolderType */
export type FolderTypeDtoSortInput = {
  /** Created at */
  createdAt?: InputMaybe<SortEnumType>;
  /** Folder type Id */
  id?: InputMaybe<SortEnumType>;
  /** Folder type name */
  title?: InputMaybe<SortEnumType>;
  /** Updated at */
  updatedAt?: InputMaybe<SortEnumType>;
};

/** Mp3 file related mutations */
export type GraphQlMutations = {
  __typename?: 'GraphQlMutations';
  /** Add paragraph */
  addParagraph: AddParagraphPayload;
  /** Changes folder type */
  changeFolderType: ChangeFolderTypePayload;
  /** Create cover type */
  createCoverType: CreateCoverTypePayload;
  /** Creates a new folder at the end of specified parent folder */
  createFolder: CreateFolderPayload;
  /** Creates a new language */
  createLanguage: CreateLanguagePayload;
  /** Create a new root folder for a language */
  createRootFolderForLanguage: CreateRootFolderForLanguagePayload;
  /** Delete cover type */
  deleteCoverType: DeleteCoverTypePayload;
  /** Delete archive entry */
  deleteEntryFromArchive: DeleteEntryFromArchivePayload;
  /** Sets main export file */
  deleteExportFile: DeleteExportFilePayload;
  /** Delete a folder by ID */
  deleteFolder: DeleteFolderPayload;
  /** Changes language details */
  deleteLanguage: DeleteLanguagePayload;
  /** Delete paragraph */
  deleteParagraph: DeleteParagraphPayload;
  /** Export */
  exportEpub: ExportEpubPayload;
  /** Moves a folder to a different parent or changes order */
  moveFolder: MoveFolderPayload;
  /** Move publication to a different folder */
  movePublication: MovePublicationPayload;
  /** Publishes a publication */
  publishPublication: PublishPublicationPayload;
  /** Recalculates global order for folders */
  recalculateFolders: RecalculateFoldersPayload;
  recalculatePublication: RecalculatePublicationPayload;
  /** Rename a folder */
  renameFolder: RenameFolderPayload;
  /** Restores archive entry */
  restoreEntryFromArchive: RestoreEntryFromArchivePayload;
  /** Sets publication permissions */
  savePublicationToArchive: SavePublicationToArchivePayload;
  /** Sets main cover of a specified type for a publication */
  setMainCover: SetMainCoverPayload;
  /** Sets main export file */
  setMainExportFile: SetMainExportFilePayload;
  /** Update paragraph content */
  setParagraphContent: SetParagraphContentPayload;
  /**
   * Update following list of publication details:
   * code
   * title(plain text)
   * publisher
   * publication year
   * author
   * description(in weml format)
   * page count
   * ISBN code
   */
  setPublicationMetadata: SetPublicationMetadataPayload;
  /** Sets publication permissions */
  setPublicationOrder: SetPublicationOrderPayload;
  /** Sets publication permissions */
  setPublicationPermission: SetPublicationPermissionPayload;
  /** Set max toc depth for a publication */
  setPublicationTocDepth: SetPublicationTocDepthPayload;
  /** Unpublishes a publication */
  unpublishPublication: UnpublishPublicationPayload;
  /** Update cover type */
  updateCoverType: UpdateCoverTypePayload;
  /** Changes language details */
  updateLanguage: UpdateLanguagePayload;
  /** Assign mp3 files to chapters */
  updateMp3Files: UpdateMp3FilesPayload;
  /** Create or Update manifest with mp3 files metadata */
  updateMp3Manifest: UpdateMp3ManifestPayload;
  /** Recalculates global order for folders */
  uploadCover: UploadCoverPayload;
  /** Upload a file as an export */
  uploadExport: UploadExportPayload;
};

/** Mp3 file related mutations */
export type GraphQlMutationsAddParagraphArgs = {
  input: AddParagraphInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsChangeFolderTypeArgs = {
  input: ChangeFolderTypeInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsCreateCoverTypeArgs = {
  input: CreateCoverTypeInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsCreateFolderArgs = {
  input: CreateFolderInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsCreateLanguageArgs = {
  input: CreateLanguageInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsCreateRootFolderForLanguageArgs = {
  input: CreateRootFolderForLanguageInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsDeleteCoverTypeArgs = {
  input: DeleteCoverTypeInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsDeleteEntryFromArchiveArgs = {
  input: DeleteEntryFromArchiveInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsDeleteExportFileArgs = {
  input: DeleteExportFileInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsDeleteFolderArgs = {
  input: DeleteFolderInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsDeleteLanguageArgs = {
  input: DeleteLanguageInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsDeleteParagraphArgs = {
  input: DeleteParagraphInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsExportEpubArgs = {
  input: ExportEpubInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsMoveFolderArgs = {
  input: MoveFolderInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsMovePublicationArgs = {
  input: MovePublicationInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsPublishPublicationArgs = {
  input: PublishPublicationInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsRecalculatePublicationArgs = {
  input: RecalculatePublicationInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsRenameFolderArgs = {
  input: RenameFolderInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsRestoreEntryFromArchiveArgs = {
  input: RestoreEntryFromArchiveInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsSavePublicationToArchiveArgs = {
  input: SavePublicationToArchiveInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsSetMainCoverArgs = {
  input: SetMainCoverInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsSetMainExportFileArgs = {
  input: SetMainExportFileInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsSetParagraphContentArgs = {
  input: SetParagraphContentInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsSetPublicationMetadataArgs = {
  input: SetPublicationMetadataInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsSetPublicationOrderArgs = {
  input: SetPublicationOrderInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsSetPublicationPermissionArgs = {
  input: SetPublicationPermissionInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsSetPublicationTocDepthArgs = {
  input: SetPublicationTocDepthInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsUnpublishPublicationArgs = {
  input: UnpublishPublicationInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsUpdateCoverTypeArgs = {
  input: UpdateCoverTypeInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsUpdateLanguageArgs = {
  input: UpdateLanguageInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsUpdateMp3FilesArgs = {
  input: UpdateMp3FilesInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsUpdateMp3ManifestArgs = {
  input: UpdateMp3ManifestInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsUploadCoverArgs = {
  input: UploadCoverInput;
};

/** Mp3 file related mutations */
export type GraphQlMutationsUploadExportArgs = {
  input: UploadExportInput;
};

/** Queue extensions */
export type GraphQlQueries = {
  __typename?: 'GraphQlQueries';
  authors?: Maybe<AuthorsCollectionSegment>;
  coverTypes?: Maybe<CoverTypesCollectionSegment>;
  covers?: Maybe<CoversCollectionSegment>;
  exportedFiles?: Maybe<ExportedFilesCollectionSegment>;
  folder?: Maybe<FolderDto>;
  folderTypes: Array<FolderTypeDto>;
  folders: Array<FolderDto>;
  languages: Array<LanguageDto>;
  mp3Files: Array<Mp3FileItem>;
  orderedParagraphs?: Maybe<Array<ParagraphDto>>;
  paragraphs?: Maybe<ParagraphsCollectionSegment>;
  publication?: Maybe<PublicationDto>;
  publicationArchive?: Maybe<PublicationArchiveCollectionSegment>;
  publicationChapters?: Maybe<PublicationChaptersCollectionSegment>;
  publicationMp3Files?: Maybe<PublicationMp3FilesCollectionSegment>;
  publicationSeries?: Maybe<PublicationSeriesCollectionSegment>;
  publications?: Maybe<PublicationsCollectionSegment>;
  /** Queue-related stats */
  queue: QueueNamespace;
};

/** Queue extensions */
export type GraphQlQueriesAuthorsArgs = {
  order?: InputMaybe<Array<PublicationAuthorDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PublicationAuthorDtoFilterInput>;
};

/** Queue extensions */
export type GraphQlQueriesCoverTypesArgs = {
  order?: InputMaybe<Array<CoverTypeDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoverTypeDtoFilterInput>;
};

/** Queue extensions */
export type GraphQlQueriesCoversArgs = {
  order?: InputMaybe<Array<CoverDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoverDtoFilterInput>;
};

/** Queue extensions */
export type GraphQlQueriesExportedFilesArgs = {
  order?: InputMaybe<Array<ExportedFileDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ExportedFileDtoFilterInput>;
};

/** Queue extensions */
export type GraphQlQueriesFolderArgs = {
  folderId: Scalars['Int']['input'];
};

/** Queue extensions */
export type GraphQlQueriesFolderTypesArgs = {
  order?: InputMaybe<Array<FolderTypeDtoSortInput>>;
  where?: InputMaybe<FolderTypeDtoFilterInput>;
};

/** Queue extensions */
export type GraphQlQueriesFoldersArgs = {
  order?: InputMaybe<Array<FolderDtoSortInput>>;
  where?: InputMaybe<FolderDtoFilterInput>;
};

/** Queue extensions */
export type GraphQlQueriesLanguagesArgs = {
  order?: InputMaybe<Array<LanguageDtoSortInput>>;
  where?: InputMaybe<LanguageDtoFilterInput>;
};

/** Queue extensions */
export type GraphQlQueriesMp3FilesArgs = {
  publicationId: Scalars['Int']['input'];
};

/** Queue extensions */
export type GraphQlQueriesOrderedParagraphsArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  paraId: Scalars['ParaId']['input'];
  take?: InputMaybe<Scalars['Int']['input']>;
};

/** Queue extensions */
export type GraphQlQueriesParagraphsArgs = {
  order?: InputMaybe<Array<ParagraphDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParagraphDtoFilterInput>;
};

/** Queue extensions */
export type GraphQlQueriesPublicationArgs = {
  publicationId: Scalars['Int']['input'];
};

/** Queue extensions */
export type GraphQlQueriesPublicationArchiveArgs = {
  order?: InputMaybe<Array<ArchivedPublicationDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchivedPublicationDtoFilterInput>;
};

/** Queue extensions */
export type GraphQlQueriesPublicationChaptersArgs = {
  order?: InputMaybe<Array<PublicationChapterDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PublicationChapterDtoFilterInput>;
};

/** Queue extensions */
export type GraphQlQueriesPublicationMp3FilesArgs = {
  order?: InputMaybe<Array<PublicationMp3FileDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PublicationMp3FileDtoFilterInput>;
};

/** Queue extensions */
export type GraphQlQueriesPublicationSeriesArgs = {
  order?: InputMaybe<Array<PublicationSeriesDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PublicationSeriesDtoFilterInput>;
};

/** Queue extensions */
export type GraphQlQueriesPublicationsArgs = {
  order?: InputMaybe<Array<PublicationDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PublicationDtoFilterInput>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

/** DTO for Language */
export type LanguageDto = {
  __typename?: 'LanguageDto';
  /** BCP47 code (en-US) */
  bcp47Code: Scalars['String']['output'];
  /** Unique publication code (eng) */
  code: Scalars['String']['output'];
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** EGW 2 (3) letter code (en) */
  egwCode: Scalars['String']['output'];
  /** Is right to left */
  isRightToLeft: Scalars['Boolean']['output'];
  rootFolder?: Maybe<FolderDto>;
  /** Root folder Id */
  rootFolderId?: Maybe<Scalars['Int']['output']>;
  /** Language title */
  title: Scalars['String']['output'];
  /** Updated at */
  updatedAt: Scalars['DateTime']['output'];
};

/** DTO for Language */
export type LanguageDtoFilterInput = {
  and?: InputMaybe<Array<LanguageDtoFilterInput>>;
  /** BCP47 code (en-US) */
  bcp47Code?: InputMaybe<StringOperationFilterInput>;
  /** Unique publication code (eng) */
  code?: InputMaybe<StringOperationFilterInput>;
  /** Created at */
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** EGW 2 (3) letter code (en) */
  egwCode?: InputMaybe<StringOperationFilterInput>;
  /** Is right to left */
  isRightToLeft?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<LanguageDtoFilterInput>>;
  /** Root folder Id */
  rootFolderId?: InputMaybe<IntOperationFilterInput>;
  /** Language title */
  title?: InputMaybe<StringOperationFilterInput>;
  /** Updated at */
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

/** DTO for Language */
export type LanguageDtoSortInput = {
  /** BCP47 code (en-US) */
  bcp47Code?: InputMaybe<SortEnumType>;
  /** Unique publication code (eng) */
  code?: InputMaybe<SortEnumType>;
  /** Created at */
  createdAt?: InputMaybe<SortEnumType>;
  /** EGW 2 (3) letter code (en) */
  egwCode?: InputMaybe<SortEnumType>;
  /** Is right to left */
  isRightToLeft?: InputMaybe<SortEnumType>;
  /** Root folder Id */
  rootFolderId?: InputMaybe<SortEnumType>;
  /** Language title */
  title?: InputMaybe<SortEnumType>;
  /** Updated at */
  updatedAt?: InputMaybe<SortEnumType>;
};

export type ListIntOperationFilterInput = {
  all?: InputMaybe<IntOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<IntOperationFilterInput>;
  some?: InputMaybe<IntOperationFilterInput>;
};

export type ListPublicationTypeOperationFilterInput = {
  all?: InputMaybe<PublicationTypeOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PublicationTypeOperationFilterInput>;
  some?: InputMaybe<PublicationTypeOperationFilterInput>;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']['input']>;
  gt?: InputMaybe<Scalars['Long']['input']>;
  gte?: InputMaybe<Scalars['Long']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  lt?: InputMaybe<Scalars['Long']['input']>;
  lte?: InputMaybe<Scalars['Long']['input']>;
  neq?: InputMaybe<Scalars['Long']['input']>;
  ngt?: InputMaybe<Scalars['Long']['input']>;
  ngte?: InputMaybe<Scalars['Long']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  nlt?: InputMaybe<Scalars['Long']['input']>;
  nlte?: InputMaybe<Scalars['Long']['input']>;
};

/** Metadata for letters/manuscripts */
export type LtMsMetadata = {
  __typename?: 'LtMsMetadata';
  /** Manuscript addressee */
  addressee?: Maybe<Scalars['String']['output']>;
  /** Manuscript place */
  place?: Maybe<Scalars['String']['output']>;
  /** Manuscript title */
  title?: Maybe<Scalars['String']['output']>;
};

/** Metadata for letters/manuscripts */
export type LtMsMetadataFilterInput = {
  /** Manuscript addressee */
  addressee?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<LtMsMetadataFilterInput>>;
  or?: InputMaybe<Array<LtMsMetadataFilterInput>>;
  /** Manuscript place */
  place?: InputMaybe<StringOperationFilterInput>;
  /** Manuscript title */
  title?: InputMaybe<StringOperationFilterInput>;
};

/** Metadata for letters/manuscripts */
export type LtMsMetadataSortInput = {
  /** Manuscript addressee */
  addressee?: InputMaybe<SortEnumType>;
  /** Manuscript place */
  place?: InputMaybe<SortEnumType>;
  /** Manuscript title */
  title?: InputMaybe<SortEnumType>;
};

/** Media format */
export enum MediaFormatEnum {
  /** JPEG */
  Jpeg = 'JPEG',
  /** PNG */
  Png = 'PNG'
}

export type MediaFormatEnumOperationFilterInput = {
  eq?: InputMaybe<MediaFormatEnum>;
  in?: InputMaybe<Array<MediaFormatEnum>>;
  neq?: InputMaybe<MediaFormatEnum>;
  nin?: InputMaybe<Array<MediaFormatEnum>>;
};

/** Move Folder Command */
export type MoveFolderInput = {
  /** Folder Id */
  id: Scalars['Int']['input'];
  /** New Parent Folder Id */
  newParent?: InputMaybe<Scalars['Int']['input']>;
  /** New Position */
  newPosition?: InputMaybe<Scalars['Int']['input']>;
};

export type MoveFolderPayload = {
  __typename?: 'MoveFolderPayload';
  folderDto?: Maybe<FolderDto>;
};

/** Sets publication max toc level */
export type MovePublicationInput = {
  /** New folder ID */
  folderId: Scalars['Int']['input'];
  /** New order to place publication into */
  order?: InputMaybe<Scalars['Int']['input']>;
  /** Publication ID */
  publicationId: Scalars['Int']['input'];
};

export type MovePublicationPayload = {
  __typename?: 'MovePublicationPayload';
  publicationDto?: Maybe<PublicationDto>;
};

/** List of mp3 files from cloud */
export type Mp3FileItem = {
  __typename?: 'Mp3FileItem';
  /** File name */
  fileName: Scalars['String']['output'];
  /** Information from filename */
  parsedData?: Maybe<Mp3ParsedData>;
};

/** Information from filename */
export type Mp3ParsedData = {
  __typename?: 'Mp3ParsedData';
  /** File number (first 4 digits) */
  fileNumber: Scalars['String']['output'];
  /** Paragraph Id */
  paraId: Scalars['ParaId']['output'];
  /** Path to file (without base bucket) */
  path: Scalars['String']['output'];
  /** Voice */
  voice: VoiceTypeEnum;
};

/** Pagination metadata */
export type PaginationMetaData = {
  __typename?: 'PaginationMetaData';
  /** Pagination paragraph */
  paragraph: Scalars['Int']['output'];
  /** Pagination section */
  section: Scalars['String']['output'];
};

/** Pagination metadata */
export type PaginationMetaDataFilterInput = {
  and?: InputMaybe<Array<PaginationMetaDataFilterInput>>;
  or?: InputMaybe<Array<PaginationMetaDataFilterInput>>;
  /** Pagination paragraph */
  paragraph?: InputMaybe<IntOperationFilterInput>;
  /** Pagination section */
  section?: InputMaybe<StringOperationFilterInput>;
};

/** Pagination metadata */
export type PaginationMetaDataSortInput = {
  /** Pagination paragraph */
  paragraph?: InputMaybe<SortEnumType>;
  /** Pagination section */
  section?: InputMaybe<SortEnumType>;
};

export type ParaIdFilterInputType = {
  eq?: InputMaybe<Scalars['ParaId']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ParaId']['input']>>>;
  neq?: InputMaybe<Scalars['ParaId']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['ParaId']['input']>>>;
};

/** Paragraph DTO */
export type ParagraphDto = {
  __typename?: 'ParagraphDto';
  /** Bible metadata */
  bibleMetadata?: Maybe<BibleMetadata>;
  /** Paragraph content */
  content: Scalars['String']['output'];
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** Paragraph date */
  date?: Maybe<Scalars['Date']['output']>;
  /** End date for paragraph */
  endDate?: Maybe<Scalars['Date']['output']>;
  /** Heading level: null for pages, 0 for paragraphs, 1 for headings, 2 for subheadings, etc. */
  headingLevel?: Maybe<Scalars['Int']['output']>;
  /** Is referenced */
  isReferenced: Scalars['Boolean']['output'];
  /** Has manuscript metadata */
  ltMsMetadata?: Maybe<LtMsMetadata>;
  /** Paragraph order (unique in book) */
  order: Scalars['Int']['output'];
  /** Pagination section */
  pagination?: Maybe<PaginationMetaData>;
  /** Paragraph ID (unique key) */
  paraId: Scalars['ParaId']['output'];
  /** Paragraph ID (unique in book) */
  paragraphId: Scalars['Int']['output'];
  /** Publication id */
  publicationId: Scalars['Int']['output'];
  /** Long reference code */
  refCodeLong?: Maybe<Scalars['String']['output']>;
  /** Short reference code */
  refCodeShort?: Maybe<Scalars['String']['output']>;
  /** Updated at */
  updatedAt: Scalars['DateTime']['output'];
};

/** Paragraph DTO */
export type ParagraphDtoFilterInput = {
  and?: InputMaybe<Array<ParagraphDtoFilterInput>>;
  /** Bible metadata */
  bibleMetadata?: InputMaybe<BibleMetadataFilterInput>;
  /** Paragraph content */
  content?: InputMaybe<StringOperationFilterInput>;
  /** Created at */
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Paragraph date */
  date?: InputMaybe<DateOperationFilterInput>;
  /** End date for paragraph */
  endDate?: InputMaybe<DateOperationFilterInput>;
  /** Heading level: null for pages, 0 for paragraphs, 1 for headings, 2 for subheadings, etc. */
  headingLevel?: InputMaybe<IntOperationFilterInput>;
  /** Is referenced */
  isReferenced?: InputMaybe<BooleanOperationFilterInput>;
  /** Has manuscript metadata */
  ltMsMetadata?: InputMaybe<LtMsMetadataFilterInput>;
  or?: InputMaybe<Array<ParagraphDtoFilterInput>>;
  /** Paragraph order (unique in book) */
  order?: InputMaybe<IntOperationFilterInput>;
  /** Pagination section */
  pagination?: InputMaybe<PaginationMetaDataFilterInput>;
  /** Paragraph ID (unique key) */
  paraId?: InputMaybe<ParaIdFilterInputType>;
  /** Paragraph ID (unique in book) */
  paragraphId?: InputMaybe<IntOperationFilterInput>;
  /** Publication id */
  publicationId?: InputMaybe<IntOperationFilterInput>;
  /** Long reference code */
  refCodeLong?: InputMaybe<StringOperationFilterInput>;
  /** Short reference code */
  refCodeShort?: InputMaybe<StringOperationFilterInput>;
  /** Updated at */
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

/** Paragraph DTO */
export type ParagraphDtoSortInput = {
  /** Bible metadata */
  bibleMetadata?: InputMaybe<BibleMetadataSortInput>;
  /** Paragraph content */
  content?: InputMaybe<SortEnumType>;
  /** Created at */
  createdAt?: InputMaybe<SortEnumType>;
  /** Paragraph date */
  date?: InputMaybe<SortEnumType>;
  /** End date for paragraph */
  endDate?: InputMaybe<SortEnumType>;
  /** Heading level: null for pages, 0 for paragraphs, 1 for headings, 2 for subheadings, etc. */
  headingLevel?: InputMaybe<SortEnumType>;
  /** Is referenced */
  isReferenced?: InputMaybe<SortEnumType>;
  /** Has manuscript metadata */
  ltMsMetadata?: InputMaybe<LtMsMetadataSortInput>;
  /** Paragraph order (unique in book) */
  order?: InputMaybe<SortEnumType>;
  /** Pagination section */
  pagination?: InputMaybe<PaginationMetaDataSortInput>;
  /** Paragraph ID (unique key) */
  paraId?: InputMaybe<SortEnumType>;
  /** Paragraph ID (unique in book) */
  paragraphId?: InputMaybe<SortEnumType>;
  /** Publication id */
  publicationId?: InputMaybe<SortEnumType>;
  /** Long reference code */
  refCodeLong?: InputMaybe<SortEnumType>;
  /** Short reference code */
  refCodeShort?: InputMaybe<SortEnumType>;
  /** Updated at */
  updatedAt?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ParagraphsCollectionSegment = {
  __typename?: 'ParagraphsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ParagraphDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** A segment of a collection. */
export type PublicationArchiveCollectionSegment = {
  __typename?: 'PublicationArchiveCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ArchivedPublicationDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/**
 * Publication author
 * PublicationAuthor
 */
export type PublicationAuthorDto = {
  __typename?: 'PublicationAuthorDto';
  /** Author biography */
  biography?: Maybe<Scalars['String']['output']>;
  /** Birth year */
  birthYear?: Maybe<Scalars['Int']['output']>;
  /** Author code */
  code?: Maybe<Scalars['String']['output']>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** Death year */
  deathYear?: Maybe<Scalars['Int']['output']>;
  /** First name */
  firstName: Scalars['String']['output'];
  /** Unique author ID */
  id: Scalars['Int']['output'];
  /** Last name */
  lastName: Scalars['String']['output'];
  /** Middle name */
  middleName: Scalars['String']['output'];
  /** Updated at */
  updatedAt: Scalars['DateTime']['output'];
};

/**
 * Publication author
 * PublicationAuthor
 */
export type PublicationAuthorDtoFilterInput = {
  and?: InputMaybe<Array<PublicationAuthorDtoFilterInput>>;
  /** Author biography */
  biography?: InputMaybe<StringOperationFilterInput>;
  /** Birth year */
  birthYear?: InputMaybe<IntOperationFilterInput>;
  /** Author code */
  code?: InputMaybe<StringOperationFilterInput>;
  /** Created at */
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Death year */
  deathYear?: InputMaybe<IntOperationFilterInput>;
  /** First name */
  firstName?: InputMaybe<StringOperationFilterInput>;
  /** Unique author ID */
  id?: InputMaybe<IntOperationFilterInput>;
  /** Last name */
  lastName?: InputMaybe<StringOperationFilterInput>;
  /** Middle name */
  middleName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PublicationAuthorDtoFilterInput>>;
  /** Updated at */
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

/**
 * Publication author
 * PublicationAuthor
 */
export type PublicationAuthorDtoSortInput = {
  /** Author biography */
  biography?: InputMaybe<SortEnumType>;
  /** Birth year */
  birthYear?: InputMaybe<SortEnumType>;
  /** Author code */
  code?: InputMaybe<SortEnumType>;
  /** Created at */
  createdAt?: InputMaybe<SortEnumType>;
  /** Death year */
  deathYear?: InputMaybe<SortEnumType>;
  /** First name */
  firstName?: InputMaybe<SortEnumType>;
  /** Unique author ID */
  id?: InputMaybe<SortEnumType>;
  /** Last name */
  lastName?: InputMaybe<SortEnumType>;
  /** Middle name */
  middleName?: InputMaybe<SortEnumType>;
  /** Updated at */
  updatedAt?: InputMaybe<SortEnumType>;
};

/**
 * Publication chapter
 * PublicationChapter
 */
export type PublicationChapterDto = {
  __typename?: 'PublicationChapterDto';
  /** Chapter identifier */
  chapterId: Scalars['ParaId']['output'];
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** Chapter level */
  level: Scalars['Int']['output'];
  /** Chapter order */
  order: Scalars['Int']['output'];
  /** Starting paragraph ID for a chapter */
  paraId: Scalars['ParaId']['output'];
  /** Chapter title paragraph */
  paragraph: ParagraphDto;
  /** Publication id */
  publicationId: Scalars['Int']['output'];
  /** Chapter title */
  title: Scalars['String']['output'];
  /** Updated at */
  updatedAt: Scalars['DateTime']['output'];
};

/**
 * Publication chapter
 * PublicationChapter
 */
export type PublicationChapterDtoFilterInput = {
  and?: InputMaybe<Array<PublicationChapterDtoFilterInput>>;
  /** Chapter identifier */
  chapterId?: InputMaybe<ParaIdFilterInputType>;
  /** Created at */
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Chapter level */
  level?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PublicationChapterDtoFilterInput>>;
  /** Chapter order */
  order?: InputMaybe<IntOperationFilterInput>;
  /** Starting paragraph ID for a chapter */
  paraId?: InputMaybe<ParaIdFilterInputType>;
  /** Publication id */
  publicationId?: InputMaybe<IntOperationFilterInput>;
  /** Chapter title */
  title?: InputMaybe<StringOperationFilterInput>;
  /** Updated at */
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

/**
 * Publication chapter
 * PublicationChapter
 */
export type PublicationChapterDtoSortInput = {
  /** Chapter identifier */
  chapterId?: InputMaybe<SortEnumType>;
  /** Created at */
  createdAt?: InputMaybe<SortEnumType>;
  /** Chapter level */
  level?: InputMaybe<SortEnumType>;
  /** Chapter order */
  order?: InputMaybe<SortEnumType>;
  /** Starting paragraph ID for a chapter */
  paraId?: InputMaybe<SortEnumType>;
  /** Publication id */
  publicationId?: InputMaybe<SortEnumType>;
  /** Chapter title */
  title?: InputMaybe<SortEnumType>;
  /** Updated at */
  updatedAt?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type PublicationChaptersCollectionSegment = {
  __typename?: 'PublicationChaptersCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PublicationChapterDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** DTO for Publication */
export type PublicationDto = {
  __typename?: 'PublicationDto';
  /** Archived versions */
  archive?: Maybe<ArchiveCollectionSegment>;
  /** Publication author */
  author?: Maybe<PublicationAuthorDto>;
  /** Publication author */
  authorId?: Maybe<Scalars['Int']['output']>;
  /** Publication chapters */
  chapters?: Maybe<Array<PublicationChapterDto>>;
  /** Publication code */
  code: Scalars['String']['output'];
  /** Publication covers */
  covers?: Maybe<Array<Maybe<CoverDto>>>;
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** Publication description */
  description: Scalars['String']['output'];
  /** Exported files */
  exports?: Maybe<Array<Maybe<ExportedFileDto>>>;
  /** Folder ID */
  folderId?: Maybe<Scalars['Int']['output']>;
  /** ISBN Code */
  isbn?: Maybe<Scalars['String']['output']>;
  /** Publication language */
  language: LanguageDto;
  /** Language code */
  languageCode: Scalars['String']['output'];
  /** Publication mp3 files */
  mp3Files?: Maybe<Array<PublicationMp3FileDto>>;
  /** Order in folder */
  order: Scalars['Int']['output'];
  /** Original publication */
  originalPublication?: Maybe<PublicationDto>;
  /** Original publication ID */
  originalPublicationId?: Maybe<Scalars['Int']['output']>;
  /** Page count */
  pageCount?: Maybe<Scalars['Int']['output']>;
  /** Paragraphs */
  paragraphs?: Maybe<Array<ParagraphDto>>;
  /** Permission */
  permission: PublicationPermissionEnum;
  /** Publication ID */
  publicationId: Scalars['Int']['output'];
  /** Year of publication */
  publicationYear?: Maybe<Scalars['Int']['output']>;
  /** Publisher */
  publisher: Scalars['String']['output'];
  /** Purchase URI */
  purchaseLink?: Maybe<Scalars['URL']['output']>;
  /** Publication title */
  title: Scalars['String']['output'];
  /** TOC depth */
  topDepth?: Maybe<Scalars['Int']['output']>;
  /** Publication type */
  type: PublicationType;
  /** Updated at */
  updatedAt: Scalars['DateTime']['output'];
  /** Web publication cover */
  webCover?: Maybe<CoverDto>;
};

/** DTO for Publication */
export type PublicationDtoArchiveArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchivedPublicationDtoFilterInput>;
};

/** DTO for Publication */
export type PublicationDtoParagraphsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['Int']['input'];
  take?: InputMaybe<Scalars['Int']['input']>;
};

/** DTO for Publication */
export type PublicationDtoFilterInput = {
  and?: InputMaybe<Array<PublicationDtoFilterInput>>;
  /** Publication author */
  authorId?: InputMaybe<IntOperationFilterInput>;
  /** Publication code */
  code?: InputMaybe<StringOperationFilterInput>;
  /** Created at */
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Publication description */
  description?: InputMaybe<StringOperationFilterInput>;
  /** Folder ID */
  folderId?: InputMaybe<IntOperationFilterInput>;
  /** ISBN Code */
  isbn?: InputMaybe<StringOperationFilterInput>;
  /** Language code */
  languageCode?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PublicationDtoFilterInput>>;
  /** Order in folder */
  order?: InputMaybe<IntOperationFilterInput>;
  /** Original publication ID */
  originalPublicationId?: InputMaybe<IntOperationFilterInput>;
  /** Page count */
  pageCount?: InputMaybe<IntOperationFilterInput>;
  /** Permission */
  permission?: InputMaybe<PublicationPermissionEnumOperationFilterInput>;
  /** Publication ID */
  publicationId?: InputMaybe<IntOperationFilterInput>;
  /** Year of publication */
  publicationYear?: InputMaybe<IntOperationFilterInput>;
  /** Publisher */
  publisher?: InputMaybe<StringOperationFilterInput>;
  /** Purchase URI */
  purchaseLink?: InputMaybe<UrlOperationFilterInput>;
  /** Publication title */
  title?: InputMaybe<StringOperationFilterInput>;
  /** Publication type */
  type?: InputMaybe<PublicationTypeOperationFilterInput>;
  /** Updated at */
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

/** DTO for Publication */
export type PublicationDtoSortInput = {
  /** Publication author */
  authorId?: InputMaybe<SortEnumType>;
  /** Publication code */
  code?: InputMaybe<SortEnumType>;
  /** Created at */
  createdAt?: InputMaybe<SortEnumType>;
  /** Publication description */
  description?: InputMaybe<SortEnumType>;
  /** Folder ID */
  folderId?: InputMaybe<SortEnumType>;
  /** ISBN Code */
  isbn?: InputMaybe<SortEnumType>;
  /** Language code */
  languageCode?: InputMaybe<SortEnumType>;
  /** Order in folder */
  order?: InputMaybe<SortEnumType>;
  /** Original publication ID */
  originalPublicationId?: InputMaybe<SortEnumType>;
  /** Page count */
  pageCount?: InputMaybe<SortEnumType>;
  /** Permission */
  permission?: InputMaybe<SortEnumType>;
  /** Publication ID */
  publicationId?: InputMaybe<SortEnumType>;
  /** Year of publication */
  publicationYear?: InputMaybe<SortEnumType>;
  /** Publisher */
  publisher?: InputMaybe<SortEnumType>;
  /** Purchase URI */
  purchaseLink?: InputMaybe<UriSortInput>;
  /** Publication title */
  title?: InputMaybe<SortEnumType>;
  /** Publication type */
  type?: InputMaybe<SortEnumType>;
  /** Updated at */
  updatedAt?: InputMaybe<SortEnumType>;
};

/**
 * Mp3 File
 * Mp3File
 */
export type PublicationMp3FileDto = {
  __typename?: 'PublicationMp3FileDto';
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** Element Id */
  elementId: Scalars['Int']['output'];
  /** Mp3 File Name */
  filename: Scalars['String']['output'];
  /** Voice is autogenerated */
  isGenerated: Scalars['Boolean']['output'];
  /** Id */
  paraId: Scalars['ParaId']['output'];
  /** Publication Id */
  publicationId: Scalars['Int']['output'];
  /** Updated at */
  updatedAt: Scalars['DateTime']['output'];
  /** Voice type */
  voiceType: VoiceTypeEnum;
};

/**
 * Mp3 File
 * Mp3File
 */
export type PublicationMp3FileDtoFilterInput = {
  and?: InputMaybe<Array<PublicationMp3FileDtoFilterInput>>;
  /** Created at */
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Element Id */
  elementId?: InputMaybe<IntOperationFilterInput>;
  /** Mp3 File Name */
  filename?: InputMaybe<StringOperationFilterInput>;
  /** Voice is autogenerated */
  isGenerated?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<PublicationMp3FileDtoFilterInput>>;
  /** Id */
  paraId?: InputMaybe<ParaIdFilterInputType>;
  /** Publication Id */
  publicationId?: InputMaybe<IntOperationFilterInput>;
  /** Updated at */
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Voice type */
  voiceType?: InputMaybe<VoiceTypeEnumOperationFilterInput>;
};

/**
 * Mp3 File
 * Mp3File
 */
export type PublicationMp3FileDtoSortInput = {
  /** Created at */
  createdAt?: InputMaybe<SortEnumType>;
  /** Element Id */
  elementId?: InputMaybe<SortEnumType>;
  /** Mp3 File Name */
  filename?: InputMaybe<SortEnumType>;
  /** Voice is autogenerated */
  isGenerated?: InputMaybe<SortEnumType>;
  /** Id */
  paraId?: InputMaybe<SortEnumType>;
  /** Publication Id */
  publicationId?: InputMaybe<SortEnumType>;
  /** Updated at */
  updatedAt?: InputMaybe<SortEnumType>;
  /** Voice type */
  voiceType?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type PublicationMp3FilesCollectionSegment = {
  __typename?: 'PublicationMp3FilesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PublicationMp3FileDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** Publication permission */
export enum PublicationPermissionEnum {
  /** Must me authorized */
  AuthenticatedOnly = 'AUTHENTICATED_ONLY',
  /** Publication is hidden from export */
  Hidden = 'HIDDEN',
  /** Publication is public */
  Public = 'PUBLIC',
  /** Must be purchased */
  PurchasedOnly = 'PURCHASED_ONLY'
}

export type PublicationPermissionEnumOperationFilterInput = {
  eq?: InputMaybe<PublicationPermissionEnum>;
  in?: InputMaybe<Array<PublicationPermissionEnum>>;
  neq?: InputMaybe<PublicationPermissionEnum>;
  nin?: InputMaybe<Array<PublicationPermissionEnum>>;
};

/** A segment of a collection. */
export type PublicationSeriesCollectionSegment = {
  __typename?: 'PublicationSeriesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PublicationSeriesDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/**
 * Publications series DTO
 * PublicationSeries
 */
export type PublicationSeriesDto = {
  __typename?: 'PublicationSeriesDto';
  /** Series code */
  code: Scalars['String']['output'];
  /** Created at */
  createdAt: Scalars['DateTime']['output'];
  /** List of publications in series */
  publications: Array<Scalars['Int']['output']>;
  /** Series title */
  title: Scalars['String']['output'];
  /** Series type */
  type: SeriesTypeEnum;
  /** Updated at */
  updatedAt: Scalars['DateTime']['output'];
};

/**
 * Publications series DTO
 * PublicationSeries
 */
export type PublicationSeriesDtoFilterInput = {
  and?: InputMaybe<Array<PublicationSeriesDtoFilterInput>>;
  /** Series code */
  code?: InputMaybe<StringOperationFilterInput>;
  /** Created at */
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PublicationSeriesDtoFilterInput>>;
  /** List of publications in series */
  publications?: InputMaybe<ListIntOperationFilterInput>;
  /** Series title */
  title?: InputMaybe<StringOperationFilterInput>;
  /** Series type */
  type?: InputMaybe<SeriesTypeEnumOperationFilterInput>;
  /** Updated at */
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

/**
 * Publications series DTO
 * PublicationSeries
 */
export type PublicationSeriesDtoSortInput = {
  /** Series code */
  code?: InputMaybe<SortEnumType>;
  /** Created at */
  createdAt?: InputMaybe<SortEnumType>;
  /** Series title */
  title?: InputMaybe<SortEnumType>;
  /** Series type */
  type?: InputMaybe<SortEnumType>;
  /** Updated at */
  updatedAt?: InputMaybe<SortEnumType>;
};

export enum PublicationType {
  Bible = 'BIBLE',
  BibleCommentary = 'BIBLE_COMMENTARY',
  Book = 'BOOK',
  Devotional = 'DEVOTIONAL',
  Dictionary = 'DICTIONARY',
  Manuscript = 'MANUSCRIPT',
  PeriodicalNoPageBreak = 'PERIODICAL_NO_PAGE_BREAK',
  PeriodicalPageBreak = 'PERIODICAL_PAGE_BREAK',
  ScriptureIndex = 'SCRIPTURE_INDEX',
  TopicalIndex = 'TOPICAL_INDEX'
}

export type PublicationTypeOperationFilterInput = {
  eq?: InputMaybe<PublicationType>;
  in?: InputMaybe<Array<PublicationType>>;
  neq?: InputMaybe<PublicationType>;
  nin?: InputMaybe<Array<PublicationType>>;
};

/** A segment of a collection. */
export type PublicationsCollectionSegment = {
  __typename?: 'PublicationsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PublicationDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** Publishes a publication */
export type PublishPublicationInput = {
  /** Folder id */
  folderId: Scalars['Int']['input'];
  /** Publication id */
  publicationId: Scalars['Int']['input'];
};

export type PublishPublicationPayload = {
  __typename?: 'PublishPublicationPayload';
  publicationDto?: Maybe<PublicationDto>;
};

/** Queue-related queries */
export type QueueNamespace = {
  __typename?: 'QueueNamespace';
  /** Completed logs */
  log: Array<BackgroundTask>;
  /** Queue */
  queue: Array<BackgroundTask>;
  /** Queue size */
  queueSize: Scalars['Int']['output'];
};

export type RecalculateFoldersPayload = {
  __typename?: 'RecalculateFoldersPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type RecalculatePublicationInput = {
  publicationId: Scalars['Int']['input'];
};

export type RecalculatePublicationPayload = {
  __typename?: 'RecalculatePublicationPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

/** Rename a folder */
export type RenameFolderInput = {
  /** Folder ID */
  id: Scalars['Int']['input'];
  /** New Title */
  title: Scalars['String']['input'];
};

export type RenameFolderPayload = {
  __typename?: 'RenameFolderPayload';
  folderDto?: Maybe<FolderDto>;
};

/** Resize type */
export enum ResizeTypeEnum {
  /** Auto */
  Auto = 'AUTO',
  /** Fill */
  Fill = 'FILL',
  /** Fit */
  Fit = 'FIT'
}

/** Restore saved publication from archive */
export type RestoreEntryFromArchiveInput = {
  /** Back up current version automatically */
  autoBackup?: Scalars['Boolean']['input'];
  /** Archive entity ID */
  id: Scalars['UUID']['input'];
  /** Publication ID */
  publicationId: Scalars['Int']['input'];
};

export type RestoreEntryFromArchivePayload = {
  __typename?: 'RestoreEntryFromArchivePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

/** Save WEML to archive */
export type SavePublicationToArchiveInput = {
  /** Publication ID */
  publicationId: Scalars['Int']['input'];
};

export type SavePublicationToArchivePayload = {
  __typename?: 'SavePublicationToArchivePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

/** Series type */
export enum SeriesTypeEnum {
  /** Series */
  Series = 'SERIES',
  /** Topic */
  Topic = 'TOPIC'
}

export type SeriesTypeEnumOperationFilterInput = {
  eq?: InputMaybe<SeriesTypeEnum>;
  in?: InputMaybe<Array<SeriesTypeEnum>>;
  neq?: InputMaybe<SeriesTypeEnum>;
  nin?: InputMaybe<Array<SeriesTypeEnum>>;
};

/** Sets main cover for a publication */
export type SetMainCoverInput = {
  /** Cover identifier */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Publication identifier */
  publicationId: Scalars['Int']['input'];
  /** Publication identifier */
  type: Scalars['String']['input'];
};

export type SetMainCoverPayload = {
  __typename?: 'SetMainCoverPayload';
  coverDto?: Maybe<CoverDto>;
};

/** Set main export file */
export type SetMainExportFileInput = {
  /** File ID */
  id: Scalars['UUID']['input'];
  /** Publication ID */
  publicationId: Scalars['Int']['input'];
  /** Export type */
  type: ExportTypeEnum;
};

export type SetMainExportFilePayload = {
  __typename?: 'SetMainExportFilePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

/** Update paragraph content */
export type SetParagraphContentInput = {
  /** Content to update */
  content: Scalars['String']['input'];
  /** Paragraph to update */
  paraId: Scalars['ParaId']['input'];
};

export type SetParagraphContentPayload = {
  __typename?: 'SetParagraphContentPayload';
  paragraphDto?: Maybe<ParagraphDto>;
};

/** Updates publication metadata */
export type SetPublicationMetadataInput = {
  /** Author Id */
  authorId?: InputMaybe<Scalars['Int']['input']>;
  /** Publication code */
  code: Scalars['String']['input'];
  /** Publication description */
  description: Scalars['String']['input'];
  /** ISBN code */
  isbn: Scalars['String']['input'];
  /** Page count */
  pageCount?: InputMaybe<Scalars['Int']['input']>;
  /** Publication id */
  publicationId: Scalars['Int']['input'];
  /** Publication year */
  publicationYear?: InputMaybe<Scalars['Int']['input']>;
  /** Publisher */
  publisher: Scalars['String']['input'];
  /** Purchase link */
  purchaseLink?: InputMaybe<Scalars['URL']['input']>;
  /** Publication title */
  title: Scalars['String']['input'];
};

export type SetPublicationMetadataPayload = {
  __typename?: 'SetPublicationMetadataPayload';
  publicationDto?: Maybe<PublicationDto>;
};

/** Sets order for a publication */
export type SetPublicationOrderInput = {
  /** New order */
  order: Scalars['Float']['input'];
  /** Publication id */
  publicationId: Scalars['Int']['input'];
};

export type SetPublicationOrderPayload = {
  __typename?: 'SetPublicationOrderPayload';
  publicationDto?: Maybe<PublicationDto>;
};

/** Sets permission for a publication */
export type SetPublicationPermissionInput = {
  /** New permission */
  permission: PublicationPermissionEnum;
  /** Publication id */
  publicationId: Scalars['Int']['input'];
};

export type SetPublicationPermissionPayload = {
  __typename?: 'SetPublicationPermissionPayload';
  publicationDto?: Maybe<PublicationDto>;
};

/** Sets publication max toc level */
export type SetPublicationTocDepthInput = {
  /** Publication ID */
  publicationId: Scalars['Int']['input'];
  /** Max toc level */
  tocDepth?: InputMaybe<Scalars['Int']['input']>;
};

export type SetPublicationTocDepthPayload = {
  __typename?: 'SetPublicationTocDepthPayload';
  publicationDto?: Maybe<PublicationDto>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

/** Unpublishes a publication */
export type UnpublishPublicationInput = {
  /** Publication id */
  publicationId: Scalars['Int']['input'];
};

export type UnpublishPublicationPayload = {
  __typename?: 'UnpublishPublicationPayload';
  publicationDto?: Maybe<PublicationDto>;
};

/** Update cover type input */
export type UpdateCoverTypeInput = {
  /** Cover type code */
  code: Scalars['String']['input'];
  /** Cover type description */
  description?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCoverTypePayload = {
  __typename?: 'UpdateCoverTypePayload';
  coverTypeDto?: Maybe<CoverTypeDto>;
};

/** Changes language details */
export type UpdateLanguageInput = {
  /** New BCP47 Code */
  bcp47Code?: InputMaybe<Scalars['String']['input']>;
  /** Language code */
  code: Scalars['String']['input'];
  /** New EGW Code */
  egwCode?: InputMaybe<Scalars['String']['input']>;
  /** New Title */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLanguagePayload = {
  __typename?: 'UpdateLanguagePayload';
  languageDto?: Maybe<LanguageDto>;
};

/** Assign mp3 file input */
export type UpdateMp3FilesInput = {
  /** Mp3 files list */
  mp3Files: Array<AssignedMp3FileInput>;
  /** Publication Id */
  publicationId: Scalars['Int']['input'];
};

export type UpdateMp3FilesPayload = {
  __typename?: 'UpdateMp3FilesPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateMp3ManifestInput = {
  /** Publication Id */
  publicationId: Scalars['Int']['input'];
  /** Update\Replace manifest */
  replaceManifest: Scalars['Boolean']['input'];
};

export type UpdateMp3ManifestPayload = {
  __typename?: 'UpdateMp3ManifestPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

/** Input for cover upload */
export type UploadCoverInput = {
  /** Uploaded file */
  file: Scalars['Upload']['input'];
  /** Cover ID */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Publication ID */
  publicationId: Scalars['Int']['input'];
  /** Cover type */
  type: Scalars['String']['input'];
};

export type UploadCoverPayload = {
  __typename?: 'UploadCoverPayload';
  coverDto?: Maybe<CoverDto>;
};

/** Input for cover upload */
export type UploadExportInput = {
  /** Uploaded file */
  file: Scalars['Upload']['input'];
  /** Cover ID */
  id: Scalars['UUID']['input'];
  /** Publication ID */
  publicationId: Scalars['Int']['input'];
  /** Cover type */
  type: ExportTypeEnum;
};

export type UploadExportPayload = {
  __typename?: 'UploadExportPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type UriSortInput = {
  absolutePath?: InputMaybe<SortEnumType>;
  absoluteUri?: InputMaybe<SortEnumType>;
  authority?: InputMaybe<SortEnumType>;
  dnsSafeHost?: InputMaybe<SortEnumType>;
  fragment?: InputMaybe<SortEnumType>;
  host?: InputMaybe<SortEnumType>;
  hostNameType?: InputMaybe<SortEnumType>;
  idnHost?: InputMaybe<SortEnumType>;
  isAbsoluteUri?: InputMaybe<SortEnumType>;
  isDefaultPort?: InputMaybe<SortEnumType>;
  isFile?: InputMaybe<SortEnumType>;
  isLoopback?: InputMaybe<SortEnumType>;
  isUnc?: InputMaybe<SortEnumType>;
  localPath?: InputMaybe<SortEnumType>;
  originalString?: InputMaybe<SortEnumType>;
  pathAndQuery?: InputMaybe<SortEnumType>;
  port?: InputMaybe<SortEnumType>;
  query?: InputMaybe<SortEnumType>;
  scheme?: InputMaybe<SortEnumType>;
  userEscaped?: InputMaybe<SortEnumType>;
  userInfo?: InputMaybe<SortEnumType>;
};

export type UrlOperationFilterInput = {
  eq?: InputMaybe<Scalars['URL']['input']>;
  gt?: InputMaybe<Scalars['URL']['input']>;
  gte?: InputMaybe<Scalars['URL']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['URL']['input']>>>;
  lt?: InputMaybe<Scalars['URL']['input']>;
  lte?: InputMaybe<Scalars['URL']['input']>;
  neq?: InputMaybe<Scalars['URL']['input']>;
  ngt?: InputMaybe<Scalars['URL']['input']>;
  ngte?: InputMaybe<Scalars['URL']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['URL']['input']>>>;
  nlt?: InputMaybe<Scalars['URL']['input']>;
  nlte?: InputMaybe<Scalars['URL']['input']>;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

/** Voice type */
export enum VoiceTypeEnum {
  /** Female voice */
  Female = 'FEMALE',
  /** Male voice */
  Male = 'MALE'
}

export type VoiceTypeEnumOperationFilterInput = {
  eq?: InputMaybe<VoiceTypeEnum>;
  in?: InputMaybe<Array<VoiceTypeEnum>>;
  neq?: InputMaybe<VoiceTypeEnum>;
  nin?: InputMaybe<Array<VoiceTypeEnum>>;
};

export enum WemlTextDirection {
  LeftToRight = 'LEFT_TO_RIGHT',
  RightToLeft = 'RIGHT_TO_LEFT'
}

export type AuthorListItemFragment = { __typename?: 'PublicationAuthorDto', biography?: string | null, birthYear?: number | null, code?: string | null, createdAt: any, deathYear?: number | null, firstName: string, id: number, lastName: string, middleName: string, updatedAt: any };

export type AuthorListItemFragmentVariables = Exact<{ [key: string]: never; }>;

export type ManagerFolderTypeListItemFragment = { __typename?: 'FolderTypeDto', allowedTypes: Array<PublicationType>, createdAt: any, id: string, title: string, updatedAt: any };

export type ManagerFolderTypeListItemFragmentVariables = Exact<{ [key: string]: never; }>;

export type ManagerFolderTreeListItemFragment = { __typename?: 'FolderDto', childFolderCount: number, childPublicationCount: number, childPublicationCountRecursive: number, id: number, order: number, parentId?: number | null, path: Array<number>, title: string, typeId: string, createdAt: any, updatedAt: any, language?: { __typename?: 'LanguageDto', bcp47Code: string, code: string, egwCode: string, isRightToLeft: boolean, title: string } | null, parents: Array<{ __typename?: 'FolderDto', childFolderCount: number, childPublicationCount: number, childPublicationCountRecursive: number, id: number, order: number, parentId?: number | null, path: Array<number>, title: string, typeId: string, createdAt: any, updatedAt: any, language?: { __typename?: 'LanguageDto', bcp47Code: string, code: string, egwCode: string, isRightToLeft: boolean, title: string } | null, type?: { __typename?: 'FolderTypeDto', allowedTypes: Array<PublicationType>, createdAt: any, id: string, title: string, updatedAt: any } | null }>, type?: { __typename?: 'FolderTypeDto', allowedTypes: Array<PublicationType>, createdAt: any, id: string, title: string, updatedAt: any } | null };

export type ManagerFolderTreeListItemFragmentVariables = Exact<{ [key: string]: never; }>;

export type ManagerParentFolderListItemFragment = { __typename?: 'FolderDto', childFolderCount: number, childPublicationCount: number, childPublicationCountRecursive: number, id: number, order: number, parentId?: number | null, path: Array<number>, title: string, typeId: string, createdAt: any, updatedAt: any, language?: { __typename?: 'LanguageDto', bcp47Code: string, code: string, egwCode: string, isRightToLeft: boolean, title: string } | null, type?: { __typename?: 'FolderTypeDto', allowedTypes: Array<PublicationType>, createdAt: any, id: string, title: string, updatedAt: any } | null };

export type ManagerParentFolderListItemFragmentVariables = Exact<{ [key: string]: never; }>;

export type ManagerLanguageListItemFragment = { __typename?: 'LanguageDto', bcp47Code: string, code: string, createdAt: any, egwCode: string, isRightToLeft: boolean, rootFolderId?: number | null, title: string, updatedAt: any };

export type ManagerLanguageListItemFragmentVariables = Exact<{ [key: string]: never; }>;

export type ManagerPublicationCoverListItemFragment = { __typename?: 'CoverDto', createdAt: any, format: MediaFormatEnum, height: number, id: any, isMain: boolean, publicationId: number, size: any, thumbnail?: any | null, typeId: string, uri?: any | null, width: number, type?: { __typename?: 'CoverTypeDto', code: string, description?: string | null, minWidth: number, minHeight: number, coverCount: number } | null };

export type ManagerPublicationCoverListItemFragmentVariables = Exact<{ [key: string]: never; }>;

export type ManagerPublicationCoverResponseFragment = { __typename?: 'CoverDto', createdAt: any, format: MediaFormatEnum, height: number, id: any, isMain: boolean, publicationId: number, size: any, typeId: string, uri?: any | null, width: number, type?: { __typename?: 'CoverTypeDto', code: string, description?: string | null, minWidth: number, minHeight: number, coverCount: number } | null };

export type ManagerPublicationCoverResponseFragmentVariables = Exact<{ [key: string]: never; }>;

export type ManagerPublicationCoverTypeListItemFragment = { __typename?: 'CoverTypeDto', code: string, description?: string | null, minWidth: number, minHeight: number, coverCount: number };

export type ManagerPublicationCoverTypeListItemFragmentVariables = Exact<{ [key: string]: never; }>;

export type ManagerPublicationEpubListItemFragment = { __typename?: 'ExportedFileDto', createdAt: any, id: any, isMain: boolean, publicationId: number, size: any, type: ExportTypeEnum, updatedAt: any, uri?: any | null };

export type ManagerPublicationEpubListItemFragmentVariables = Exact<{ [key: string]: never; }>;

export type ManagerPublicationListItemFragment = { __typename?: 'PublicationDto', authorId?: number | null, code: string, createdAt: any, description: string, folderId?: number | null, isbn?: string | null, languageCode: string, order: number, originalPublicationId?: number | null, pageCount?: number | null, permission: PublicationPermissionEnum, publicationId: number, publicationYear?: number | null, publisher: string, purchaseLink?: any | null, title: string, type: PublicationType, updatedAt: any, author?: { __typename?: 'PublicationAuthorDto', code?: string | null, createdAt: any, deathYear?: number | null, firstName: string, id: number, lastName: string, middleName: string } | null, language: { __typename?: 'LanguageDto', bcp47Code: string, code: string, createdAt: any, egwCode: string, isRightToLeft: boolean, rootFolderId?: number | null, title: string, updatedAt: any }, webCover?: { __typename?: 'CoverDto', thumbnail?: any | null, uri?: any | null } | null };

export type ManagerPublicationListItemFragmentVariables = Exact<{ [key: string]: never; }>;

export type ManagerPublicationMutationResponseFragment = { __typename?: 'PublicationDto', authorId?: number | null, code: string, createdAt: any, description: string, folderId?: number | null, isbn?: string | null, languageCode: string, order: number, originalPublicationId?: number | null, pageCount?: number | null, permission: PublicationPermissionEnum, publicationId: number, publicationYear?: number | null, publisher: string, purchaseLink?: any | null, title: string, type: PublicationType, updatedAt: any, author?: { __typename?: 'PublicationAuthorDto', code?: string | null, createdAt: any, deathYear?: number | null, firstName: string, id: number, lastName: string, middleName: string } | null, language: { __typename?: 'LanguageDto', bcp47Code: string, code: string, createdAt: any, egwCode: string, isRightToLeft: boolean, rootFolderId?: number | null, title: string, updatedAt: any } };

export type ManagerPublicationMutationResponseFragmentVariables = Exact<{ [key: string]: never; }>;

export type PageInfoFragment = { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean };

export type PageInfoFragmentVariables = Exact<{ [key: string]: never; }>;

export type PublicationChaptersReaderDataFragment = { __typename?: 'PublicationDto', publicationId: number, title: string, type: PublicationType, chapters?: Array<{ __typename?: 'PublicationChapterDto', chapterId: any, paraId: any, level: number, order: number, title: string, publicationId: number }> | null };

export type PublicationChaptersReaderDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type PublicationChaptersReaderChapterListItemFragment = { __typename?: 'PublicationChapterDto', chapterId: any, paraId: any, level: number, order: number, title: string, publicationId: number };

export type PublicationChaptersReaderChapterListItemFragmentVariables = Exact<{ [key: string]: never; }>;

export type PublicationChaptersReaderParagraphListItemFragment = { __typename?: 'ParagraphDto', paraId: any, content: string, order: number, publicationId: number, paragraphId: number, headingLevel?: number | null };

export type PublicationChaptersReaderParagraphListItemFragmentVariables = Exact<{ [key: string]: never; }>;

export type RecalculateGlobalFolderPositionsMutationVariables = Exact<{ [key: string]: never; }>;

export type RecalculateGlobalFolderPositionsMutation = { __typename?: 'GraphQlMutations', recalculateFolders: { __typename?: 'RecalculateFoldersPayload', boolean?: boolean | null } };

export type RecalculatePublicationMutationVariables = Exact<{
  input: RecalculatePublicationInput;
}>;

export type RecalculatePublicationMutation = { __typename?: 'GraphQlMutations', recalculatePublication: { __typename?: 'RecalculatePublicationPayload', boolean?: boolean | null } };

export type CreateRootFolderForLanguageMutationVariables = Exact<{
  input: CreateRootFolderForLanguageInput;
}>;

export type CreateRootFolderForLanguageMutation = { __typename?: 'GraphQlMutations', createRootFolderForLanguage: { __typename?: 'CreateRootFolderForLanguagePayload', languageDto?: { __typename?: 'LanguageDto', bcp47Code: string, code: string, createdAt: any, egwCode: string, isRightToLeft: boolean, rootFolderId?: number | null, title: string, updatedAt: any } | null } };

export type CreateFolderMutationVariables = Exact<{
  input: CreateFolderInput;
}>;

export type CreateFolderMutation = { __typename?: 'GraphQlMutations', createFolder: { __typename?: 'CreateFolderPayload', boolean?: boolean | null } };

export type MoveFolderMutationVariables = Exact<{
  input: MoveFolderInput;
}>;

export type MoveFolderMutation = { __typename?: 'GraphQlMutations', moveFolder: { __typename?: 'MoveFolderPayload', folderDto?: { __typename?: 'FolderDto', childFolderCount: number, childPublicationCount: number, childPublicationCountRecursive: number, id: number, order: number, parentId?: number | null, path: Array<number>, title: string, typeId: string, createdAt: any, updatedAt: any, language?: { __typename?: 'LanguageDto', bcp47Code: string, code: string, egwCode: string, isRightToLeft: boolean, title: string } | null, parents: Array<{ __typename?: 'FolderDto', childFolderCount: number, childPublicationCount: number, childPublicationCountRecursive: number, id: number, order: number, parentId?: number | null, path: Array<number>, title: string, typeId: string, createdAt: any, updatedAt: any, language?: { __typename?: 'LanguageDto', bcp47Code: string, code: string, egwCode: string, isRightToLeft: boolean, title: string } | null, type?: { __typename?: 'FolderTypeDto', allowedTypes: Array<PublicationType>, createdAt: any, id: string, title: string, updatedAt: any } | null }>, type?: { __typename?: 'FolderTypeDto', allowedTypes: Array<PublicationType>, createdAt: any, id: string, title: string, updatedAt: any } | null } | null } };

export type RenameFolderMutationVariables = Exact<{
  input: RenameFolderInput;
}>;

export type RenameFolderMutation = { __typename?: 'GraphQlMutations', renameFolder: { __typename?: 'RenameFolderPayload', folderDto?: { __typename?: 'FolderDto', childFolderCount: number, childPublicationCount: number, childPublicationCountRecursive: number, id: number, order: number, parentId?: number | null, path: Array<number>, title: string, typeId: string, createdAt: any, updatedAt: any, language?: { __typename?: 'LanguageDto', bcp47Code: string, code: string, egwCode: string, isRightToLeft: boolean, title: string } | null, parents: Array<{ __typename?: 'FolderDto', childFolderCount: number, childPublicationCount: number, childPublicationCountRecursive: number, id: number, order: number, parentId?: number | null, path: Array<number>, title: string, typeId: string, createdAt: any, updatedAt: any, language?: { __typename?: 'LanguageDto', bcp47Code: string, code: string, egwCode: string, isRightToLeft: boolean, title: string } | null, type?: { __typename?: 'FolderTypeDto', allowedTypes: Array<PublicationType>, createdAt: any, id: string, title: string, updatedAt: any } | null }>, type?: { __typename?: 'FolderTypeDto', allowedTypes: Array<PublicationType>, createdAt: any, id: string, title: string, updatedAt: any } | null } | null } };

export type ChangeFolderTypeMutationVariables = Exact<{
  input: ChangeFolderTypeInput;
}>;

export type ChangeFolderTypeMutation = { __typename?: 'GraphQlMutations', changeFolderType: { __typename?: 'ChangeFolderTypePayload', folderDto?: { __typename?: 'FolderDto', childFolderCount: number, childPublicationCount: number, childPublicationCountRecursive: number, id: number, order: number, parentId?: number | null, path: Array<number>, title: string, typeId: string, createdAt: any, updatedAt: any, language?: { __typename?: 'LanguageDto', bcp47Code: string, code: string, egwCode: string, isRightToLeft: boolean, title: string } | null, parents: Array<{ __typename?: 'FolderDto', childFolderCount: number, childPublicationCount: number, childPublicationCountRecursive: number, id: number, order: number, parentId?: number | null, path: Array<number>, title: string, typeId: string, createdAt: any, updatedAt: any, language?: { __typename?: 'LanguageDto', bcp47Code: string, code: string, egwCode: string, isRightToLeft: boolean, title: string } | null, type?: { __typename?: 'FolderTypeDto', allowedTypes: Array<PublicationType>, createdAt: any, id: string, title: string, updatedAt: any } | null }>, type?: { __typename?: 'FolderTypeDto', allowedTypes: Array<PublicationType>, createdAt: any, id: string, title: string, updatedAt: any } | null } | null } };

export type DeleteFolderMutationVariables = Exact<{
  input: DeleteFolderInput;
}>;

export type DeleteFolderMutation = { __typename?: 'GraphQlMutations', deleteFolder: { __typename?: 'DeleteFolderPayload', boolean?: boolean | null } };

export type CreateLanguageMutationVariables = Exact<{
  input: CreateLanguageInput;
}>;

export type CreateLanguageMutation = { __typename?: 'GraphQlMutations', createLanguage: { __typename?: 'CreateLanguagePayload', languageDto?: { __typename?: 'LanguageDto', bcp47Code: string, code: string, createdAt: any, egwCode: string, isRightToLeft: boolean, rootFolderId?: number | null, title: string, updatedAt: any } | null } };

export type UpdateLanguageMutationVariables = Exact<{
  input: UpdateLanguageInput;
}>;

export type UpdateLanguageMutation = { __typename?: 'GraphQlMutations', updateLanguage: { __typename?: 'UpdateLanguagePayload', languageDto?: { __typename?: 'LanguageDto', bcp47Code: string, code: string, createdAt: any, egwCode: string, isRightToLeft: boolean, rootFolderId?: number | null, title: string, updatedAt: any } | null } };

export type DeleteLanguageMutationVariables = Exact<{
  input: DeleteLanguageInput;
}>;

export type DeleteLanguageMutation = { __typename?: 'GraphQlMutations', deleteLanguage: { __typename?: 'DeleteLanguagePayload', languageDto?: { __typename?: 'LanguageDto', bcp47Code: string, code: string, createdAt: any, egwCode: string, isRightToLeft: boolean, rootFolderId?: number | null, title: string, updatedAt: any } | null } };

export type SetPublicationChaptersParagraphContentMutationVariables = Exact<{
  input: SetParagraphContentInput;
}>;

export type SetPublicationChaptersParagraphContentMutation = { __typename?: 'GraphQlMutations', setParagraphContent: { __typename?: 'SetParagraphContentPayload', paragraphDto?: { __typename?: 'ParagraphDto', paraId: any, content: string, order: number, publicationId: number, paragraphId: number, headingLevel?: number | null } | null } };

export type AddPublicationChaptersParagraphMutationVariables = Exact<{
  input: AddParagraphInput;
}>;

export type AddPublicationChaptersParagraphMutation = { __typename?: 'GraphQlMutations', addParagraph: { __typename?: 'AddParagraphPayload', paragraphDto?: { __typename?: 'ParagraphDto', paraId: any, content: string, order: number, publicationId: number, paragraphId: number, headingLevel?: number | null } | null } };

export type DeletePublicationChaptersParagraphMutationVariables = Exact<{
  input: DeleteParagraphInput;
}>;

export type DeletePublicationChaptersParagraphMutation = { __typename?: 'GraphQlMutations', deleteParagraph: { __typename?: 'DeleteParagraphPayload', paraId?: any | null } };

export type SetPublicationMainCoverMutationVariables = Exact<{
  input: SetMainCoverInput;
}>;

export type SetPublicationMainCoverMutation = { __typename?: 'GraphQlMutations', setMainCover: { __typename?: 'SetMainCoverPayload', coverDto?: { __typename?: 'CoverDto', createdAt: any, format: MediaFormatEnum, height: number, id: any, isMain: boolean, publicationId: number, size: any, typeId: string, uri?: any | null, width: number, type?: { __typename?: 'CoverTypeDto', code: string, description?: string | null, minWidth: number, minHeight: number, coverCount: number } | null } | null } };

export type UploadPublicationCoverMutationVariables = Exact<{
  input: UploadCoverInput;
}>;

export type UploadPublicationCoverMutation = { __typename?: 'GraphQlMutations', uploadCover: { __typename?: 'UploadCoverPayload', coverDto?: { __typename?: 'CoverDto', createdAt: any, format: MediaFormatEnum, height: number, id: any, isMain: boolean, publicationId: number, size: any, typeId: string, uri?: any | null, width: number, type?: { __typename?: 'CoverTypeDto', code: string, description?: string | null, minWidth: number, minHeight: number, coverCount: number } | null } | null } };

export type CreatePublicationCoverTypeMutationVariables = Exact<{
  input: CreateCoverTypeInput;
}>;

export type CreatePublicationCoverTypeMutation = { __typename?: 'GraphQlMutations', createCoverType: { __typename?: 'CreateCoverTypePayload', coverTypeDto?: { __typename?: 'CoverTypeDto', code: string, description?: string | null, minWidth: number, minHeight: number, coverCount: number } | null } };

export type UpdatePublicationCoverTypeMutationVariables = Exact<{
  input: UpdateCoverTypeInput;
}>;

export type UpdatePublicationCoverTypeMutation = { __typename?: 'GraphQlMutations', updateCoverType: { __typename?: 'UpdateCoverTypePayload', coverTypeDto?: { __typename?: 'CoverTypeDto', code: string, description?: string | null, minWidth: number, minHeight: number, coverCount: number } | null } };

export type DeletePublicationCoverTypeMutationVariables = Exact<{
  input: DeleteCoverTypeInput;
}>;

export type DeletePublicationCoverTypeMutation = { __typename?: 'GraphQlMutations', deleteCoverType: { __typename?: 'DeleteCoverTypePayload', coverTypeDto?: { __typename?: 'CoverTypeDto', code: string, description?: string | null, minWidth: number, minHeight: number, coverCount: number } | null } };

export type SetPublicationMainEpubMutationVariables = Exact<{
  input: SetMainExportFileInput;
}>;

export type SetPublicationMainEpubMutation = { __typename?: 'GraphQlMutations', setMainExportFile: { __typename?: 'SetMainExportFilePayload', boolean?: boolean | null } };

export type GenerateNewPublicationEpubMutationVariables = Exact<{
  input: ExportEpubInput;
}>;

export type GenerateNewPublicationEpubMutation = { __typename?: 'GraphQlMutations', exportEpub: { __typename?: 'ExportEpubPayload', boolean?: boolean | null } };

export type DeletePublicationEpubMutationVariables = Exact<{
  input: DeleteExportFileInput;
}>;

export type DeletePublicationEpubMutation = { __typename?: 'GraphQlMutations', deleteExportFile: { __typename?: 'DeleteExportFilePayload', boolean?: boolean | null } };

export type UnpublishPublicationMutationVariables = Exact<{
  input: UnpublishPublicationInput;
}>;

export type UnpublishPublicationMutation = { __typename?: 'GraphQlMutations', unpublishPublication: { __typename?: 'UnpublishPublicationPayload', publicationDto?: { __typename?: 'PublicationDto', authorId?: number | null, code: string, createdAt: any, description: string, folderId?: number | null, isbn?: string | null, languageCode: string, order: number, originalPublicationId?: number | null, pageCount?: number | null, permission: PublicationPermissionEnum, publicationId: number, publicationYear?: number | null, publisher: string, purchaseLink?: any | null, title: string, type: PublicationType, updatedAt: any, author?: { __typename?: 'PublicationAuthorDto', code?: string | null, createdAt: any, deathYear?: number | null, firstName: string, id: number, lastName: string, middleName: string } | null, language: { __typename?: 'LanguageDto', bcp47Code: string, code: string, createdAt: any, egwCode: string, isRightToLeft: boolean, rootFolderId?: number | null, title: string, updatedAt: any } } | null } };

export type PublishPublicationMutationVariables = Exact<{
  input: PublishPublicationInput;
}>;

export type PublishPublicationMutation = { __typename?: 'GraphQlMutations', publishPublication: { __typename?: 'PublishPublicationPayload', publicationDto?: { __typename?: 'PublicationDto', authorId?: number | null, code: string, createdAt: any, description: string, folderId?: number | null, isbn?: string | null, languageCode: string, order: number, originalPublicationId?: number | null, pageCount?: number | null, permission: PublicationPermissionEnum, publicationId: number, publicationYear?: number | null, publisher: string, purchaseLink?: any | null, title: string, type: PublicationType, updatedAt: any, author?: { __typename?: 'PublicationAuthorDto', code?: string | null, createdAt: any, deathYear?: number | null, firstName: string, id: number, lastName: string, middleName: string } | null, language: { __typename?: 'LanguageDto', bcp47Code: string, code: string, createdAt: any, egwCode: string, isRightToLeft: boolean, rootFolderId?: number | null, title: string, updatedAt: any } } | null } };

export type MovePublicationMutationVariables = Exact<{
  input: MovePublicationInput;
}>;

export type MovePublicationMutation = { __typename?: 'GraphQlMutations', movePublication: { __typename?: 'MovePublicationPayload', publicationDto?: { __typename?: 'PublicationDto', authorId?: number | null, code: string, createdAt: any, description: string, folderId?: number | null, isbn?: string | null, languageCode: string, order: number, originalPublicationId?: number | null, pageCount?: number | null, permission: PublicationPermissionEnum, publicationId: number, publicationYear?: number | null, publisher: string, purchaseLink?: any | null, title: string, type: PublicationType, updatedAt: any, author?: { __typename?: 'PublicationAuthorDto', code?: string | null, createdAt: any, deathYear?: number | null, firstName: string, id: number, lastName: string, middleName: string } | null, language: { __typename?: 'LanguageDto', bcp47Code: string, code: string, createdAt: any, egwCode: string, isRightToLeft: boolean, rootFolderId?: number | null, title: string, updatedAt: any } } | null } };

export type SetPublicationPermissionMutationVariables = Exact<{
  input: SetPublicationPermissionInput;
}>;

export type SetPublicationPermissionMutation = { __typename?: 'GraphQlMutations', setPublicationPermission: { __typename?: 'SetPublicationPermissionPayload', publicationDto?: { __typename?: 'PublicationDto', authorId?: number | null, code: string, createdAt: any, description: string, folderId?: number | null, isbn?: string | null, languageCode: string, order: number, originalPublicationId?: number | null, pageCount?: number | null, permission: PublicationPermissionEnum, publicationId: number, publicationYear?: number | null, publisher: string, purchaseLink?: any | null, title: string, type: PublicationType, updatedAt: any, author?: { __typename?: 'PublicationAuthorDto', code?: string | null, createdAt: any, deathYear?: number | null, firstName: string, id: number, lastName: string, middleName: string } | null, language: { __typename?: 'LanguageDto', bcp47Code: string, code: string, createdAt: any, egwCode: string, isRightToLeft: boolean, rootFolderId?: number | null, title: string, updatedAt: any } } | null } };

export type SetPublicationOrderMutationVariables = Exact<{
  input: SetPublicationOrderInput;
}>;

export type SetPublicationOrderMutation = { __typename?: 'GraphQlMutations', setPublicationOrder: { __typename?: 'SetPublicationOrderPayload', publicationDto?: { __typename?: 'PublicationDto', authorId?: number | null, code: string, createdAt: any, description: string, folderId?: number | null, isbn?: string | null, languageCode: string, order: number, originalPublicationId?: number | null, pageCount?: number | null, permission: PublicationPermissionEnum, publicationId: number, publicationYear?: number | null, publisher: string, purchaseLink?: any | null, title: string, type: PublicationType, updatedAt: any, author?: { __typename?: 'PublicationAuthorDto', code?: string | null, createdAt: any, deathYear?: number | null, firstName: string, id: number, lastName: string, middleName: string } | null, language: { __typename?: 'LanguageDto', bcp47Code: string, code: string, createdAt: any, egwCode: string, isRightToLeft: boolean, rootFolderId?: number | null, title: string, updatedAt: any } } | null } };

export type SetPublicationTocDepthMutationVariables = Exact<{
  input: SetPublicationTocDepthInput;
}>;

export type SetPublicationTocDepthMutation = { __typename?: 'GraphQlMutations', setPublicationTocDepth: { __typename?: 'SetPublicationTocDepthPayload', publicationDto?: { __typename?: 'PublicationDto', authorId?: number | null, code: string, createdAt: any, description: string, folderId?: number | null, isbn?: string | null, languageCode: string, order: number, originalPublicationId?: number | null, pageCount?: number | null, permission: PublicationPermissionEnum, publicationId: number, publicationYear?: number | null, publisher: string, purchaseLink?: any | null, title: string, type: PublicationType, updatedAt: any, author?: { __typename?: 'PublicationAuthorDto', code?: string | null, createdAt: any, deathYear?: number | null, firstName: string, id: number, lastName: string, middleName: string } | null, language: { __typename?: 'LanguageDto', bcp47Code: string, code: string, createdAt: any, egwCode: string, isRightToLeft: boolean, rootFolderId?: number | null, title: string, updatedAt: any } } | null } };

export type SetPublicationMetaDataMutationVariables = Exact<{
  input: SetPublicationMetadataInput;
}>;

export type SetPublicationMetaDataMutation = { __typename?: 'GraphQlMutations', setPublicationMetadata: { __typename?: 'SetPublicationMetadataPayload', publicationDto?: { __typename?: 'PublicationDto', authorId?: number | null, code: string, createdAt: any, description: string, folderId?: number | null, isbn?: string | null, languageCode: string, order: number, originalPublicationId?: number | null, pageCount?: number | null, permission: PublicationPermissionEnum, publicationId: number, publicationYear?: number | null, publisher: string, purchaseLink?: any | null, title: string, type: PublicationType, updatedAt: any, author?: { __typename?: 'PublicationAuthorDto', code?: string | null, createdAt: any, deathYear?: number | null, firstName: string, id: number, lastName: string, middleName: string } | null, language: { __typename?: 'LanguageDto', bcp47Code: string, code: string, createdAt: any, egwCode: string, isRightToLeft: boolean, rootFolderId?: number | null, title: string, updatedAt: any } } | null } };

export type GetAuthorListQueryVariables = Exact<{
  where?: InputMaybe<PublicationAuthorDtoFilterInput>;
  order?: InputMaybe<Array<PublicationAuthorDtoSortInput> | PublicationAuthorDtoSortInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetAuthorListQuery = { __typename?: 'GraphQlQueries', authors?: { __typename?: 'AuthorsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'PublicationAuthorDto', biography?: string | null, birthYear?: number | null, code?: string | null, createdAt: any, deathYear?: number | null, firstName: string, id: number, lastName: string, middleName: string, updatedAt: any }> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type GetManagerFolderTreeListQueryVariables = Exact<{
  where?: InputMaybe<FolderDtoFilterInput>;
  order?: InputMaybe<Array<FolderDtoSortInput> | FolderDtoSortInput>;
}>;

export type GetManagerFolderTreeListQuery = { __typename?: 'GraphQlQueries', folders: Array<{ __typename?: 'FolderDto', childFolderCount: number, childPublicationCount: number, childPublicationCountRecursive: number, id: number, order: number, parentId?: number | null, path: Array<number>, title: string, typeId: string, createdAt: any, updatedAt: any, language?: { __typename?: 'LanguageDto', bcp47Code: string, code: string, egwCode: string, isRightToLeft: boolean, title: string } | null, parents: Array<{ __typename?: 'FolderDto', childFolderCount: number, childPublicationCount: number, childPublicationCountRecursive: number, id: number, order: number, parentId?: number | null, path: Array<number>, title: string, typeId: string, createdAt: any, updatedAt: any, language?: { __typename?: 'LanguageDto', bcp47Code: string, code: string, egwCode: string, isRightToLeft: boolean, title: string } | null, type?: { __typename?: 'FolderTypeDto', allowedTypes: Array<PublicationType>, createdAt: any, id: string, title: string, updatedAt: any } | null }>, type?: { __typename?: 'FolderTypeDto', allowedTypes: Array<PublicationType>, createdAt: any, id: string, title: string, updatedAt: any } | null }> };

export type GetManagerSelectedFolderQueryVariables = Exact<{
  folderId: Scalars['Int']['input'];
}>;

export type GetManagerSelectedFolderQuery = { __typename?: 'GraphQlQueries', folder?: { __typename?: 'FolderDto', childFolderCount: number, childPublicationCount: number, childPublicationCountRecursive: number, id: number, order: number, parentId?: number | null, path: Array<number>, title: string, typeId: string, createdAt: any, updatedAt: any, language?: { __typename?: 'LanguageDto', bcp47Code: string, code: string, egwCode: string, isRightToLeft: boolean, title: string } | null, parents: Array<{ __typename?: 'FolderDto', childFolderCount: number, childPublicationCount: number, childPublicationCountRecursive: number, id: number, order: number, parentId?: number | null, path: Array<number>, title: string, typeId: string, createdAt: any, updatedAt: any, language?: { __typename?: 'LanguageDto', bcp47Code: string, code: string, egwCode: string, isRightToLeft: boolean, title: string } | null, type?: { __typename?: 'FolderTypeDto', allowedTypes: Array<PublicationType>, createdAt: any, id: string, title: string, updatedAt: any } | null }>, type?: { __typename?: 'FolderTypeDto', allowedTypes: Array<PublicationType>, createdAt: any, id: string, title: string, updatedAt: any } | null } | null };

export type GetManagerFolderTypeListQueryVariables = Exact<{
  where?: InputMaybe<FolderTypeDtoFilterInput>;
  order?: InputMaybe<Array<FolderTypeDtoSortInput> | FolderTypeDtoSortInput>;
}>;

export type GetManagerFolderTypeListQuery = { __typename?: 'GraphQlQueries', folderTypes: Array<{ __typename?: 'FolderTypeDto', allowedTypes: Array<PublicationType>, createdAt: any, id: string, title: string, updatedAt: any }> };

export type GetManagerLanguageListQueryVariables = Exact<{
  where?: InputMaybe<LanguageDtoFilterInput>;
  order?: InputMaybe<Array<LanguageDtoSortInput> | LanguageDtoSortInput>;
}>;

export type GetManagerLanguageListQuery = { __typename?: 'GraphQlQueries', languages: Array<{ __typename?: 'LanguageDto', bcp47Code: string, code: string, createdAt: any, egwCode: string, isRightToLeft: boolean, rootFolderId?: number | null, title: string, updatedAt: any }> };

export type GetManagerPublicationCoverListQueryVariables = Exact<{
  where?: InputMaybe<CoverDtoFilterInput>;
  order?: InputMaybe<Array<CoverDtoSortInput> | CoverDtoSortInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  thumbnailWidth?: InputMaybe<Scalars['Int']['input']>;
  thumbnailHeight?: InputMaybe<Scalars['Int']['input']>;
  thumbnailResize?: InputMaybe<ResizeTypeEnum>;
}>;

export type GetManagerPublicationCoverListQuery = { __typename?: 'GraphQlQueries', covers?: { __typename?: 'CoversCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'CoverDto', createdAt: any, format: MediaFormatEnum, height: number, id: any, isMain: boolean, publicationId: number, size: any, thumbnail?: any | null, typeId: string, uri?: any | null, width: number, type?: { __typename?: 'CoverTypeDto', code: string, description?: string | null, minWidth: number, minHeight: number, coverCount: number } | null }> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type GetManagerPublicationCoverTypesQueryVariables = Exact<{
  where?: InputMaybe<CoverTypeDtoFilterInput>;
  order?: InputMaybe<Array<CoverTypeDtoSortInput> | CoverTypeDtoSortInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetManagerPublicationCoverTypesQuery = { __typename?: 'GraphQlQueries', coverTypes?: { __typename?: 'CoverTypesCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'CoverTypeDto', code: string, description?: string | null, minWidth: number, minHeight: number, coverCount: number }> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type GetManagerPublicationEpubListQueryVariables = Exact<{
  where?: InputMaybe<ExportedFileDtoFilterInput>;
  order?: InputMaybe<Array<ExportedFileDtoSortInput> | ExportedFileDtoSortInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetManagerPublicationEpubListQuery = { __typename?: 'GraphQlQueries', exportedFiles?: { __typename?: 'ExportedFilesCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'ExportedFileDto', createdAt: any, id: any, isMain: boolean, publicationId: number, size: any, type: ExportTypeEnum, updatedAt: any, uri?: any | null }> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type GetManagerPublicationListQueryVariables = Exact<{
  where?: InputMaybe<PublicationDtoFilterInput>;
  order?: InputMaybe<Array<PublicationDtoSortInput> | PublicationDtoSortInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  withWebCover?: InputMaybe<Scalars['Boolean']['input']>;
  thumbnailWidth?: InputMaybe<Scalars['Int']['input']>;
  thumbnailHeight?: InputMaybe<Scalars['Int']['input']>;
  thumbnailResize?: InputMaybe<ResizeTypeEnum>;
}>;

export type GetManagerPublicationListQuery = { __typename?: 'GraphQlQueries', publications?: { __typename?: 'PublicationsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'PublicationDto', authorId?: number | null, code: string, createdAt: any, description: string, folderId?: number | null, isbn?: string | null, languageCode: string, order: number, originalPublicationId?: number | null, pageCount?: number | null, permission: PublicationPermissionEnum, publicationId: number, publicationYear?: number | null, publisher: string, purchaseLink?: any | null, title: string, type: PublicationType, updatedAt: any, author?: { __typename?: 'PublicationAuthorDto', code?: string | null, createdAt: any, deathYear?: number | null, firstName: string, id: number, lastName: string, middleName: string } | null, language: { __typename?: 'LanguageDto', bcp47Code: string, code: string, createdAt: any, egwCode: string, isRightToLeft: boolean, rootFolderId?: number | null, title: string, updatedAt: any }, webCover?: { __typename?: 'CoverDto', thumbnail?: any | null, uri?: any | null } | null }> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type GetPublicationChaptersReaderDataQueryVariables = Exact<{
  publicationId: Scalars['Int']['input'];
}>;

export type GetPublicationChaptersReaderDataQuery = { __typename?: 'GraphQlQueries', publication?: { __typename?: 'PublicationDto', publicationId: number, title: string, type: PublicationType, chapters?: Array<{ __typename?: 'PublicationChapterDto', chapterId: any, paraId: any, level: number, order: number, title: string, publicationId: number }> | null } | null };

export type GetPublicationChaptersReaderParagraphListQueryVariables = Exact<{
  publicationId: Scalars['Int']['input'];
  order?: InputMaybe<IntOperationFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetPublicationChaptersReaderParagraphListQuery = { __typename?: 'GraphQlQueries', paragraphs?: { __typename?: 'ParagraphsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'ParagraphDto', paraId: any, content: string, order: number, publicationId: number, paragraphId: number, headingLevel?: number | null }> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type GetPublicationPageHocDataQueryVariables = Exact<{
  publicationId: Scalars['Int']['input'];
}>;

export type GetPublicationPageHocDataQuery = { __typename?: 'GraphQlQueries', publication?: { __typename?: 'PublicationDto', publicationId: number, title: string, type: PublicationType } | null };

export type PublicationPageHocDataFragment = { __typename?: 'PublicationDto', publicationId: number, title: string, type: PublicationType };

export type PublicationPageHocDataFragmentVariables = Exact<{ [key: string]: never; }>;

export const AuthorListItemFragmentDoc = gql`
    fragment AuthorListItem on PublicationAuthorDto {
  biography
  birthYear
  code
  createdAt
  deathYear
  firstName
  id
  lastName
  middleName
  updatedAt
}
    `;
export const ManagerFolderTypeListItemFragmentDoc = gql`
    fragment ManagerFolderTypeListItem on FolderTypeDto {
  allowedTypes
  createdAt
  id
  title
  updatedAt
}
    `;
export const ManagerParentFolderListItemFragmentDoc = gql`
    fragment ManagerParentFolderListItem on FolderDto {
  childFolderCount
  childPublicationCount
  childPublicationCountRecursive
  id
  language {
    bcp47Code
    code
    egwCode
    isRightToLeft
    title
  }
  order
  parentId
  path
  title
  type {
    ...ManagerFolderTypeListItem
  }
  typeId
  createdAt
  updatedAt
}
    `;
export const ManagerFolderTreeListItemFragmentDoc = gql`
    fragment ManagerFolderTreeListItem on FolderDto {
  childFolderCount
  childPublicationCount
  childPublicationCountRecursive
  id
  language {
    bcp47Code
    code
    egwCode
    isRightToLeft
    title
  }
  parents {
    ...ManagerParentFolderListItem
  }
  order
  parentId
  path
  title
  type {
    ...ManagerFolderTypeListItem
  }
  typeId
  createdAt
  updatedAt
}
    `;
export const ManagerPublicationCoverTypeListItemFragmentDoc = gql`
    fragment ManagerPublicationCoverTypeListItem on CoverTypeDto {
  code
  description
  minWidth
  minHeight
  coverCount
}
    `;
export const ManagerPublicationCoverListItemFragmentDoc = gql`
    fragment ManagerPublicationCoverListItem on CoverDto {
  createdAt
  format
  height
  id
  isMain
  publicationId
  size
  thumbnail(
    width: $thumbnailWidth
    height: $thumbnailHeight
    resize: $thumbnailResize
  )
  type {
    ...ManagerPublicationCoverTypeListItem
  }
  typeId
  uri
  width
}
    `;
export const ManagerPublicationCoverResponseFragmentDoc = gql`
    fragment ManagerPublicationCoverResponse on CoverDto {
  createdAt
  format
  height
  id
  isMain
  publicationId
  size
  type {
    ...ManagerPublicationCoverTypeListItem
  }
  typeId
  uri
  width
}
    `;
export const ManagerPublicationEpubListItemFragmentDoc = gql`
    fragment ManagerPublicationEpubListItem on ExportedFileDto {
  createdAt
  id
  isMain
  publicationId
  size
  type
  updatedAt
  uri
}
    `;
export const ManagerLanguageListItemFragmentDoc = gql`
    fragment ManagerLanguageListItem on LanguageDto {
  bcp47Code
  code
  createdAt
  egwCode
  isRightToLeft
  rootFolderId
  title
  updatedAt
}
    `;
export const ManagerPublicationListItemFragmentDoc = gql`
    fragment ManagerPublicationListItem on PublicationDto {
  author {
    code
    createdAt
    deathYear
    firstName
    id
    lastName
    middleName
  }
  authorId
  code
  createdAt
  description
  folderId
  isbn
  language {
    ...ManagerLanguageListItem
  }
  languageCode
  order
  originalPublicationId
  pageCount
  permission
  publicationId
  publicationYear
  publisher
  purchaseLink
  title
  type
  updatedAt
  webCover @include(if: $withWebCover) {
    thumbnail(
      width: $thumbnailWidth
      height: $thumbnailHeight
      resize: $thumbnailResize
    )
    uri
  }
}
    `;
export const ManagerPublicationMutationResponseFragmentDoc = gql`
    fragment ManagerPublicationMutationResponse on PublicationDto {
  author {
    code
    createdAt
    deathYear
    firstName
    id
    lastName
    middleName
  }
  authorId
  code
  createdAt
  description
  folderId
  isbn
  language {
    ...ManagerLanguageListItem
  }
  languageCode
  order
  originalPublicationId
  pageCount
  permission
  publicationId
  publicationYear
  publisher
  purchaseLink
  title
  type
  updatedAt
}
    `;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on CollectionSegmentInfo {
  hasNextPage
  hasPreviousPage
}
    `;
export const PublicationChaptersReaderChapterListItemFragmentDoc = gql`
    fragment PublicationChaptersReaderChapterListItem on PublicationChapterDto {
  chapterId
  paraId
  level
  order
  title
  publicationId
}
    `;
export const PublicationChaptersReaderDataFragmentDoc = gql`
    fragment PublicationChaptersReaderData on PublicationDto {
  publicationId
  title
  type
  chapters {
    ...PublicationChaptersReaderChapterListItem
  }
}
    `;
export const PublicationChaptersReaderParagraphListItemFragmentDoc = gql`
    fragment PublicationChaptersReaderParagraphListItem on ParagraphDto {
  paraId
  content
  order
  publicationId
  paragraphId
  headingLevel
}
    `;
export const PublicationPageHocDataFragmentDoc = gql`
    fragment PublicationPageHocData on PublicationDto {
  publicationId
  title
  type
}
    `;
export const RecalculateGlobalFolderPositionsDocument = gql`
    mutation RecalculateGlobalFolderPositions {
  recalculateFolders {
    boolean
  }
}
    `;
export type RecalculateGlobalFolderPositionsMutationFn = Apollo.MutationFunction<RecalculateGlobalFolderPositionsMutation, RecalculateGlobalFolderPositionsMutationVariables>;

/**
 * __useRecalculateGlobalFolderPositionsMutation__
 *
 * To run a mutation, you first call `useRecalculateGlobalFolderPositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecalculateGlobalFolderPositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recalculateGlobalFolderPositionsMutation, { data, loading, error }] = useRecalculateGlobalFolderPositionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRecalculateGlobalFolderPositionsMutation(baseOptions?: Apollo.MutationHookOptions<RecalculateGlobalFolderPositionsMutation, RecalculateGlobalFolderPositionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<RecalculateGlobalFolderPositionsMutation, RecalculateGlobalFolderPositionsMutationVariables>(RecalculateGlobalFolderPositionsDocument, options);
      }
export type RecalculateGlobalFolderPositionsMutationHookResult = ReturnType<typeof useRecalculateGlobalFolderPositionsMutation>;
export type RecalculateGlobalFolderPositionsMutationResult = Apollo.MutationResult<RecalculateGlobalFolderPositionsMutation>;
export type RecalculateGlobalFolderPositionsMutationOptions = Apollo.BaseMutationOptions<RecalculateGlobalFolderPositionsMutation, RecalculateGlobalFolderPositionsMutationVariables>;
export const RecalculatePublicationDocument = gql`
    mutation RecalculatePublication($input: RecalculatePublicationInput!) {
  recalculatePublication(input: $input) {
    boolean
  }
}
    `;
export type RecalculatePublicationMutationFn = Apollo.MutationFunction<RecalculatePublicationMutation, RecalculatePublicationMutationVariables>;

/**
 * __useRecalculatePublicationMutation__
 *
 * To run a mutation, you first call `useRecalculatePublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecalculatePublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recalculatePublicationMutation, { data, loading, error }] = useRecalculatePublicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecalculatePublicationMutation(baseOptions?: Apollo.MutationHookOptions<RecalculatePublicationMutation, RecalculatePublicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<RecalculatePublicationMutation, RecalculatePublicationMutationVariables>(RecalculatePublicationDocument, options);
      }
export type RecalculatePublicationMutationHookResult = ReturnType<typeof useRecalculatePublicationMutation>;
export type RecalculatePublicationMutationResult = Apollo.MutationResult<RecalculatePublicationMutation>;
export type RecalculatePublicationMutationOptions = Apollo.BaseMutationOptions<RecalculatePublicationMutation, RecalculatePublicationMutationVariables>;
export const CreateRootFolderForLanguageDocument = gql`
    mutation CreateRootFolderForLanguage($input: CreateRootFolderForLanguageInput!) {
  createRootFolderForLanguage(input: $input) {
    languageDto {
      ...ManagerLanguageListItem
    }
  }
}
    ${ManagerLanguageListItemFragmentDoc}`;
export type CreateRootFolderForLanguageMutationFn = Apollo.MutationFunction<CreateRootFolderForLanguageMutation, CreateRootFolderForLanguageMutationVariables>;

/**
 * __useCreateRootFolderForLanguageMutation__
 *
 * To run a mutation, you first call `useCreateRootFolderForLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRootFolderForLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRootFolderForLanguageMutation, { data, loading, error }] = useCreateRootFolderForLanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRootFolderForLanguageMutation(baseOptions?: Apollo.MutationHookOptions<CreateRootFolderForLanguageMutation, CreateRootFolderForLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<CreateRootFolderForLanguageMutation, CreateRootFolderForLanguageMutationVariables>(CreateRootFolderForLanguageDocument, options);
      }
export type CreateRootFolderForLanguageMutationHookResult = ReturnType<typeof useCreateRootFolderForLanguageMutation>;
export type CreateRootFolderForLanguageMutationResult = Apollo.MutationResult<CreateRootFolderForLanguageMutation>;
export type CreateRootFolderForLanguageMutationOptions = Apollo.BaseMutationOptions<CreateRootFolderForLanguageMutation, CreateRootFolderForLanguageMutationVariables>;
export const CreateFolderDocument = gql`
    mutation CreateFolder($input: CreateFolderInput!) {
  createFolder(input: $input) {
    boolean
  }
}
    `;
export type CreateFolderMutationFn = Apollo.MutationFunction<CreateFolderMutation, CreateFolderMutationVariables>;

/**
 * __useCreateFolderMutation__
 *
 * To run a mutation, you first call `useCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderMutation, { data, loading, error }] = useCreateFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFolderMutation(baseOptions?: Apollo.MutationHookOptions<CreateFolderMutation, CreateFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<CreateFolderMutation, CreateFolderMutationVariables>(CreateFolderDocument, options);
      }
export type CreateFolderMutationHookResult = ReturnType<typeof useCreateFolderMutation>;
export type CreateFolderMutationResult = Apollo.MutationResult<CreateFolderMutation>;
export type CreateFolderMutationOptions = Apollo.BaseMutationOptions<CreateFolderMutation, CreateFolderMutationVariables>;
export const MoveFolderDocument = gql`
    mutation MoveFolder($input: MoveFolderInput!) {
  moveFolder(input: $input) {
    folderDto {
      ...ManagerFolderTreeListItem
    }
  }
}
    ${ManagerFolderTreeListItemFragmentDoc}
${ManagerParentFolderListItemFragmentDoc}
${ManagerFolderTypeListItemFragmentDoc}`;
export type MoveFolderMutationFn = Apollo.MutationFunction<MoveFolderMutation, MoveFolderMutationVariables>;

/**
 * __useMoveFolderMutation__
 *
 * To run a mutation, you first call `useMoveFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveFolderMutation, { data, loading, error }] = useMoveFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveFolderMutation(baseOptions?: Apollo.MutationHookOptions<MoveFolderMutation, MoveFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<MoveFolderMutation, MoveFolderMutationVariables>(MoveFolderDocument, options);
      }
export type MoveFolderMutationHookResult = ReturnType<typeof useMoveFolderMutation>;
export type MoveFolderMutationResult = Apollo.MutationResult<MoveFolderMutation>;
export type MoveFolderMutationOptions = Apollo.BaseMutationOptions<MoveFolderMutation, MoveFolderMutationVariables>;
export const RenameFolderDocument = gql`
    mutation RenameFolder($input: RenameFolderInput!) {
  renameFolder(input: $input) {
    folderDto {
      ...ManagerFolderTreeListItem
    }
  }
}
    ${ManagerFolderTreeListItemFragmentDoc}
${ManagerParentFolderListItemFragmentDoc}
${ManagerFolderTypeListItemFragmentDoc}`;
export type RenameFolderMutationFn = Apollo.MutationFunction<RenameFolderMutation, RenameFolderMutationVariables>;

/**
 * __useRenameFolderMutation__
 *
 * To run a mutation, you first call `useRenameFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameFolderMutation, { data, loading, error }] = useRenameFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameFolderMutation(baseOptions?: Apollo.MutationHookOptions<RenameFolderMutation, RenameFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<RenameFolderMutation, RenameFolderMutationVariables>(RenameFolderDocument, options);
      }
export type RenameFolderMutationHookResult = ReturnType<typeof useRenameFolderMutation>;
export type RenameFolderMutationResult = Apollo.MutationResult<RenameFolderMutation>;
export type RenameFolderMutationOptions = Apollo.BaseMutationOptions<RenameFolderMutation, RenameFolderMutationVariables>;
export const ChangeFolderTypeDocument = gql`
    mutation ChangeFolderType($input: ChangeFolderTypeInput!) {
  changeFolderType(input: $input) {
    folderDto {
      ...ManagerFolderTreeListItem
    }
  }
}
    ${ManagerFolderTreeListItemFragmentDoc}
${ManagerParentFolderListItemFragmentDoc}
${ManagerFolderTypeListItemFragmentDoc}`;
export type ChangeFolderTypeMutationFn = Apollo.MutationFunction<ChangeFolderTypeMutation, ChangeFolderTypeMutationVariables>;

/**
 * __useChangeFolderTypeMutation__
 *
 * To run a mutation, you first call `useChangeFolderTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeFolderTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeFolderTypeMutation, { data, loading, error }] = useChangeFolderTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeFolderTypeMutation(baseOptions?: Apollo.MutationHookOptions<ChangeFolderTypeMutation, ChangeFolderTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<ChangeFolderTypeMutation, ChangeFolderTypeMutationVariables>(ChangeFolderTypeDocument, options);
      }
export type ChangeFolderTypeMutationHookResult = ReturnType<typeof useChangeFolderTypeMutation>;
export type ChangeFolderTypeMutationResult = Apollo.MutationResult<ChangeFolderTypeMutation>;
export type ChangeFolderTypeMutationOptions = Apollo.BaseMutationOptions<ChangeFolderTypeMutation, ChangeFolderTypeMutationVariables>;
export const DeleteFolderDocument = gql`
    mutation DeleteFolder($input: DeleteFolderInput!) {
  deleteFolder(input: $input) {
    boolean
  }
}
    `;
export type DeleteFolderMutationFn = Apollo.MutationFunction<DeleteFolderMutation, DeleteFolderMutationVariables>;

/**
 * __useDeleteFolderMutation__
 *
 * To run a mutation, you first call `useDeleteFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFolderMutation, { data, loading, error }] = useDeleteFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFolderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFolderMutation, DeleteFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<DeleteFolderMutation, DeleteFolderMutationVariables>(DeleteFolderDocument, options);
      }
export type DeleteFolderMutationHookResult = ReturnType<typeof useDeleteFolderMutation>;
export type DeleteFolderMutationResult = Apollo.MutationResult<DeleteFolderMutation>;
export type DeleteFolderMutationOptions = Apollo.BaseMutationOptions<DeleteFolderMutation, DeleteFolderMutationVariables>;
export const CreateLanguageDocument = gql`
    mutation CreateLanguage($input: CreateLanguageInput!) {
  createLanguage(input: $input) {
    languageDto {
      ...ManagerLanguageListItem
    }
  }
}
    ${ManagerLanguageListItemFragmentDoc}`;
export type CreateLanguageMutationFn = Apollo.MutationFunction<CreateLanguageMutation, CreateLanguageMutationVariables>;

/**
 * __useCreateLanguageMutation__
 *
 * To run a mutation, you first call `useCreateLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLanguageMutation, { data, loading, error }] = useCreateLanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLanguageMutation(baseOptions?: Apollo.MutationHookOptions<CreateLanguageMutation, CreateLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<CreateLanguageMutation, CreateLanguageMutationVariables>(CreateLanguageDocument, options);
      }
export type CreateLanguageMutationHookResult = ReturnType<typeof useCreateLanguageMutation>;
export type CreateLanguageMutationResult = Apollo.MutationResult<CreateLanguageMutation>;
export type CreateLanguageMutationOptions = Apollo.BaseMutationOptions<CreateLanguageMutation, CreateLanguageMutationVariables>;
export const UpdateLanguageDocument = gql`
    mutation UpdateLanguage($input: UpdateLanguageInput!) {
  updateLanguage(input: $input) {
    languageDto {
      ...ManagerLanguageListItem
    }
  }
}
    ${ManagerLanguageListItemFragmentDoc}`;
export type UpdateLanguageMutationFn = Apollo.MutationFunction<UpdateLanguageMutation, UpdateLanguageMutationVariables>;

/**
 * __useUpdateLanguageMutation__
 *
 * To run a mutation, you first call `useUpdateLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLanguageMutation, { data, loading, error }] = useUpdateLanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLanguageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLanguageMutation, UpdateLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<UpdateLanguageMutation, UpdateLanguageMutationVariables>(UpdateLanguageDocument, options);
      }
export type UpdateLanguageMutationHookResult = ReturnType<typeof useUpdateLanguageMutation>;
export type UpdateLanguageMutationResult = Apollo.MutationResult<UpdateLanguageMutation>;
export type UpdateLanguageMutationOptions = Apollo.BaseMutationOptions<UpdateLanguageMutation, UpdateLanguageMutationVariables>;
export const DeleteLanguageDocument = gql`
    mutation DeleteLanguage($input: DeleteLanguageInput!) {
  deleteLanguage(input: $input) {
    languageDto {
      ...ManagerLanguageListItem
    }
  }
}
    ${ManagerLanguageListItemFragmentDoc}`;
export type DeleteLanguageMutationFn = Apollo.MutationFunction<DeleteLanguageMutation, DeleteLanguageMutationVariables>;

/**
 * __useDeleteLanguageMutation__
 *
 * To run a mutation, you first call `useDeleteLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLanguageMutation, { data, loading, error }] = useDeleteLanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLanguageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLanguageMutation, DeleteLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<DeleteLanguageMutation, DeleteLanguageMutationVariables>(DeleteLanguageDocument, options);
      }
export type DeleteLanguageMutationHookResult = ReturnType<typeof useDeleteLanguageMutation>;
export type DeleteLanguageMutationResult = Apollo.MutationResult<DeleteLanguageMutation>;
export type DeleteLanguageMutationOptions = Apollo.BaseMutationOptions<DeleteLanguageMutation, DeleteLanguageMutationVariables>;
export const SetPublicationChaptersParagraphContentDocument = gql`
    mutation SetPublicationChaptersParagraphContent($input: SetParagraphContentInput!) {
  setParagraphContent(input: $input) {
    paragraphDto {
      ...PublicationChaptersReaderParagraphListItem
    }
  }
}
    ${PublicationChaptersReaderParagraphListItemFragmentDoc}`;
export type SetPublicationChaptersParagraphContentMutationFn = Apollo.MutationFunction<SetPublicationChaptersParagraphContentMutation, SetPublicationChaptersParagraphContentMutationVariables>;

/**
 * __useSetPublicationChaptersParagraphContentMutation__
 *
 * To run a mutation, you first call `useSetPublicationChaptersParagraphContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPublicationChaptersParagraphContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPublicationChaptersParagraphContentMutation, { data, loading, error }] = useSetPublicationChaptersParagraphContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPublicationChaptersParagraphContentMutation(baseOptions?: Apollo.MutationHookOptions<SetPublicationChaptersParagraphContentMutation, SetPublicationChaptersParagraphContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<SetPublicationChaptersParagraphContentMutation, SetPublicationChaptersParagraphContentMutationVariables>(SetPublicationChaptersParagraphContentDocument, options);
      }
export type SetPublicationChaptersParagraphContentMutationHookResult = ReturnType<typeof useSetPublicationChaptersParagraphContentMutation>;
export type SetPublicationChaptersParagraphContentMutationResult = Apollo.MutationResult<SetPublicationChaptersParagraphContentMutation>;
export type SetPublicationChaptersParagraphContentMutationOptions = Apollo.BaseMutationOptions<SetPublicationChaptersParagraphContentMutation, SetPublicationChaptersParagraphContentMutationVariables>;
export const AddPublicationChaptersParagraphDocument = gql`
    mutation addPublicationChaptersParagraph($input: AddParagraphInput!) {
  addParagraph(input: $input) {
    paragraphDto {
      ...PublicationChaptersReaderParagraphListItem
    }
  }
}
    ${PublicationChaptersReaderParagraphListItemFragmentDoc}`;
export type AddPublicationChaptersParagraphMutationFn = Apollo.MutationFunction<AddPublicationChaptersParagraphMutation, AddPublicationChaptersParagraphMutationVariables>;

/**
 * __useAddPublicationChaptersParagraphMutation__
 *
 * To run a mutation, you first call `useAddPublicationChaptersParagraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPublicationChaptersParagraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPublicationChaptersParagraphMutation, { data, loading, error }] = useAddPublicationChaptersParagraphMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPublicationChaptersParagraphMutation(baseOptions?: Apollo.MutationHookOptions<AddPublicationChaptersParagraphMutation, AddPublicationChaptersParagraphMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<AddPublicationChaptersParagraphMutation, AddPublicationChaptersParagraphMutationVariables>(AddPublicationChaptersParagraphDocument, options);
      }
export type AddPublicationChaptersParagraphMutationHookResult = ReturnType<typeof useAddPublicationChaptersParagraphMutation>;
export type AddPublicationChaptersParagraphMutationResult = Apollo.MutationResult<AddPublicationChaptersParagraphMutation>;
export type AddPublicationChaptersParagraphMutationOptions = Apollo.BaseMutationOptions<AddPublicationChaptersParagraphMutation, AddPublicationChaptersParagraphMutationVariables>;
export const DeletePublicationChaptersParagraphDocument = gql`
    mutation deletePublicationChaptersParagraph($input: DeleteParagraphInput!) {
  deleteParagraph(input: $input) {
    paraId
  }
}
    `;
export type DeletePublicationChaptersParagraphMutationFn = Apollo.MutationFunction<DeletePublicationChaptersParagraphMutation, DeletePublicationChaptersParagraphMutationVariables>;

/**
 * __useDeletePublicationChaptersParagraphMutation__
 *
 * To run a mutation, you first call `useDeletePublicationChaptersParagraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublicationChaptersParagraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublicationChaptersParagraphMutation, { data, loading, error }] = useDeletePublicationChaptersParagraphMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePublicationChaptersParagraphMutation(baseOptions?: Apollo.MutationHookOptions<DeletePublicationChaptersParagraphMutation, DeletePublicationChaptersParagraphMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<DeletePublicationChaptersParagraphMutation, DeletePublicationChaptersParagraphMutationVariables>(DeletePublicationChaptersParagraphDocument, options);
      }
export type DeletePublicationChaptersParagraphMutationHookResult = ReturnType<typeof useDeletePublicationChaptersParagraphMutation>;
export type DeletePublicationChaptersParagraphMutationResult = Apollo.MutationResult<DeletePublicationChaptersParagraphMutation>;
export type DeletePublicationChaptersParagraphMutationOptions = Apollo.BaseMutationOptions<DeletePublicationChaptersParagraphMutation, DeletePublicationChaptersParagraphMutationVariables>;
export const SetPublicationMainCoverDocument = gql`
    mutation SetPublicationMainCover($input: SetMainCoverInput!) {
  setMainCover(input: $input) {
    coverDto {
      ...ManagerPublicationCoverResponse
    }
  }
}
    ${ManagerPublicationCoverResponseFragmentDoc}
${ManagerPublicationCoverTypeListItemFragmentDoc}`;
export type SetPublicationMainCoverMutationFn = Apollo.MutationFunction<SetPublicationMainCoverMutation, SetPublicationMainCoverMutationVariables>;

/**
 * __useSetPublicationMainCoverMutation__
 *
 * To run a mutation, you first call `useSetPublicationMainCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPublicationMainCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPublicationMainCoverMutation, { data, loading, error }] = useSetPublicationMainCoverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPublicationMainCoverMutation(baseOptions?: Apollo.MutationHookOptions<SetPublicationMainCoverMutation, SetPublicationMainCoverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<SetPublicationMainCoverMutation, SetPublicationMainCoverMutationVariables>(SetPublicationMainCoverDocument, options);
      }
export type SetPublicationMainCoverMutationHookResult = ReturnType<typeof useSetPublicationMainCoverMutation>;
export type SetPublicationMainCoverMutationResult = Apollo.MutationResult<SetPublicationMainCoverMutation>;
export type SetPublicationMainCoverMutationOptions = Apollo.BaseMutationOptions<SetPublicationMainCoverMutation, SetPublicationMainCoverMutationVariables>;
export const UploadPublicationCoverDocument = gql`
    mutation UploadPublicationCover($input: UploadCoverInput!) {
  uploadCover(input: $input) {
    coverDto {
      ...ManagerPublicationCoverResponse
    }
  }
}
    ${ManagerPublicationCoverResponseFragmentDoc}
${ManagerPublicationCoverTypeListItemFragmentDoc}`;
export type UploadPublicationCoverMutationFn = Apollo.MutationFunction<UploadPublicationCoverMutation, UploadPublicationCoverMutationVariables>;

/**
 * __useUploadPublicationCoverMutation__
 *
 * To run a mutation, you first call `useUploadPublicationCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPublicationCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPublicationCoverMutation, { data, loading, error }] = useUploadPublicationCoverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPublicationCoverMutation(baseOptions?: Apollo.MutationHookOptions<UploadPublicationCoverMutation, UploadPublicationCoverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<UploadPublicationCoverMutation, UploadPublicationCoverMutationVariables>(UploadPublicationCoverDocument, options);
      }
export type UploadPublicationCoverMutationHookResult = ReturnType<typeof useUploadPublicationCoverMutation>;
export type UploadPublicationCoverMutationResult = Apollo.MutationResult<UploadPublicationCoverMutation>;
export type UploadPublicationCoverMutationOptions = Apollo.BaseMutationOptions<UploadPublicationCoverMutation, UploadPublicationCoverMutationVariables>;
export const CreatePublicationCoverTypeDocument = gql`
    mutation CreatePublicationCoverType($input: CreateCoverTypeInput!) {
  createCoverType(input: $input) {
    coverTypeDto {
      ...ManagerPublicationCoverTypeListItem
    }
  }
}
    ${ManagerPublicationCoverTypeListItemFragmentDoc}`;
export type CreatePublicationCoverTypeMutationFn = Apollo.MutationFunction<CreatePublicationCoverTypeMutation, CreatePublicationCoverTypeMutationVariables>;

/**
 * __useCreatePublicationCoverTypeMutation__
 *
 * To run a mutation, you first call `useCreatePublicationCoverTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicationCoverTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicationCoverTypeMutation, { data, loading, error }] = useCreatePublicationCoverTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePublicationCoverTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreatePublicationCoverTypeMutation, CreatePublicationCoverTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<CreatePublicationCoverTypeMutation, CreatePublicationCoverTypeMutationVariables>(CreatePublicationCoverTypeDocument, options);
      }
export type CreatePublicationCoverTypeMutationHookResult = ReturnType<typeof useCreatePublicationCoverTypeMutation>;
export type CreatePublicationCoverTypeMutationResult = Apollo.MutationResult<CreatePublicationCoverTypeMutation>;
export type CreatePublicationCoverTypeMutationOptions = Apollo.BaseMutationOptions<CreatePublicationCoverTypeMutation, CreatePublicationCoverTypeMutationVariables>;
export const UpdatePublicationCoverTypeDocument = gql`
    mutation UpdatePublicationCoverType($input: UpdateCoverTypeInput!) {
  updateCoverType(input: $input) {
    coverTypeDto {
      ...ManagerPublicationCoverTypeListItem
    }
  }
}
    ${ManagerPublicationCoverTypeListItemFragmentDoc}`;
export type UpdatePublicationCoverTypeMutationFn = Apollo.MutationFunction<UpdatePublicationCoverTypeMutation, UpdatePublicationCoverTypeMutationVariables>;

/**
 * __useUpdatePublicationCoverTypeMutation__
 *
 * To run a mutation, you first call `useUpdatePublicationCoverTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicationCoverTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicationCoverTypeMutation, { data, loading, error }] = useUpdatePublicationCoverTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublicationCoverTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublicationCoverTypeMutation, UpdatePublicationCoverTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<UpdatePublicationCoverTypeMutation, UpdatePublicationCoverTypeMutationVariables>(UpdatePublicationCoverTypeDocument, options);
      }
export type UpdatePublicationCoverTypeMutationHookResult = ReturnType<typeof useUpdatePublicationCoverTypeMutation>;
export type UpdatePublicationCoverTypeMutationResult = Apollo.MutationResult<UpdatePublicationCoverTypeMutation>;
export type UpdatePublicationCoverTypeMutationOptions = Apollo.BaseMutationOptions<UpdatePublicationCoverTypeMutation, UpdatePublicationCoverTypeMutationVariables>;
export const DeletePublicationCoverTypeDocument = gql`
    mutation DeletePublicationCoverType($input: DeleteCoverTypeInput!) {
  deleteCoverType(input: $input) {
    coverTypeDto {
      ...ManagerPublicationCoverTypeListItem
    }
  }
}
    ${ManagerPublicationCoverTypeListItemFragmentDoc}`;
export type DeletePublicationCoverTypeMutationFn = Apollo.MutationFunction<DeletePublicationCoverTypeMutation, DeletePublicationCoverTypeMutationVariables>;

/**
 * __useDeletePublicationCoverTypeMutation__
 *
 * To run a mutation, you first call `useDeletePublicationCoverTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublicationCoverTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublicationCoverTypeMutation, { data, loading, error }] = useDeletePublicationCoverTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePublicationCoverTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeletePublicationCoverTypeMutation, DeletePublicationCoverTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<DeletePublicationCoverTypeMutation, DeletePublicationCoverTypeMutationVariables>(DeletePublicationCoverTypeDocument, options);
      }
export type DeletePublicationCoverTypeMutationHookResult = ReturnType<typeof useDeletePublicationCoverTypeMutation>;
export type DeletePublicationCoverTypeMutationResult = Apollo.MutationResult<DeletePublicationCoverTypeMutation>;
export type DeletePublicationCoverTypeMutationOptions = Apollo.BaseMutationOptions<DeletePublicationCoverTypeMutation, DeletePublicationCoverTypeMutationVariables>;
export const SetPublicationMainEpubDocument = gql`
    mutation SetPublicationMainEpub($input: SetMainExportFileInput!) {
  setMainExportFile(input: $input) {
    boolean
  }
}
    `;
export type SetPublicationMainEpubMutationFn = Apollo.MutationFunction<SetPublicationMainEpubMutation, SetPublicationMainEpubMutationVariables>;

/**
 * __useSetPublicationMainEpubMutation__
 *
 * To run a mutation, you first call `useSetPublicationMainEpubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPublicationMainEpubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPublicationMainEpubMutation, { data, loading, error }] = useSetPublicationMainEpubMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPublicationMainEpubMutation(baseOptions?: Apollo.MutationHookOptions<SetPublicationMainEpubMutation, SetPublicationMainEpubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<SetPublicationMainEpubMutation, SetPublicationMainEpubMutationVariables>(SetPublicationMainEpubDocument, options);
      }
export type SetPublicationMainEpubMutationHookResult = ReturnType<typeof useSetPublicationMainEpubMutation>;
export type SetPublicationMainEpubMutationResult = Apollo.MutationResult<SetPublicationMainEpubMutation>;
export type SetPublicationMainEpubMutationOptions = Apollo.BaseMutationOptions<SetPublicationMainEpubMutation, SetPublicationMainEpubMutationVariables>;
export const GenerateNewPublicationEpubDocument = gql`
    mutation GenerateNewPublicationEpub($input: ExportEpubInput!) {
  exportEpub(input: $input) {
    boolean
  }
}
    `;
export type GenerateNewPublicationEpubMutationFn = Apollo.MutationFunction<GenerateNewPublicationEpubMutation, GenerateNewPublicationEpubMutationVariables>;

/**
 * __useGenerateNewPublicationEpubMutation__
 *
 * To run a mutation, you first call `useGenerateNewPublicationEpubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateNewPublicationEpubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateNewPublicationEpubMutation, { data, loading, error }] = useGenerateNewPublicationEpubMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateNewPublicationEpubMutation(baseOptions?: Apollo.MutationHookOptions<GenerateNewPublicationEpubMutation, GenerateNewPublicationEpubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<GenerateNewPublicationEpubMutation, GenerateNewPublicationEpubMutationVariables>(GenerateNewPublicationEpubDocument, options);
      }
export type GenerateNewPublicationEpubMutationHookResult = ReturnType<typeof useGenerateNewPublicationEpubMutation>;
export type GenerateNewPublicationEpubMutationResult = Apollo.MutationResult<GenerateNewPublicationEpubMutation>;
export type GenerateNewPublicationEpubMutationOptions = Apollo.BaseMutationOptions<GenerateNewPublicationEpubMutation, GenerateNewPublicationEpubMutationVariables>;
export const DeletePublicationEpubDocument = gql`
    mutation DeletePublicationEpub($input: DeleteExportFileInput!) {
  deleteExportFile(input: $input) {
    boolean
  }
}
    `;
export type DeletePublicationEpubMutationFn = Apollo.MutationFunction<DeletePublicationEpubMutation, DeletePublicationEpubMutationVariables>;

/**
 * __useDeletePublicationEpubMutation__
 *
 * To run a mutation, you first call `useDeletePublicationEpubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublicationEpubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublicationEpubMutation, { data, loading, error }] = useDeletePublicationEpubMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePublicationEpubMutation(baseOptions?: Apollo.MutationHookOptions<DeletePublicationEpubMutation, DeletePublicationEpubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<DeletePublicationEpubMutation, DeletePublicationEpubMutationVariables>(DeletePublicationEpubDocument, options);
      }
export type DeletePublicationEpubMutationHookResult = ReturnType<typeof useDeletePublicationEpubMutation>;
export type DeletePublicationEpubMutationResult = Apollo.MutationResult<DeletePublicationEpubMutation>;
export type DeletePublicationEpubMutationOptions = Apollo.BaseMutationOptions<DeletePublicationEpubMutation, DeletePublicationEpubMutationVariables>;
export const UnpublishPublicationDocument = gql`
    mutation UnpublishPublication($input: UnpublishPublicationInput!) {
  unpublishPublication(input: $input) {
    publicationDto {
      ...ManagerPublicationMutationResponse
    }
  }
}
    ${ManagerPublicationMutationResponseFragmentDoc}
${ManagerLanguageListItemFragmentDoc}`;
export type UnpublishPublicationMutationFn = Apollo.MutationFunction<UnpublishPublicationMutation, UnpublishPublicationMutationVariables>;

/**
 * __useUnpublishPublicationMutation__
 *
 * To run a mutation, you first call `useUnpublishPublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishPublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishPublicationMutation, { data, loading, error }] = useUnpublishPublicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishPublicationMutation(baseOptions?: Apollo.MutationHookOptions<UnpublishPublicationMutation, UnpublishPublicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<UnpublishPublicationMutation, UnpublishPublicationMutationVariables>(UnpublishPublicationDocument, options);
      }
export type UnpublishPublicationMutationHookResult = ReturnType<typeof useUnpublishPublicationMutation>;
export type UnpublishPublicationMutationResult = Apollo.MutationResult<UnpublishPublicationMutation>;
export type UnpublishPublicationMutationOptions = Apollo.BaseMutationOptions<UnpublishPublicationMutation, UnpublishPublicationMutationVariables>;
export const PublishPublicationDocument = gql`
    mutation PublishPublication($input: PublishPublicationInput!) {
  publishPublication(input: $input) {
    publicationDto {
      ...ManagerPublicationMutationResponse
    }
  }
}
    ${ManagerPublicationMutationResponseFragmentDoc}
${ManagerLanguageListItemFragmentDoc}`;
export type PublishPublicationMutationFn = Apollo.MutationFunction<PublishPublicationMutation, PublishPublicationMutationVariables>;

/**
 * __usePublishPublicationMutation__
 *
 * To run a mutation, you first call `usePublishPublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishPublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishPublicationMutation, { data, loading, error }] = usePublishPublicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishPublicationMutation(baseOptions?: Apollo.MutationHookOptions<PublishPublicationMutation, PublishPublicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<PublishPublicationMutation, PublishPublicationMutationVariables>(PublishPublicationDocument, options);
      }
export type PublishPublicationMutationHookResult = ReturnType<typeof usePublishPublicationMutation>;
export type PublishPublicationMutationResult = Apollo.MutationResult<PublishPublicationMutation>;
export type PublishPublicationMutationOptions = Apollo.BaseMutationOptions<PublishPublicationMutation, PublishPublicationMutationVariables>;
export const MovePublicationDocument = gql`
    mutation MovePublication($input: MovePublicationInput!) {
  movePublication(input: $input) {
    publicationDto {
      ...ManagerPublicationMutationResponse
    }
  }
}
    ${ManagerPublicationMutationResponseFragmentDoc}
${ManagerLanguageListItemFragmentDoc}`;
export type MovePublicationMutationFn = Apollo.MutationFunction<MovePublicationMutation, MovePublicationMutationVariables>;

/**
 * __useMovePublicationMutation__
 *
 * To run a mutation, you first call `useMovePublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovePublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movePublicationMutation, { data, loading, error }] = useMovePublicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMovePublicationMutation(baseOptions?: Apollo.MutationHookOptions<MovePublicationMutation, MovePublicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<MovePublicationMutation, MovePublicationMutationVariables>(MovePublicationDocument, options);
      }
export type MovePublicationMutationHookResult = ReturnType<typeof useMovePublicationMutation>;
export type MovePublicationMutationResult = Apollo.MutationResult<MovePublicationMutation>;
export type MovePublicationMutationOptions = Apollo.BaseMutationOptions<MovePublicationMutation, MovePublicationMutationVariables>;
export const SetPublicationPermissionDocument = gql`
    mutation SetPublicationPermission($input: SetPublicationPermissionInput!) {
  setPublicationPermission(input: $input) {
    publicationDto {
      ...ManagerPublicationMutationResponse
    }
  }
}
    ${ManagerPublicationMutationResponseFragmentDoc}
${ManagerLanguageListItemFragmentDoc}`;
export type SetPublicationPermissionMutationFn = Apollo.MutationFunction<SetPublicationPermissionMutation, SetPublicationPermissionMutationVariables>;

/**
 * __useSetPublicationPermissionMutation__
 *
 * To run a mutation, you first call `useSetPublicationPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPublicationPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPublicationPermissionMutation, { data, loading, error }] = useSetPublicationPermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPublicationPermissionMutation(baseOptions?: Apollo.MutationHookOptions<SetPublicationPermissionMutation, SetPublicationPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<SetPublicationPermissionMutation, SetPublicationPermissionMutationVariables>(SetPublicationPermissionDocument, options);
      }
export type SetPublicationPermissionMutationHookResult = ReturnType<typeof useSetPublicationPermissionMutation>;
export type SetPublicationPermissionMutationResult = Apollo.MutationResult<SetPublicationPermissionMutation>;
export type SetPublicationPermissionMutationOptions = Apollo.BaseMutationOptions<SetPublicationPermissionMutation, SetPublicationPermissionMutationVariables>;
export const SetPublicationOrderDocument = gql`
    mutation SetPublicationOrder($input: SetPublicationOrderInput!) {
  setPublicationOrder(input: $input) {
    publicationDto {
      ...ManagerPublicationMutationResponse
    }
  }
}
    ${ManagerPublicationMutationResponseFragmentDoc}
${ManagerLanguageListItemFragmentDoc}`;
export type SetPublicationOrderMutationFn = Apollo.MutationFunction<SetPublicationOrderMutation, SetPublicationOrderMutationVariables>;

/**
 * __useSetPublicationOrderMutation__
 *
 * To run a mutation, you first call `useSetPublicationOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPublicationOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPublicationOrderMutation, { data, loading, error }] = useSetPublicationOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPublicationOrderMutation(baseOptions?: Apollo.MutationHookOptions<SetPublicationOrderMutation, SetPublicationOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<SetPublicationOrderMutation, SetPublicationOrderMutationVariables>(SetPublicationOrderDocument, options);
      }
export type SetPublicationOrderMutationHookResult = ReturnType<typeof useSetPublicationOrderMutation>;
export type SetPublicationOrderMutationResult = Apollo.MutationResult<SetPublicationOrderMutation>;
export type SetPublicationOrderMutationOptions = Apollo.BaseMutationOptions<SetPublicationOrderMutation, SetPublicationOrderMutationVariables>;
export const SetPublicationTocDepthDocument = gql`
    mutation SetPublicationTocDepth($input: SetPublicationTocDepthInput!) {
  setPublicationTocDepth(input: $input) {
    publicationDto {
      ...ManagerPublicationMutationResponse
    }
  }
}
    ${ManagerPublicationMutationResponseFragmentDoc}
${ManagerLanguageListItemFragmentDoc}`;
export type SetPublicationTocDepthMutationFn = Apollo.MutationFunction<SetPublicationTocDepthMutation, SetPublicationTocDepthMutationVariables>;

/**
 * __useSetPublicationTocDepthMutation__
 *
 * To run a mutation, you first call `useSetPublicationTocDepthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPublicationTocDepthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPublicationTocDepthMutation, { data, loading, error }] = useSetPublicationTocDepthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPublicationTocDepthMutation(baseOptions?: Apollo.MutationHookOptions<SetPublicationTocDepthMutation, SetPublicationTocDepthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<SetPublicationTocDepthMutation, SetPublicationTocDepthMutationVariables>(SetPublicationTocDepthDocument, options);
      }
export type SetPublicationTocDepthMutationHookResult = ReturnType<typeof useSetPublicationTocDepthMutation>;
export type SetPublicationTocDepthMutationResult = Apollo.MutationResult<SetPublicationTocDepthMutation>;
export type SetPublicationTocDepthMutationOptions = Apollo.BaseMutationOptions<SetPublicationTocDepthMutation, SetPublicationTocDepthMutationVariables>;
export const SetPublicationMetaDataDocument = gql`
    mutation SetPublicationMetaData($input: SetPublicationMetadataInput!) {
  setPublicationMetadata(input: $input) {
    publicationDto {
      ...ManagerPublicationMutationResponse
    }
  }
}
    ${ManagerPublicationMutationResponseFragmentDoc}
${ManagerLanguageListItemFragmentDoc}`;
export type SetPublicationMetaDataMutationFn = Apollo.MutationFunction<SetPublicationMetaDataMutation, SetPublicationMetaDataMutationVariables>;

/**
 * __useSetPublicationMetaDataMutation__
 *
 * To run a mutation, you first call `useSetPublicationMetaDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPublicationMetaDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPublicationMetaDataMutation, { data, loading, error }] = useSetPublicationMetaDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPublicationMetaDataMutation(baseOptions?: Apollo.MutationHookOptions<SetPublicationMetaDataMutation, SetPublicationMetaDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useMutation<SetPublicationMetaDataMutation, SetPublicationMetaDataMutationVariables>(SetPublicationMetaDataDocument, options);
      }
export type SetPublicationMetaDataMutationHookResult = ReturnType<typeof useSetPublicationMetaDataMutation>;
export type SetPublicationMetaDataMutationResult = Apollo.MutationResult<SetPublicationMetaDataMutation>;
export type SetPublicationMetaDataMutationOptions = Apollo.BaseMutationOptions<SetPublicationMetaDataMutation, SetPublicationMetaDataMutationVariables>;
export const GetAuthorListDocument = gql`
    query GetAuthorList($where: PublicationAuthorDtoFilterInput, $order: [PublicationAuthorDtoSortInput!], $take: Int, $skip: Int) {
  authors(where: $where, order: $order, take: $take, skip: $skip) {
    items {
      ...AuthorListItem
    }
    pageInfo {
      ...PageInfo
    }
    totalCount
  }
}
    ${AuthorListItemFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useGetAuthorListQuery__
 *
 * To run a query within a React component, call `useGetAuthorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetAuthorListQuery(baseOptions?: Apollo.QueryHookOptions<GetAuthorListQuery, GetAuthorListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useQuery<GetAuthorListQuery, GetAuthorListQueryVariables>(GetAuthorListDocument, options);
      }
export function useGetAuthorListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthorListQuery, GetAuthorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useLazyQuery<GetAuthorListQuery, GetAuthorListQueryVariables>(GetAuthorListDocument, options);
        }
export function useGetAuthorListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAuthorListQuery, GetAuthorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useSuspenseQuery<GetAuthorListQuery, GetAuthorListQueryVariables>(GetAuthorListDocument, options);
        }
export type GetAuthorListQueryHookResult = ReturnType<typeof useGetAuthorListQuery>;
export type GetAuthorListLazyQueryHookResult = ReturnType<typeof useGetAuthorListLazyQuery>;
export type GetAuthorListSuspenseQueryHookResult = ReturnType<typeof useGetAuthorListSuspenseQuery>;
export type GetAuthorListQueryResult = Apollo.QueryResult<GetAuthorListQuery, GetAuthorListQueryVariables>;
export const GetManagerFolderTreeListDocument = gql`
    query GetManagerFolderTreeList($where: FolderDtoFilterInput, $order: [FolderDtoSortInput!]) {
  folders(where: $where, order: $order) {
    ...ManagerFolderTreeListItem
  }
}
    ${ManagerFolderTreeListItemFragmentDoc}
${ManagerParentFolderListItemFragmentDoc}
${ManagerFolderTypeListItemFragmentDoc}`;

/**
 * __useGetManagerFolderTreeListQuery__
 *
 * To run a query within a React component, call `useGetManagerFolderTreeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerFolderTreeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerFolderTreeListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetManagerFolderTreeListQuery(baseOptions?: Apollo.QueryHookOptions<GetManagerFolderTreeListQuery, GetManagerFolderTreeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useQuery<GetManagerFolderTreeListQuery, GetManagerFolderTreeListQueryVariables>(GetManagerFolderTreeListDocument, options);
      }
export function useGetManagerFolderTreeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagerFolderTreeListQuery, GetManagerFolderTreeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useLazyQuery<GetManagerFolderTreeListQuery, GetManagerFolderTreeListQueryVariables>(GetManagerFolderTreeListDocument, options);
        }
export function useGetManagerFolderTreeListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManagerFolderTreeListQuery, GetManagerFolderTreeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useSuspenseQuery<GetManagerFolderTreeListQuery, GetManagerFolderTreeListQueryVariables>(GetManagerFolderTreeListDocument, options);
        }
export type GetManagerFolderTreeListQueryHookResult = ReturnType<typeof useGetManagerFolderTreeListQuery>;
export type GetManagerFolderTreeListLazyQueryHookResult = ReturnType<typeof useGetManagerFolderTreeListLazyQuery>;
export type GetManagerFolderTreeListSuspenseQueryHookResult = ReturnType<typeof useGetManagerFolderTreeListSuspenseQuery>;
export type GetManagerFolderTreeListQueryResult = Apollo.QueryResult<GetManagerFolderTreeListQuery, GetManagerFolderTreeListQueryVariables>;
export const GetManagerSelectedFolderDocument = gql`
    query GetManagerSelectedFolder($folderId: Int!) {
  folder(folderId: $folderId) {
    ...ManagerFolderTreeListItem
  }
}
    ${ManagerFolderTreeListItemFragmentDoc}
${ManagerParentFolderListItemFragmentDoc}
${ManagerFolderTypeListItemFragmentDoc}`;

/**
 * __useGetManagerSelectedFolderQuery__
 *
 * To run a query within a React component, call `useGetManagerSelectedFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerSelectedFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerSelectedFolderQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useGetManagerSelectedFolderQuery(baseOptions: Apollo.QueryHookOptions<GetManagerSelectedFolderQuery, GetManagerSelectedFolderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useQuery<GetManagerSelectedFolderQuery, GetManagerSelectedFolderQueryVariables>(GetManagerSelectedFolderDocument, options);
      }
export function useGetManagerSelectedFolderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagerSelectedFolderQuery, GetManagerSelectedFolderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useLazyQuery<GetManagerSelectedFolderQuery, GetManagerSelectedFolderQueryVariables>(GetManagerSelectedFolderDocument, options);
        }
export function useGetManagerSelectedFolderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManagerSelectedFolderQuery, GetManagerSelectedFolderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useSuspenseQuery<GetManagerSelectedFolderQuery, GetManagerSelectedFolderQueryVariables>(GetManagerSelectedFolderDocument, options);
        }
export type GetManagerSelectedFolderQueryHookResult = ReturnType<typeof useGetManagerSelectedFolderQuery>;
export type GetManagerSelectedFolderLazyQueryHookResult = ReturnType<typeof useGetManagerSelectedFolderLazyQuery>;
export type GetManagerSelectedFolderSuspenseQueryHookResult = ReturnType<typeof useGetManagerSelectedFolderSuspenseQuery>;
export type GetManagerSelectedFolderQueryResult = Apollo.QueryResult<GetManagerSelectedFolderQuery, GetManagerSelectedFolderQueryVariables>;
export const GetManagerFolderTypeListDocument = gql`
    query GetManagerFolderTypeList($where: FolderTypeDtoFilterInput, $order: [FolderTypeDtoSortInput!]) {
  folderTypes(where: $where, order: $order) {
    ...ManagerFolderTypeListItem
  }
}
    ${ManagerFolderTypeListItemFragmentDoc}`;

/**
 * __useGetManagerFolderTypeListQuery__
 *
 * To run a query within a React component, call `useGetManagerFolderTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerFolderTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerFolderTypeListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetManagerFolderTypeListQuery(baseOptions?: Apollo.QueryHookOptions<GetManagerFolderTypeListQuery, GetManagerFolderTypeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useQuery<GetManagerFolderTypeListQuery, GetManagerFolderTypeListQueryVariables>(GetManagerFolderTypeListDocument, options);
      }
export function useGetManagerFolderTypeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagerFolderTypeListQuery, GetManagerFolderTypeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useLazyQuery<GetManagerFolderTypeListQuery, GetManagerFolderTypeListQueryVariables>(GetManagerFolderTypeListDocument, options);
        }
export function useGetManagerFolderTypeListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManagerFolderTypeListQuery, GetManagerFolderTypeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useSuspenseQuery<GetManagerFolderTypeListQuery, GetManagerFolderTypeListQueryVariables>(GetManagerFolderTypeListDocument, options);
        }
export type GetManagerFolderTypeListQueryHookResult = ReturnType<typeof useGetManagerFolderTypeListQuery>;
export type GetManagerFolderTypeListLazyQueryHookResult = ReturnType<typeof useGetManagerFolderTypeListLazyQuery>;
export type GetManagerFolderTypeListSuspenseQueryHookResult = ReturnType<typeof useGetManagerFolderTypeListSuspenseQuery>;
export type GetManagerFolderTypeListQueryResult = Apollo.QueryResult<GetManagerFolderTypeListQuery, GetManagerFolderTypeListQueryVariables>;
export const GetManagerLanguageListDocument = gql`
    query GetManagerLanguageList($where: LanguageDtoFilterInput, $order: [LanguageDtoSortInput!]) {
  languages(where: $where, order: $order) {
    ...ManagerLanguageListItem
  }
}
    ${ManagerLanguageListItemFragmentDoc}`;

/**
 * __useGetManagerLanguageListQuery__
 *
 * To run a query within a React component, call `useGetManagerLanguageListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerLanguageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerLanguageListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetManagerLanguageListQuery(baseOptions?: Apollo.QueryHookOptions<GetManagerLanguageListQuery, GetManagerLanguageListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useQuery<GetManagerLanguageListQuery, GetManagerLanguageListQueryVariables>(GetManagerLanguageListDocument, options);
      }
export function useGetManagerLanguageListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagerLanguageListQuery, GetManagerLanguageListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useLazyQuery<GetManagerLanguageListQuery, GetManagerLanguageListQueryVariables>(GetManagerLanguageListDocument, options);
        }
export function useGetManagerLanguageListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManagerLanguageListQuery, GetManagerLanguageListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useSuspenseQuery<GetManagerLanguageListQuery, GetManagerLanguageListQueryVariables>(GetManagerLanguageListDocument, options);
        }
export type GetManagerLanguageListQueryHookResult = ReturnType<typeof useGetManagerLanguageListQuery>;
export type GetManagerLanguageListLazyQueryHookResult = ReturnType<typeof useGetManagerLanguageListLazyQuery>;
export type GetManagerLanguageListSuspenseQueryHookResult = ReturnType<typeof useGetManagerLanguageListSuspenseQuery>;
export type GetManagerLanguageListQueryResult = Apollo.QueryResult<GetManagerLanguageListQuery, GetManagerLanguageListQueryVariables>;
export const GetManagerPublicationCoverListDocument = gql`
    query GetManagerPublicationCoverList($where: CoverDtoFilterInput, $order: [CoverDtoSortInput!], $take: Int, $skip: Int, $thumbnailWidth: Int = 100, $thumbnailHeight: Int = 100, $thumbnailResize: ResizeTypeEnum) {
  covers(where: $where, order: $order, take: $take, skip: $skip) {
    items {
      ...ManagerPublicationCoverListItem
    }
    pageInfo {
      ...PageInfo
    }
    totalCount
  }
}
    ${ManagerPublicationCoverListItemFragmentDoc}
${ManagerPublicationCoverTypeListItemFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useGetManagerPublicationCoverListQuery__
 *
 * To run a query within a React component, call `useGetManagerPublicationCoverListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerPublicationCoverListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerPublicationCoverListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      thumbnailWidth: // value for 'thumbnailWidth'
 *      thumbnailHeight: // value for 'thumbnailHeight'
 *      thumbnailResize: // value for 'thumbnailResize'
 *   },
 * });
 */
export function useGetManagerPublicationCoverListQuery(baseOptions?: Apollo.QueryHookOptions<GetManagerPublicationCoverListQuery, GetManagerPublicationCoverListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useQuery<GetManagerPublicationCoverListQuery, GetManagerPublicationCoverListQueryVariables>(GetManagerPublicationCoverListDocument, options);
      }
export function useGetManagerPublicationCoverListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagerPublicationCoverListQuery, GetManagerPublicationCoverListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useLazyQuery<GetManagerPublicationCoverListQuery, GetManagerPublicationCoverListQueryVariables>(GetManagerPublicationCoverListDocument, options);
        }
export function useGetManagerPublicationCoverListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManagerPublicationCoverListQuery, GetManagerPublicationCoverListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useSuspenseQuery<GetManagerPublicationCoverListQuery, GetManagerPublicationCoverListQueryVariables>(GetManagerPublicationCoverListDocument, options);
        }
export type GetManagerPublicationCoverListQueryHookResult = ReturnType<typeof useGetManagerPublicationCoverListQuery>;
export type GetManagerPublicationCoverListLazyQueryHookResult = ReturnType<typeof useGetManagerPublicationCoverListLazyQuery>;
export type GetManagerPublicationCoverListSuspenseQueryHookResult = ReturnType<typeof useGetManagerPublicationCoverListSuspenseQuery>;
export type GetManagerPublicationCoverListQueryResult = Apollo.QueryResult<GetManagerPublicationCoverListQuery, GetManagerPublicationCoverListQueryVariables>;
export const GetManagerPublicationCoverTypesDocument = gql`
    query GetManagerPublicationCoverTypes($where: CoverTypeDtoFilterInput, $order: [CoverTypeDtoSortInput!], $take: Int, $skip: Int) {
  coverTypes(where: $where, order: $order, take: $take, skip: $skip) {
    items {
      ...ManagerPublicationCoverTypeListItem
    }
    pageInfo {
      ...PageInfo
    }
    totalCount
  }
}
    ${ManagerPublicationCoverTypeListItemFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useGetManagerPublicationCoverTypesQuery__
 *
 * To run a query within a React component, call `useGetManagerPublicationCoverTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerPublicationCoverTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerPublicationCoverTypesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetManagerPublicationCoverTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetManagerPublicationCoverTypesQuery, GetManagerPublicationCoverTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useQuery<GetManagerPublicationCoverTypesQuery, GetManagerPublicationCoverTypesQueryVariables>(GetManagerPublicationCoverTypesDocument, options);
      }
export function useGetManagerPublicationCoverTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagerPublicationCoverTypesQuery, GetManagerPublicationCoverTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useLazyQuery<GetManagerPublicationCoverTypesQuery, GetManagerPublicationCoverTypesQueryVariables>(GetManagerPublicationCoverTypesDocument, options);
        }
export function useGetManagerPublicationCoverTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManagerPublicationCoverTypesQuery, GetManagerPublicationCoverTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useSuspenseQuery<GetManagerPublicationCoverTypesQuery, GetManagerPublicationCoverTypesQueryVariables>(GetManagerPublicationCoverTypesDocument, options);
        }
export type GetManagerPublicationCoverTypesQueryHookResult = ReturnType<typeof useGetManagerPublicationCoverTypesQuery>;
export type GetManagerPublicationCoverTypesLazyQueryHookResult = ReturnType<typeof useGetManagerPublicationCoverTypesLazyQuery>;
export type GetManagerPublicationCoverTypesSuspenseQueryHookResult = ReturnType<typeof useGetManagerPublicationCoverTypesSuspenseQuery>;
export type GetManagerPublicationCoverTypesQueryResult = Apollo.QueryResult<GetManagerPublicationCoverTypesQuery, GetManagerPublicationCoverTypesQueryVariables>;
export const GetManagerPublicationEpubListDocument = gql`
    query GetManagerPublicationEpubList($where: ExportedFileDtoFilterInput, $order: [ExportedFileDtoSortInput!], $take: Int, $skip: Int) {
  exportedFiles(where: $where, order: $order, take: $take, skip: $skip) {
    items {
      ...ManagerPublicationEpubListItem
    }
    pageInfo {
      ...PageInfo
    }
    totalCount
  }
}
    ${ManagerPublicationEpubListItemFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useGetManagerPublicationEpubListQuery__
 *
 * To run a query within a React component, call `useGetManagerPublicationEpubListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerPublicationEpubListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerPublicationEpubListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetManagerPublicationEpubListQuery(baseOptions?: Apollo.QueryHookOptions<GetManagerPublicationEpubListQuery, GetManagerPublicationEpubListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useQuery<GetManagerPublicationEpubListQuery, GetManagerPublicationEpubListQueryVariables>(GetManagerPublicationEpubListDocument, options);
      }
export function useGetManagerPublicationEpubListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagerPublicationEpubListQuery, GetManagerPublicationEpubListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useLazyQuery<GetManagerPublicationEpubListQuery, GetManagerPublicationEpubListQueryVariables>(GetManagerPublicationEpubListDocument, options);
        }
export function useGetManagerPublicationEpubListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManagerPublicationEpubListQuery, GetManagerPublicationEpubListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useSuspenseQuery<GetManagerPublicationEpubListQuery, GetManagerPublicationEpubListQueryVariables>(GetManagerPublicationEpubListDocument, options);
        }
export type GetManagerPublicationEpubListQueryHookResult = ReturnType<typeof useGetManagerPublicationEpubListQuery>;
export type GetManagerPublicationEpubListLazyQueryHookResult = ReturnType<typeof useGetManagerPublicationEpubListLazyQuery>;
export type GetManagerPublicationEpubListSuspenseQueryHookResult = ReturnType<typeof useGetManagerPublicationEpubListSuspenseQuery>;
export type GetManagerPublicationEpubListQueryResult = Apollo.QueryResult<GetManagerPublicationEpubListQuery, GetManagerPublicationEpubListQueryVariables>;
export const GetManagerPublicationListDocument = gql`
    query GetManagerPublicationList($where: PublicationDtoFilterInput, $order: [PublicationDtoSortInput!], $take: Int, $skip: Int, $withWebCover: Boolean = false, $thumbnailWidth: Int = 100, $thumbnailHeight: Int = 100, $thumbnailResize: ResizeTypeEnum) {
  publications(where: $where, order: $order, take: $take, skip: $skip) {
    items {
      ...ManagerPublicationListItem
    }
    pageInfo {
      ...PageInfo
    }
    totalCount
  }
}
    ${ManagerPublicationListItemFragmentDoc}
${ManagerLanguageListItemFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useGetManagerPublicationListQuery__
 *
 * To run a query within a React component, call `useGetManagerPublicationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerPublicationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerPublicationListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      withWebCover: // value for 'withWebCover'
 *      thumbnailWidth: // value for 'thumbnailWidth'
 *      thumbnailHeight: // value for 'thumbnailHeight'
 *      thumbnailResize: // value for 'thumbnailResize'
 *   },
 * });
 */
export function useGetManagerPublicationListQuery(baseOptions?: Apollo.QueryHookOptions<GetManagerPublicationListQuery, GetManagerPublicationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useQuery<GetManagerPublicationListQuery, GetManagerPublicationListQueryVariables>(GetManagerPublicationListDocument, options);
      }
export function useGetManagerPublicationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagerPublicationListQuery, GetManagerPublicationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useLazyQuery<GetManagerPublicationListQuery, GetManagerPublicationListQueryVariables>(GetManagerPublicationListDocument, options);
        }
export function useGetManagerPublicationListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManagerPublicationListQuery, GetManagerPublicationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useSuspenseQuery<GetManagerPublicationListQuery, GetManagerPublicationListQueryVariables>(GetManagerPublicationListDocument, options);
        }
export type GetManagerPublicationListQueryHookResult = ReturnType<typeof useGetManagerPublicationListQuery>;
export type GetManagerPublicationListLazyQueryHookResult = ReturnType<typeof useGetManagerPublicationListLazyQuery>;
export type GetManagerPublicationListSuspenseQueryHookResult = ReturnType<typeof useGetManagerPublicationListSuspenseQuery>;
export type GetManagerPublicationListQueryResult = Apollo.QueryResult<GetManagerPublicationListQuery, GetManagerPublicationListQueryVariables>;
export const GetPublicationChaptersReaderDataDocument = gql`
    query GetPublicationChaptersReaderData($publicationId: Int!) {
  publication(publicationId: $publicationId) {
    ...PublicationChaptersReaderData
  }
}
    ${PublicationChaptersReaderDataFragmentDoc}
${PublicationChaptersReaderChapterListItemFragmentDoc}`;

/**
 * __useGetPublicationChaptersReaderDataQuery__
 *
 * To run a query within a React component, call `useGetPublicationChaptersReaderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicationChaptersReaderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicationChaptersReaderDataQuery({
 *   variables: {
 *      publicationId: // value for 'publicationId'
 *   },
 * });
 */
export function useGetPublicationChaptersReaderDataQuery(baseOptions: Apollo.QueryHookOptions<GetPublicationChaptersReaderDataQuery, GetPublicationChaptersReaderDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useQuery<GetPublicationChaptersReaderDataQuery, GetPublicationChaptersReaderDataQueryVariables>(GetPublicationChaptersReaderDataDocument, options);
      }
export function useGetPublicationChaptersReaderDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicationChaptersReaderDataQuery, GetPublicationChaptersReaderDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useLazyQuery<GetPublicationChaptersReaderDataQuery, GetPublicationChaptersReaderDataQueryVariables>(GetPublicationChaptersReaderDataDocument, options);
        }
export function useGetPublicationChaptersReaderDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPublicationChaptersReaderDataQuery, GetPublicationChaptersReaderDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useSuspenseQuery<GetPublicationChaptersReaderDataQuery, GetPublicationChaptersReaderDataQueryVariables>(GetPublicationChaptersReaderDataDocument, options);
        }
export type GetPublicationChaptersReaderDataQueryHookResult = ReturnType<typeof useGetPublicationChaptersReaderDataQuery>;
export type GetPublicationChaptersReaderDataLazyQueryHookResult = ReturnType<typeof useGetPublicationChaptersReaderDataLazyQuery>;
export type GetPublicationChaptersReaderDataSuspenseQueryHookResult = ReturnType<typeof useGetPublicationChaptersReaderDataSuspenseQuery>;
export type GetPublicationChaptersReaderDataQueryResult = Apollo.QueryResult<GetPublicationChaptersReaderDataQuery, GetPublicationChaptersReaderDataQueryVariables>;
export const GetPublicationChaptersReaderParagraphListDocument = gql`
    query GetPublicationChaptersReaderParagraphList($publicationId: Int!, $order: IntOperationFilterInput, $take: Int, $skip: Int) {
  paragraphs(
    order: [{order: ASC}]
    where: {publicationId: {eq: $publicationId}, order: $order}
    take: $take
    skip: $skip
  ) {
    items {
      ...PublicationChaptersReaderParagraphListItem
    }
    pageInfo {
      ...PageInfo
    }
    totalCount
  }
}
    ${PublicationChaptersReaderParagraphListItemFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useGetPublicationChaptersReaderParagraphListQuery__
 *
 * To run a query within a React component, call `useGetPublicationChaptersReaderParagraphListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicationChaptersReaderParagraphListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicationChaptersReaderParagraphListQuery({
 *   variables: {
 *      publicationId: // value for 'publicationId'
 *      order: // value for 'order'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetPublicationChaptersReaderParagraphListQuery(baseOptions: Apollo.QueryHookOptions<GetPublicationChaptersReaderParagraphListQuery, GetPublicationChaptersReaderParagraphListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useQuery<GetPublicationChaptersReaderParagraphListQuery, GetPublicationChaptersReaderParagraphListQueryVariables>(GetPublicationChaptersReaderParagraphListDocument, options);
      }
export function useGetPublicationChaptersReaderParagraphListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicationChaptersReaderParagraphListQuery, GetPublicationChaptersReaderParagraphListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useLazyQuery<GetPublicationChaptersReaderParagraphListQuery, GetPublicationChaptersReaderParagraphListQueryVariables>(GetPublicationChaptersReaderParagraphListDocument, options);
        }
export function useGetPublicationChaptersReaderParagraphListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPublicationChaptersReaderParagraphListQuery, GetPublicationChaptersReaderParagraphListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useSuspenseQuery<GetPublicationChaptersReaderParagraphListQuery, GetPublicationChaptersReaderParagraphListQueryVariables>(GetPublicationChaptersReaderParagraphListDocument, options);
        }
export type GetPublicationChaptersReaderParagraphListQueryHookResult = ReturnType<typeof useGetPublicationChaptersReaderParagraphListQuery>;
export type GetPublicationChaptersReaderParagraphListLazyQueryHookResult = ReturnType<typeof useGetPublicationChaptersReaderParagraphListLazyQuery>;
export type GetPublicationChaptersReaderParagraphListSuspenseQueryHookResult = ReturnType<typeof useGetPublicationChaptersReaderParagraphListSuspenseQuery>;
export type GetPublicationChaptersReaderParagraphListQueryResult = Apollo.QueryResult<GetPublicationChaptersReaderParagraphListQuery, GetPublicationChaptersReaderParagraphListQueryVariables>;
export const GetPublicationPageHocDataDocument = gql`
    query GetPublicationPageHocData($publicationId: Int!) {
  publication(publicationId: $publicationId) {
    ...PublicationPageHocData
  }
}
    ${PublicationPageHocDataFragmentDoc}`;

/**
 * __useGetPublicationPageHocDataQuery__
 *
 * To run a query within a React component, call `useGetPublicationPageHocDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicationPageHocDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicationPageHocDataQuery({
 *   variables: {
 *      publicationId: // value for 'publicationId'
 *   },
 * });
 */
export function useGetPublicationPageHocDataQuery(baseOptions: Apollo.QueryHookOptions<GetPublicationPageHocDataQuery, GetPublicationPageHocDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions};
        return Apollo.useQuery<GetPublicationPageHocDataQuery, GetPublicationPageHocDataQueryVariables>(GetPublicationPageHocDataDocument, options);
      }
export function useGetPublicationPageHocDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicationPageHocDataQuery, GetPublicationPageHocDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useLazyQuery<GetPublicationPageHocDataQuery, GetPublicationPageHocDataQueryVariables>(GetPublicationPageHocDataDocument, options);
        }
export function useGetPublicationPageHocDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPublicationPageHocDataQuery, GetPublicationPageHocDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions};
          return Apollo.useSuspenseQuery<GetPublicationPageHocDataQuery, GetPublicationPageHocDataQueryVariables>(GetPublicationPageHocDataDocument, options);
        }
export type GetPublicationPageHocDataQueryHookResult = ReturnType<typeof useGetPublicationPageHocDataQuery>;
export type GetPublicationPageHocDataLazyQueryHookResult = ReturnType<typeof useGetPublicationPageHocDataLazyQuery>;
export type GetPublicationPageHocDataSuspenseQueryHookResult = ReturnType<typeof useGetPublicationPageHocDataSuspenseQuery>;
export type GetPublicationPageHocDataQueryResult = Apollo.QueryResult<GetPublicationPageHocDataQuery, GetPublicationPageHocDataQueryVariables>;