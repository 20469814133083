import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionTag,
  FromToProps,
  GetMarkRange,
  Handler,
  isAllSelection,
  isElementDomNode,
  isMarkActive,
  isSelectionEmpty,
  isTextSelection,
  MarkExtension,
  MarkExtensionSpec,
  MarkSpecOverride,
  ProsemirrorAttributes,
  removeMark,
  Static,
  updateMark,
} from '@remirror/core';
import { CreateEventHandlers } from '@remirror/extension-events';
import React from 'react';

const CREATE_COMMAND_LABEL = 'Toggle wLang';
const CREATE_COMMAND_DESCRIPTION = 'Insert wLang Attributes';
const CREATE_COMMAND_SHORTCUT = 'Mod-T';

const toggleWLangOptions: Remirror.CommandDecoratorOptions = {
  icon: 'translate2',
  label: ({ t }) => t(CREATE_COMMAND_LABEL),
  description: ({ t }) => t(CREATE_COMMAND_DESCRIPTION),
  // shortcut: CREATE_COMMAND_SHORTCUT,
};

type WLangDirProperty = 'ltr' | 'rtl';

type WLangLangProperty = string;

export interface WLangProperties {
  langList: Static<WLangLangProperty>[];
  dirList: Static<WLangDirProperty>[];
}

export type WLangAttributes = ProsemirrorAttributes<{
  lang?: Static<WLangLangProperty>;
  dir?: Static<WLangDirProperty>;
}>;

export const W_LANG_PROPERTIES: Static<WLangProperties> = {
  dirList: ['rtl', 'ltr'],
  langList: [
    'af',
    'am',
    'ar',
    'arn',
    'as',
    'az',
    'ba',
    'be',
    'bg',
    'bn',
    'bo',
    'br',
    'bs',
    'ca',
    'co',
    'cs',
    'cy',
    'da',
    'de',
    'dsb',
    'dv',
    'el',
    'en',
    'es',
    'et',
    'eu',
    'fa',
    'fi',
    'fil',
    'fo',
    'fr',
    'fy',
    'ga',
    'gd',
    'gl',
    'gsw',
    'gu',
    'ha',
    'he',
    'hi',
    'hr',
    'hsb',
    'hu',
    'hy',
    'id',
    'ig',
    'ii',
    'is',
    'it',
    'iu',
    'ja',
    'ka',
    'kk',
    'kl',
    'km',
    'kn',
    'ko',
    '',
    'kok',
    'ky',
    'lb',
    'lo',
    'lt',
    'lv',
    'mi',
    'mk',
    'ml',
    'mn',
    'moh',
    'mr',
    'ms',
    'mt',
    'my',
    'nb',
    'ne',
    'nl',
    'nn',
    'no',
    'oc',
    'or',
    'pa',
    'pl',
    'prs',
    'ps',
    'pt',
    'qu',
    'quc',
    'rm',
    'ro',
    'ru',
    'rw',
    'sa',
    'sah',
    'se',
    'si',
    'sk',
    'sl',
    'sma',
    'smj',
    'smn',
    'sms',
    'sq',
    'sr',
    'st',
    'sv',
    'sw',
    'syc',
    'ta',
    'te',
    'tg',
    'th',
    'tk',
    'tn',
    'tr',
    'tt',
    'tzm',
    'ug',
    'uk',
    'ur',
    'uz',
    'vi',
    'wo',
    'xh',
    'yo',
    'zh',
    'zu',
  ],
};

export const W_LANG_ATTRIBUTES: Static<WLangAttributes> = {
  lang: 'en',
  dir: 'ltr',
};

export interface WLangClickData extends GetMarkRange, WLangAttributes {}

export interface WLangViewClickHandlerProps {
  event: MouseEvent;
  markWithPosition?: WLangClickData;
}

export interface WLangOptions {
  properties?: Static<WLangProperties>;
  attributes?: Static<WLangAttributes>;
  onViewClick?: Handler<(props: WLangViewClickHandlerProps) => boolean | undefined | void>;
}

@extension<WLangOptions>({
  defaultOptions: {
    properties: W_LANG_PROPERTIES,
    attributes: W_LANG_ATTRIBUTES,
  },
  handlerKeys: ['onViewClick'],
  staticKeys: ['properties', 'attributes'],
})
export class WLangExtension extends MarkExtension<WLangOptions> {
  get name() {
    return 'wLang' as const;
  }

  createTags() {
    return [ExtensionTag.FormattingMark];
  }

  createMarkSpec(extra: ApplySchemaAttributes, override: MarkSpecOverride): MarkExtensionSpec {
    return {
      ...override,
      attrs: {
        ...extra.defaults(),
        lang: { default: null },
        dir: { default: null },
      },
      parseDOM: [
        {
          tag: 'w-lang',
          getAttrs: (mark) => {
            return isElementDomNode(mark)
              ? {
                  dir: mark.getAttribute('dir') as string,
                  lang: mark.getAttribute('lang') as string,
                }
              : false;
          },
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (mark) => {
        return [
          'w-lang',
          {
            ...(mark?.attrs?.lang ? { lang: mark.attrs.lang.toString() } : {}),
            ...(mark?.attrs?.dir ? { dir: mark.attrs.dir.toString() } : {}),
          },
          0,
        ];
      },
    };
  }

  createEventHandlers(): CreateEventHandlers {
    return {
      clickMark: (event, clickState) => {
        const markRange = clickState.getMark(this.type);

        if (!markRange) {
          return this.options.onViewClick({ event });
        }

        const attrs = markRange.mark.attrs as WLangAttributes;
        const markWithPosition: WLangClickData = { ...attrs, ...markRange };

        if (!markWithPosition || markWithPosition.mark.type !== this.type) {
          return this.options.onViewClick({ event });
        }

        return this.options.onViewClick({ event, markWithPosition });
      },
    };
  }

  @command(toggleWLangOptions)
  toggleWLang(attrs?: WLangAttributes, range?: FromToProps): CommandFunction {
    return (props) => {
      const { tr } = props;
      const selectionIsValid =
        (isTextSelection(tr.selection) && !isSelectionEmpty(tr.selection)) ||
        isAllSelection(tr.selection) ||
        isMarkActive({ trState: tr, type: this.type });

      if (!selectionIsValid && !range) {
        return false;
      }

      tr.setMeta(this.name, {
        command: 'toggleWLang',
        attrs,
        range,
      });

      return updateMark({ type: this.type, attrs, range })(props);
    };
  }

  @command()
  removeWLang(range?: FromToProps): CommandFunction {
    return (props) => {
      const { tr } = props;

      if (!isMarkActive({ trState: tr, type: this.type, ...range })) {
        return false;
      }

      return removeMark({ type: this.type, expand: true, range })(props);
    };
  }

  @command()
  selectWLang(): CommandFunction {
    return this.store.commands.selectMark.original(this.type);
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wLang: WLangExtension;
    }
  }
}
