import { TextField } from '@mui/material';
import React from 'react';
import { CommandForm, ICommandFormProps } from '../../../components';

export interface IToggleWPageBlockFormProps extends Omit<ICommandFormProps, 'children' | 'fields'> {
  initialOption?: string;
  initialValues?: any;
  onRemove?: () => void;
}

export const ToggleWPageBlockForm = (props: IToggleWPageBlockFormProps) => {
  const { ...rest } = props;

  return (
    <CommandForm name="wPageBlockForm" {...rest}>
      <TextField
        name="number"
        label="Number"
        size="small"
        variant="standard"
        // inputProps={{ inputMode: 'text', pattern: 'M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3}' }}
      />
    </CommandForm>
  );
};
