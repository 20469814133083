import { InputAdornment, Stack, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useState } from 'react';

import { IwLinkCommandOptions, wLinkCommandOptions } from '../../extensions';
import { CommandForm, ICommandFormProps } from './CommandForm';

export interface IWLinkFormProps extends Omit<ICommandFormProps, 'children' | 'fields'> {
  initialOption?: string;
  initialValues?: any;
  onRemove?: () => void;
}

export const ToggleWLinkForm = (props: IWLinkFormProps) => {
  const { initialOption, onSubmit, ...rest } = props;
  const [selectedOption, setSelectedOption] = useState<IwLinkCommandOptions>(
    wLinkCommandOptions.find((item) => item.prefix === initialOption) || wLinkCommandOptions[0],
  );
  const handleSelectType = (e: React.MouseEvent<HTMLElement>, newOption: any) => {
    setSelectedOption(wLinkCommandOptions.find((item) => item.prefix === newOption.prefix) || wLinkCommandOptions[0]);
  };

  const handleSubmit = (values) => {
    const transformValues = {
      ...values,
      [selectedOption.attrs[0]]: `${selectedOption.prefix}${values[selectedOption.attrs[0]]}`,
    };
    onSubmit && onSubmit(transformValues);
  };

  return (
    <Stack spacing={1} direction="column">
      {wLinkCommandOptions.length > 1 && (
        <ToggleButtonGroup
          size="small"
          value={selectedOption}
          exclusive
          onChange={handleSelectType}
          aria-label="wLink types"
        >
          {wLinkCommandOptions.map((option) => {
            return (
              <ToggleButton
                key={`wLink-type-${option.title}`}
                color="primary"
                value={option}
                aria-label={option.title}
                sx={{ lineHeight: 'unset' }}
              >
                {option.title}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      )}
      <CommandForm name="wLink" onSubmit={handleSubmit} {...rest}>
        {selectedOption.attrs.map((field, key) => {
          return (
            <TextField
              key={`${field}-${key}`}
              name={field}
              size="small"
              label={field}
              variant="standard"
              id="outlined-start-adornment"
              InputProps={
                field === 'href'
                  ? {
                      startAdornment: <InputAdornment position="start">{selectedOption.prefix}</InputAdornment>,
                    }
                  : {}
              }
            />
          );
        })}
      </CommandForm>
    </Stack>
  );
};
