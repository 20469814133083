import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { Typography } from 'antd';
import { AppAuthenticationServiceProvider } from './AppAuthenticationServiceProvider';
import { ApolloGraphqlAppApiClientProvider } from './ApolloGraphqlAppApiClientProvider';
import { GetLegacyUserInfoQuery } from './apolloGraphqlAppApiClient/rest/restTypes';
import { CONSTANTS } from '../config';
import { SpinnerLayout } from '../layout';

interface IAppServicesContextProps {
  authProfile: GetLegacyUserInfoQuery['legacyUserInfo'];
}

export const AppServicesContext = createContext<IAppServicesContextProps | undefined>(undefined);
AppServicesContext.displayName = 'AppServicesContext';

export const useAppServices = () => {
  const context = useContext(AppServicesContext);
  if (context === undefined) {
    throw new Error('useAppServices must be used within AppServicesProvider');
  }
  return context;
};

export const AppServicesProviderComponent = ({ children }) => {
  const values = useMemo(() => ({}), []);
  const [authProfile, setAuthProfile] = useState<GetLegacyUserInfoQuery['legacyUserInfo']>(null);
  const [hasTriedSignIn, setHasTriedSignIn] = useState<boolean>(false);
  const authService = useAuth();

  useEffect(() => {
    if (authService?.error) {
      // @ts-ignore
      if (authService.error?.state) {
        // @ts-ignore
        const { state } = authService.error;
        window.location.replace((state as { preAuthLocation: Location })?.preAuthLocation?.href);
        return;
      }
      window.location.replace(window.location.href);
      return;
    }
    if (
      !hasAuthParams() &&
      !authService.isAuthenticated &&
      !authService.activeNavigator &&
      !authService.isLoading &&
      !hasTriedSignIn
    ) {
      void authService.signinRedirect({
        state: { preAuthLocation: window.location },
      });
      setHasTriedSignIn(true);
    }
  }, [authService, hasTriedSignIn]);

  useEffect(() => {
    if (
      !hasAuthParams() &&
      authService.isAuthenticated &&
      authService.user?.access_token &&
      authService.user?.profile.sub &&
      parseInt(authService.user?.profile.sub, 10) !== authProfile?.id
    ) {
      console.log({ authService, authProfile });
      const fetcher = async () => {
        if (!authService.isAuthenticated || !authService.user?.access_token) {
          setAuthProfile(null);
          return;
        }
        try {
          const userDataResponse = await fetch(`${CONSTANTS.APP_API_SESSION_ACCESS_URL}/connect/legacy-userinfo`, {
            headers: {
              Authorization: `Bearer ${authService?.user?.access_token ?? ''}`,
            },
          });
          const userData = await userDataResponse.json();
          if (userData?.id) {
            setAuthProfile(userData);
          }
          if (!userData?.id) {
            void authService.signinRedirect({
              state: { preAuthLocation: window.location },
            });
          }
        } catch (error) {
          console.log('[User error]:', error);
          void authService.signinRedirect({
            state: { preAuthLocation: window.location },
          });
        }
      };
      void fetcher();
    }
  }, [authService.user?.profile?.sub]);

  const AppServicesContextObject: IAppServicesContextProps = useMemo(
    () => ({
      authProfile,
    }),
    [authProfile],
  );

  return !authService.isLoading && authService.isAuthenticated && !!authProfile ? (
    <AppServicesContext.Provider value={AppServicesContextObject}>{children}</AppServicesContext.Provider>
  ) : (
    <SpinnerLayout size="large" tip=" ">
      <Typography.Title level={1} style={{ textAlign: 'center' }}>
        Authorization. Please wait...
      </Typography.Title>
    </SpinnerLayout>
  );
};

export const AppServicesProvider = ({ children }) => {
  return (
    <AppAuthenticationServiceProvider>
      <AppServicesProviderComponent>
        <ApolloGraphqlAppApiClientProvider>{children}</ApolloGraphqlAppApiClientProvider>
      </AppServicesProviderComponent>
    </AppAuthenticationServiceProvider>
  );
};
