import React from 'react';

export const PreventOnClickParentsEvent: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {children}
    </div>
  );
};
