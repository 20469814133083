import { useActive, useAttrs, useCommands, useCurrentSelection, useExtensionEvent } from '@remirror/react';
import { useHelpers } from '@remirror/react-core';
import { useCallback, useMemo, useState } from 'react';

import { WEntityExtension, WEntityViewClickHandlerProps } from '../WEntityExtension';

function useWEntityClick() {
  const [wEntityClickHandlerProps, setWEntityClickHandlerProps] = useState<WEntityViewClickHandlerProps | undefined>();
  const [isEditing, setIsEditing] = useState(false);

  useExtensionEvent(
    WEntityExtension,
    'onViewClick',
    useCallback(
      (props) => {
        if (!isEditing && props?.markWithPosition) {
          setIsEditing(true);
        }

        setWEntityClickHandlerProps(props);
      },
      [isEditing],
    ),
  );

  return { isEditing, wEntityClickHandlerProps, setIsEditing };
}

export interface IUseWEntityState {
  wEntityClickHandlerProps: WEntityViewClickHandlerProps | undefined;
  handleSubmit: (values: any) => void;
  handleCancel: () => void;
  isEditing: boolean;
  handleClickEdit: () => void;
  active: boolean;
  handleRemove: () => void;
  enabled: boolean;
  attrs: (Record<string, unknown> & Remirror.Attributes & object & { class?: string }) | undefined;
}

export function useWEntityState(): IUseWEntityState {
  const { toggleWEntity, removeWEntity, selectWEntity, focus } = useCommands();
  const { isEditing, wEntityClickHandlerProps, setIsEditing } = useWEntityClick();
  const { to, empty } = useCurrentSelection();
  const { isSelectionEmpty } = useHelpers();

  const attrs = useAttrs<WEntityExtension>().wEntity();

  const active = useActive<WEntityExtension>().wEntity();
  const enabled = (!isSelectionEmpty() && toggleWEntity.enabled()) || isEditing;

  const handleRemove = useCallback(() => {
    removeWEntity();
    focus();
  }, [removeWEntity]);

  // const updateReason = useUpdateReason();

  // useLayoutEffect(() => {
  //   if (!isEditing) {
  //     return;
  //   }
  //
  //   // if (updateReason.doc || updateReason.selection) {
  //   //   setIsEditing(false);
  //   // }
  // }, [isEditing, setIsEditing, updateReason.doc, updateReason.selection]);

  const handleSubmit = useCallback(
    (values) => {
      setIsEditing(false);
      const range = wEntityClickHandlerProps?.markWithPosition;
      if (range) {
        handleClickEdit();
      }
      if (values?.type) {
        toggleWEntity({ ...values }, range);
      }
      focus();
    },
    [setIsEditing, wEntityClickHandlerProps, toggleWEntity, attrs],
  );

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    focus();
  }, [setIsEditing]);

  const handleClickEdit = useCallback(() => {
    if (empty) {
      selectWEntity();
    }
    setIsEditing(true);
  }, [selectWEntity, empty, setIsEditing]);

  return useMemo<IUseWEntityState>(
    () => ({
      attrs,
      wEntityClickHandlerProps,
      isEditing,
      handleRemove,
      handleSubmit,
      handleCancel,
      active,
      enabled,
      handleClickEdit,
    }),
    [
      attrs,
      active,
      enabled,
      wEntityClickHandlerProps,
      isEditing,
      handleRemove,
      handleSubmit,
      handleCancel,
      handleClickEdit,
    ],
  );
}
