import React from 'react';
import { FolderFilled, FolderOpenFilled, FolderOpenOutlined } from '@ant-design/icons';
import { TreeNodeProps } from 'antd';

export const FolderIcon = (props: TreeNodeProps) => {
  const { isLeaf, expanded } = props;
  if (isLeaf) {
    return <FolderOpenOutlined />;
  }
  return expanded ? <FolderOpenFilled /> : <FolderFilled />;
};
