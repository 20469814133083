import React, { FC } from 'react';

import { useWNoteState, WNoteIcon } from '../../extensions';
import { ToggleWNoteForm } from '../forms';
import { CommandPopoverButton, ICommandPopoverButtonProps } from './CommandPopoverButton';

export type IToggleWNoteButtonProps = Omit<
  ICommandPopoverButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'popoverContent' | 'onSelect'
>;

export const ToggleWNoteButton: FC<IToggleWNoteButtonProps> = (props) => {
  const { wNoteClickProps, attrs, active, enabled, isEditing, handleRemove, handleSubmit } = useWNoteState();

  return (
    <CommandPopoverButton
      {...props}
      commandName="toggleWNote"
      active={active}
      enabled={enabled}
      popoverContent={
        <ToggleWNoteForm initialValues={attrs} onRemove={active ? handleRemove : undefined} onSubmit={handleSubmit} />
      }
      icon={<WNoteIcon />}
    />
  );
};
