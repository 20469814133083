import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionTag,
  getTextSelection,
  isElementDomNode,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
  omitExtraAttributes,
  PrimitiveSelection,
  ProsemirrorAttributes,
} from '@remirror/core';
import { DefaultProtocol } from '../WLinkExtension';

export interface WImageExtensionAttributes {
  alt?: string;
  src: string;
}

export type WImageAttributes = ProsemirrorAttributes<WImageExtensionAttributes>;

const INSERT_COMMAND_LABEL = 'Toggle Image';
const INSERT_COMMAND_DESCRIPTION = 'Toggle Image Attributes';

const insertWImageOptions: Remirror.CommandDecoratorOptions = {
  icon: 'imageLine',
  label: ({ t }) => t(INSERT_COMMAND_LABEL),
  description: ({ t }) => t(INSERT_COMMAND_DESCRIPTION),
};

export interface WImageOptions {
  resizable?: boolean;
}

export interface IwImageCommandOptions {
  title: 'Https' | 'Http';
  prefix?: DefaultProtocol;
  attrs: Array<keyof WImageExtensionAttributes>;
}

export const wImageCommandOptions: IwImageCommandOptions[] = [
  { title: 'Https', prefix: 'https://', attrs: ['src', 'alt'] },
  { title: 'Http', prefix: 'http://', attrs: ['src', 'alt'] },
];

@extension<WImageOptions>({
  defaultOptions: {
    resizable: false,
  },
})
export class WImageExtension extends NodeExtension<WImageOptions> {
  get name() {
    return 'wImage' as const;
  }

  createTags() {
    // I don't like this at all, would very much appreciate feedback here
    // const hasCaption = this.store.extensions.find((e: AnyExtension) => e instanceof WFigureExtension);
    //
    // if (hasCaption) {
    //   return [ExtensionTag.BlockNode, ExtensionTag.Media];
    // }

    return [ExtensionTag.Media];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      inline: true,
      draggable: true,
      selectable: false,
      ...override,
      attrs: {
        ...extra.defaults(),
        alt: { default: null },
        src: { default: null },
      },
      parseDOM: [
        {
          tag: 'img[src]',
          getAttrs: (element) =>
            isElementDomNode(element) ? getWImageAttributes({ element, parse: extra.parse }) : {},
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (node) => {
        const attrs = omitExtraAttributes(node.attrs, extra);
        return ['img', { ...extra.dom(node), ...attrs }];
      },
    };
  }

  @command(insertWImageOptions)
  insertWImage(attributes?: WImageAttributes, selection?: PrimitiveSelection): CommandFunction {
    return ({ tr, dispatch, state }) => {
      const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc);
      const node = this.type.create(attributes);

      dispatch?.(tr.replaceRangeWith(from, to, node));

      return true;
    };
  }
}

export function getWImageAttributes({
  element,
  parse,
}: {
  element: HTMLElement;
  parse: ApplySchemaAttributes['parse'];
}) {
  return {
    ...parse(element),
    alt: element.getAttribute('alt') ?? null,
    src: element.getAttribute('src') || 'https://media2.egwwritings.org/covers/no_cover_s.jpg',
  };
}

export function extractWImageUrl({ url }: { url: string | any }): { url: string; protocol: DefaultProtocol } {
  const arr = url.split(/^(http:\/\/?|https:\/\/?|mailto:\/\/?|egw:\/\/book\/?|egw:\/\/bible\/?|#?)/);
  return {
    protocol: arr[1] || '',
    url: arr[2] || '',
  };
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wImage: WImageExtension;
    }
  }
}
