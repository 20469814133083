import { useActive, useCommands } from '@remirror/react';
import React, { FC, ReactNode } from 'react';

import { useWLangState, useWNonEgwState, W_N0N_EGW_PROPERTIES, WNonEgwExtension } from '../../extensions';
import { ToggleWNonEgwDropdownMenuItem } from '../menus';
import { CommandDropdownButton, ICommandButtonProps } from './index';

export interface IToggleWNonEgwButtonProps {
  children?: ReactNode | ReactNode[];
  commandButtonProps?: Omit<ICommandButtonProps, 'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'>;
}

export const ToggleWNonEgwButton: FC<IToggleWNonEgwButtonProps> = ({ commandButtonProps, children }) => {
  const state = useWNonEgwState();

  const { attrs, active, enabled } = state;

  return (
    <CommandDropdownButton
      commandButtonProps={{
        ...commandButtonProps,
        commandName: 'toggleWNonEgw',
        active: active(),
        enabled,
        icon: <>{attrs?.type || 'non-egw'}</>,
      }}
    >
      {W_N0N_EGW_PROPERTIES.typeList.map((type, key) => {
        return <ToggleWNonEgwDropdownMenuItem key={`${key}-${type}`} attrs={{ type }} state={state} />;
      })}
    </CommandDropdownButton>
  );
};
