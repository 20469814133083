import { createContext, useContext, useMemo } from 'react';
import { IUseFoldersTreeStoreResult, useFoldersTreeStore } from './useFoldersTreeStore';

export const FoldersTreeStoreContext = createContext<IUseFoldersTreeStoreResult | undefined>(undefined);
FoldersTreeStoreContext.displayName = 'FoldersTreeStoreContext';

export const useFoldersTreeStoreContext = () => {
  const context = useContext(FoldersTreeStoreContext);
  if (context === undefined) {
    throw new Error('useFoldersTreeStoreContext must be used within FoldersTreeStoreProvider');
  }
  return context;
};

export const FoldersTreeStoreProvider = ({ children }) => {
  const foldersTreeStore = useFoldersTreeStore();

  const foldersTreeStoreContextObject: IUseFoldersTreeStoreResult = useMemo(() => foldersTreeStore, [foldersTreeStore]);

  return (
    <FoldersTreeStoreContext.Provider value={foldersTreeStoreContextObject}>
      {children}
    </FoldersTreeStoreContext.Provider>
  );
};
