import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionTag,
  FromToProps,
  GetMarkRange,
  getTextSelection,
  Handler,
  isAllSelection,
  isMarkActive,
  isSelectionEmpty,
  isTextSelection,
  MarkExtension,
  MarkExtensionSpec,
  MarkSpecOverride,
  PrimitiveSelection,
  ProsemirrorAttributes,
  removeMark,
  Static,
  updateMark,
} from '@remirror/core';
import { CreateEventHandlers } from '@remirror/extension-events';
import React from 'react';

const CREATE_COMMAND_LABEL = 'Toggle Sentence';
const CREATE_COMMAND_DESCRIPTION = 'Toggle Sentence';
const CREATE_COMMAND_SHORTCUT = 'Mod-S-E';

const toggleWSentOptions: Remirror.CommandDecoratorOptions = {
  label: ({ t }) => t(CREATE_COMMAND_LABEL),
  description: ({ t }) => t(CREATE_COMMAND_DESCRIPTION),
  shortcut: CREATE_COMMAND_SHORTCUT,
};

export type WSentTypeProperty = '';

export interface WSentProperties {
  types: Static<WSentTypeProperty>[];
}

export type WSentAttributes = ProsemirrorAttributes<{
  type?: Static<WSentTypeProperty>;
}>;

const W_SENT_PROPERTIES: Static<WSentProperties> = {
  types: [''],
};

const W_SENT_ATTRIBUTES: Static<WSentAttributes> = {
  type: '',
};

export interface WSentClickData extends GetMarkRange, WSentAttributes {}

export interface WSentViewClickHandlerProps {
  event: MouseEvent;
  markWithPosition?: WSentClickData;
}

export interface WSentOptions {
  properties?: Static<WSentProperties>;
  attributes?: Static<WSentAttributes>;
  onViewClick?: Handler<(props: WSentViewClickHandlerProps) => boolean | undefined | void>;
}

@extension<WSentOptions>({
  defaultOptions: {
    properties: W_SENT_PROPERTIES,
    attributes: W_SENT_ATTRIBUTES,
  },
  handlerKeys: ['onViewClick'],
  staticKeys: ['properties', 'attributes'],
})
export class WSentExtension extends MarkExtension<WSentOptions> {
  get name() {
    return 'wSent' as const;
  }

  createTags() {
    return [ExtensionTag.FormattingMark, ExtensionTag.FontStyle];
  }

  createMarkSpec(extra: ApplySchemaAttributes, override: MarkSpecOverride): MarkExtensionSpec {
    return {
      ...override,
      attrs: {
        ...extra.defaults(),
      },
      parseDOM: [
        ...this.options.properties.types.map((type) => ({
          tag: `w-sent`,
          getAttrs: (element: string | Node) => ({
            ...extra.parse(element),
          }),
        })),
        ...(override.parseDOM ?? []),
      ],
      toDOM: (mark) => {
        const {
          properties: { types },
        } = this.options;

        return ['w-sent', extra.dom(mark), 0];
      },
    };
  }

  createEventHandlers(): CreateEventHandlers {
    return {
      clickMark: (event, clickState) => {
        const markRange = clickState.getMark(this.type);

        if (!markRange) {
          return this.options.onViewClick({ event });
        }

        const attrs = markRange.mark.attrs as WSentAttributes;
        const markWithPosition: WSentClickData = { ...attrs, ...markRange };

        if (!markWithPosition || markWithPosition.mark.type !== this.type) {
          return this.options.onViewClick({ event });
        }

        return this.options.onViewClick({ event, markWithPosition });
      },
    };
  }

  @command(toggleWSentOptions)
  toggleWSent(attrs?: WSentAttributes, range?: FromToProps): CommandFunction {
    return (props) => {
      const { tr } = props;
      const selectionIsValid =
        (isTextSelection(tr.selection) && !isSelectionEmpty(tr.selection)) ||
        isAllSelection(tr.selection) ||
        isMarkActive({ trState: tr, type: this.type });

      if (!selectionIsValid && !range) {
        return false;
      }

      tr.setMeta(this.name, {
        command: 'toggleWSent',
        attrs,
        range,
      });

      return updateMark({ type: this.type, attrs, range })(props);
    };
  }

  @command()
  setWSent(selection?: PrimitiveSelection): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc);
      dispatch?.(tr.addMark(from, to, this.type.create()));

      return true;
    };
  }

  @command()
  removeWSent(range?: FromToProps): CommandFunction {
    return (props) => {
      const { tr } = props;

      if (!isMarkActive({ trState: tr, type: this.type, ...range })) {
        return false;
      }

      return removeMark({ type: this.type, expand: true, range })(props);
    };
  }

  @command()
  selectWSent(): CommandFunction {
    return this.store.commands.selectMark.original(this.type);
  }
}

interface WSentCommandOptions {
  attrs?: ProsemirrorAttributes;
  selection?: PrimitiveSelection;
  preserveAttrs?: boolean;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wSent: WSentExtension;
    }
  }
}
