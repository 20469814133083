import React, { useEffect, useState } from 'react';
import CONSTANTS from '../../config/CONSTANTS';
import { getThemes } from '../models/Themes';
import { useSystemThemeDetector } from './useSystemThemeDetector';

const getTheme = (): string =>
  JSON.parse(<string>localStorage.getItem(CONSTANTS.APP_LOCAL_STORAGE_THEME_MODE_POINT)) || getThemes().system.id;

export const useTheme = (): {
  theme: string;
  setTheme: React.Dispatch<React.SetStateAction<string>>;
  isSystemDarkModeTheme: boolean;
} => {
  const isSystemDarkModeTheme = useSystemThemeDetector();
  const [theme, setTheme] = useState<string>(getTheme);
  const html = document.getElementsByTagName('html')[0];

  useEffect(() => {
    const initialValue = getTheme();
    html.style.colorScheme = theme === 'system' ? (isSystemDarkModeTheme ? 'dark' : 'light') : theme;
    if (initialValue !== theme) {
      localStorage.setItem(CONSTANTS.APP_LOCAL_STORAGE_THEME_MODE_POINT, JSON.stringify(theme));
      // window.location.reload();
    }
  }, [theme, isSystemDarkModeTheme]);

  return { theme, setTheme, isSystemDarkModeTheme };
};

// export const useApplyStyles = (styles: LazyStyle): void => {
//   useEffect(() => {
//     styles.use();
//     return () => styles.unuse();
//   });
// };
