import { createContext, useContext, useMemo } from 'react';
import { IUsePublicationListStoreResult, usePublicationListStore } from './usePublicationListStore';

export const PublicationListStoreContext = createContext<IUsePublicationListStoreResult | undefined>(undefined);
PublicationListStoreContext.displayName = 'PublicationListStoreContext';

export const usePublicationListStoreContext = () => {
  const context = useContext(PublicationListStoreContext);
  if (context === undefined) {
    throw new Error('usePublicationListStoreContext must be used within PublicationListStoreProvider');
  }
  return context;
};

export const PublicationListStoreProvider = ({ children }) => {
  const publicationListStore = usePublicationListStore();

  const publicationListStoreContextObject: IUsePublicationListStoreResult = useMemo(
    () => publicationListStore,
    [publicationListStore],
  );

  return (
    <PublicationListStoreContext.Provider value={publicationListStoreContextObject}>
      {children}
    </PublicationListStoreContext.Provider>
  );
};
