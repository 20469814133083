import {
  ApplySchemaAttributes,
  extension,
  ExtensionPriority,
  ExtensionTag,
  NodeExtension,
  NodeSpecOverride,
  Static,
  uniqueId,
} from '@remirror/core';
import { NodeViewComponentProps } from '@remirror/react';
import { ComponentType } from 'react';

import { WNoteBodyView } from './views';
import { createWNoteSchema, WNoteSchemaSpec } from './wNote-utils';
import { WNoteTextBlockExtension } from './WNoteTextBlockExtension';

export interface WNoteBodyOptions {
  content?: Static<string>;

  bodyAttributes?: Static<string[]> | Static<Record<string, string | null>>;
}

@extension<WNoteBodyOptions>({
  defaultOptions: {
    content: 'wNoteTextBlock{1}',
    bodyAttributes: [],
    extraAttributes: {
      id: () => uniqueId(),
    },
  },
  defaultPriority: ExtensionPriority.Highest,
  staticKeys: ['content', 'bodyAttributes'],
  // disableExtraAttributes: true,
})
export class WNoteBodyExtension extends NodeExtension<WNoteBodyOptions> {
  get name() {
    return 'wNoteBody' as const;
  }

  createTags() {
    return [ExtensionTag.InlineNode, ExtensionTag.FormattingNode];
  }

  ReactComponent: ComponentType<NodeViewComponentProps> = WNoteBodyView;

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): WNoteSchemaSpec {
    return createWNoteSchema(extra, override, this.options).wNoteBody;
  }

  createExtensions(): Array<WNoteTextBlockExtension> {
    return [new WNoteTextBlockExtension({ priority: ExtensionPriority.Medium })];
  }
}
