import React, { forwardRef, useEffect, useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Spin } from 'antd';
import { ItemType } from 'antd/lib/breadcrumb/Breadcrumb';
import { PageHeaderProps } from '@ant-design/pro-layout/es/components/PageHeader';
import { useResizeDetector } from 'react-resize-detector';
import { createStyles } from 'antd-style';
import { useBreadcrumbTree } from '../../hooks';
import { useAppLayoutContext } from '../../layout';

export interface IPageTemplateProps extends PageHeaderProps {
  routes?: ItemType[];
  hideBreadcrumbs?: boolean;
  loading?: boolean;
  contentLoading?: boolean;
}

const usePageTemplateStyles = createStyles(({ token }) => ({
  breadcrambStyles: {
    display: 'grid',
    whiteSpace: 'nowrap',
    ol: {
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
      li: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
}));

export const PageHeaderWithRef: React.ForwardRefExoticComponent<PageHeaderProps & React.RefAttributes<HTMLDivElement>> =
  forwardRef((props, ref) => {
    const now = new Date();
    return (
      <div
        ref={ref}
        style={{
          minHeight: '100%',
        }}
      >
        <PageHeader {...props} />
      </div>
    );
  });

export const PageTemplate: React.FC<IPageTemplateProps> = (props) => {
  const {
    contentLoading,
    title,
    subTitle,
    routes,
    extra,
    children,
    hideBreadcrumbs = false,
    loading = false,
    style,
    ...rest
  } = props;
  const { styles } = usePageTemplateStyles();
  const { routes: gatheredRoutes } = useBreadcrumbTree(routes);
  const { setMinPageContentHeight, footerHeight, headerHeight } = useAppLayoutContext();
  const { width: pageContentWidth, height: pageContentHeight, ref: pageContentRef } = useResizeDetector();
  useEffect(() => {
    if (pageContentRef.current && headerHeight) {
      const pageContentOffsetTop = pageContentRef.current?.offsetTop;
      setMinPageContentHeight(window.innerHeight - headerHeight - (pageContentOffsetTop + (footerHeight || 0)) - 16);
    }
  }, [pageContentRef.current?.offsetTop, footerHeight]);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...child.props });
    }
    return child;
  });

  return (
    <PageHeaderWithRef
      title={!loading ? title : ''}
      subTitle={!loading && subTitle}
      breadcrumb={
        hideBreadcrumbs
          ? undefined
          : {
              items: gatheredRoutes,
              className: styles.breadcrambStyles,
            }
      }
      extra={!loading && extra}
      style={{
        ...style,
      }}
      {...rest}
    >
      {!contentLoading ? <div ref={pageContentRef}>{childrenWithProps}</div> : <Spin />}
    </PageHeaderWithRef>
  );
};
