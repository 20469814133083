import { useMemo } from 'react';

const getMobileDetect = (userAgent: string) => {
  const isAndroid = (): boolean => Boolean(userAgent.match(/Android/i));
  const isIos = (): boolean => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = (): boolean => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = (): boolean => Boolean(userAgent.match(/IEMobile|Windows Phone/i));
  const isSSR = (): boolean => Boolean(userAgent.match(/SSR/i));
  const isOldMobile = (): boolean => Boolean(userAgent.match(/webOS|BlackBerry/i));
  const isMobile = (): boolean => Boolean(isAndroid() || isIos() || isOpera() || isWindows() || isOldMobile());
  const isDesktop = (): boolean => Boolean(!isMobile() && !isSSR());

  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
  };
};
export const useMobileDetect = () => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  return useMemo(() => getMobileDetect(userAgent), [userAgent]);
};
