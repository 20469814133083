import { App, Form, Input, ModalFuncProps } from 'antd';
import React, { useRef } from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { DeleteOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';
import {
  DeleteParagraphInput,
  PublicationChaptersReaderParagraphListItemFragment,
  useDeletePublicationChaptersParagraphMutation,
} from '../../../services/apolloGraphqlAppApiClient/models/types';
import { FlexListTemplate } from '../../../components';
import { usePublicationChaptersReaderStore } from '../PublicationChaptersReaderStoreProvider';

export interface IUseDeletePublicationChaptersParagraphActionProps {
  paragraphData: PublicationChaptersReaderParagraphListItemFragment;
}

export interface IUseDeletePublicationChaptersParagraphActionResult {
  deletePublicationChaptersParagraphAction: ItemType;
}

export const useDeletePublicationChaptersParagraphAction = (
  props: IUseDeletePublicationChaptersParagraphActionProps,
): IUseDeletePublicationChaptersParagraphActionResult => {
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();
  const { paragraphData } = props;
  const menuItemKey = `DeletePublicationChaptersParagraphAction-${paragraphData.paraId}`;
  const infoKey = uuidV4();
  const modalRef = useRef<ReturnType<typeof confirm>>();
  const { activeChapterOrder, setScrollToItem, fetchParagraphsLazy, publicationChaptersReaderStore, historyParams } =
    usePublicationChaptersReaderStore();

  const visible = true;

  const [deletePublicationChaptersParagraph] = useDeletePublicationChaptersParagraphMutation({
    onCompleted: (result) => {
      info({
        placement: 'topRight',
        message: `Delete Paragraph status: true`,
        description: (
          <FlexListTemplate gap={5} alignItems="left" justifyContent="left" style={{ flexDirection: 'column' }}>
            {/* info */}
          </FlexListTemplate>
        ),
        key: infoKey,
        duration: 7,
      });
      publicationChaptersReaderStore
        .refetch({ publicationId: historyParams?.publicationId })
        .then((res) => {
          const lastChapterOrder = publicationChaptersReaderStore.data?.publication?.chapters?.slice(-1)[0].order;

          const resultParagraphOrder = paragraphData.order;

          const nextOrder =
            typeof lastChapterOrder === 'number' && resultParagraphOrder - lastChapterOrder > 20
              ? resultParagraphOrder > 1
                ? resultParagraphOrder - 1
                : resultParagraphOrder
              : resultParagraphOrder - 18;
          fetchParagraphsLazy({
            order: nextOrder,
            isRefetch: true,
          })
            .then(() => {
              setScrollToItem(resultParagraphOrder);
            })
            .catch((e) => {
              console.log({ e });
            });
        })
        .catch((e) => {
          console.log('[fetchReaderParagraphError]', { e });
        });
    },
    onError: (e) => {
      return e;
    },
  });

  const handleOk: ModalFuncProps['onOk'] = async () => {
    try {
      const res = await deletePublicationChaptersParagraph({
        variables: {
          input: { paraId: paragraphData.paraId },
        },
      });
      if (modalRef.current) {
        modalRef.current.destroy();
      }
    } catch (e) {
      return e;
    }
  };

  const handleCancel = () => {
    if (modalRef.current) {
      modalRef.current.destroy();
    }
  };

  return {
    deletePublicationChaptersParagraphAction: visible
      ? {
          key: menuItemKey,
          icon: <DeleteOutlined />,
          label: 'Delete Paragraph',
          danger: true,
          onClick: (menuInfo) => {
            menuInfo.domEvent.preventDefault();
            menuInfo.domEvent.stopPropagation();
            modalRef.current = confirm({
              title: `Delete Paragraph: ${paragraphData.paraId} !`,
              onOk: handleOk,
              onCancel: handleCancel,
              okText: 'Delete paragraph',
              content: 'Are you sure you want to delete this paragraph ?',
            });
          },
        }
      : null,
  };
};
