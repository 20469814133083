import { useActive, useCommands } from '@remirror/react';
import React, { FC, useCallback } from 'react';
import { CommandButton, ICommandButtonProps } from '../../../components';
import { WFormatItalicExtension } from '../WFormatItalicExtension';

export type IToggleWFormatItalicButtonProps = Omit<
  ICommandButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'
>;

export const ToggleWFormatItalicButton: FC<IToggleWFormatItalicButtonProps> = (props) => {
  const type = 'italic';
  const { toggleWFormatItalic: command, focus } = useCommands<WFormatItalicExtension>();
  const active = useActive<WFormatItalicExtension>().wFormatItalic({
    type,
  });
  const enabled = command.enabled({ type });

  const handleClick = useCallback(() => {
    if (enabled) {
      command({ type });
      focus();
    }
  }, [command]);

  return (
    <CommandButton
      {...props}
      commandName="toggleWFormatItalic"
      active={active}
      enabled={enabled}
      onSelect={handleClick}
    />
  );
};
