import { useActive, useCommands } from '@remirror/react';
import React, { FC, useCallback } from 'react';
import { HorizontalRuleExtension } from 'remirror/extensions';

import { CommandButton, ICommandButtonProps } from './CommandButton';

export type IToggleWHorizontalRuleButtonProps = Omit<
  ICommandButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'
>;

export const ToggleWHorizontalRuleButton: FC<IToggleWHorizontalRuleButtonProps> = (props) => {
  const { insertHorizontalRule: commandInsert, focus } = useCommands<HorizontalRuleExtension>();
  const active = useActive<HorizontalRuleExtension>(false).horizontalRule();
  const enabled = commandInsert.enabled();

  const handleClick = useCallback(() => {
    // if (enabled) {
    commandInsert();
    focus();
    // }
  }, [commandInsert]);

  return (
    <CommandButton
      {...props}
      commandName="insertHorizontalRule"
      label="Horizontal Rule"
      active={active}
      enabled={enabled}
      onSelect={handleClick}
    />
  );
};
