import React, { FC, useCallback } from 'react';
import { useActive, useCommands } from '@remirror/react-core';
import { useAttrs } from '@remirror/react';
import { CommandPopoverButton, ICommandPopoverButtonProps } from '../../../components';
import { WHeadingExtension } from '../WHeadingExtension';
import { ToggleWHeadingForm } from './ToggleWHeadingForm';

export type IToggleWHeadingButtonProps = Omit<
  ICommandPopoverButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'popoverContent' | 'onSelect'
>;

export const ToggleWHeadingButton: FC<IToggleWHeadingButtonProps> = (props) => {
  const { toggleWHeading } = useCommands<WHeadingExtension>();
  const attrs = useAttrs<WHeadingExtension>(true).wHeading();

  const active = useActive<WHeadingExtension>().wHeading();
  const enabled = toggleWHeading.enabled();
  const handleSubmit = useCallback(
    (values) => {
      if (toggleWHeading.enabled()) {
        toggleWHeading(values);
      }
    },
    [toggleWHeading, active],
  );
  const handleRemove = useCallback(() => {
    if (toggleWHeading.enabled()) {
      toggleWHeading();
    }
  }, [toggleWHeading]);

  return (
    <CommandPopoverButton
      {...props}
      commandName="toggleWHeading"
      active={active}
      enabled={enabled}
      popoverContent={
        <ToggleWHeadingForm
          onRemove={active ? handleRemove : undefined}
          initialValues={attrs}
          onSubmit={handleSubmit}
        />
      }
    />
  );
};
