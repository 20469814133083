import {
  ApplySchemaAttributes,
  EditorSchema,
  ErrorConstant,
  findParentNodeOfType,
  FindProsemirrorNodeResult,
  includes,
  invariant,
  NodeExtensionSpec,
  NodeSpecOverride,
  NodeType,
  object,
  ProsemirrorAttributes,
  ResolvedPos,
  SchemaProps,
  Selection,
  uniqueId,
  values,
} from '@remirror/core';
import { ValidOptions } from '@remirror/core-types/dist-types/annotation-types';
import { isElementDomNode } from 'remirror';

import type { Node as ProsemirrorNode } from '@remirror/pm/model';

// eslint-disable-next-line import/no-unresolved

export interface WNoteSchemaSpec extends NodeExtensionSpec {
  wNoteRole: WNoteRole;
}

export interface CreateWNoteCommand {
  attrs?: ProsemirrorAttributes;
  /**
   * Defines the content of each Header as a prosemirror node.
   *
   * @defaultValue undefined
   */
  wNoteHeaderContent?: ProsemirrorNode;

  /**
   * Defines the content of each Body as a prosemirror node.
   *
   * @defaultValue undefined
   */
  wNoteBodyContent?: ProsemirrorNode;
}

export interface CreateWNoteProps extends SchemaProps, CreateWNoteCommand {}

export function createWNoteSchema(
  extra: ApplySchemaAttributes,
  override: NodeSpecOverride,
  options?: ValidOptions,
): Record<'wNote' | 'wNoteHeader' | 'wNoteBody' | 'wNoteTextBlock', WNoteSchemaSpec> {
  const noteAttrs = {
    ...extra.defaults(),
    // id: { default: () => uniqueId() },
    type: { default: null },
  };
  const headerAttrs = {
    ...extra.defaults(),
  };
  const bodyAttrs = {
    ...extra.defaults(),
  };
  const bodyTextBlockAttrs = {
    ...extra.defaults(),
    type: { default: 'paragraph' },
  };

  return {
    wNote: {
      inline: true,
      group: 'wTextBlock',
      marks: '',
      // atom: true,
      isolating: true,
      selectable: true,
      draggable: true,
      defining: true,
      ...override,
      attrs: noteAttrs,
      content: 'wNoteHeader{1} wNoteBody{1}',
      wNoteRole: 'wNote',
      parseDOM: [
        {
          tag: 'w-note',
          getAttrs: (node) => {
            return isElementDomNode(node)
              ? {
                  type: node.getAttribute('type') as string,
                }
              : false;
          },
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM(node) {
        if (node?.attrs) {
          return ['w-note', { ...(node.attrs?.type ? { type: node.attrs.type.toString() } : {}) }, 0];
        }
        return ['w-note', extra.dom(node), 0];
      },
    },
    wNoteHeader: {
      inline: true,
      content: 'inline*',
      marks: '_',
      group: 'wNote',
      isolating: true,
      selectable: true,
      ...override,
      attrs: headerAttrs,
      wNoteRole: 'wNoteHeader',
      parseDOM: [
        {
          tag: 'w-note-header',
          // getAttrs: (dom) => ({ ...extra.parse(dom) }),
          getAttrs: (node) => {
            return isElementDomNode(node)
              ? {
                  id: node.getAttribute('id') as string,
                }
              : false;
          },
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM(node) {
        if (node?.attrs?.id) {
          return ['w-note-header', { ...{} }, 0];
        }
        return ['w-note-header', { ...extra.dom(node) }, 0];
      },
    },
    wNoteBody: {
      inline: true,
      group: 'wNote',
      isolating: true,
      selectable: true,
      attrs: bodyAttrs,
      content: options?.content || 'wNoteTextBlock{1}',
      wNoteRole: 'wNoteBody',
      marks: '',
      ...override,
      parseDOM: [
        {
          tag: 'w-note-body',
          // getAttrs: (dom) => ({ ...extra.parse(dom) }),
          getAttrs: (node) => {
            return isElementDomNode(node)
              ? {
                  id: node.getAttribute('id') as string,
                }
              : false;
          },
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM(node) {
        if (node?.attrs?.id) {
          return ['w-note-body', { ...{} }, 0];
        }
        return ['w-note-body', { ...extra.dom(node) }, 0];
      },
    },
    wNoteTextBlock: {
      inline: true,
      marks: '_',
      attrs: bodyTextBlockAttrs,
      // draggable: true,
      group: 'wNoteBody',
      selectable: false,
      content: 'inline*',
      wNoteRole: 'wNoteTextBlock',
      ...override,
      parseDOM: [
        {
          tag: 'w-text-block',
          getAttrs: (dom) => ({ ...extra.parse(dom) }),
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM(node) {
        return ['w-text-block', { ...extra.dom(node) }, 0];
      },
    },
  };
}

const WNOTE_ROLES = ['wNote', 'wNoteHeader', 'wNoteBody', 'wNoteTextBlock'] as const;
export type WNoteRole = (typeof WNOTE_ROLES)[number];

function wNoteTypes(schema: EditorSchema): Record<string, NodeType> {
  if (schema.cached.wNoteTypes) {
    return schema.cached.wNoteTypes;
  }

  const roles: Record<WNoteRole, NodeType> = object();
  schema.cached.wNoteTypes = roles;

  for (const nodeType of values(schema.nodes)) {
    if (includes(WNOTE_ROLES, nodeType.spec.wNoteRole)) {
      roles[nodeType.spec.wNoteRole] = nodeType;
    }
  }

  return roles;
}

interface CreateWNoteChildProps {
  type: NodeType;
  content?: ProsemirrorNode;
}

/**
 * Create a WNote Body with the provided content.
 */
function createWNoteChild(props: CreateWNoteChildProps) {
  const { content, type } = props;

  if (content) {
    return type.createChecked(null, content);
  }

  return type.createAndFill();
}

export function createWNote(props: CreateWNoteProps): ProsemirrorNode {
  const { schema, wNoteHeaderContent, wNoteBodyContent, attrs } = props;
  const { wNoteHeader, wNoteBody, wNote } = wNoteTypes(schema);

  invariant(wNoteHeader && wNoteBody && wNote, {
    code: ErrorConstant.EXTENSION,
    message: 'WNote Extension unable to locate required nodes.',
  });

  const wNoteHeaderChild = createWNoteChild({
    type: wNoteHeader,
    content: wNoteHeaderContent || schema.text('*'),
  }) as ProsemirrorNode;
  const wNoteBodyChild = createWNoteChild({
    type: wNoteBody,
    content: wNoteBodyContent || schema.nodes.wNoteTextBlock.create(null),
  }) as ProsemirrorNode;
  const wNoteContent: ProsemirrorNode[] = [wNoteHeaderChild, wNoteBodyChild];

  return wNote.createChecked(attrs, wNoteContent);
}

export function findWNoteParent(selection: Selection | ResolvedPos): FindProsemirrorNodeResult | undefined {
  return findParentNodeOfType({ selection, types: 'wNote' });
}

export function findWNote(selection: Selection | ResolvedPos): FindProsemirrorNodeResult | undefined {
  return findParentNodeOfType({ selection, types: ['wNoteHeader', 'wNoteBody'] });
}
