import { createStyles } from 'antd-style';
import React, { CSSProperties, FC, useMemo, useState } from 'react';
import Resizable, { ResizableProps } from 'react-resizable-layout';

const useResizeSeparatorStyles = createStyles<{ disabled?: boolean }, any>((utils, props) => {
  const { token } = utils;
  const { disabled } = props;
  return {
    sampleDragBar: {
      flexShrink: 0,
      margin: 0,
      width: !disabled ? 5 : 1,
      ...(!disabled ? { cursor: 'col-resize' } : {}),
      transition: 'background-color 0.15s 0.15s ease-in-out',
      border: 'none',
      backgroundColor: token.colorBorderSecondary,
      height: '100%',
    },
    sampleDragBarVirtual: {
      flexShrink: 0,
      margin: 0,
      width: !disabled ? 5 : 1,
      ...(!disabled ? { cursor: 'col-resize' } : {}),
      position: 'absolute',
      transition: 'background-color 0.15s 0.15s ease-in-out',
      border: 'none',
      backgroundColor: token.colorBorderSecondary,
      height: '100%',
      '&:hover': {
        backgroundColor: token.colorPrimaryBg,
      },
    },
    sampleDragBarHorizontal: {
      height: !disabled ? 5 : 1,
      width: '100%',
      ...(!disabled ? { cursor: 'row-resize' } : {}),
    },
    sampleDragBarDisabled: {
      // cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: token.colorBgContainerDisabled,
      },
    },
    sampleDragBarDragging: {
      backgroundColor: token.colorPrimaryBg,
      '&:hover': {
        backgroundColor: token.colorPrimaryBg,
      },
    },
  };
});
const ResizeSeparator: FC<
  React.PropsWithChildren<
    {
      isDragging?: boolean;
      disabled?: boolean;
      virtual?: boolean;
      position: number;
    } & Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLHRElement>, HTMLHRElement>, 'ref'>
  >
> = (props) => {
  const { position, virtual, id = 'splitter', dir, isDragging, disabled, ...rest } = props;
  const [isFocused, setIsFocused] = useState(false);
  const { styles } = useResizeSeparatorStyles({ disabled });
  return (
    <>
      <hr
        id={id}
        data-testid={id}
        tabIndex={disabled ? -1 : 0}
        {...(!virtual ? { ...rest } : {})}
        className={`${styles.sampleDragBar}${dir === 'horizontal' ? ` ${styles.sampleDragBarHorizontal}` : ''}${
          !disabled && isDragging && !virtual ? ` ${styles.sampleDragBarDragging}` : ''
        }${disabled ? ` ${styles.sampleDragBarDisabled}` : ''}`}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        style={{
          ...(virtual ? { cursor: 'default !important' } : {}),
          ...((isDragging || isFocused) && virtual ? { display: 'none' } : {}),
        }}
      />
      {virtual && (
        <hr
          id="virtual-splitter"
          {...rest}
          className={`${styles.sampleDragBarVirtual}${
            dir === 'horizontal' ? ` ${styles.sampleDragBarHorizontal}` : ''
          }${!disabled && (isDragging || isFocused) ? ` ${styles.sampleDragBarDragging}` : ''}${
            disabled ? ` ${styles.sampleDragBarDisabled}` : ''
          }`}
          style={{
            ...(dir === 'horizontal'
              ? {
                  top: position,
                  left: 0,
                }
              : {
                  top: 0,
                  left: position,
                }),
            opacity: isDragging || isFocused ? 0.7 : 0,
          }}
        />
      )}
    </>
  );
};

export const ResizeBox: FC<
  React.PropsWithChildren<
    {
      width?: CSSProperties['width'];
      height?: CSSProperties['height'];
    } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  >
> = (props) => {
  const { id, width = '100%', height = '100%', children, style, ...rest } = props;
  return (
    <div
      id={id}
      data-testid={id}
      style={{
        width,
        height,
      }}
      {...rest}
    >
      <div style={{ width: '100%', height: '100%', padding: 8, ...style }}>{children}</div>
    </div>
  );
};

export interface IAxisPosition {
  startContent: { width?: CSSProperties['width']; height?: CSSProperties['height'] };
  endContent: { width?: CSSProperties['width']; height?: CSSProperties['height'] };
}

export const ResizableLayout: React.FC<
  {
    virtual?: boolean;
    reverse?: boolean;
    startContent: React.ReactNode;
    endContent: React.ReactNode;
    styles?: {
      wrapperStyles?: CSSProperties;
      startContentStyles?: CSSProperties;
      endContentStyles?: CSSProperties;
    };
  } & Omit<ResizableProps, 'children'>
> = (props) => {
  const { disabled, styles, reverse = false, startContent, endContent, virtual = false, axis, ...rest } = props;

  return (
    <Resizable disabled={disabled} axis={axis} {...rest}>
      {({ position, endPosition, isDragging, separatorProps }) => {
        const axisPosition: IAxisPosition = !reverse
          ? {
              startContent: { [axis === 'x' ? 'width' : 'height']: virtual ? endPosition : position },
              endContent: { [axis === 'x' ? 'width' : 'height']: `calc(100% - ${virtual ? endPosition : position}px)` },
            }
          : {
              startContent: {
                [axis === 'x' ? 'width' : 'height']: `calc(100% - ${virtual ? endPosition : position}px)`,
              },
              endContent: { [axis === 'x' ? 'width' : 'height']: virtual ? endPosition : position },
            };

        return (
          <div
            id="wrapper"
            style={{
              position: 'relative',
              overflow: 'hidden',
              display: 'flex',
              // textAlign: 'center',
              flexDirection: axis === 'x' ? 'row' : 'column',
              flexWrap: 'nowrap',
              height: '100%',
              width: '100%',
              ...styles?.wrapperStyles,
            }}
          >
            <ResizeBox
              id={axis === 'x' ? 'left-block' : 'top-block'}
              {...axisPosition.startContent}
              style={{ ...styles?.startContentStyles }}
            >
              {startContent}
            </ResizeBox>
            <ResizeSeparator
              virtual={virtual}
              {...separatorProps}
              dir={axis === 'x' ? 'vertical' : 'horizontal'}
              isDragging={isDragging}
              disabled={disabled}
              position={position}
            />
            <ResizeBox
              id={axis === 'x' ? 'right-block' : 'bottom-block'}
              {...axisPosition.endContent}
              style={{ ...styles?.endContentStyles }}
            >
              {endContent}
            </ResizeBox>
          </div>
        );
      }}
    </Resizable>
  );
};
