import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  CreateExtensionPlugin,
  EditorState,
  extension,
  ExtensionPriority,
  ExtensionTag,
  FindProsemirrorNodeResult,
  Handler,
  keyBinding,
  KeyBindingProps,
  NodeExtension,
  NodeSpecOverride,
  NodeWithPosition,
  Transaction,
  uniqueId,
} from '@remirror/core';
import { NodeViewComponentProps } from '@remirror/react';
import { ComponentType } from 'react';
import { CreateEventHandlers } from '@remirror/extension-events';
import { TextSelection } from '@remirror/pm/state';
import { findNodeAtSelection, findParentNode, toggleWrap } from 'remirror';

import { createWNoteSchema, WNoteSchemaSpec } from './wNote-utils';

import './assets/styles/view_w-note-header.scss';
import { WNoteHeaderView } from './views';

export interface WNoteHeaderViewClickHandlerProps {
  event: MouseEvent;
  nodeWithPosition: NodeWithPosition;
  parentNodeWithPosition?: FindProsemirrorNodeResult | undefined;
}

export interface WNoteHeaderOptions {
  onViewClick?: Handler<(props: WNoteHeaderViewClickHandlerProps) => boolean | undefined | void>;
}

@extension<WNoteHeaderOptions>({
  defaultOptions: {
    extraAttributes: {
      id: () => uniqueId(),
    },
  },
  handlerKeys: ['onViewClick'],
  defaultPriority: ExtensionPriority.Low,
})
export class WNoteHeaderExtension extends NodeExtension<WNoteHeaderOptions> {
  get name() {
    return 'wNoteHeader' as const;
  }

  ReactComponent: ComponentType<NodeViewComponentProps> = WNoteHeaderView;

  readonly tags = [ExtensionTag.InlineNode];

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): WNoteSchemaSpec {
    return createWNoteSchema(extra, override).wNoteHeader;
  }

  @command()
  toggleWNoteHeader(attributes = {}): CommandFunction {
    return toggleWrap(this.type, attributes);
  }

  createEventHandlers(): CreateEventHandlers {
    return {
      click: (event, clickState) => {
        // Check if this is a direct click which must be the case for atom
        if (!clickState.direct) {
          return;
        }

        const nodeWithPosition = clickState.getNode(this.type);

        if (!nodeWithPosition || nodeWithPosition.node.type !== this.type) {
          return;
        }

        const selection = clickState.state.tr.setSelection(
          TextSelection.create(clickState.state.tr.doc, nodeWithPosition.pos),
        );

        const clickedNodeParent = findParentNode({
          predicate: (clickedNode) => this.store.schema.nodes.wNote === clickedNode.type,
          selection: selection.selection,
        });
        if (clickedNodeParent?.node.firstChild === nodeWithPosition.node) {
          return this.options.onViewClick({
            event,
            nodeWithPosition,
            parentNodeWithPosition: clickedNodeParent,
          });
        }

        return this.options.onViewClick({ event, nodeWithPosition });
      },
    };
  }

  // /**
  //  * Update the callout at the current position. Primarily this is used
  //  * to change the type.
  //  *
  //  * ```ts
  //  * if (commands.updateCallout.enabled()) {
  //  *   commands.updateCallout({ type: 'error' });
  //  * }
  //  * ```
  //  */
  // @command()
  // updateWNoteHeader(attributes, pos?: number): CommandFunction {
  //   return updateNodeAttributes(this.type)(attributes, pos);
  // }

  @keyBinding({ shortcut: 'Backspace' })
  handleBackspace({ dispatch, tr, state }: KeyBindingProps): boolean {
    if (!tr.selection.empty) {
      return false;
    }
    const { $from } = tr.selection;
    if ($from.parentOffset !== 0) {
      return false;
    }

    // if ($from.depth === 0) {
    //   return false;
    // }
    const previousPosition = $from.before($from.depth) - 1;
    if (previousPosition >= 0) {
      const previousPos = tr.doc.resolve(previousPosition);
      const { node, pos } = findNodeAtSelection(tr.selection);

      if (!tr.selection.empty && node.type === this.type) {
        if ($from.parentOffset > 0) {
          return false;
        }
        if (dispatch) {
          dispatch(tr);
        }

        return false;
      }

      // if ($from.parentOffset === 1 && node.type === this.type) {
      //   console.log({ tr, node, state, parent: $from.parentOffset });
      //   const textNode = state.schema.text('*');
      //   if (dispatch) {
      //     // @ts-ignore
      //     dispatch(
      //       tr
      //         .replaceRangeWith($from.pos - 1, $from.pos, textNode)
      //         .setSelection(TextSelection.create(tr.doc, $from.pos))
      //         .scrollIntoView(),
      //     );
      //   }
      //   return true;
      // }

      // if (node.childCount == 1 && node?.firstChild.content.size == 0) {
      //   return DecorationSet.create(node, [Decoration.widget(1, document.createTextNode('text'))]);
      // }

      // if (node.textContent.length === 1 && node.type === this.type) {
      //   if (dispatch) {
      //     dispatch(tr);
      //   }
      //   return true;
      // }

      // If not at the start of current node, no joining will happen
      if ($from.parentOffset !== 0 && node.type === this.type) {
        // console.log('$from.parentOffset !== 0 && node.type === this.type');
        // console.log({ tr, parent: $from.parentOffset });
        return false;
      }

      // If resolving previous position fails, bail out
      if (node.type === this.type) {
        if (dispatch) {
          dispatch(tr);
        }

        return true;
      }
    }

    return false;
  }

  @keyBinding({ shortcut: 'Delete' })
  handleDelete({ dispatch, tr, state }: KeyBindingProps): boolean {
    if (!tr.selection.empty) {
      return false;
    }
    const { $from } = tr.selection;
    // If not at the start of current node, no joining will happen
    if ($from.parentOffset !== 0) {
      return false;
    }

    const previousPosition = $from.before($from.depth) - 1;
    if (previousPosition >= 0) {
      const previousPos = tr.doc.resolve(previousPosition);
      const { node, pos } = findNodeAtSelection(tr.selection);

      if (!tr.selection.empty && node.type === this.type) {
        if ($from.parentOffset > 0) {
          return false;
        }
        if (dispatch) {
          dispatch(tr);
        }

        return false;
      }

      // If resolving previous position fails, bail out
      if ((!$from.nodeAfter || $from.textOffset === 0) && node.type === this.type) {
        if (dispatch) {
          dispatch(tr);
        }

        return true;
      }
    }

    return false;
  }

  @keyBinding({ shortcut: 'Mod-a' })
  handleSelect({ dispatch, tr, state }: KeyBindingProps): boolean {
    const { $from } = tr.selection;
    const previousPosition = $from.before($from.depth) - 1;
    const previousPos = tr.doc.resolve(previousPosition);
    const { node, pos } = findNodeAtSelection(tr.selection);

    if (node.type === this.type) {
      node?.firstChild &&
        tr.setSelection(
          TextSelection.create(
            tr.doc,
            // @ts-ignore
            $from.start(),
            $from.end(),
          ),
        );
      if (dispatch) {
        dispatch(tr);
      }

      return true;
    }

    return false;
  }
}
