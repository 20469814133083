import React, { useEffect, useState } from 'react';
import {
  GetManagerPublicationListQueryHookResult,
  GetManagerPublicationListQueryVariables,
  ManagerPublicationListItemFragment,
  SortEnumType,
  useGetManagerPublicationListQuery,
} from '../../../../services/apolloGraphqlAppApiClient/models/types';
import { usePublicationsManagerStoreContext } from '../Manager';
import { useDidMountEffect } from '../../../../hooks';

export type TPublicationsStoreFilter = GetManagerPublicationListQueryVariables['where'];
export type TPublicationsStoreSort = GetManagerPublicationListQueryVariables['order'];
export type TPublicationsStorePagination = {
  skip?: GetManagerPublicationListQueryVariables['skip'];
  take?: GetManagerPublicationListQueryVariables['take'];
};

export type TManagerPublicationListItem = ManagerPublicationListItemFragment;

export interface IUsePublicationListStoreResult {
  pagination: TPublicationsStorePagination;
  setPagination: React.Dispatch<React.SetStateAction<TPublicationsStorePagination>>;
  publicationList: TManagerPublicationListItem[];
  setPublicationList: React.Dispatch<React.SetStateAction<TManagerPublicationListItem[]>>;
  publicationsStore: GetManagerPublicationListQueryHookResult;
  fetchMore: () => void;
  fetchRest: () => void;
  hasNextPage: boolean;
  scrollToIndex: number | undefined;
  refetch: (index: number | undefined) => void;
}

export const usePublicationListStore = (): IUsePublicationListStoreResult => {
  const { selectedFolder, setFooterData } = usePublicationsManagerStoreContext();
  const { historySearchProps } = usePublicationsManagerStoreContext();
  const [publicationList, setPublicationList] = useState<TManagerPublicationListItem[]>([]);
  const [pagination, setPagination] = useState<TPublicationsStorePagination>({ take: 20, skip: 0 });
  const [hasNextPage, setHasNextPage] = useState(true);
  const [scrollToIndex, setScrollToIndex] = useState<number | undefined>(undefined);

  const coverProps = {
    width: 500,
    height: 500,
    withWebCover: true,
  };

  const publicationsStore: GetManagerPublicationListQueryHookResult = useGetManagerPublicationListQuery({
    variables: {
      ...coverProps,
      where: historySearchProps?.filter?.publications
        ? {
            folderId: {
              eq: selectedFolder && selectedFolder?.id !== 0 ? selectedFolder.id : null,
            },
            title: { contains: historySearchProps.filter.publications },
          }
        : {
            folderId: {
              eq: selectedFolder && selectedFolder?.id !== 0 ? selectedFolder.id : null,
            },
          },
      order: { order: SortEnumType.Asc },
      ...pagination,
    },
    fetchPolicy: 'network-only',
    onCompleted: (newData) => {
      //
    },
    notifyOnNetworkStatusChange: true,
  });

  const refetch = async (index: number | undefined) => {
    await publicationsStore
      .refetch({
        ...coverProps,
        where: historySearchProps?.filter?.publications
          ? {
              folderId: {
                eq: selectedFolder && selectedFolder?.id !== 0 ? selectedFolder.id : null,
              },
              title: { contains: historySearchProps?.filter?.publications },
            }
          : {
              folderId: {
                eq: selectedFolder && selectedFolder?.id !== 0 ? selectedFolder.id : null,
              },
            },
        order: { order: SortEnumType.Asc },
        ...pagination,
      })
      .then(() => {
        // setScrollToIndex(index);
      });
  };

  const fetchMore = async () => {
    if (publicationsStore.data?.publications?.pageInfo.hasNextPage) {
      const pagination: TPublicationsStorePagination = {
        skip: publicationsStore.data?.publications.items?.length,
        take: 20,
      };
      await publicationsStore.fetchMore({
        variables: {
          ...coverProps,
          where: historySearchProps?.filter?.publications
            ? {
                folderId: {
                  eq: selectedFolder && selectedFolder?.id !== 0 ? selectedFolder.id : null,
                },
                title: { contains: historySearchProps.filter.publications },
              }
            : {
                folderId: {
                  eq: selectedFolder && selectedFolder?.id !== 0 ? selectedFolder.id : null,
                },
              },
          order: { order: SortEnumType.Asc },
          ...pagination,
        },
        // @ts-ignore
        updateQuery: (previousQueryResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousQueryResult;
          // @ts-ignore
          // eslint-disable-next-line no-unsafe-optional-chaining
          const newItems = [...previousQueryResult?.publications?.items, ...fetchMoreResult?.publications?.items];
          return {
            ...previousQueryResult,
            publications: {
              ...fetchMoreResult.publications,
              // @ts-ignore
              // eslint-disable-next-line no-unsafe-optional-chaining
              items: newItems.filter(
                (el, i) => newItems.findIndex((obj, j) => obj?.publicationId === el?.publicationId) === i,
              ),
            },
          };
        },
      });
    }
  };

  const fetchRest = async () => {
    if (publicationsStore.data?.publications?.pageInfo.hasNextPage) {
      await fetchMore();
      // return await fetchRest();
    }
  };

  useDidMountEffect(() => {
    setPublicationList([]);
    setPagination({ take: 20, skip: 0 });
  }, [selectedFolder]);

  useEffect(() => {
    setFooterData((original) => {
      return {
        ...original,
        filteredPublicationCount: publicationsStore.data?.publications?.totalCount,
        loadedPublicationCount: publicationsStore.data?.publications?.items?.length,
      };
    });
  }, [publicationsStore.data?.publications?.items?.length]);

  return {
    pagination,
    publicationList,
    setPublicationList,
    publicationsStore,
    fetchMore,
    fetchRest,
    setPagination,
    hasNextPage,
    scrollToIndex,
    refetch,
  };
};
