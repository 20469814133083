import React from 'react';
import { Space, theme } from 'antd';
import { Data, DragOverEvent, useDndMonitor, useDroppable } from '@dnd-kit/core';
import { TreeProps } from 'antd/lib';
import { useFoldersTreeStoreContext } from './FoldersTreeStoreProvider';
import { useDebounce } from '../../../../hooks';
import { ManagerFolderTreeNodeType } from './useFoldersTreeStore';
import { FolderIcon } from './FolderIcon';
import { ManagerFolderTreeListItemFragment } from '../../../../services/apolloGraphqlAppApiClient/models/types';
import { FoldersTreeItemContextActionsRender } from './FoldersTreeItemContextActionsRender';

export interface IFolderTreeNodeTitleRenderComponentProps {
  treeNode: ManagerFolderTreeNodeType;
}

export const FolderTreeItemTitleRenderComponent = (
  props: IFolderTreeNodeTitleRenderComponentProps,
): React.JSX.Element => {
  const { treeNode } = props;
  const { expandOptions, toggleExpandNode } = useFoldersTreeStoreContext();
  const { token } = theme.useToken();
  const droppableId = `folder-${treeNode?.id}`;
  const { setNodeRef, isOver, over, node } = useDroppable({
    id: droppableId,
    data: { folder: treeNode as Data<ManagerFolderTreeNodeType> },
  });

  useDndMonitor({
    onDragOver: useDebounce((event: DragOverEvent) => {
      const { active, over } = event;
      if (
        over?.data?.current?.folder?.id === treeNode.id &&
        over?.data?.current?.folder?.isLeaf === false &&
        !expandOptions.expandedKeys?.includes(over?.data?.current?.folder?.id)
      ) {
        toggleExpandNode(over?.data?.current?.folder?.id, false);
      }
    }, 1000),
  });

  // useLayoutEffect(() => {
  //   if (node?.current?.offsetParent && treeNode.key !== selectedFolder.key) {
  //     // node.current.offsetParent.classList.toggle(
  //     //   'ant-tree-node-selected',
  //     //   over?.data?.current?.wrapper?.isLeaf === true && isOver,
  //     // );
  //     // eslint-disable-next-line
  //     node.current.offsetParent['style'].backgroundColor = over?.data?.current?.wrapper?.isLeaf === true && isOver ? token.colorBgTextHover : '';
  //   }
  // }, [isOver]);
  //
  // useLayoutEffect(() => {
  //   if (node?.current?.offsetParent) {
  //     // node.current.offsetParent.classList.toggle('ant-tree-node-selected', open === true);
  //     // eslint-disable-next-line
  //     node.current.offsetParent['style'].backgroundColor = `wrapper-${treeNode.key}` === openedMenuKey && treeNode.key !== selectedFolder.key ? token.colorBgTextHover : '';
  //   }
  // }, [openedMenuKey]);

  const nodeStyle: React.CSSProperties = {
    // @ts-ignore
    // position: 'relative !important',
    ...(isOver && treeNode?.isLeaf ? { backgroundColor: token.colorPrimary, borderRadius: 6 } : {}),
    width: '100% !important',
    padding: '0 4px',
  };

  const nodeClassName = 'custom-ant-tree-title';

  return (
    <FoldersTreeItemContextActionsRender folderData={treeNode as ManagerFolderTreeListItemFragment}>
      <span ref={setNodeRef} style={nodeStyle} className="contextMenuCursor">
        <Space wrap={false} style={{ width: '100%' }}>
          <FolderIcon
            isLeaf={treeNode?.isLeaf}
            expanded={!!expandOptions.expandedKeys?.find((k) => k === treeNode?.id)}
          />
          <span className="custom-ant-tree-title--text">
            {treeNode?.title as string}
            {/* {process.env.NODE_ENV === 'development' */}
            {/*  ? ` { id: ${treeNode.id}, order: ${treeNode.order} }` */}
            {/*  : ''} */}
          </span>
        </Space>
      </span>
    </FoldersTreeItemContextActionsRender>
  );
};

export const FolderTreeItemTitleRender: TreeProps<ManagerFolderTreeNodeType>['titleRender'] = (
  node,
): React.ReactNode => {
  return <FolderTreeItemTitleRenderComponent treeNode={node} />;
};
