import { Autocomplete, Box, TextField } from '@mui/material';
import React from 'react';
import { CommandForm, ICommandFormProps } from '../../../components';
import { W_PARA_PROPERTIES, WParaAttributes } from '../WParaExtension';

export interface IToggleWParaNodeFormProps extends Omit<ICommandFormProps, 'children' | 'fields'> {
  initialOption?: string;
  initialValues?: WParaAttributes;
  onRemove?: () => void;
}

export const ToggleWParaForm = (props: IToggleWParaNodeFormProps) => {
  const { ...rest } = props;

  return (
    <CommandForm name="wPara" {...rest}>
      <Autocomplete
        id="role"
        size="small"
        options={W_PARA_PROPERTIES.roleList}
        autoHighlight
        getOptionLabel={(option: any) => option}
        renderOption={(props, option) => {
          props['data-name'] = 'role';
          return (
            <Box component="li" {...props}>
              {option}
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="role"
            label="Role"
            size="small"
            variant="standard"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
      <Autocomplete
        id="align"
        size="small"
        options={W_PARA_PROPERTIES.alignList}
        autoHighlight
        getOptionLabel={(option: any) => option}
        renderOption={(props, option) => {
          props['data-name'] = 'align';
          return (
            <Box component="li" {...props}>
              {option}
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="align"
            label="Align"
            size="small"
            variant="standard"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
      <TextField
        name="indent"
        type="number"
        label="Indent"
        size="small"
        variant="standard"
        // rules={[{ required: true, message: 'Number is required' }]}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]{2}', min: 0, max: 10 }}
      />
      <TextField name="skip" size="small" label="Skip" variant="standard" id="outlined-start-skip" />
    </CommandForm>
  );
};
