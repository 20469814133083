import React, { FC, useCallback } from 'react';
import { useActive, useCommands } from '@remirror/react-core';

import { CommandButton, ICommandButtonProps } from '../../../components';
import { OrderedWListExtension } from '../OrderedWListExtension';

export type IToggleOrderedWListButtonProps = Omit<
  ICommandButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'
>;
export const ToggleOrderedWListButton: FC<IToggleOrderedWListButtonProps> = (props) => {
  const { toggleOrderedWList } = useCommands<OrderedWListExtension>();

  const handleSelect = useCallback(() => {
    if (toggleOrderedWList.enabled()) {
      toggleOrderedWList();
    }
  }, [toggleOrderedWList]);

  const active = useActive<OrderedWListExtension>().orderedWList();
  const enabled = toggleOrderedWList.enabled();

  return (
    <CommandButton
      {...props}
      commandName="toggleOrderedWList"
      active={active}
      enabled={enabled}
      onSelect={handleSelect}
    />
  );
};
