import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  ErrorConstant,
  extension,
  ExtensionPriority,
  ExtensionTag,
  findParentNodeOfType,
  Handler,
  invariant,
  isEqual,
  isTextSelection,
  keyBinding,
  KeyBindingProps,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
  NodeWithPosition,
  ProsemirrorAttributes,
  Static,
  Transaction,
} from '@remirror/core';
import { findNodeAtSelection, isElementDomNode, isEmptyBlockNode, isNodeSelection, toggleWrap } from 'remirror';

import './assets/styles/view_w-page.scss';

import { Fragment, Slice } from '@remirror/pm/model';
import { TextSelection } from '@remirror/pm/state';

const TOGGLE_COMMAND_LABEL = 'Toggle WPageBlock';
const TOGGLE_COMMAND_DESCRIPTION = 'Insert WPageBlock Attributes';
const TOGGLE_COMMAND_SHORTCUT = 'Ctrl-Shift-B';

const toggleWPageBlockOptions: Remirror.CommandDecoratorOptions = {
  icon: 'pageSeparator',
  label: ({ t }) => t(TOGGLE_COMMAND_LABEL),
  description: ({ t }) => t(TOGGLE_COMMAND_DESCRIPTION),
  shortcut: TOGGLE_COMMAND_SHORTCUT,
};

export type WPageBlockNumberProperty = string;

export interface WPageBlockProperties {
  number: Static<WPageBlockNumberProperty>;
}

export type WPageBlockAttributes = ProsemirrorAttributes<{
  number: Static<WPageBlockNumberProperty>;
}>;

export const W_PAGE_BLOCK_PROPERTIES: Static<WPageBlockProperties> = {
  number: 'X',
};

const W_PAGE_BLOCK_ATTRIBUTES: Static<WPageBlockAttributes> = {
  number: '',
};

export interface WPageBlockOptions {
  properties?: Static<WPageBlockProperties>;
  attributes?: Static<WPageBlockAttributes>;
  type?: 'convert' | 'insert';
  insertionNode?: string | false;
}

@extension<WPageBlockOptions>({
  defaultOptions: {
    properties: W_PAGE_BLOCK_PROPERTIES,
    attributes: W_PAGE_BLOCK_ATTRIBUTES,
    insertionNode: false,
    type: 'insert',
  },
  defaultPriority: ExtensionPriority.Medium,
  staticKeys: ['properties', 'attributes'],
})
export class WPageBlockExtension extends NodeExtension<WPageBlockOptions> {
  get name() {
    return 'wPageBlock' as const;
  }

  createTags() {
    return [ExtensionTag.WemlContainer];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      // draggable: true,
      // selectable: true,
      // content: 'inline*',
      // marks: '',
      // atom: true,
      // isolating: true,
      // defining: true,
      // selectable: true,
      // atom: true,
      // // content: '',
      // group: 'block',
      // // leafText: (node) => '',
      // draggable: false,
      ...override,
      attrs: {
        ...extra.defaults(),
        number: { default: this.options.attributes.number },
      },
      parseDOM: [
        {
          tag: `w-page`,
          context: 'doc',
          getAttrs: (node) => {
            return isElementDomNode(node)
              ? {
                  ...extra.parse(node),
                  number: node.getAttribute('number') as string,
                }
              : false;
          },
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (node) => {
        return ['w-page', { number: node.attrs.number.toString() }];
      },
    };
  }

  // @command(toggleWPageBlockOptions)
  // toggleWPageBlock(attrs?: WPageBlockAttributes): CommandFunction {
  //   // return toggleWrap(this.type, attrs);
  //   if (this.options.type === 'insert') {
  //     return this.store.commands.insertNode.original(this.type, { attrs });
  //   }
  //   return this.store.commands.setBlockNodeType.original(this.type, attrs);
  // }

  /**
   * Inserts a horizontal line into the editor.
   */
  @command(toggleWPageBlockOptions)
  toggleWPageBlock(attrs?: WPageBlockAttributes): CommandFunction {
    // return updateNodeAttributes(this.type)(attrs, pos);
    return (props) => {
      // if (!isValidCalloutExtensionAttributes(attributes)) {
      //   throw new Error('Invalid attrs passed to the updateAttributes method');
      // }
      const {
        state: { tr, selection, schema, doc },
        dispatch,
      } = props;

      const parent = findParentNodeOfType({
        types: [this.type, schema.nodes.wHeading, schema.nodes.wPara],
        selection,
      });

      if (!parent || isEqual(attrs, parent.node.attrs)) {
        // Do nothing since the attrs are the same
        // return false;
        return toggleWrap(this.type, attrs)(props);
      }

      tr.replaceWith(parent.pos, parent.node.nodeSize, this.type.create(attrs));

      // tr.setNodeMarkup(parent.pos, this.type, {
      //   ...parent.node.attrs,
      //   ...attrs,
      // });

      if (dispatch) {
        dispatch(tr);
      }

      return true;
    };
    // return (props) => {
    //   const { tr, dispatch } = props;
    //   const $pos = tr.selection.$anchor;
    //   const initialParent = $pos.parent;
    //
    //   if (initialParent.type.name === 'doc' || initialParent.isAtom || initialParent.isLeaf) {
    //     return false;
    //   }
    //
    //   if (!dispatch) {
    //     return true;
    //   }
    //
    //   // A boolean value that is true when the current node is empty and
    //   // should be duplicated before the replacement of the current node by
    //   // the `hr`.
    //   const shouldDuplicateEmptyNode = tr.selection.empty && isEmptyBlockNode(initialParent);
    //
    //   // When the node should eb duplicated add it to the position after
    //   // before the replacement.
    //   if (shouldDuplicateEmptyNode) {
    //     tr.insert($pos.pos + 1, initialParent);
    //   }
    //
    //   // Create the horizontal rule by replacing the selection
    //   tr.replaceSelectionWith(this.type.create(attrs));
    //
    //   // Update the selection if currently pointed at the node.
    //   this.updateFromNodeSelection(tr);
    //
    //   dispatch(tr.scrollIntoView());
    //
    //   return true;
    // };
  }

  /**
   * Updates the transaction after a `horizontalRule` has been inserted to make
   * sure the currently selected node isn't a Horizontal Rule.
   *
   * This should only be called for empty selections.
   */
  private updateFromNodeSelection(tr: Transaction): void {
    // Make sure  the `horizontalRule` that is selected. Otherwise do nothing.
    if (!isNodeSelection(tr.selection) || tr.selection.node.type.name !== this.name) {
      return;
    }

    // Get the position right after the current selection for inserting the
    // node.
    const pos = tr.selection.$from.pos + 1;
    const { insertionNode } = this.options;

    // If `insertionNode` was set to false, then don't insert anything.
    if (!insertionNode) {
      return;
    }

    const type = this.store.schema.nodes[insertionNode];

    invariant(type, {
      code: ErrorConstant.EXTENSION,
      message: `'${insertionNode}' node provided as the insertionNode to the '${this.constructorName}' does not exist.`,
    });

    // Insert the new node
    const node = type.create();
    tr.insert(pos, node);

    // Set the new selection to be inside the inserted node.
    tr.setSelection(TextSelection.near(tr.doc.resolve(pos + 1)));
  }

  @command(toggleWPageBlockOptions)
  updateWPageBlock(attrs?: WPageBlockAttributes, pos?: number): CommandFunction {
    // return updateNodeAttributes(this.type)(attrs, pos);
    return ({ state: { tr, selection, doc }, dispatch }) => {
      tr.setNodeMarkup(selection.from, this.type, {
        ...attrs,
      });

      if (dispatch) {
        dispatch(tr);
      }

      return true;
    };
  }

  // @keyBinding({ shortcut: 'Enter' })
  // handleEnterKey({ dispatch, tr, state }: KeyBindingProps): boolean {
  //   if (!(isTextSelection(tr.selection) && tr.selection.empty)) {
  //     return false;
  //   }
  //
  //   const { nodeBefore, nodeAfter, parent } = tr.selection.$from;
  //
  //   if (!nodeBefore?.isText || !parent.type.isTextblock) {
  //     return false;
  //   }
  //
  //   if (!nodeAfter?.isText || !parent.type.isTextblock) {
  //     const pos = tr.selection.$from.after();
  //     const end = pos + 1;
  //     // +1 to account for the extra pos a node takes up
  //
  //     if (dispatch) {
  //       const slice = new Slice(Fragment.from(state.schema.nodes.wTextBlock.create()), 0, 1);
  //       tr.replace(pos, end, slice);
  //
  //       // Set the selection to within the callout
  //       tr.setSelection(TextSelection.near(tr.doc.resolve(pos + 1)));
  //       dispatch(tr);
  //     }
  //
  //     return true;
  //   }
  //
  //   const regex = /^:::([A-Za-z]*)?$/;
  //   const { text, nodeSize } = nodeBefore;
  //   const { textContent } = parent;
  //
  //   if (!text) {
  //     return false;
  //   }
  //
  //   const matchesNodeBefore = text.match(regex);
  //   const matchesParent = textContent.match(regex);
  //
  //   if (!matchesNodeBefore || !matchesParent) {
  //     return false;
  //   }
  //
  //   const pos = tr.selection.$from.before();
  //   const end = pos + nodeSize + 1;
  //   // +1 to account for the extra pos a node takes up
  //
  //   if (dispatch) {
  //     const slice = new Slice(Fragment.from(this.type.create({ type: 'wTextBlock' })), 0, 1);
  //     tr.replace(pos, end, slice);
  //
  //     // Set the selection to within the callout
  //     tr.setSelection(TextSelection.near(tr.doc.resolve(pos + 1)));
  //     dispatch(tr);
  //   }
  //
  //   return true;
  // }
  //
  // @keyBinding({ shortcut: 'Backspace' })
  // handleBackspace({ dispatch, tr }: KeyBindingProps): boolean {
  //   // Aims to stop merging callouts when deleting content in between
  //
  //   // If the selection is not empty return false and let other extension
  //   // (ie: BaseKeymapExtension) to do the deleting operation.
  //   if (!tr.selection.empty) {
  //     return false;
  //   }
  //
  //   const { $from } = tr.selection;
  //
  //   // If not at the start of current node, no joining will happen
  //   if ($from.parentOffset !== 0) {
  //     return false;
  //   }
  //
  //   const previousPosition = $from.before($from.depth) - 1;
  //
  //   // If nothing above to join with
  //   if (previousPosition < 1) {
  //     return false;
  //   }
  //
  //   const previousPos = tr.doc.resolve(previousPosition);
  //
  //   // If resolving previous position fails, bail out
  //   if (!previousPos?.parent) {
  //     return false;
  //   }
  //
  //   const previousNode = previousPos.parent;
  //   const { node, pos } = findNodeAtSelection(tr.selection);
  //
  //   // If previous node is a callout, cut current node's content into it
  //   if (node.type !== this.type && previousNode.type === this.type) {
  //     const { content, nodeSize } = node;
  //     tr.delete(pos, pos + nodeSize);
  //     tr.setSelection(TextSelection.near(tr.doc.resolve(previousPosition - 1)));
  //     tr.insert(previousPosition - 1, content);
  //
  //     if (dispatch) {
  //       dispatch(tr);
  //     }
  //
  //     return true;
  //   }
  //
  //   return false;
  // }

  @keyBinding({ shortcut: TOGGLE_COMMAND_SHORTCUT, command: 'toggleWPageBlock' })
  shortcut(props: KeyBindingProps): boolean {
    return this.toggleWPageBlock()(props);
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wPageBlock: WPageBlockExtension;
    }
  }
}
