import React, { useCallback, useState } from 'react';
import { Button, Flex } from 'antd';
import Icon, {
  DownloadOutlined,
  FileTextOutlined,
  LeftOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ReadOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { useEpubReaderStore } from './EpubReaderStoreProvider';
import { FlexListTemplate } from '../../../../components';
import { ReactComponent as EpubIcon } from '../../../../assets/images/egw_font/epub.svg';
import { IEpubReaderProps } from './EpubReader';
import { useEpubReaderActions } from './EpubReaderActionsProvider';
import { useMobileDetect } from '../../../../hooks';

export type IEpubReaderHeaderProps = IEpubReaderProps;

const Component: React.FC<IEpubReaderHeaderProps> = (props) => {
  const { url, extra } = props;
  const { bookRef } = useEpubReaderStore();
  const {
    isOpenToc,
    spread,
    handleSpreadChange,
    handleTocOpenChange,
    isDisableBtnGoPrevPage,
    isDisableBtnGoNextPage,
    handlePrevPageChange,
    handleNextPageChange,
  } = useEpubReaderActions();
  const { isDesktop } = useMobileDetect();

  return (
    <Flex justify="space-between" wrap="wrap">
      <Flex style={{ width: 'auto', overflow: 'hidden' }} wrap="nowrap" gap={8}>
        <Icon component={EpubIcon} />
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {bookRef.current?.book?.packaging?.metadata?.title}
        </div>
      </Flex>
      <Flex style={{ width: 'auto' }} gap={5} wrap="nowrap">
        <Button
          disabled={!bookRef.current?.state.isLoaded || isDisableBtnGoPrevPage}
          icon={<LeftOutlined />}
          onClick={handlePrevPageChange}
        />
        <Button
          disabled={!bookRef.current?.state.isLoaded || isDisableBtnGoNextPage}
          icon={<RightOutlined />}
          onClick={handleNextPageChange}
        />
        <Button
          icon={isOpenToc ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
          onClick={() => {
            handleTocOpenChange();
          }}
        />
        {isDesktop() && (
          <Button
            disabled={!bookRef.current?.state.isLoaded || isDisableBtnGoNextPage}
            icon={spread === 'none' ? <ReadOutlined /> : <FileTextOutlined />}
            onClick={() => {
              handleSpreadChange();
            }}
          />
        )}
        <Button icon={<DownloadOutlined />} href={url} />
      </Flex>
      {extra}
    </Flex>
  );
};

export const EpubReaderHeader: React.FC<IEpubReaderHeaderProps> = (props) => {
  return <Component {...props} />;
};
