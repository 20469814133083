import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useCreateSubFolderAction } from './useCreateSubFolderAction';
import { useChangeFolderTypeAction } from './useChangeFolderTypeAction';
import { useDeleteFolderAction } from './useDeleteFolderAction';
import { useCreateRootFolderAction } from './useCreateRootFolderAction';
import { useRenameFolderAction } from './useRenameFolderAction';
import { ManagerFolderTreeListItemFragment } from '../../../../../services/apolloGraphqlAppApiClient/models/types';

export interface IUseFolderActionsProps {
  folderData: ManagerFolderTreeListItemFragment;
}

export type IUseFolderActionsResult = ItemType[];
export const useFolderActions = (props: IUseFolderActionsProps): IUseFolderActionsResult => {
  const { folderData } = props;
  const { createSubFolderAction } = useCreateSubFolderAction({ folderData });
  const { createRootFolderAction } = useCreateRootFolderAction({ folderData });
  const { renameFolderAction } = useRenameFolderAction({ folderData });
  const { deleteFolderAction } = useDeleteFolderAction({ folderData });
  const { changeFolderTypeAction } = useChangeFolderTypeAction({ folderData });

  return [
    {
      key: 'folderName',
      label: folderData.id === 0 ? 'Root' : folderData.title,
      type: 'group',
    },
    createSubFolderAction,
    createRootFolderAction,
    renameFolderAction,
    changeFolderTypeAction,
    deleteFolderAction,
  ];
};
