import { App, ModalFuncProps } from 'antd';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { FlexListTemplate } from '../../../../../components';
import {
  useMovePublicationMutation,
  MovePublicationInput,
} from '../../../../../services/apolloGraphqlAppApiClient/models/types';
import { ManagerFolderTreeNodeType } from '../../Folders';
import { TManagerPublicationListItem } from '../usePublicationListStore';
import { usePublicationListStoreContext } from '../PublicationListStoreProvider';

export interface IUseMovePublicationActionResult {
  movePublicationAction: (...args: [MovePublicationInput, IMovePublicationActionOptions]) => void;
}

export type IMovePublicationActionOptions = {
  dragTitle: TManagerPublicationListItem['title'];
  dropTitle: ManagerFolderTreeNodeType['title'];
};

export const useMovePublicationAction = (): IUseMovePublicationActionResult => {
  const { refetch } = usePublicationListStoreContext();
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();

  const [movePublication] = useMovePublicationMutation({
    onCompleted: (result) => {
      info({
        placement: 'topRight',
        message: `Move publication status: true`,
        description: (
          <FlexListTemplate gap={5} alignItems="left" justifyContent="left" style={{ flexDirection: 'column' }}>
            ¦{/* info */}
          </FlexListTemplate>
        ),
        key: uuidV4(),
        duration: 7,
      });
      refetch(
        result.movePublication.publicationDto?.order ? result.movePublication.publicationDto.order - 1 : undefined,
      );
    },
    onError: (e) => {
      return e;
    },
  });

  const handleOk: ModalFuncProps['onOk'] = async (values: MovePublicationInput) => {
    await movePublication({
      variables: {
        input: { ...values },
      },
    });
  };

  const movePublicationAction: IUseMovePublicationActionResult['movePublicationAction'] = (values, options) => {
    const { dragTitle, dropTitle } = options;
    confirm({
      title: `Move ${dragTitle} Publication!`,
      onOk: () => handleOk(values),
      okText: 'Move',
      content: `Are you sure you want to move "${dragTitle}" in "${dropTitle}"`,
    });
  };

  return {
    movePublicationAction,
  };
};
