import React, { FC } from 'react';

import { extractUrl, useWLinkState } from '../../extensions';
import { ToggleWLinkForm } from '../forms';
import { CommandPopoverButton, ICommandPopoverButtonProps } from './CommandPopoverButton';

export type IToggleWLinkButtonProps = Omit<
  ICommandPopoverButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect' | 'popoverContent'
>;
export const ToggleWLinkButton: FC<IToggleWLinkButtonProps> = (props) => {
  const { attrs, active, enabled, wLinkShortcutHandlerProps, isEditing, handleClickEdit, handleRemove, handleSubmit } =
    useWLinkState();

  const initialValues = {
    ...(attrs?.href ? { href: extractUrl({ url: attrs.href }).url } : {}),
    ...(attrs?.title ? { title: attrs.title } : {}),
  };

  return (
    <CommandPopoverButton
      {...props}
      commandName="toggleWLink"
      active={active}
      enabled={enabled}
      onSelect={handleClickEdit}
      popoverContent={
        <ToggleWLinkForm
          initialOption={isEditing && attrs?.href ? extractUrl({ url: attrs.href })?.protocol : ''}
          initialValues={isEditing ? initialValues : undefined}
          onSubmit={handleSubmit}
          onRemove={active ? handleRemove : undefined}
        />
      }
    />
  );
};
