import { CreateExtensionPlugin, environment, KeyBindings, PlainExtension } from '@remirror/core';

import { dedentListCommand, indentListCommand, listBackspace, maybeJoinList } from './commands';

/**
 * Provides some shared thing used by both `listItem` and `taskListItem`
 */
export class WListItemSharedExtension extends PlainExtension {
  get name() {
    return 'wListItemShared' as const;
  }

  createKeymap(): KeyBindings {
    const pcKeymap = {
      Tab: indentListCommand,
      'Shift-Tab': dedentListCommand,
      Backspace: listBackspace,
      'Mod-Backspace': listBackspace,
    };

    if (environment.isMac) {
      const macKeymap = {
        'Ctrl-h': pcKeymap.Backspace,
        'Alt-Backspace': pcKeymap['Mod-Backspace'],
      };
      return { ...pcKeymap, ...macKeymap };
    }

    return pcKeymap;
  }

  createPlugin(): CreateExtensionPlugin {
    return {
      appendTransaction: (_transactions, _oldState, newState) => {
        const { tr } = newState;
        const updated = maybeJoinList(tr);
        return updated ? tr : null;
      },
    };
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wListItemShared: WListItemSharedExtension;
    }
  }
}
