import { Box, Menu, MenuProps, Stack } from '@mui/material';
import { uniqueId } from '@remirror/core';
import { Icon } from '@remirror/react';
import React, { FC, MouseEventHandler, useCallback, useRef, useState } from 'react';

import { CommandButton, ICommandButtonProps } from './CommandButton';

export interface ICommandDropdownButtonProps extends Omit<MenuProps, 'open' | 'anchorEl' | 'id'> {
  commandButtonProps: Omit<ICommandButtonProps, 'onSelect'>;
}

export const CommandDropdownButton: FC<ICommandDropdownButtonProps> = ({
  children,
  onClose,
  commandButtonProps,
  ...rest
}) => {
  const id = useRef<string>(uniqueId());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMouseDown: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleClick: MouseEventHandler<HTMLElement> = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose: MenuProps['onClose'] = useCallback(
    (e: Event, reason: 'backdropClick' | 'escapeKeyDown') => {
      setAnchorEl(null);
      onClose?.(e, reason);
    },
    [onClose],
  );

  const handleCloseClick: MenuProps['onClick'] = useCallback(
    (e) => {
      setAnchorEl(null);
    },
    [onClose],
  );

  return (
    <>
      <CommandButton
        {...commandButtonProps}
        onMouseDown={handleMouseDown}
        onClick={handleClick}
        onSelect={() => null}
        icon={
          <Stack spacing={1} direction="row">
            <Box sx={{ lineHeight: 'normal', minWidth: '5em' }}>{commandButtonProps.icon}</Box>
            <Icon name="arrowDownSFill" size="1rem" />
          </Stack>
        }
      />
      <Menu
        {...rest}
        id={id.current}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleCloseClick}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: { width: anchorEl && anchorEl.offsetWidth },
        }}
      >
        {children}
      </Menu>
    </>
  );
};
