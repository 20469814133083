import React, { useEffect, useState } from 'react';
import { Button, Result } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { PageTemplate } from '../components';

export interface IResultPageProps {
  path?: string;
  backTo?: string;
  backToPath?: string;
  status?: 403 | 404 | 500 | '403' | '404' | '500' | 'success' | 'error' | 'info' | 'warning' | undefined | any;
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
  className?: string;
  redirectTimer?: number;
  clearError?: boolean;
}

const getMessage = (status: any) => {
  if (['401', '403', 401, 403].includes(status)) {
    return 'Sorry, you are not authorized to access this page.';
  }
  if (['404', 404].includes(status)) {
    return 'Sorry, you request data not found';
  }

  if (['500', 500].includes(status)) {
    return 'Sorry, we have a problem with server.';
  }

  return 'Sorry, something went wrong.';
};

const getDate = (timer: number): Date => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + timer);
  return time;
};

export const ResultPage = (props: IResultPageProps): React.ReactElement => {
  const navigate = useNavigate();
  const {
    title = 'Wrong',
    path = '/',
    backTo = 'Home',
    backToPath = '/',
    status = 'warning',
    clearError = false,
    subTitle,
    redirectTimer = 10,
    children,
    className,
  } = props;

  const [isTimeOut, setIsTimeOut] = useState(false);

  const goBackHandler = () => {
    if (window.history.state) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  };

  const { seconds } = useTimer({
    expiryTimestamp: getDate(redirectTimer || 0),
    onExpire: () => redirectTimer && setIsTimeOut(true),
  });

  useEffect(() => {
    if (isTimeOut) {
      goBackHandler();
    }
  }, [isTimeOut]);

  return (
    <PageTemplate title={title} hideBreadcrumbs>
      {/* {isTimeOut && <Redirect to={`${path}`} />} */}
      <Result
        status={status}
        title={title}
        subTitle={subTitle || getMessage(status)}
        extra={
          <>
            <Link
              to={{
                pathname: backToPath,
              }}
            >
              <Button type="primary">Back to {backTo}</Button>
            </Link>
            {/* <Link */}
            {/*  to={{ */}
            {/*    pathname: path, */}
            {/*  }} */}
            {/* > */}
            <Button type="primary" onClick={() => goBackHandler()}>
              Back to last page {redirectTimer ? seconds : ''}
            </Button>
            {/* </Link> */}
          </>
        }
      />
    </PageTemplate>
  );
};
