import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  CommandFunctionProps,
  composeTransactionSteps,
  CreateExtensionPlugin,
  EditorState,
  extension,
  ExtensionPriority,
  ExtensionTag,
  findMatches,
  FromToProps,
  getChangedRanges,
  GetMarkRange,
  getMarkRange,
  getMatchString,
  getSelectedWord,
  getTextSelection,
  Handler,
  includes,
  isAllSelection,
  isElementDomNode,
  isMarkActive,
  isSelectionEmpty,
  isTextSelection,
  keyBinding,
  KeyBindingProps,
  last,
  LiteralUnion,
  MarkExtension,
  MarkExtensionSpec,
  MarkSpecOverride,
  NodeWithPosition,
  omitExtraAttributes,
  ProsemirrorAttributes,
  ProsemirrorNode,
  removeMark,
  Static,
  updateMark,
} from '@remirror/core';
import { CoreIcon } from '@remirror/icons';
import { undoDepth } from '@remirror/pm/history';
import { MarkPasteRule } from '@remirror/pm/paste-rules';
import { Selection, TextSelection } from '@remirror/pm/state';
import { ReplaceAroundStep, ReplaceStep } from '@remirror/pm/transform';
import extractDomain from 'extract-domain';

import type { CreateEventHandlers } from '@remirror/extension-events';

import { WLangAttributes, WLangViewClickHandlerProps } from '../WLangExtension';

const TOGGLE_COMMAND_LABEL = 'Toggle Link';
const TOGGLE_COMMAND_DESCRIPTION = 'Toggle Link Attributes';
const TOGGLE_COMMAND_SHORTCUT = 'Mod-L';

const toggleWLinkOptions: Remirror.CommandDecoratorOptions = {
  icon: 'link',
  label: ({ t }) => t(TOGGLE_COMMAND_LABEL),
  description: ({ t }) => t(TOGGLE_COMMAND_DESCRIPTION),
  shortcut: TOGGLE_COMMAND_SHORTCUT,
};

const REMOVE_COMMAND_LABEL = 'Toggle Link';
const REMOVE_COMMAND_DESCRIPTION = 'Toggle Link Attributes';
const REMOVE_COMMAND_SHORTCUT = 'Mod-L';

const removeWLinkOptions: Remirror.CommandDecoratorOptions = {
  label: ({ t }) => t(REMOVE_COMMAND_LABEL),
  description: ({ t }) => t(REMOVE_COMMAND_DESCRIPTION),
  // shortcut: REMOVE_COMMAND_SHORTCUT,
};

export const TOP_50_TLDS = [
  'com',
  'de',
  'net',
  'org',
  'uk',
  'cn',
  'ga',
  'nl',
  'cf',
  'ml',
  'tk',
  'ru',
  'br',
  'gq',
  'xyz',
  'fr',
  'eu',
  'info',
  'co',
  'au',
  'ca',
  'it',
  'in',
  'ch',
  'pl',
  'es',
  'online',
  'us',
  'top',
  'be',
  'jp',
  'biz',
  'se',
  'at',
  'dk',
  'cz',
  'za',
  'me',
  'ir',
  'icu',
  'shop',
  'kr',
  'site',
  'mx',
  'hu',
  'io',
  'cc',
  'club',
  'no',
  'cyou',
];

const TOGGLE_LINK = 'toggleWLink';

// Based on https://gist.github.com/dperini/729294
const DEFAULT_AUTO_LINK_REGEX =
  /(?:(?:(?:https?|ftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?:[\da-z\u00A1-\uFFFF][\w\u00A1-\uFFFF-]{0,62})?[\da-z\u00A1-\uFFFF]\.)*(?:(?:\d(?!\.)|[a-z\u00A1-\uFFFF])(?:[\da-z\u00A1-\uFFFF][\w\u00A1-\uFFFF-]{0,62})?[\da-z\u00A1-\uFFFF]\.)+[a-z\u00A1-\uFFFF]{2,}(?::\d{2,5})?(?:[#/?]\S*)?/gi;

/**
 * Can be an empty string which sets url's to '//google.com'.
 */
export type DefaultProtocol = 'http://' | 'https://' | 'egw://book/' | 'egw://bible/' | 'mailto://' | '#' | string;

export interface IwLinkCommandOptions {
  title: string;
  prefix?: DefaultProtocol;
  attrs: string[];
}

export const wLinkCommandOptions: IwLinkCommandOptions[] = [
  { title: 'EGW Book', prefix: 'egw://book/', attrs: ['href', 'title'] },
  { title: 'Bible', prefix: 'egw://bible/', attrs: ['href', 'title'] },
  { title: 'Https', prefix: 'https://', attrs: ['href'] },
  { title: 'Http', prefix: 'http://', attrs: ['href'] },
  { title: 'eMail', prefix: 'mailto://', attrs: ['href'] },
  { title: 'WAnchor', prefix: '#', attrs: ['href'] },
];

export interface FoundAutoWLink {
  /** link href */
  href: string;
  /** link text */
  text: string;
  /** offset of matched text */
  start: number;
  /** index of next char after match end */
  end: number;
}

interface WLinkWithProperties extends Omit<FoundAutoWLink, 'href'> {
  range: FromToProps;
  attrs: WLinkAttributes;
}

interface EventMeta {
  selection: Selection;
  range: FromToProps | undefined;
  doc: ProsemirrorNode;
  attrs: WLinkAttributes;
}

interface ShortcutHandlerActiveWLink extends FromToProps {
  attrs: WLinkAttributes;
}

export interface ShortcutHandlerProps extends FromToProps {
  selectedText: string;
  activeWLink: ShortcutHandlerActiveWLink | undefined;
}

type WLinkTarget = LiteralUnion<'_blank' | '_self' | '_parent' | '_top', string> | null;

export interface WLinkClickData extends GetMarkRange, WLinkAttributes {}

export interface WLinkViewClickHandlerProps {
  event: MouseEvent;
  markWithPosition?: WLinkClickData;
}

export interface WLinkOptions {
  onViewClick?: Handler<(props: WLinkViewClickHandlerProps) => boolean | undefined | void>;

  /**
   * @deprecated use `onShortcut` instead
   */
  onActivateWLink?: Handler<(selectedText: string) => void>;

  /**
   * Called when the user activates the keyboard shortcut.
   *
   * It is called with the active link in the selected range, if it exists.
   *
   * If multiple links exist within the range, only the first is returned. I'm
   * open to PR's if you feel it's important to capture all contained links.
   *
   * @defaultValue undefined
   */
  onShortcut?: Handler<(props: ShortcutHandlerProps) => void>;

  /**
   * Called after the `commands.updateWLink` has been called.
   *
   * @defaultValue undefined
   */
  onUpdateWLink?: Handler<(selectedText: string, meta: EventMeta) => void>;

  /**
   * Whether to select the text of the full active link when clicked.
   *
   * @defaultValue false
   */
  selectTextOnClick?: boolean;

  /**
   * Listen to click events for links.
   */
  onClick?: Handler<(event: MouseEvent, data: WLinkClickData) => boolean>;

  /**
   * Extract the `href` attribute from the provided `url` text.
   *
   * @remarks
   *
   * By default this will return the `url` text with a `${defaultProtocol}//` or
   * `mailto:` prefix if needed.
   */
  extractHref?: Static<(props: { url: string; defaultProtocol: DefaultProtocol }) => string>;

  /**
   * Whether the link is opened when being clicked.
   *
   * @deprecated use `onClick` handler instead.
   */
  openWLinkOnClick?: boolean;

  /**
   * Whether automatic links should be created.
   *
   * @defaultValue false
   */
  autoWLink?: boolean;

  /**
   * The regex matcher for matching against the RegExp. The matcher must capture
   * the URL part of the string as it's first match. Take a look at the default
   * value.
   *
   * @default
   * /(?:(?:(?:https?|ftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?:[\da-z\u00A1-\uFFFF][\w\u00A1-\uFFFF-]{0,62})?[\da-z\u00A1-\uFFFF]\.)*(?:(?:\d(?!\.)|[a-z\u00A1-\uFFFF])(?:[\da-z\u00A1-\uFFFF][\w\u00A1-\uFFFF-]{0,62})?[\da-z\u00A1-\uFFFF]\.)+[a-z\u00A1-\uFFFF]{2,}(?::\d{2,5})?(?:[#/?]\S*)?/gi
   */
  autoWLinkRegex?: Static<RegExp>;

  /**
   * An array of valid Top Level Domains (TLDs) to limit the scope of auto linking.
   *
   * @remarks
   *
   * The default autoWLinkRegex does not limit the TLD of a URL for performance and maintenance reasons.
   * This can lead to the auto link behaviour being overly aggressive.
   *
   * Defaults to the top 50 TLDs (as of May 2022).
   *
   * If you find this too permissive, you can override this with an array of your own TLDs.  i.e. you could use the top
   *     10 TLDs.
   *
   * ['com', 'de', 'net', 'org', 'uk', 'cn', 'ga', 'nl', 'cf', 'ml']
   *
   * However, this would prevent auto linking to domains like remirror.io!
   *
   * For a complete list of TLDs, you could use an external package like "tlds" or "global-tld-list"
   *
   * Or to extend the default list you could
   *
   * ```ts
   * import { WLinkExtension, TOP_50_TLDS } from 'remirror/extensions';
   * const extensions = () => [
   *   new WLinkExtension({ autoWLinkAllowedTLDs: [...TOP_50_TLDS, 'london', 'tech'] })
   * ];
   * ```
   *
   * @defaultValue the top 50 TLDs by usage (May 2022)
   */
  autoWLinkAllowedTLDs?: Static<string[]>;

  /**
   * Returns a list of links found in string where each element is a hash
   * with properties { href: string; text: string; start: number; end: number; }
   *
   * @remarks
   *
   * This function is used instead of matching links with the autoWLinkRegex option.
   *
   * @default null
   *
   * @param {string} input
   * @param {string} defaultProtocol
   * @returns {array} FoundAutoWLink[]
   */
  findAutoWLinks?: Static<(input: string, defaultProtocol: string) => FoundAutoWLink[]>;

  /**
   * Check if the given string is a link
   *
   * @remarks
   *
   * Used instead of validating a link with the autoWLinkRegex and autoWLinkAllowedTLDs option.
   *
   * @default null
   *
   * @param {string} input
   * @param {string} defaultProtocol
   * @returns {boolean}
   */
  isValidUrl?: Static<(input: string, defaultProtocol: string) => boolean>;

  /**
   * The default protocol to use when it can't be inferred.
   *
   * @defaultValue ''
   */
  defaultProtocol?: DefaultProtocol;

  /**
   * The default target to use for links.
   *
   * @defaultValue null
   */
  defaultTarget?: WLinkTarget;

  /**
   * The supported targets which can be parsed from the DOM or added with
   * `insertWLink`.
   *
   * @defaultValue []
   */
  supportedTargets?: WLinkTarget[];
}

export type WLinkAttributes = ProsemirrorAttributes<{
  /**
   * The link which is a required property for the link mark.
   */
  href: string;

  /**
   * The link title required only egw:  link protocols.
   */
  title?: string;
  /**
   * True when this was an automatically generated link. False when the link was
   * added specifically by the user.
   *
   * @defaultValue false
   */
  auto?: boolean;

  /**
   * The target for the link..
   */
  target?: WLinkTarget;
}>;

@extension<WLinkOptions>({
  defaultOptions: {
    autoWLink: false,
    defaultProtocol: '',
    selectTextOnClick: false,
    openWLinkOnClick: false,
    autoWLinkRegex: DEFAULT_AUTO_LINK_REGEX,
    autoWLinkAllowedTLDs: TOP_50_TLDS,
    findAutoWLinks: undefined,
    isValidUrl: undefined,
    defaultTarget: null,
    supportedTargets: [],
    extractHref,
  },
  staticKeys: ['autoWLinkRegex'],
  handlerKeyOptions: { onClick: { earlyReturnValue: true } },
  handlerKeys: ['onActivateWLink', 'onShortcut', 'onUpdateWLink', 'onClick', 'onViewClick'],
  defaultPriority: ExtensionPriority.Medium,
})
export class WLinkExtension extends MarkExtension<WLinkOptions> {
  get name() {
    return 'wLink' as const;
  }

  /**
   * The autoWLinkRegex option with the global flag removed, ensure no "lastIndex" state is maintained over multiple
   * matches
   * @private
   */
  private _autoWLinkRegexNonGlobal: RegExp | undefined = undefined;

  createTags() {
    return [ExtensionTag.Link, ExtensionTag.ExcludeInputRules];
  }

  createMarkSpec(extra: ApplySchemaAttributes, override: MarkSpecOverride): MarkExtensionSpec {
    const AUTO_ATTRIBUTE = 'data-link-auto';

    const getTargetObject = (target: string | null | undefined): { target: string } | undefined => {
      const { defaultTarget, supportedTargets } = this.options;
      const targets = defaultTarget ? [...supportedTargets, defaultTarget] : supportedTargets;
      return target && includes(targets, target) ? { target } : undefined;
    };

    return {
      inclusive: false,
      excludes: '_',
      ...override,
      attrs: {
        ...extra.defaults(),
        href: {},
        title: { default: null },
        // target: { default: this.options.defaultTarget },
        // auto: { default: false },
      },
      parseDOM: [
        {
          tag: 'a[href]',
          getAttrs: (node) => {
            if (!isElementDomNode(node)) {
              return false;
            }

            const href = node.getAttribute('href');
            const title = node.getAttribute('title');
            const text = node.textContent;

            // If link text content equals href value we "auto link"
            // e.g [test](//test.com) - not "auto link"
            // e.g [test.com](//test.com) - "auto link"
            const auto =
              this.options.autoWLink &&
              (node.hasAttribute(AUTO_ATTRIBUTE) ||
                href === text ||
                href?.replace(`${this.options.defaultProtocol}`, '') === text);

            return {
              ...extra.parse(node),
              href,
              title,
              // auto,
              // ...getTargetObject(node.getAttribute('target')),
            };
          },
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (node) => {
        const {
          // auto: _,
          // target: __,
          ...rest
        } = omitExtraAttributes(node.attrs, extra);
        // const auto = node.attrs.auto ? { [AUTO_ATTRIBUTE]: '' } : {};
        const attrs = {
          ...extra.dom(node),
          ...rest,
          // ...auto,
          // ...getTargetObject(node.attrs.target),
        };

        return ['a', attrs, 0];
      },
    };
  }

  onCreate(): void {
    const { autoWLinkRegex } = this.options;
    // Remove the global flag from autoWLinkRegex, and wrap in start (^) and end ($) terminator to test for exact match
    this._autoWLinkRegexNonGlobal = new RegExp(`^${autoWLinkRegex.source}$`, autoWLinkRegex.flags.replace('g', ''));
  }

  /**
   * Add a handler to the `onActivateWLink` to capture when .
   */
  @keyBinding({ shortcut: '_|wAnchor|_' })
  shortcut({ tr }: KeyBindingProps): boolean {
    let selectedText = '';
    // eslint-disable-next-line prefer-const
    let { from, to, empty, $from } = tr.selection;
    let expandedSelection = false;
    const mark = getMarkRange($from, this.type);

    // When the selection is empty, expand it to the active mark
    if (empty) {
      const selectedWord = mark ?? getSelectedWord(tr);

      if (!selectedWord) {
        return false;
      }

      ({ text: selectedText, from, to } = selectedWord);
      expandedSelection = true;
    }

    if (from === to) {
      return false;
    }

    if (!expandedSelection) {
      selectedText = tr.doc.textBetween(from, to);
    }

    this.options.onActivateWLink(selectedText);
    this.options.onShortcut({
      activeWLink: mark ? { attrs: mark.mark.attrs as WLinkAttributes, from: mark.from, to: mark.to } : undefined,
      selectedText,
      from,
      to,
    });

    return true;
  }

  /**
   * Create or update the link if it doesn't currently exist at the current
   * selection or provided range.
   */
  @command(toggleWLinkOptions)
  toggleWLink(attrs?: WLinkAttributes, range?: FromToProps): CommandFunction {
    return (props) => {
      const { tr } = props;
      const selectionIsValid =
        (isTextSelection(tr.selection) && !isSelectionEmpty(tr.selection)) ||
        isAllSelection(tr.selection) ||
        isMarkActive({ trState: tr, type: this.type });

      if (!selectionIsValid && !range) {
        if (!attrs?.href) {
          return false;
        }
        return this.toggleAutoWLink(attrs, props);
      }

      tr.setMeta(this.name, {
        command: TOGGLE_LINK,
        attrs,
        range,
      });
      return updateMark({ type: this.type, attrs, range })(props);
    };
  }

  private toggleAutoWLink = (attrs: WLinkAttributes, props: CommandFunctionProps): boolean => {
    const { tr } = props;
    const text = attrs?.title || attrs.href;
    const textNode = this.type.schema.text(text);
    const { selection } = tr
      .replaceSelectionWith(textNode)
      .scrollIntoView()
      .setSelection(TextSelection.near(tr.doc.resolve(tr.selection.anchor - text.length)));
    const { from } = getTextSelection(selection, tr.doc);
    tr.setMeta(this.name, {
      command: TOGGLE_LINK,
      attrs,
      range: { from, to: from + text.length },
    })
      .scrollIntoView()
      .setSelection(TextSelection.near(tr.doc.resolve(tr.selection.anchor - text.length)));
    return updateMark({ type: this.type, attrs, range: { from, to: from + text.length } })(props);
  };

  /**
   * Select the link at the current location.
   */
  @command()
  selectWLink(): CommandFunction {
    return this.store.commands.selectMark.original(this.type);
  }

  /**
   * Remove the link at the current selection
   */
  @command(removeWLinkOptions)
  removeWLink(range?: FromToProps): CommandFunction {
    return (props) => {
      const { tr } = props;

      if (!isMarkActive({ trState: tr, type: this.type, ...range })) {
        return false;
      }

      return removeMark({ type: this.type, expand: true, range })(props);
    };
  }

  /**
   * Create the paste rules that can transform a pasted link in the document.
   */
  createPasteRules(): MarkPasteRule[] {
    return [
      {
        type: 'mark',
        regexp: this.options.autoWLinkRegex,
        markType: this.type,
        getAttributes: (url, isReplacement) => ({
          href: this.buildHref(getMatchString(url)),
          auto: !isReplacement,
        }),
        transformMatch: (match) => {
          const url = getMatchString(match);

          if (!url) {
            return false;
          }

          if (!this.isValidUrl(url)) {
            return false;
          }

          return url;
        },
      },
    ];
  }

  /**
   * Track click events passed through to the editor.
   */
  createEventHandlers(): CreateEventHandlers {
    return {
      clickMark: (event, clickState) => {
        const markRange = clickState.getMark(this.type);

        if (!markRange) {
          return this.options.onViewClick({ event });
        }

        const attrs = markRange.mark.attrs as WLinkAttributes;
        const data: WLinkClickData = { ...attrs, ...markRange };

        if (!data || data.mark.type !== this.type) {
          this.options.onViewClick({ event });
        }

        this.options.onViewClick({ event, markWithPosition: data });

        // If one of the handlers returns `true` then return early.
        if (this.options.onClick(event, data)) {
          return true;
        }

        let handled = false;

        if (this.options.openWLinkOnClick) {
          handled = true;
          const { href } = attrs;
          window.open(href, '_blank');
        }

        if (this.options.selectTextOnClick) {
          handled = true;
          this.store.commands.selectText(markRange);
        }

        return handled;
      },
    };
  }

  /**
   * The plugin for handling click events in the editor.
   *
   * TODO extract this into the events extension and move that extension into
   * core.
   */
  createPlugin(): CreateExtensionPlugin {
    return {
      props: {
        handleClick: (view, pos) => {
          if (!this.options.selectTextOnClick && !this.options.openWLinkOnClick) {
            return false;
          }

          const { doc, tr } = view.state;
          const range = getMarkRange(doc.resolve(pos), this.type);

          if (!range) {
            return false;
          }

          if (this.options.openWLinkOnClick) {
            const { href } = range.mark.attrs;
            window.open(href, '_blank');
          }

          if (this.options.selectTextOnClick) {
            const $start = doc.resolve(range.from);
            const $end = doc.resolve(range.to);
            const transaction = tr.setSelection(TextSelection.between($start, $end));

            view.dispatch(transaction);
          }

          return true;
        },
      },
      appendTransaction: (transactions, prevState, state: EditorState) => {
        const transactionsWithWLinkMeta = transactions.filter((tr) => !!tr.getMeta(this.name));

        transactionsWithWLinkMeta.forEach((tr) => {
          const trMeta = tr.getMeta(this.name);

          if (trMeta.command === TOGGLE_LINK) {
            const { range, attrs } = trMeta;
            const { selection, doc } = state;
            const meta = { range, selection, doc, attrs };

            const { from, to } = range ?? selection;
            this.options.onUpdateWLink(doc.textBetween(from, to), meta);
          }
        });

        if (!this.options.autoWLink) {
          return;
        }

        const isUndo = undoDepth(prevState) - undoDepth(state) === 1;

        if (isUndo) {
          return; // Don't execute auto link logic if an undo was performed.
        }

        const docChanged = transactions.some((tr) => tr.docChanged);

        if (!docChanged) {
          return; // Don't execute auto link logic if nothing has changed.
        }

        // Create a single transaction, by combining all transactions
        const composedTransaction = composeTransactionSteps(transactions, prevState);

        const changes = getChangedRanges(composedTransaction, [ReplaceAroundStep, ReplaceStep]);
        const { mapping } = composedTransaction;
        const { tr, doc } = state;

        const { updateWLink, removeWLink } = this.store.chain(tr);

        changes.forEach(({ prevFrom, prevTo, from, to }) => {
          // Store all the callbacks we need to make
          const onUpdateCallbacks: Array<Pick<EventMeta, 'range' | 'attrs'> & { text: string }> = [];

          // Check if node was split into two by `Enter` key press
          const isNodeSeparated = to - from === 2;

          // Get previous links
          const prevMarks = this.getWLinksInRange(prevState.doc, prevFrom, prevTo, true)
            .filter((item) => item.mark.type === this.type)
            .map(({ from, to, text }) => ({
              mappedFrom: mapping.map(from),
              mappedTo: mapping.map(to),
              text,
              from,
              to,
            }));

          // Check if links need to be removed or updated.
          prevMarks.forEach(({ mappedFrom: newFrom, mappedTo: newTo, from: prevMarkFrom, to: prevMarkTo }, i) =>
            this.getWLinksInRange(doc, newFrom, newTo, true)
              .filter((item) => item.mark.type === this.type)
              .forEach((newMark) => {
                const prevWLinkText = prevState.doc.textBetween(prevMarkFrom, prevMarkTo, undefined, ' ');

                const newWLinkText = doc.textBetween(newMark.from, newMark.to + 1, undefined, ' ').trim();

                const wasWLink = this.isValidUrl(prevWLinkText);
                const isWLink = this.isValidUrl(newWLinkText);

                if (isWLink) {
                  return;
                }

                if (wasWLink) {
                  removeWLink({ from: newMark.from, to: newMark.to }).tr();

                  prevMarks.splice(i, 1);
                }

                if (isNodeSeparated) {
                  return;
                }

                // If link characters have been deleted
                from === to &&
                  // Check newWLinkText for a remaining valid link
                  this.findAutoWLinks(newWLinkText)
                    .map((link) =>
                      this.addWLinkProperties({
                        ...link,
                        from: newFrom + link.start,
                        to: newFrom + link.end,
                      }),
                    )
                    .forEach(({ attrs, range, text }) => {
                      updateWLink(attrs, range).tr();

                      onUpdateCallbacks.push({ attrs, range, text });
                    });
              }),
          );

          // Find text that can be auto linked
          this.findTextBlocksInRange(doc, { from, to }).forEach(({ text, positionStart }) => {
            // Match links in text node
            this.findAutoWLinks(text)
              .map((link) =>
                this.addWLinkProperties({
                  ...link,
                  // Calculate link position.
                  from: positionStart + link.start + 1,
                  to: positionStart + link.end + 1,
                }),
              )
              // Check if link is within the changed range.
              .filter(({ range }) => {
                const fromIsInRange = from >= range.from && from <= range.to;
                const toIsInRange = to >= range.from && to <= range.to;

                return fromIsInRange || toIsInRange || isNodeSeparated;
              })
              // Avoid overwriting manually created links.
              .filter(({ range }) => this.getWLinksInRange(tr.doc, range.from, range.to, false).length === 0)
              // Prevent updating existing auto links
              .filter(
                ({ range: { from }, text }) =>
                  !prevMarks.some(({ text: prevMarkText, mappedFrom }) => mappedFrom === from && prevMarkText === text),
              )
              .forEach(({ attrs, text, range }) => {
                updateWLink(attrs, range).tr();

                onUpdateCallbacks.push({ attrs, range, text });
              });
          });

          window.requestAnimationFrame(() => {
            onUpdateCallbacks.forEach(({ attrs, range, text }) => {
              const { doc, selection } = tr;
              this.options.onUpdateWLink(text, { attrs, doc, range, selection });
            });
          });
        });

        if (tr.steps.length === 0) {
          return;
        }

        return tr;
      },
    };
  }

  private buildHref(url: string): string {
    return this.options.extractHref({
      url,
      defaultProtocol: this.options.defaultProtocol,
    });
  }

  private getWLinksInRange(doc: ProsemirrorNode, from: number, to: number, isAutoWLink: boolean): GetMarkRange[] {
    const linkMarks: GetMarkRange[] = [];

    if (from === to) {
      const resolveFrom = Math.max(from - 1, 0);

      const $pos = doc.resolve(resolveFrom);
      const range = getMarkRange($pos, this.type);

      if (range?.mark.attrs.auto === isAutoWLink) {
        linkMarks.push(range);
      }
    } else {
      doc.nodesBetween(from, to, (node, pos) => {
        const marks = node.marks ?? [];
        const linkMark = marks.find(({ type, attrs }) => type === this.type && attrs.auto === isAutoWLink);

        if (linkMark) {
          linkMarks.push({
            from: pos,
            to: pos + node.nodeSize,
            mark: linkMark,
            text: node.textContent,
          });
        }
      });
    }

    return linkMarks;
  }

  private findTextBlocksInRange(
    node: ProsemirrorNode,
    range: FromToProps,
  ): Array<{ text: string; positionStart: number }> {
    const nodesWithPos: NodeWithPosition[] = [];

    // define a placeholder for leaf nodes to calculate link position
    node.nodesBetween(range.from, range.to, (node, pos) => {
      if (!node.isTextblock || !node.type.allowsMarkType(this.type)) {
        return;
      }

      nodesWithPos.push({
        node,
        pos,
      });
    });

    return nodesWithPos.map((textBlock) => ({
      text: node.textBetween(textBlock.pos, textBlock.pos + textBlock.node.nodeSize, undefined, ' '),
      positionStart: textBlock.pos,
    }));
  }

  private addWLinkProperties({ from, to, href, ...link }: FoundAutoWLink & FromToProps): WLinkWithProperties {
    return {
      ...link,
      range: { from, to },
      attrs: { href, auto: true },
    };
  }

  private findAutoWLinks(str: string): FoundAutoWLink[] {
    if (this.options.findAutoWLinks) {
      return this.options.findAutoWLinks(str, this.options.defaultProtocol);
    }

    const toAutoWLink: FoundAutoWLink[] = [];

    for (const match of findMatches(str, this.options.autoWLinkRegex)) {
      const text = getMatchString(match);

      if (!text) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const href = this.buildHref(text);

      if (!this.isValidTLD(href)) {
        // eslint-disable-next-line no-continue
        continue;
      }

      toAutoWLink.push({
        text,
        href,
        start: match.index,
        end: match.index + text.length,
      });
    }

    return toAutoWLink;
  }

  private isValidUrl(text: string): boolean {
    if (this.options.isValidUrl) {
      return this.options.isValidUrl(text, this.options.defaultProtocol);
    }

    return this.isValidTLD(this.buildHref(text)) && !!this._autoWLinkRegexNonGlobal?.test(text);
  }

  private isValidTLD(str: string): boolean {
    const { autoWLinkAllowedTLDs } = this.options;

    if (autoWLinkAllowedTLDs.length === 0) {
      return true;
    }

    const domain = extractDomain(str);

    if (domain === '') {
      // Not a domain
      return true;
    }

    const tld = last<string>(domain.split('.'));

    return autoWLinkAllowedTLDs.includes(tld);
  }
}

/**
 * Extract the `href` from the provided text.
 *
 * @remarks
 *
 * This will return the `url` text with a `${defaultProtocol}//` or `mailto:` prefix if needed.
 */
export function extractHref({ url, defaultProtocol }: { url: string; defaultProtocol: DefaultProtocol }): string {
  const startsWithProtocol = /^((?:https?|ftp)?:)\/\//.test(url);

  // This isn't 100% precise because we allowed URLs without protocol
  // For example, userid@example.com could be email address or link http://userid@example.com
  const isEmail = !startsWithProtocol && url.includes('@');

  if (isEmail) {
    return `mailto:${url}`;
  }

  return startsWithProtocol ? url : `${defaultProtocol}//${url}`;
}

export function extractUrl({ url }: { url: string | any }): { url: string; protocol: DefaultProtocol } {
  const arr = url.split(/^(http:\/\/?|https:\/\/?|mailto:\/\/?|egw:\/\/book\/?|egw:\/\/bible\/?|#?)/);
  return {
    protocol: arr[1] || '',
    url: arr[2] && arr[2] !== 'undefined' ? arr[2] : '',
  };
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wLink: WLinkExtension;
    }
  }
}
