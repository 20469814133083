import {
  EditorComponent,
  ReactExtensions,
  Remirror,
  RemirrorProps,
  useRemirror,
  UseRemirrorReturn,
} from '@remirror/react';
import {
  CodeBlockExtension,
  DocExtension,
  DropCursorExtension,
  HorizontalRuleExtension,
  TrailingNodeExtension,
} from 'remirror/extensions';
import { ExtensionPriority, uniqueId } from '@remirror/core';
import { prosemirrorNodeToHtml } from 'remirror';
import { createContextState } from 'create-context-state';
import mk from 'refractor/lang/markup';

import { css } from '@emotion/css';
import { useMemo } from 'react';
import {
  CommandButtonGroup,
  ControlledEditorToolbar,
  EditorThemeProvider,
  IControlledEditorProps,
  ToggleCopyButton,
  ToggleCutButton,
  TogglePasteButton,
  ToggleRedoButton,
  ToggleResetButton,
  ToggleUndoButton,
  ToggleWAnchorButton,
  ToggleWEntityButton,
  ToggleWFormatAllCapsButton,
  ToggleWFormatBoldButton,
  ToggleWFormatItalicButton,
  ToggleWFormatSmallCapsButton,
  ToggleWFormatSubscriptButton,
  ToggleWFormatSuperscriptButton,
  ToggleWFormatUnderlineButton,
  ToggleWHardBreakButton,
  ToggleWHeadingButton,
  ToggleWLangButton,
  ToggleWLinkButton,
  ToggleWNonEgwButton,
  ToggleWNoteButton,
  ToggleWPageButton,
  ToggleWParaButton,
  ToggleWSentButton,
  useDebounce,
  WAnchorExtension,
  WEntityExtension,
  WFormatAllCapsExtension,
  WFormatBoldExtension,
  WFormatItalicExtension,
  WFormatSmallCapsExtension,
  WFormatSubscriptExtension,
  WFormatSuperscriptExtension,
  WFormatUnderlineExtension,
  WHardBreakExtension,
  WHeadingExtension,
  WLangExtension,
  WLinkExtension,
  WNonEgwExtension,
  WNoteExtension,
  WPageBlockExtension,
  WPageExtension,
  WParaExtension,
  WSentExtension,
  WTextBlockExtension,
  ToggleWPageBlockButton,
  ToggleWHorizontalRuleButton,
  ToggleUnorderedWListButton,
  OrderedWListExtension,
  UnorderedWListExtension,
  ToggleOrderedWListButton,
  WFigureExtension,
  WImageExtension,
} from '../../../../components';
import { useAppThemeContext } from '../../../../themes';
import './PublicationParagraphEditor.scss';
import { ToggleWImageButton } from '../../../../components/ParagraphEditor/extensions/WImageExtension/views';
import { PublicationChaptersReaderParagraphListItemFragment } from '../../../../services/apolloGraphqlAppApiClient/models/types';
import { ToggleWTextBlockButton } from '../../../../components/ParagraphEditor/extensions/WTextBlockExtension/views';

export interface IPublicationParagraphEditorProps {
  onChange?: (value: string) => void;
  value?: string;
  initialContent: string;
  placeholder?: string;
  headingLevel?: PublicationChaptersReaderParagraphListItemFragment['headingLevel'];
}

const useParagraphEditorExtensions = (props: Pick<IPublicationParagraphEditorProps, 'headingLevel'>) => {
  const { headingLevel } = props;
  const wysiwygEditorExtensions = () => [
    new DocExtension({
      // ...(typeof headingLevel !== 'undefined'
      //   ? {
      //       content:
      //         typeof headingLevel === 'number' ? (headingLevel > 0 ? 'wHeading{1}' : 'wPara{1}') : 'wPageBlock{1}',
      //     }
      //   : {}),
      // content: '(wTextBlock | wPara | wHeading | orderedWList | unorderedWList | wPageBlock | horizontalRule | wFigure)+',
      // content: '(wPara | wHeading | wPageBlock ){1}',
      content: 'wemlContainer+',
      // docAttributes: { contentType: 'wHeading{1}' }
    }),
    new HorizontalRuleExtension({ insertionNode: 'wPara' }),

    // Nodes
    new WHeadingExtension(),
    new WParaExtension(),
    new WPageBlockExtension(),

    // Block Nodes
    new WTextBlockExtension(),
    new WFigureExtension(),
    new OrderedWListExtension(),
    new UnorderedWListExtension(),
    new WNoteExtension({
      priority: ExtensionPriority.Medium,
    }),
    new WPageExtension({ priority: ExtensionPriority.Medium }),
    new WAnchorExtension(),

    // Marks
    new WFormatBoldExtension(),
    new WFormatItalicExtension(),
    new WFormatUnderlineExtension(),
    new WFormatSuperscriptExtension(),
    new WFormatSubscriptExtension(),
    new WFormatSmallCapsExtension(),
    new WFormatAllCapsExtension(),
    new WHardBreakExtension(),
    new WLinkExtension({ priority: ExtensionPriority.Medium }),
    new WLangExtension({ priority: ExtensionPriority.Medium }),
    new WNonEgwExtension({ priority: ExtensionPriority.Medium }),
    new WEntityExtension({ priority: ExtensionPriority.Medium }),
    new WSentExtension({ priority: ExtensionPriority.Medium }),

    // Etc... Extensions
    // new DropCursorExtension({ color: 'gray' }),
    // new TrailingNodeExtension({ nodeName: 'wTextBlock' }),
  ];
  const codeEditorExtensions = () => [
    new DocExtension({ content: 'codeBlock' }),
    new CodeBlockExtension({
      supportedLanguages: [mk],
      defaultLanguage: 'markup',
      syntaxTheme: 'base16_ateliersulphurpool_light',
      defaultWrap: true,
    }),
  ];

  return useMemo(
    () => ({
      wysiwygEditorExtensions,
      codeEditorExtensions,
    }),
    [headingLevel],
  );
};

interface PublicationParagraphEditorProviderContext extends PublicationParagraphEditorProviderProps {
  setWysiwyg: (rawContent: string) => void;
  setCode: (rawContent: string) => void;
}

interface PublicationParagraphEditorProviderProps {
  wysiwyg: UseRemirrorReturn<
    ReactExtensions<ReturnType<ReturnType<typeof useParagraphEditorExtensions>['wysiwygEditorExtensions']>[number]>
  >;
  code: UseRemirrorReturn<
    ReactExtensions<ReturnType<ReturnType<typeof useParagraphEditorExtensions>['codeEditorExtensions']>[number]>
  >;
  onChangeCallBack?: IPublicationParagraphEditorProps['onChange'];
  initialContent?: IPublicationParagraphEditorProps['initialContent'];
}

const [PublicationParagraphEditorProvider, usePublicationParagraphEditor] = createContextState<
  PublicationParagraphEditorProviderContext,
  PublicationParagraphEditorProviderProps
>(({ props }) => ({
  ...props,

  setCode: (rawContent: string) =>
    props.code.getContext()?.setContent({
      type: 'doc',
      content: [
        {
          type: 'codeBlock',
          attrs: { language: 'markup' },
          content: rawContent ? [{ type: 'text', text: rawContent }] : undefined,
        },
      ],
    }),
  setWysiwyg: (rawContent: string) => props.wysiwyg.getContext()?.setContent(rawContent),
}));
const WysiwygEditorComponent = () => {
  const { wysiwyg, setCode, onChangeCallBack, initialContent } = usePublicationParagraphEditor();

  const onEditorChange: RemirrorProps['onChange'] = (event) => {
    const { state, helpers } = event;
    const rawContent = prosemirrorNodeToHtml(state.doc);
    if (onChangeCallBack) {
      onChangeCallBack(rawContent);
    }
    setCode(rawContent);
  };

  return (
    <Remirror
      manager={wysiwyg.manager}
      onChange={onEditorChange}
      classNames={['weml-editor']}
      autoFocus
      initialContent={wysiwyg.state}
      placeholder="please input Paragraph text"
    >
      <ControlledEditorToolbar className="remirror-toolbar">
        <CommandButtonGroup>
          <ToggleWHeadingButton />
          <ToggleWParaButton />
          <ToggleWPageBlockButton />
        </CommandButtonGroup>
        <CommandButtonGroup>
          <ToggleWTextBlockButton />
          <ToggleWHardBreakButton />
          <ToggleOrderedWListButton />
          <ToggleUnorderedWListButton />
          <ToggleWHorizontalRuleButton />
          <ToggleWImageButton />
        </CommandButtonGroup>
        <CommandButtonGroup>
          <ToggleWFormatBoldButton />
          <ToggleWFormatItalicButton />
          <ToggleWFormatUnderlineButton />
          <ToggleWFormatSmallCapsButton />
          <ToggleWFormatAllCapsButton />
          <ToggleWFormatSuperscriptButton />
          <ToggleWFormatSubscriptButton />
        </CommandButtonGroup>
        <CommandButtonGroup>
          <ToggleWLinkButton />
          <ToggleWAnchorButton />
          <ToggleWPageButton />
        </CommandButtonGroup>
        <CommandButtonGroup>
          <ToggleWSentButton />
          <ToggleWLangButton />
          <ToggleWNoteButton />
        </CommandButtonGroup>
        <CommandButtonGroup>
          <ToggleWNonEgwButton />
          <ToggleWEntityButton />
        </CommandButtonGroup>
        <CommandButtonGroup>
          <ToggleRedoButton />
          <ToggleUndoButton />
          <TogglePasteButton />
          <ToggleCopyButton />
          <ToggleCutButton />
        </CommandButtonGroup>
        <CommandButtonGroup>
          <ToggleResetButton label="Reset" type="reset" value={initialContent} />
          <ToggleResetButton label="Clear" />
        </CommandButtonGroup>
      </ControlledEditorToolbar>
      <EditorComponent />
    </Remirror>
  );
};
const CodeEditorComponent = () => {
  const { code, setWysiwyg, onChangeCallBack } = usePublicationParagraphEditor();

  const onEditorChange: RemirrorProps['onChange'] = (event) => {
    const { state, helpers } = event;
    const rawContent = helpers.getText({ state });
    if (onChangeCallBack) {
      onChangeCallBack(rawContent);
    }
    setWysiwyg(rawContent);
  };

  return (
    <Remirror
      manager={code.manager}
      placeholder="please input paragraph raw code"
      onChange={onEditorChange}
      classNames={[
        css`
          &.ProseMirror {
            pre {
              height: 100%;
              color: inherit;
              background: none;
            }
          }
        `,
      ]}
    >
      <div
        style={{
          padding: '8px 0',
          margin: 0,
          whiteSpace: 'initial',
          textAlign: 'start',
        }}
      >
        Raw content code editor
      </div>
      <EditorComponent />
    </Remirror>
  );
};
export const PublicationParagraphEditor = (props: IPublicationParagraphEditorProps) => {
  const { initialContent, onChange, headingLevel, placeholder, value } = props;
  const { theme, isSystemDarkModeTheme } = useAppThemeContext();
  const { wysiwygEditorExtensions, codeEditorExtensions } = useParagraphEditorExtensions({ headingLevel });
  const wysiwyg = useRemirror({
    extensions: wysiwygEditorExtensions,
    stringHandler: 'html',
    content: initialContent,
  });

  const code = useRemirror({
    extensions: codeEditorExtensions,
    stringHandler: 'html',
  });

  const handleEditorStateChange = useDebounce(
    (rawContent: string) => {
      if (onChange) {
        onChange(rawContent);
      }
    },
    [100],
  );

  return (
    <PublicationParagraphEditorProvider
      wysiwyg={wysiwyg}
      code={code}
      onChangeCallBack={handleEditorStateChange}
      initialContent={initialContent}
    >
      <EditorThemeProvider
        themeMode={
          theme === 'system'
            ? isSystemDarkModeTheme
              ? 'dark'
              : 'light'
            : (theme as IControlledEditorProps['themeMode'])
        }
        className="publicationParagraph--editor"
      >
        <WysiwygEditorComponent />
        <CodeEditorComponent />
      </EditorThemeProvider>
    </PublicationParagraphEditorProvider>
  );
};
