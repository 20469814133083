import { App, Form, Input } from 'antd';
import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';
import {
  ManagerPublicationCoverTypeListItemFragment,
  UpdateCoverTypeInput,
  useUpdatePublicationCoverTypeMutation,
} from '../../../../services/apolloGraphqlAppApiClient/models/types';
import { usePublicationCoverTypesManagerStore } from '../../PublicationCoverTypesManagerStoreProvider';
import { IModalFormTemplateProps, ModalFormTemplate } from '../../../../components';

export const UpdatePublicationCoverTypeActionButton = (record: ManagerPublicationCoverTypeListItemFragment) => {
  const {
    notification: { info },
  } = App.useApp();

  const { refetchCoverTypes } = usePublicationCoverTypesManagerStore();
  const infoKey = uuidV4();

  const [UpdatePublicationCoverType] = useUpdatePublicationCoverTypeMutation({
    onCompleted: (result) => {
      refetchCoverTypes();
      info({
        placement: 'topRight',
        message: `Update Cover Type ${result.updateCoverType.coverTypeDto?.code} status: true`,
        key: infoKey,
        duration: 7,
      });
    },
    onError: (e) => {
      return e;
    },
  });

  const handleFinish: IModalFormTemplateProps<UpdateCoverTypeInput>['onFinish'] = async (values, form) => {
    const r = await UpdatePublicationCoverType({
      variables: {
        input: values,
      },
    });
    if (r.errors) {
      console.log({ res: r });
      return Promise.reject(r.errors);
    }
    return r;
  };

  return (
    <ModalFormTemplate<UpdateCoverTypeInput>
      title={`Update ${record?.code} cover type`}
      okText="Update"
      onFinish={handleFinish}
      triggerProps={{
        icon: <EditOutlined />,
      }}
      formProps={{
        initialValues: {
          code: record.code,
          description: record.description,
        },
        layout: 'vertical',
        name: 'updatePublicationsCoverTypeForm',
      }}
    >
      <Form.Item
        name="code"
        label="Code"
        rules={[
          {
            type: 'string',
            max: 32,
            message: 'Please input 32 characters max',
          },
          {
            required: true,
            message: 'Code is required',
          },
        ]}
        hidden
      >
        <Input maxLength={32} disabled />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea
          minLength={2}
          autoSize={{
            minRows: 2,
            maxRows: 5,
          }}
        />
      </Form.Item>
    </ModalFormTemplate>
  );
};
