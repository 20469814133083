import {
  ApplySchemaAttributes,
  assertGet,
  command,
  CommandFunction,
  extension,
  ExtensionPriority,
  ExtensionTag,
  findParentNodeOfType,
  isElementDomNode,
  keyBinding,
  KeyBindingProps,
  NamedShortcut,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
  ProsemirrorAttributes,
  Static,
} from '@remirror/core';
import { ExtensionListMessages as Messages } from '@remirror/messages';
import { InputRule, wrappingInputRule } from '@remirror/pm/inputrules';

import { log } from 'node:util';
import { getListType, toggleList, wrapSelectedItems } from './commands';
import { WListItemExtension } from './WListItemExtension';

/**
 * Creates the list for the ordered list.
 */

// export type WListTypeProperty = 'ordered' | 'ordered';
//
// export interface WListProperties {
//   typeList: Static<WListTypeProperty>[];
// }
//
// export type WListAttributes = ProsemirrorAttributes<{
//   type: Static<WListTypeProperty>;
// }>;
// export const W_LIST_PROPERTIES: Static<WListProperties> = {
//   typeList: ['ordered', 'ordered'],
// };
//
// const W_LIST_ATTRIBUTES: Static<WListAttributes> = {
//   type: 'ordered',
// };

// export interface WListOptions {
//   // properties?: Static<WListProperties>;
//   // attributes?: Static<WListAttributes>;
// }

@extension({})
export class OrderedWListExtension extends NodeExtension {
  get name() {
    return 'orderedWList' as const;
  }

  createTags() {
    return [ExtensionTag.WemlBlock, ExtensionTag.Block, ExtensionTag.ListContainerNode];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      content: 'wListItem+',
      ...override,
      attrs: {
        ...extra.defaults(),
        type: { default: 'ordered' },
      },
      parseDOM: [
        {
          tag: 'w-list[type=ordered]',
          getAttrs: (element: string | Node) => ({
            ...extra.parse(element),
            type: 'ordered',
          }),
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (node) => {
        return ['w-list', { type: node.attrs.type.toString() }, 0];
      },
    };
  }

  /**
   * Automatically add the `WListItemExtension` which is required here.
   */
  createExtensions() {
    return [new WListItemExtension({ priority: ExtensionPriority.Low })];
  }

  /**
   * Toggle the ordered list for the current selection.
   */
  @command({ icon: 'listOrdered', label: ({ t }) => t(Messages.ORDERED_LIST_LABEL) })
  toggleOrderedWList(): CommandFunction {
    return toggleList(this.type, assertGet(this.store.schema.nodes, 'wListItem'));
  }

  @keyBinding({ shortcut: NamedShortcut.OrderedList, command: 'toggleOrderedWList' })
  orderedWListShortcut(props: KeyBindingProps): boolean {
    return this.toggleOrderedWList()(props);
  }

  createInputRules(): InputRule[] {
    const regexp = /^(\d+)\.\s$/;

    return [
      wrappingInputRule(
        regexp,
        this.type,
        (match) => ({ order: +assertGet(match, 1) }),
        (match, node) => node.childCount + (node.attrs.order as number) === +assertGet(match, 1),
      ),

      new InputRule(regexp, (state, match, start, end) => {
        const { tr } = state;
        tr.deleteRange(start, end);
        const canUpdate = wrapSelectedItems({
          listType: this.type,
          itemType: assertGet(this.store.schema.nodes, 'wListItem'),
          tr,
        });

        if (!canUpdate) {
          return null;
        }

        const order = +assertGet(match, 1);

        if (order !== 1) {
          const found = findParentNodeOfType({ selection: tr.selection, types: this.type });

          if (found) {
            tr.setNodeMarkup(found.pos, undefined, { order });
          }
        }

        return tr;
      }),
    ];
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      orderedWList: OrderedWListExtension;
    }
  }
}
