import React from 'react';
import { Button, ConfigProvider, Dropdown, Menu, MenuTheme, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useAppThemeContext } from './AppThemeProvider';

export interface IAppThemeSwitcherProps {
  type?: 'dropdown' | 'menu';
}

export const AppThemeSwitcher = (props: IAppThemeSwitcherProps): React.ReactElement => {
  const { type = 'dropdown' } = props;
  const { menuItems, themes, theme, isSystemDarkModeTheme } = useAppThemeContext();

  if (type === 'dropdown') {
    return (
      <ConfigProvider theme={themes[theme]}>
        <Dropdown
          menu={{
            items: menuItems[0].children,
            selectedKeys: [theme],
            theme: theme === 'system' ? (isSystemDarkModeTheme ? 'dark' : 'light') : (theme as MenuTheme),
          }}
          placement="bottom"
          arrow={{ pointAtCenter: true }}
        >
          <Button icon={themes[theme].icon} type="text">
            <Space align="center">
              {`${themes[theme].label} Theme`}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </ConfigProvider>
    );
  }
  return <Menu items={menuItems} theme="dark" mode="inline" selectedKeys={[theme]} />;
};
