import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionTag,
  getTextSelection,
  keyBinding,
  KeyBindingProps,
  MarkExtension,
  MarkExtensionSpec,
  MarkSpecOverride,
  PrimitiveSelection,
  ProsemirrorAttributes,
  Static,
  toggleMark,
} from '@remirror/core';
import { CoreIcon } from '@remirror/icons';
import { GenIcon, IconType } from '@remirror/react';
import React from 'react';
import { InputRule, markInputRule } from 'remirror';

import { WFormatTypeProperty } from './_interface';

export interface IconTree {
  /**
   * The name of the tag to render.
   */
  tag: string;

  /**
   * The attributes (camel cased) to add to the rendered tag.
   */
  attr: { [key: string]: string };

  /**
   * The child tags to render.
   */
  child?: IconTree[];
}

export const smallcaps: IconTree[] = [
  { tag: 'path', attr: { fill: 'none', d: 'M0 0h24v24H0z' } },
  {
    tag: 'path',
    attr: {
      d: 'M18.5 10l4.4 11h-2.155l-1.201-3h-4.09l-1.199 3h-2.154L16.5 10h2zM10 2v2h6v2h-1.968a18.222 18.222 0 0 1-3.62 6.301 14.864 14.864 0 0 0 2.336 1.707l-.751 1.878A17.015 17.015 0 0 1 9 13.725a16.676 16.676 0 0 1-6.201 3.548l-.536-1.929a14.7 14.7 0 0 0 5.327-3.042A18.078 18.078 0 0 1 4.767 8h2.24A16.032 16.032 0 0 0 9 10.877a16.165 16.165 0 0 0 2.91-4.876L2 6V4h6V2h2zm7.5 10.885L16.253 16h2.492L17.5 12.885z',
    },
  },
];

export const SmallCapsIcon: IconType = (props) => {
  return GenIcon(smallcaps)(props);
};

const CREATE_COMMAND_LABEL = 'Toggle SmallCaps';
const CREATE_COMMAND_SHORTCUT = 'Mod-S';

const toggleWFormatSmallCapsOptions: Remirror.CommandDecoratorOptions = {
  label: ({ t }) => t(CREATE_COMMAND_LABEL),
  shortcut: CREATE_COMMAND_SHORTCUT,
};

export type WFormatSmallCapsExtensionAttributes = ProsemirrorAttributes<{
  /**
   * The Mark type.
   */
  type?: WFormatTypeProperty;
}>;
export interface WFormatSmallCapsOptions {
  /**
   * Require set the mark type property for this extension.
   */
  type?: Static<WFormatTypeProperty>;
  defaultType?: Static<WFormatTypeProperty>;
}

@extension<WFormatSmallCapsOptions>({
  defaultOptions: {
    type: undefined,
    defaultType: undefined,
  },
  staticKeys: ['defaultType', 'type'],
})
export class WFormatSmallCapsExtension extends MarkExtension<WFormatSmallCapsOptions> {
  get name() {
    return 'wFormatSmallCaps' as const;
  }

  createTags() {
    return [ExtensionTag.FormattingMark];
  }

  createMarkSpec(extra: ApplySchemaAttributes, override: MarkSpecOverride): MarkExtensionSpec {
    return {
      ...override,
      attrs: {
        ...extra.defaults(),
        type: { default: this.options.defaultType },
      },
      parseDOM: [
        {
          tag: 'w-format[type=small-caps]',
          getAttrs: (element: string | Node) => ({
            ...extra.parse(element),
            type: 'small-caps',
          }),
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (mark) => {
        const { type } = this.options;
        return ['w-format', { type: mark.attrs.type.toString() }, 0];
      },
    };
  }

  createInputRules(): InputRule[] {
    return [
      markInputRule({
        regexp: /(?:\*\*|__)([^*_]+)(?:\*\*|__)$/,
        type: this.type,
        ignoreWhitespace: true,
      }),
    ];
  }

  @command(toggleWFormatSmallCapsOptions)
  toggleWFormatSmallCaps(attrs?: WFormatSmallCapsExtensionAttributes, selection?: PrimitiveSelection): CommandFunction {
    return toggleMark({ type: this.type, selection, attrs: { ...attrs, type: 'small-caps' } });
  }

  @command()
  setWFormatSmallCaps(selection?: PrimitiveSelection): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc);
      dispatch?.(tr.addMark(from, to, this.type.create()));

      return true;
    };
  }

  @command()
  removeWFormatSmallCaps(selection?: PrimitiveSelection): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc);

      if (!tr.doc.rangeHasMark(from, to, this.type)) {
        return false;
      }

      dispatch?.(tr.removeMark(from, to, this.type));

      return true;
    };
  }

  @keyBinding({ shortcut: 'Mod-S', command: 'toggleWFormatSmallCaps' })
  shortcut(props: KeyBindingProps): boolean {
    return this.toggleWFormatSmallCaps()(props);
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface SmallExtensions {
      wFormatSmallCapsItalic: WFormatSmallCapsExtension;
    }
  }
}
