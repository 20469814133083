import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionPriority,
  ExtensionTag,
  keyBinding,
  KeyBindingProps,
  NamedShortcut,
  NodeExtension,
  NodeSpecOverride,
  PrimitiveSelection,
  ProsemirrorAttributes,
  Static,
} from '@remirror/core';
import { TextSelection } from '@remirror/pm/state';
import { findNodeAtSelection, getActiveNode, toggleWrap } from 'remirror';

import { createWNoteSchema, WNoteSchemaSpec } from './wNote-utils';

export type WNoteTextBlockTypeProperty = 'blockquote' | 'paragraph' | 'poem';

export interface WNoteTextBlockOptions {
  type?: Static<WNoteTextBlockTypeProperty>;
}

@extension<WNoteTextBlockOptions>({
  staticKeys: ['type'],
  defaultPriority: ExtensionPriority.Medium,
  defaultOptions: { type: 'paragraph' },
})
export class WNoteTextBlockExtension extends NodeExtension<WNoteTextBlockOptions> {
  get name() {
    return 'wNoteTextBlock' as const;
  }

  readonly tags = [ExtensionTag.InlineNode, ExtensionTag.LastNodeCompatible, ExtensionTag.FormattingNode];

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): WNoteSchemaSpec {
    return createWNoteSchema(extra, override).wNoteTextBlock;
  }

  // @command()
  // toggleWNoteTextBlock(attributes = {}): CommandFunction {
  //   return toggleWrap(this.type, attributes);
  // }

  @command()
  convertWNoteTextBlock(options: WNoteTextBlockCommandOptions = {}): CommandFunction {
    const { attrs, selection, preserveAttrs } = options;

    return toggleWrap(this.type, attrs, selection);
  }

  /**
   * Inserts a paragraph into the editor at the current selection.
   */
  @command()
  insertWNoteTextBlock(content: string, options: WNoteTextBlockCommandOptions = {}): CommandFunction {
    const { selection, attrs } = options;
    return this.store.commands.insertNode.original(this.type, { content, selection, attrs });
  }

  /**
   * Add the paragraph shortcut to the editor. This makes a paragraph into a
   */
  @keyBinding({ shortcut: NamedShortcut.Paragraph, command: 'convertWTextBlock' })
  shortcut(props: KeyBindingProps): boolean {
    return this.convertWNoteTextBlock()(props);
  }

  @keyBinding({ shortcut: 'Backspace' })
  handleBackspace({ dispatch, tr, state }: KeyBindingProps): boolean {
    if (!tr.selection.empty) {
      return false;
    }
    const { $from } = tr.selection;
    // If not at the start of current node, no joining will happen
    if ($from.parentOffset !== 0) {
      return false;
    }

    // if ($from.depth === 0) {
    //   return false;
    // }

    const previousPosition = $from.before($from.depth);
    if (previousPosition >= 0) {
      const previousPos = tr.doc.resolve(previousPosition);
      const previousNode = previousPos.parent;
      const { node, pos } = findNodeAtSelection(tr.selection);

      if (!tr.selection.empty && node.type === this.type) {
        if ($from.parentOffset > 0) {
          return false;
        }
        if (dispatch) {
          dispatch(tr);
        }

        return false;
      }

      // If not at the start of current node, no joining will happen
      if ($from.parentOffset !== 0 && node.type === this.type) {
        return false;
      }

      // If resolving previous position fails, bail out
      if (node.type === this.type) {
        if (dispatch) {
          dispatch(tr);
        }

        return true;
      }
    }

    return false;
  }

  @keyBinding({ shortcut: 'Delete' })
  handleDelete({ dispatch, tr, state }: KeyBindingProps): boolean {
    if (!tr.selection.empty) {
      return false;
    }

    const { $from } = tr.selection;
    // If not at the start of current node, no joining will happen
    if ($from.parentOffset !== 0) {
      return false;
    }
    const previousPosition = $from.before($from.depth) - 1;
    if (previousPosition >= 0) {
      const previousPos = tr.doc.resolve(previousPosition);
      const { node, pos } = findNodeAtSelection(tr.selection);
      console.log({ $from });

      if (!tr.selection.empty && node.type === this.type) {
        if ($from.parentOffset > 0) {
          return false;
        }
        if (dispatch) {
          dispatch(tr);
        }

        return false;
      }

      // If resolving previous position fails, bail out
      if ((!$from.nodeAfter || $from.textOffset === 0) && node.type === this.type) {
        if (dispatch) {
          dispatch(tr);
        }

        return true;
      }
    }

    return false;
  }

  @keyBinding({ shortcut: 'Mod-a' })
  handleSelect({ dispatch, tr, state }: KeyBindingProps): boolean {
    const { $from } = tr.selection;
    const previousPosition = $from.before($from.depth) - 1;
    const previousPos = tr.doc.resolve(previousPosition);
    const { node, pos } = findNodeAtSelection(tr.selection);

    if (node.type === this.type) {
      node?.firstChild &&
        tr.setSelection(
          TextSelection.create(
            tr.doc,
            // @ts-ignore
            $from.start(),
            $from.end(),
          ),
        );
      if (dispatch) {
        dispatch(tr);
      }

      return true;
    }

    return false;
  }
}

interface WNoteTextBlockCommandOptions {
  attrs?: ProsemirrorAttributes;
  selection?: PrimitiveSelection;
  preserveAttrs?: boolean;
}
