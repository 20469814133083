import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionTag,
  FromToProps,
  GetMarkRange,
  Handler,
  isAllSelection,
  isMarkActive,
  isSelectionEmpty,
  isTextSelection,
  MarkExtension,
  MarkExtensionSpec,
  MarkSpecOverride,
  PrimitiveSelection,
  ProsemirrorAttributes,
  removeMark,
  Static,
  updateMark,
} from '@remirror/core';
import { CreateEventHandlers } from '@remirror/extension-events';

const CREATE_COMMAND_LABEL = 'Toggle wEntity';
const CREATE_COMMAND_DESCRIPTION = 'Insert wEntity Attributes';

export const toggleWEntityOptions: Remirror.CommandDecoratorOptions = {
  label: ({ t }) => t(CREATE_COMMAND_LABEL),
  description: ({ t }) => t(CREATE_COMMAND_DESCRIPTION),
};

export type WEntityTypeProperty = 'addressee' | 'date' | 'place' | 'topic' | 'topic-word';
export type WEntityValueProperty = string;

export interface WEntityProperties {
  types: Static<WEntityTypeProperty>[];
}

export type WEntityAttributes = ProsemirrorAttributes<{
  type: Static<WEntityTypeProperty>;
  value?: Static<WEntityValueProperty>;
}>;

export interface WEntityClickData extends GetMarkRange, WEntityAttributes {}

export const W_ENTITY_PROPERTIES: Static<WEntityProperties> = {
  types: ['addressee', 'date', 'place', 'topic', 'topic-word'],
};

export const W_ENTITY_ATTRIBUTES: Static<WEntityAttributes> = {
  type: 'place',
};

export interface WEntityViewClickHandlerProps {
  event: MouseEvent;
  markWithPosition?: WEntityClickData;
}

export interface WEntityOptions {
  properties?: Static<WEntityProperties>;
  attributes?: Static<WEntityAttributes>;
  onViewClick?: Handler<(props: WEntityViewClickHandlerProps) => boolean | undefined | void>;
}

@extension<WEntityOptions>({
  defaultOptions: {
    properties: W_ENTITY_PROPERTIES,
    attributes: W_ENTITY_ATTRIBUTES,
  },
  handlerKeys: ['onViewClick'],
  staticKeys: ['properties', 'attributes'],
})
export class WEntityExtension extends MarkExtension<WEntityOptions> {
  get name() {
    return 'wEntity' as const;
  }

  createTags() {
    return [ExtensionTag.FormattingMark, ExtensionTag.FontStyle];
  }

  createMarkSpec(extra: ApplySchemaAttributes, override: MarkSpecOverride): MarkExtensionSpec {
    return {
      ...override,
      attrs: {
        ...extra.defaults(),
        type: { default: this.options.attributes.type },
        value: { default: undefined },
      },
      parseDOM: [
        ...this.options.properties.types.map((type) => ({
          tag: `w-entity[type=${type}]`,
          getAttrs: (node) => ({
            ...extra.parse(node),
            type,
            value: node.getAttribute('value') as string,
          }),
        })),
        ...(override.parseDOM ?? []),
      ],
      toDOM: (mark) => {
        const {
          properties: { types },
        } = this.options;

        if (mark?.attrs?.type) {
          return [
            'w-entity',
            {
              type: mark.attrs.type.toString(),
              ...(mark?.attrs?.value ? { value: mark.attrs.value.toString() } : {}),
            },
            0,
          ];
        }

        return ['w-entity', extra.dom(mark), 0];
      },
    };
  }

  createEventHandlers(): CreateEventHandlers {
    return {
      clickMark: (event, clickState) => {
        const markRange = clickState.getMark(this.type);

        if (!markRange) {
          return this.options.onViewClick({ event });
        }

        const attrs = markRange.mark.attrs as WEntityAttributes;
        const markWithPosition: WEntityClickData = { ...attrs, ...markRange };

        if (!markWithPosition || markWithPosition.mark.type !== this.type) {
          return this.options.onViewClick({ event });
        }

        return this.options.onViewClick({ event, markWithPosition });
      },
    };
  }

  @command(toggleWEntityOptions)
  toggleWEntity(attrs?: WEntityAttributes, range?: FromToProps): CommandFunction {
    return (props) => {
      const { tr } = props;
      const selectionIsValid =
        (isTextSelection(tr.selection) && !isSelectionEmpty(tr.selection)) ||
        isAllSelection(tr.selection) ||
        isMarkActive({ trState: tr, type: this.type });

      if (!selectionIsValid && !range) {
        return false;
      }

      tr.setMeta(this.name, {
        command: 'toggleWEntity',
        attrs,
        range,
      });

      return updateMark({ type: this.type, attrs, range })(props);
    };
  }

  @command()
  removeWEntity(range?: FromToProps): CommandFunction {
    return (props) => {
      const { tr } = props;

      if (!isMarkActive({ trState: tr, type: this.type, ...range })) {
        return false;
      }

      return removeMark({ type: this.type, expand: true, range })(props);
    };
  }

  @command()
  selectWEntity(): CommandFunction {
    return this.store.commands.selectMark.original(this.type);
  }
}

interface WEntityCommandOptions {
  attrs?: ProsemirrorAttributes;
  selection?: PrimitiveSelection;
  preserveAttrs?: boolean;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wEntity: WEntityExtension;
    }
  }
}
