import { App, Form, Input, InputNumber } from 'antd';
import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';
import {
  CreateCoverTypeInput,
  useCreatePublicationCoverTypeMutation,
} from '../../../../services/apolloGraphqlAppApiClient/models/types';
import { usePublicationCoverTypesManagerStore } from '../../PublicationCoverTypesManagerStoreProvider';
import { FlexListTemplate, IModalFormTemplateProps, ModalFormTemplate } from '../../../../components';

export const CreatePublicationCoverTypeActionButton = () => {
  const {
    notification: { info },
  } = App.useApp();

  const { refetchCoverTypes } = usePublicationCoverTypesManagerStore();
  const infoKey = uuidV4();

  const [CreatePublicationCoverType] = useCreatePublicationCoverTypeMutation({
    onCompleted: (result) => {
      refetchCoverTypes();
      info({
        placement: 'topRight',
        message: `Create Cover Type ${result.createCoverType.coverTypeDto?.code} status: true`,
        key: infoKey,
        duration: 7,
      });
    },
    onError: (e) => {
      return e;
    },
  });

  const handleFinish: IModalFormTemplateProps<CreateCoverTypeInput>['onFinish'] = async (values, form) => {
    const parsedValues = { ...values };
    // @ts-ignore
    delete parsedValues?.aspectRatio;
    const r = await CreatePublicationCoverType({
      variables: {
        input: parsedValues,
      },
    });
    if (r.errors) {
      return Promise.reject(r.errors);
    }
    form.resetFields();
    return r;
  };

  return (
    <ModalFormTemplate<CreateCoverTypeInput>
      title="Create new cover type"
      okText="Create"
      onFinish={handleFinish}
      triggerProps={{
        icon: <PlusOutlined />,
        type: 'primary',
        children: 'Create New',
      }}
      onValuesChange={(changedValues, values, form) => {
        if (changedValues?.minWidth || changedValues?.minHeight) {
          const aspectRatio = (values.minWidth / values.minHeight).toFixed(3);
          form.setFieldValue('aspectRatio', aspectRatio !== 'NaN' ? aspectRatio : '');
        }
      }}
      formProps={{
        initialValues: {},
        layout: 'vertical',
        name: 'createPublicationsCoverTypeForm',
      }}
    >
      <Form.Item
        name="code"
        label="Code"
        rules={[
          {
            type: 'string',
            max: 32,
            message: 'Please input 32 characters max',
          },
          {
            required: true,
            message: 'Code is required',
          },
        ]}
      >
        <Input maxLength={32} />
      </Form.Item>
      <FlexListTemplate justifyContent="space-between" alignItems="flex-start">
        <Form.Item
          name="minWidth"
          label="Min Width"
          rules={[
            {
              type: 'integer',
              message: 'Please input only integer number',
            },
            {
              required: true,
              message: 'Required',
            },
          ]}
        >
          <InputNumber
            onKeyDown={(event) => {
              if (!/[0-9]/.test(event.key) && !['Backspace', 'Delete'].includes(event.key)) {
                event.preventDefault();
              }
            }}
            min={1}
          />
        </Form.Item>
        <Form.Item
          name="minHeight"
          label="Min Height"
          rules={[
            {
              type: 'integer',
              message: 'Please input only integer number',
            },
            {
              required: true,
              message: 'Required',
            },
          ]}
        >
          <InputNumber
            onKeyDown={(event) => {
              if (!/[0-9]/.test(event.key) && !['Backspace', 'Delete'].includes(event.key)) {
                event.preventDefault();
              }
            }}
            min={1}
          />
        </Form.Item>
        <Form.Item name="aspectRatio" label="AspectRatio">
          <InputNumber disabled />
        </Form.Item>
      </FlexListTemplate>
      <Form.Item name="description" label="Description">
        <Input.TextArea
          minLength={2}
          autoSize={{
            minRows: 2,
            maxRows: 5,
          }}
        />
      </Form.Item>
    </ModalFormTemplate>
  );
};
