import React from 'react';
import { Tabs, theme, Tree } from 'antd';
import { BasicDataNode } from 'rc-tree/lib/interface';
import { createStyles } from 'antd-style';
import { NavItem } from 'epubjs';
import { BookOutlined, MenuOutlined } from '@ant-design/icons';
import { useResizeDetector } from 'react-resize-detector';
import { useEpubReaderStore } from './EpubReaderStoreProvider';
import { useEpubReaderActions } from './EpubReaderActionsProvider';
import { IEpubReaderProps } from './EpubReader';
import { findSpineItemByEpubcifi } from './EpubUtils';
import { useMobileDetect } from '../../../../hooks';

const useEpubReaderTocStyles = createStyles((props) => {
  return {
    epubReaderTocTree: {
      '.ant-tree-node-content-wrapper': {
        overflow: 'hidden !important',
      },
      '.ant-tree-title': {
        display: 'block',
        width: '100%',
        textAlign: 'start',
        textWrap: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '.ant-tree-indent-unit': {
        width: 10,
      },
    },
    epubReaderTocTabs: {
      '.ant-tabs-content': {
        height: '100%',
      },
      '.ant-tabs-tabpane': {
        height: '100%',
      },
      '.ant-tabs-nav': {
        marginBottom: 0,
      },
    },
  };
});

export type IEpubReaderTocProps = IEpubReaderProps;
const Component: React.FC<IEpubReaderTocProps> = (props) => {
  const { height } = props;
  const { width: resizeWidth, height: resizeHeight, ref: resizeRef } = useResizeDetector();
  const { bookRef, epubReaderState } = useEpubReaderStore();
  const { isOpenToc, handleTocOpenChange } = useEpubReaderActions();
  const { styles } = useEpubReaderTocStyles();
  const { token } = theme.useToken();
  const { isDesktop } = useMobileDetect();

  return (
    <div
      style={{
        minWidth: isOpenToc ? (isDesktop() ? '25%' : '100%') : 0,
        width: isOpenToc ? (isDesktop() ? '25%' : '100%') : 0,
        display: isOpenToc ? 'block' : 'none',
        transition: 'all .1s ease',
        ...(isDesktop() ? { borderRight: `1px solid ${token.colorBorder}` } : {}),
        height: '100%',
        ...(!isOpenToc
          ? {
              transform: 'translateX(-100%)',
            }
          : {}),
      }}
    >
      <Tabs
        className={styles.epubReaderTocTabs}
        style={{ height: '100%' }}
        defaultActiveKey="toc"
        centered
        moreIcon={<></>}
        type="card"
        items={[
          {
            label: (
              <MenuOutlined
                style={{
                  fontSize: 24,
                }}
              />
            ),
            key: 'toc',
            children: (
              <div
                ref={resizeRef}
                style={{
                  height: '100%',
                  padding: 8,
                }}
              >
                <Tree<BasicDataNode & NavItem & { cfi?: string }>
                  height={resizeHeight ? resizeHeight - 8 : undefined}
                  className={styles.epubReaderTocTree}
                  fieldNames={{
                    key: 'href',
                    title: 'label',
                    children: 'subitems',
                  }}
                  selectedKeys={[epubReaderState.location]}
                  onSelect={(selectedKeys, info) => {
                    if (info.node?.href !== bookRef.current?.location) {
                      bookRef.current?.rendition
                        ?.display(info.node?.href)
                        .then((value) => {
                          // console.log({ value });
                          !isDesktop() && handleTocOpenChange(false);
                        })
                        .catch((error) => {
                          console.log({ error });
                          bookRef.current?.rendition?.display(0);
                        });
                    }
                  }}
                  treeData={epubReaderState.toc}
                  blockNode
                  autoExpandParent
                />
              </div>
            ),
          },
          {
            label: (
              <BookOutlined
                style={{
                  fontSize: 24,
                }}
              />
            ),
            key: 'bookmark',
            children: (
              <div
                style={{
                  height: '100%',
                  padding: 8,
                }}
              >
                <></>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export const EpubReaderToc: React.FC<IEpubReaderTocProps> = (props) => {
  return <Component {...props} />;
};
