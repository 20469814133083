import React, { useCallback, useEffect, useState } from 'react';
import { Button, Divider, Flex, Form, FormInstance, FormProps, Modal, ModalProps } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import { IPageTemplateProps } from './PageTemplate';
import { FlexListTemplate } from './FlexListTemplate';

export interface IModalFormTemplateProps<ValuesType = any> extends Omit<IPageTemplateProps, 'footer'>, ModalProps {
  triggerProps?: ButtonProps;
  form?: FormProps<ValuesType>['form'];
  formProps?: Omit<FormProps<ValuesType>, 'form' | 'onValuesChange'>;
  onValuesChange?: (changedValues: any, values: ValuesType, form: FormInstance<ValuesType>) => void;
  outerTrigger?: (props: { setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => React.ReactNode;
  title?: React.ReactNode;
  onFinish?: (values: ValuesType, form: FormInstance<ValuesType>) => Promise<any>;
  okText?: string;
  cancelText?: string;
  isFlex?: boolean;
  layout?: 'vertical' | 'horizontal';
}

const ModalComponent = <ValuesType = any,>(
  props: IModalFormTemplateProps<ValuesType> & { setOpen: React.Dispatch<React.SetStateAction<boolean>> },
): React.ReactElement => {
  const {
    onFinish,
    layout,
    children,
    footer,
    form,
    loading,
    okText = 'Create',
    cancelText = 'Cancel',
    isFlex,
    title,
    triggerProps,
    formProps,
    onValuesChange,
    outerTrigger,
    setOpen,
    ...rest
  } = props;

  const [defForm] = Form.useForm<ValuesType>(form);

  const handleCancel = () => {
    setOpen(false);
    defForm.resetFields();
  };

  const handleFinish: ModalProps['onOk'] = useCallback(
    async (e) => {
      return defForm
        .validateFields()
        .then((values) => {
          if (onFinish) {
            return onFinish(values, defForm);
          }
          return Promise.reject();
        })
        .then((r) => {
          setOpen(false);
        })
        .catch((e) => {
          return e;
        });
    },
    [onFinish],
  );

  const handleValuesChange: FormProps<ValuesType>['onValuesChange'] = (changedValues, values) => {
    if (onValuesChange) {
      onValuesChange(changedValues, values, defForm);
    }
  };

  return (
    <Modal
      onOk={handleFinish}
      onCancel={handleCancel}
      okText={okText}
      cancelText={cancelText}
      title={title}
      destroyOnClose
      // forceRender
      {...rest}
    >
      {rest?.open && (
        <Form<ValuesType> form={defForm} onValuesChange={handleValuesChange} {...formProps}>
          {children}
        </Form>
      )}
    </Modal>
  );
};

export const ModalFormTemplate = <ValuesType = any,>(
  props: IModalFormTemplateProps<ValuesType>,
): React.ReactElement => {
  const { triggerProps, outerTrigger, ...rest } = props;

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      {!outerTrigger ? (
        <Button
          {...{
            ...triggerProps,
            title: triggerProps?.title || 'Modal Form',
          }}
          onClick={() => setOpen(true)}
        >
          {triggerProps?.children}
        </Button>
      ) : (
        outerTrigger({ setOpen })
      )}
      {open && <ModalComponent<ValuesType> open={open} setOpen={setOpen} {...rest} />}
    </>
  );
};

export interface IModalContentFormTemplateProps<ValuesType = any>
  extends Omit<FormProps<ValuesType>, 'onValuesChange' | 'onFinish'> {
  children?: React.ReactNode;
  onValuesChange?: (changedValues: any, values: ValuesType, form: FormInstance<ValuesType>) => void;
  onFinish?: (values: ValuesType, form: FormInstance<ValuesType>) => Promise<any>;
  onCancel?: (form: FormInstance<ValuesType>) => Promise<any>;
  loading?: boolean;
  buttonProps?: {
    cancel?: { label?: React.ReactNode };
    submit?: { label?: React.ReactNode };
  };
}

export const ModalContentFormTemplate = <ValuesType = any,>(
  props: IModalContentFormTemplateProps<ValuesType>,
): React.ReactElement => {
  const { onFinish, buttonProps, onCancel, form, loading, onValuesChange, children, ...rest } = props;

  const [defForm] = Form.useForm<ValuesType>(form);

  const handleCancel: ButtonProps['onClick'] = useCallback(
    (e) => {
      if (onCancel) {
        return onCancel(defForm);
      }
      return defForm.resetFields();
    },
    [onCancel],
  );

  const handleFinish: ButtonProps['onClick'] = useCallback(
    async (e) => {
      return defForm
        .validateFields()
        .then((values) => {
          if (onFinish) {
            return onFinish(values, defForm);
          }
          return Promise.reject();
        })
        .catch((e) => {
          return e;
        });
    },
    [onFinish],
  );

  const handleValuesChange: FormProps<ValuesType>['onValuesChange'] = (changedValues, values) => {
    if (onValuesChange) {
      onValuesChange(changedValues, values, defForm);
    }
  };
  const CancelBtnLabel = buttonProps?.cancel?.label || 'Cancel';
  const SubmitBtnLabel = buttonProps?.submit?.label || 'Submit';

  return (
    <Form form={defForm} onValuesChange={handleValuesChange} {...rest}>
      {children}
      <Divider type="horizontal" />
      <Form.Item>
        <Flex justify="end" gap="1em">
          <Button onClick={handleCancel}>{CancelBtnLabel}</Button>
          <Button type="primary" onClick={handleFinish} loading={loading}>
            {SubmitBtnLabel}
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};
