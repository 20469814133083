import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Col, Layout, Row } from 'antd';
import { BasicProps } from 'antd/es/layout/layout';
import { CONSTANTS } from '../../config';

export const FooterLayout: React.ForwardRefExoticComponent<BasicProps & React.RefAttributes<HTMLDivElement>> =
  forwardRef((props, ref) => {
    const { style, ...rest } = props;
    const now = new Date();
    return (
      <div ref={ref} className="app-footer">
        <Layout.Footer style={{ padding: 16, ...style }} {...rest}>
          <Row justify="space-between" align="stretch">
            <Col>
              Copyright © {now.getFullYear()} {CONSTANTS.APP_COPYRIGHT}
            </Col>
            <Col style={{ textAlign: 'end' }}>
              <Link to="/">Home</Link>
            </Col>
          </Row>
        </Layout.Footer>
      </div>
    );
  });
