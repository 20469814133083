import React, { useCallback, useEffect, useState } from 'react';
import { EpubView, IEpubViewProps } from 'react-reader';
import { NavItem, Rendition } from 'epubjs';
import { GlobalToken } from 'antd/es/theme/interface';
import { Button, Space, Spin, theme } from 'antd';
import {
  FileTextOutlined,
  LeftOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ReadOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { createStyles } from 'antd-style';
import { useResizeDetector } from 'react-resize-detector';
import { useSwipeable } from 'react-swipeable';
import { IEpubReaderStateActionType, useEpubReaderStore } from './EpubReaderStoreProvider';
import { findSpineItemByEpubcifi } from './EpubUtils';
import { useEpubReaderActions } from './EpubReaderActionsProvider';
import { IEpubReaderProps } from './EpubReader';
import { EpubReaderFooter } from './EpubReaderFooter';
import { useDidMountEffect, useMobileDetect } from '../../../../hooks';
import { clutch } from './number';

const useEpubReaderViewStyles = createStyles((props) => {
  return {
    epubReaderViewWrapper: {
      position: 'relative',
      zIndex: 1,
      width: '100%',
      transition: 'all .1s ease',
      '.epubReaderViewLeftNavPane': {
        position: 'absolute',
        left: 0,
        height: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        '.epubReaderViewLeftNavPane_prevNavBtn': {
          height: 'auto',
          width: 'auto',
        },
      },
      '.epubReaderViewRightNavPane': {
        position: 'absolute',
        right: 0,
        height: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        '.epubReaderViewRightNavPane_nextNavBtn': {
          height: 'auto',
          width: 'auto',
        },
      },
      '.epubReaderViewLayout': {
        position: 'absolute',
        // width: '100%',
        height: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        '.epubReaderViewHeader': {
          padding: 6,
        },
        '.epubReaderViewFooter': {
          padding: 6,
        },
        '.epubReaderView': {
          height: '100%',
          width: '100%',
          '.epubReaderViewInnerLayout': {
            position: 'relative',
            height: '100%',
            width: '100%',
          },
          '.epubReaderViewInnerLayoutSpread': {
            '&:after': {
              position: 'absolute',
              width: 1,
              height: '94%',
              borderRight: `1px solid ${props.token.colorBorder}`,
              zIndex: 1,
              left: '50%',
              marginLeft: '-1px',
              transform: 'translateX(-50%)',
              top: '3%',
              bottom: '3%',
              opacity: 0.75,
              boxShadow: props.token.boxShadow,
              content: '""',
            },
          },
        },
      },
      '.ant-progress-line': {
        marginBottom: 0,
      },
    },
    epubReaderLoading: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  };
});

export type IEpubReaderViewProps = IEpubReaderProps;

function updateTheme(rendition: Rendition, theme: GlobalToken) {
  const { themes } = rendition;

  rendition.themes.register('default', {
    ...themes.default,
    // fontSize: '75% !important',
    body: {
      // padding: '0 !important',
      background: `${theme.colorBgContainer}  !important`,
      color: `${theme.colorText}  !important`,
      // 'font-size': `normal  !important`,
    },
    // 'h1, h2, h3, h4, h5, h6': {
    //   // 'font-size': `unset  !important`,
    // },
    h1: {
      'font-size': '2em !important',
      margin: '0.67em 0 !important',
    },
    h2: {
      'font-size': '1.5em !important',
      margin: '0.75em 0 !important',
    },
    h3: {
      'font-size': '1.17em !important',
      margin: '0.83em 0 !important',
    },
    h4: {
      'font-size': '1em !important',
      margin: '0.83em 0 !important',
    },
    h5: {
      'font-size': '0.83em !important',
      margin: '1.5em 0 !important',
    },
    h6: {
      'font-size': '0.75em !important',
      margin: '1.67em 0 !important',
    },
    a: {
      color: `${theme.colorLink} !important`,
      'text-decoration': 'none !important',
      '-webkit-text-fill-color': `${theme.colorLink} !important`,
      '-webkit-any-link': `${theme.colorLink} !important`,
    },
    'a:hover': {
      color: `${theme.colorLinkHover} !important`,
      'text-decoration': 'none !important',
      '-webkit-text-fill-color': `${theme.colorLinkHover} !important`,
      '-webkit-any-link': `${theme.colorLinkHover} !important`,
    },
    'a:active': {
      color: `${theme.colorLinkActive} !important`,
      'text-decoration': 'none !important',
      '-webkit-text-fill-color': `${theme.colorLinkActive} !important`,
      '-webkit-any-link': `${theme.colorLinkActive} !important`,
    },
    'span.pagebreak': {
      display: 'block !important',
      'margin-left': 'auto !important',
      'margin-right': 'auto !important',
      'text-align': 'center !important',
      width: '100% !important',
    },
    '.chapterhead': {
      'padding-top': '0 !important',
    },
  });
  rendition.themes.select('default');
}

const Component: React.FC<IEpubReaderViewProps> = (props) => {
  const { url, height } = props;
  const { token } = theme.useToken();
  const { styles } = useEpubReaderViewStyles();
  const { bookRef, epubReaderState, setEpubReaderState } = useEpubReaderStore();
  const {
    isOpenToc,
    spread,
    handleTocOpenChange,
    isDisableBtnGoPrevPage,
    isDisableBtnGoNextPage,
    handlePrevPageChange,
    handleNextPageChange,
    handleSpreadChange,
    onResizePaneReader,
  } = useEpubReaderActions();
  const { width: resizeWidth, height: resizeHeight, ref: resizeRef } = useResizeDetector();
  useEffect(() => {
    if (bookRef.current?.rendition) {
      updateTheme(bookRef.current?.rendition, token);
    }
  }, [token]);
  const { isDesktop } = useMobileDetect();

  const handleLocationChange: IEpubViewProps['locationChanged'] = (epubCfi) => {
    if (bookRef.current) {
      let currentChapterId = findSpineItemByEpubcifi(bookRef.current.book, epubCfi)?.href;
      if (!currentChapterId) {
        // @ts-ignore
        currentChapterId = bookRef.current.book?.spine?.spineItems[0]?.href;
      }

      if (currentChapterId) {
        setEpubReaderState({ type: IEpubReaderStateActionType.SET_LOCATION, payload: currentChapterId });
      }
    }
  };

  const handleTocChange: IEpubViewProps['tocChanged'] = (toc) => {
    setEpubReaderState({ type: IEpubReaderStateActionType.SET_TOC, payload: toc });
  };

  const handleRenditionChange: IEpubViewProps['getRendition'] = (rendition) => {
    const spine_get = rendition.book.spine.get.bind(rendition.book.spine);
    rendition.book.spine.get = (target) => {
      let t = spine_get(target) || spine_get(`xhtml/${target}`);
      if (!t) {
        t = spine_get(0);
      }
      return t;
    };
    rendition.book.locations.generate(16000);
    updateTheme(rendition, token);
    setEpubReaderState({ type: IEpubReaderStateActionType.SET_RENDITION, payload: rendition });
  };

  useDidMountEffect(() => {
    onResizePaneReader(resizeWidth);
  }, [resizeWidth]);

  return (
    <div
      className={`${styles.epubReaderViewWrapper} epubReaderViewWrapper`}
      style={{
        height,
      }}
    >
      {isDesktop() && (
        <>
          <div className="epubReaderViewLeftNavPane">
            <Button
              disabled={!bookRef.current?.state.isLoaded || isDisableBtnGoPrevPage}
              type="text"
              className="epubReaderViewLeftNavPane_prevNavBtn"
              icon={
                <LeftOutlined
                  style={{
                    fontSize: 48,
                  }}
                />
              }
              onClick={handlePrevPageChange}
            />
          </div>
          <div className="epubReaderViewRightNavPane">
            <Button
              disabled={!bookRef.current?.state.isLoaded || isDisableBtnGoNextPage}
              type="text"
              className="epubReaderViewRightNavPane_nextNavBtn"
              icon={
                <RightOutlined
                  style={{
                    fontSize: 48,
                  }}
                />
              }
              onClick={handleNextPageChange}
            />
          </div>
        </>
      )}
      <div
        className="epubReaderViewLayout"
        style={
          isDesktop()
            ? {
                left: 50,
                right: 50,
              }
            : {
                left: 0,
                right: 0,
              }
        }
      >
        {isDesktop() && (
          <Space className="epubReaderViewHeader">
            <Button
              type="text"
              style={{
                height: 'auto',
                width: 'auto',
              }}
              icon={
                isOpenToc ? (
                  <MenuFoldOutlined
                    style={{
                      fontSize: 24,
                    }}
                  />
                ) : (
                  <MenuUnfoldOutlined
                    style={{
                      fontSize: 24,
                    }}
                  />
                )
              }
              onClick={() => {
                handleTocOpenChange();
              }}
            />
            <Button
              disabled={!bookRef.current?.state.isLoaded}
              type="text"
              style={{
                height: 'auto',
                width: 'auto',
              }}
              icon={
                spread === 'none' ? (
                  <ReadOutlined
                    style={{
                      fontSize: 24,
                    }}
                  />
                ) : (
                  <FileTextOutlined
                    style={{
                      fontSize: 24,
                    }}
                  />
                )
              }
              onClick={() => {
                handleSpreadChange();
              }}
            />
          </Space>
        )}
        <div
          className="epubReaderView"
          style={isDesktop() ? { border: `1px solid ${token.colorBorder}` } : {}}
          ref={resizeRef}
        >
          <div className={`epubReaderViewInnerLayout${spread === 'none' ? '' : ' epubReaderViewInnerLayoutSpread'}`}>
            <EpubView
              epubViewStyles={{
                /* Grow blocks heights. */
                view: {
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  width: '100%',
                },
                viewHolder: {
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                },
                /* ============ */
              }}
              loadingView={
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <Spin spinning>Loading...</Spin>
                </div>
              }
              ref={bookRef}
              url={url}
              location={epubReaderState.location}
              epubInitOptions={{
                openAs: 'epub',
              }}
              epubOptions={{
                allowPopups: true,
                allowScriptedContent: true,
                spread,
              }}
              locationChanged={handleLocationChange}
              tocChanged={handleTocChange}
              getRendition={handleRenditionChange}
            />
          </div>
        </div>
        <div className="epubReaderViewFooter">
          <EpubReaderFooter {...props} key="footer" />
        </div>
      </div>
    </div>
  );
};

export const EpubReaderView: React.FC<IEpubReaderViewProps> = (props) => {
  return <Component {...props} />;
};
