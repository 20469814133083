import { Button } from 'antd';
import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { ManagerPublicationEpubListItemFragment } from '../../../services/apolloGraphqlAppApiClient/models/types';

export const DownloadPublicationEpubActionButton = (record: ManagerPublicationEpubListItemFragment) => {
  return (
    <Button type="link" icon={<DownloadOutlined />} href={record.uri}>
      Download
    </Button>
  );
};
