import { Button, ConfigProvider, Layout, Space } from 'antd';
import React, { forwardRef } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { BasicProps } from 'antd/es/layout/layout';
import { useMobileDetect, useWindowDimensions } from '../../hooks';
import { ProfileSection, TitleSection } from './index';
import { AppThemeSwitcher, useAppThemeContext } from '../../themes';
import { FlexListTemplate } from '../../components';
import { IUseAppLayoutResult } from '../hooks';

export interface IHeaderLayoutProps extends BasicProps, React.RefAttributes<HTMLElement> {
  appLayout: IUseAppLayoutResult;
  withProfile?: boolean;
}

export const HeaderLayoutComponent: React.FC<React.PropsWithChildren<IHeaderLayoutProps>> = ({
  withProfile = true,
  appLayout,
  ...rest
}) => {
  const { width } = useWindowDimensions();
  const { isMobile, isDesktop } = useMobileDetect();

  const { themes } = useAppThemeContext();
  return (
    <ConfigProvider theme={themes.dark.antdTheme}>
      <Layout.Header
        style={{
          position: 'fixed',
          width: '100vw',
          height: appLayout.headerHeight,
          zIndex: 111,
          paddingInline: isMobile() ? 5 : 25,
          top: 0,
        }}
        {...rest}
      >
        <FlexListTemplate
          justifyContent="space-between"
          alignItems="center"
          gap={8}
          flexWrap="nowrap"
          style={{ width: '100%', height: '100%' }}
        >
          <TitleSection />
          {isDesktop() && width > 880 && (
            <Space wrap={false} align="center" style={{ height: '100%', textOverflow: 'ellipsis' }}>
              <AppThemeSwitcher />
              <ProfileSection />
            </Space>
          )}
          {isMobile() && appLayout && (
            <Button
              type="text"
              size="large"
              icon={appLayout?.manualCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => {
                appLayout?.setManualCollapsed(!appLayout?.manualCollapsed);
              }}
            />
          )}
        </FlexListTemplate>
      </Layout.Header>
    </ConfigProvider>
  );
};

export const HeaderLayout: React.ForwardRefExoticComponent<IHeaderLayoutProps> = forwardRef((props, ref) => {
  return <HeaderLayoutComponent {...props} ref={ref} />;
});
