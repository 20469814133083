import { ItemType } from 'antd/es/menu/hooks/useItems';
import { ManagerPublicationListItemFragment } from '../../../../../services/apolloGraphqlAppApiClient/models/types';
import { useSetPublicationPermissionAction } from './useSetPublicationPermissionAction';
import { useSetPublicationTocDepthAction } from './useSetPublicationTocDepthAction';
import { useSetPublicationMetaDataAction } from './useSetPublicationMetaDataAction';
import { useManagePublicationCoversAction } from './useManagePublicationCoversAction';
import { useManagePublicationEpubsAction } from './useManagePublicationEpubsAction';
import { useManagePublicationChaptersAction } from './useManagePublicationChaptersAction';

export interface IUseContextPublicationActionsProps {
  publicationData: ManagerPublicationListItemFragment;
}

export type IUseContextPublicationActionsResult = ItemType[];

export const useContextPublicationActions = (
  props: IUseContextPublicationActionsProps,
): IUseContextPublicationActionsResult => {
  const { publicationData } = props;
  const { setPublicationMetaDataAction } = useSetPublicationMetaDataAction({ publicationData });
  const { setPublicationPermissionAction } = useSetPublicationPermissionAction({ publicationData });
  const { setPublicationTocDepthAction } = useSetPublicationTocDepthAction({ publicationData });
  const { managePublicationCoversAction } = useManagePublicationCoversAction({ publicationData });
  const { managePublicationEpubsAction } = useManagePublicationEpubsAction({ publicationData });
  const { managePublicationChaptersAction } = useManagePublicationChaptersAction({ publicationData });

  return [
    {
      key: 'publicationName',
      label: publicationData.title.length < 15 ? publicationData.title : `${publicationData.title.slice(0, 12)}...`,
      type: 'group',
    },
    setPublicationPermissionAction,
    setPublicationTocDepthAction,
    setPublicationMetaDataAction,
    managePublicationCoversAction,
    managePublicationEpubsAction,
    managePublicationChaptersAction,
  ];
};
