import React, { useState } from 'react';
import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import { BasicDataNode } from 'antd/es/tree';
import {
  GetManagerFolderTreeListDocument,
  GetManagerFolderTreeListQuery,
  GetManagerFolderTreeListQueryHookResult,
  GetManagerFolderTreeListQueryVariables,
  ManagerFolderTreeListItemFragment,
  useGetManagerFolderTreeListQuery,
} from '../../../../services/apolloGraphqlAppApiClient/models/types';
import { IUseAntdTreeDataResult, useAntdTreePropsData } from '../../../../hooks';
import { usePublicationsManagerStoreContext } from '../Manager';
import { ManagerSelectedFolderType } from '../Manager/usePublicationsManagerStore';

export type TFoldersStoreFilter = GetManagerFolderTreeListQueryVariables['where'];
export type TFoldersStoreSort = GetManagerFolderTreeListQueryVariables['order'];

export type ManagerFolderTreeNodeType = BasicDataNode &
  ManagerFolderTreeListItemFragment & {
    children?: ManagerFolderTreeNodeType[];
  };

export interface IUseFoldersTreeStoreResult extends IUseAntdTreeDataResult<ManagerFolderTreeNodeType> {
  foldersStore: GetManagerFolderTreeListQueryHookResult;
  draggedFolder: ManagerSelectedFolderType | null;
  setDraggedFolder: React.Dispatch<React.SetStateAction<ManagerSelectedFolderType | null>>;
  refetchFolders: () => void;
}

export const unpublishedFolder: ManagerFolderTreeNodeType = {
  id: 0,
  title: 'Unpublished',
  childFolderCount: 0,
  childPublicationCount: 0,
  childPublicationCountRecursive: 0,
  order: -1,
  parentId: null,
  path: [],
  typeId: 'BOOK',
  createdAt: 'any',
  updatedAt: 'any',
  parents: [],
  isLeaf: true,
  selectable: true,
};

export const useFoldersTreeStore = (): IUseFoldersTreeStoreResult => {
  const [folderList, setFolderList] = useState<ManagerFolderTreeNodeType[]>([]);
  const { historySearchProps } = usePublicationsManagerStoreContext();
  const apolloGraphqlAppClient = useApolloClient();
  const [draggedFolder, setDraggedFolder] = useState<ManagerSelectedFolderType | null>(null);

  const { treeData, loadedKeys, loadData, setLoadedKeys, expandOptions, setExpandOptions, onExpand, toggleExpandNode } =
    useAntdTreePropsData<ManagerFolderTreeNodeType, TFoldersStoreFilter>({
      data: folderList,
      setSelectable: (dataNode) => dataNode?.childFolderCount === 0,
      treeOptions: {
        idKey: 'id',
        parentKey: 'parentId',
        titleKey: 'title',
        childrenKey: 'children',
        childCountKey: 'childFolderCount',
      },
      setLoadDataFetchProps: (treeNode) => ({ parentId: { eq: treeNode?.id } }),
      fetchData: async (callback, params) => {
        try {
          const response: ApolloQueryResult<GetManagerFolderTreeListQuery> = await apolloGraphqlAppClient.query({
            query: GetManagerFolderTreeListDocument,
            variables: { where: { parentId: params.parentId } },
          });
          const {
            data: { folders },
          } = response;
          if (Array.isArray(folders)) {
            callback([...folders]);
          }
        } catch (error: any) {
          // console.log({ error });
        }
      },
    });

  const foldersStore: GetManagerFolderTreeListQueryHookResult = useGetManagerFolderTreeListQuery({
    variables: {
      where: historySearchProps?.filter?.folders
        ? { title: { contains: historySearchProps.filter.folders } }
        : {
            // parentId: {
            //   in: [null, ...(expandOptions.expandedKeys as number[])],
            // },
          },
    },
    onCompleted: (newData) => {
      if (historySearchProps?.filter?.folders) {
        const newTree = [...newData.folders] as ManagerFolderTreeNodeType[];
        const parents = newData.folders
          .map((f) => {
            return f.parents;
          })
          .filter((f) => f !== undefined)
          .flat() as ManagerFolderTreeNodeType[];
        setLoadedKeys(parents.map((p) => p.id));
        newTree.push(...parents);
        setFolderList(newTree.filter((el, i) => newTree.findIndex((obj, j) => obj?.id === el?.id) === i));
        return;
      }
      setLoadedKeys([]);
      setFolderList([...newData.folders]);
    },
    notifyOnNetworkStatusChange: true,
  });

  const refetchFolders = () => {
    foldersStore.refetch({
      where: historySearchProps?.filter?.folders
        ? { title: { contains: historySearchProps?.filter?.folders } }
        : {
            // parentId: {
            //   in: [null, ...(expandOptions.expandedKeys as number[])],
            // },
          },
    });
  };

  return {
    foldersStore,
    treeData: [unpublishedFolder, ...treeData],
    loadData,
    loadedKeys,
    expandOptions,
    setExpandOptions,
    onExpand,
    toggleExpandNode,
    setLoadedKeys,
    draggedFolder,
    setDraggedFolder,
    refetchFolders,
  };
};
