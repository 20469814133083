import React from 'react';
import Icon, { CodeOutlined, DatabaseOutlined, FileImageOutlined, HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Avatar, Space, Typography } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { ReactComponent as SelectOutlined } from '../assets/images/SelectOutlined.svg';
import { ReactComponent as LogOutOutlined } from '../assets/images/LogOutOutlined.svg';
import { ReactComponent as ProposalOutlined } from '../assets/images/ProposalOutlined.svg';
import { ReactComponent as Periodicals } from '../assets/images/egw_font/periodicals.svg';
import { CONSTANTS, PUBLICATION_TYPE_ICONS } from './index';
import { getGravatar } from '../utils';

export type TMenu = ItemType & {
  className?: string;
  path?: string;
  roles?: string[];
  children?: TMenu[];
  label?: React.ReactNode;
  icon?: React.ReactNode;
};

const SIDEBAR_MENU: TMenu[] = [
  {
    key: '/',
    path: '/',
    icon: <HomeOutlined />,
    label: <Link to="/">Home</Link>,
  },
  {
    key: 'publications',
    icon: <Periodicals width="1em" />,
    label: 'Publications',
    children: [
      {
        key: '/publications',
        path: '/publications',
        icon: <DatabaseOutlined />,
        label: (
          <Link replace to="/publications">
            Manager
          </Link>
        ),
      },
      {
        key: '/coverTypes',
        path: '/coverTypes',
        icon: <FileImageOutlined />,
        label: (
          <Link replace to="/coverTypes">
            Cover Types
          </Link>
        ),
      },
    ],
  },
  ...(process.env.NODE_ENV !== 'production'
    ? [
        {
          key: 'dev',
          icon: <CodeOutlined />,
          label: 'Dev',
          children: [
            {
              path: '/dev/services',
              key: '/dev/services',
              label: <Link to="/dev/services">Services Tests</Link>,
            },
            {
              path: '/dev/editor',
              key: '/dev/editor',
              label: <Link to="/dev/editor">Text Editor</Link>,
            },
          ],
        },
      ]
    : []),
];

const PROFILE_MENU = (profile, options: { logoSize?: number; [key: string]: any } = {}): TMenu[] => {
  const LOGO_SIZE = options?.logoSize || 'large';
  const authService = options?.authService;
  return [
    {
      key: 'profileLabel',
      icon: <Avatar shape="square" size={LOGO_SIZE} src={getGravatar(profile?.email, { size: '64' })} />,
      label: (
        <Space
          size={0}
          direction="vertical"
          wrap={false}
          align="start"
          // style={{ lineHeight: 'inherit' }}
          // className="ant-menu-title-content"
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            lineHeight: 1.3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Typography.Text ellipsis>{`${profile?.first_name} ${profile?.last_name}`}</Typography.Text>
        </Space>
      ),
      children: [
        {
          key: 'profile',
          label: 'Profile',
          icon: <Icon component={SelectOutlined} />,
          onClick: () => {
            const url = `${CONSTANTS.APP_API_SESSION_PROVIDER}/profile`;
            window.open(url, '_blanc');
          },
        },
        {
          key: 'preLogout',
          type: 'divider',
        },
        {
          key: 'logout',
          icon: <Icon style={{ color: 'red' }} component={LogOutOutlined} />,
          label: <span style={{ color: 'red' }}>Log Out</span>,
          onClick: () => {
            authService && authService.removeUser();
          },
        },
      ],
    },
  ];
};

export default { PROFILE_MENU, SIDEBAR_MENU };
