import { useActive, useCommands } from '@remirror/react';
import React, { FC, useCallback } from 'react';
import { CommandButton, ICommandButtonProps } from '../../../components';
import { WFormatAllCupsIcon } from './WFormatAllcupsIcon';
import { WFormatAllCapsExtension } from '../WFormatAllCapsExtension';

export type IToggleWFormatAllCapsButtonProps = Omit<
  ICommandButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'
>;

export const ToggleWFormatAllCapsButton: FC<IToggleWFormatAllCapsButtonProps> = (props) => {
  const type = 'all-caps';
  const { toggleWFormatAllCaps: command, focus } = useCommands<WFormatAllCapsExtension>();
  const active = useActive<WFormatAllCapsExtension>().wFormatAllCaps({
    type,
  });
  const enabled = command.enabled({ type });

  const handleClick = useCallback(() => {
    if (enabled) {
      command({ type });
      focus();
    }
  }, [command]);

  return (
    <CommandButton
      {...props}
      commandName="toggleWFormatAllCaps"
      active={active}
      enabled={enabled}
      onSelect={handleClick}
      icon={<WFormatAllCupsIcon />}
    />
  );
};
