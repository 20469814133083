import { App, Form, ModalFuncProps } from 'antd';
import React, { useRef } from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { FolderAddOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';
import { useApolloClient } from '@apollo/client';
import { DebounceSelect, FlexListTemplate } from '../../../../../components';
import {
  CreateRootFolderForLanguageInput,
  GetManagerLanguageListDocument,
  GetManagerLanguageListQuery,
  LanguageDtoFilterInput,
  ManagerFolderTreeListItemFragment,
  useCreateRootFolderForLanguageMutation,
} from '../../../../../services/apolloGraphqlAppApiClient/models/types';
import { useFoldersTreeStoreContext } from '../FoldersTreeStoreProvider';

export interface IUseCreateSubFolderActionProps {
  folderData: ManagerFolderTreeListItemFragment;
}

export interface IUseCreateSubFolderActionResult {
  createRootFolderAction: ItemType;
}

export const useCreateRootFolderAction = (props: IUseCreateSubFolderActionProps): IUseCreateSubFolderActionResult => {
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();
  const { folderData } = props;
  const apolloGraphqlAppClient = useApolloClient();
  const { refetchFolders } = useFoldersTreeStoreContext();
  const menuItemKey = `CreateRootFolderAction-${folderData.id}`;
  const infoKey = uuidV4();
  const modalRef = useRef<ReturnType<typeof confirm>>();

  const visible = folderData.id === 0;

  const [createRootFolderForm] = Form.useForm<CreateRootFolderForLanguageInput>();

  const [createRootFolder] = useCreateRootFolderForLanguageMutation({
    onCompleted: (result) => {
      refetchFolders();
      info({
        placement: 'topRight',
        message: `New root Folder create status: true`,
        description: (
          <FlexListTemplate gap={5} alignItems="left" justifyContent="left" style={{ flexDirection: 'column' }}>
            {/* info */}
          </FlexListTemplate>
        ),
        key: infoKey,
        duration: 7,
      });
    },
    onError: (e) => {
      return e;
    },
  });

  const handleOk: ModalFuncProps['onOk'] = () => {
    createRootFolderForm
      .validateFields()
      .then((values) => {
        return createRootFolder({
          variables: {
            input: { ...values },
          },
        });
      })
      .then((res) => {
        // createRootFolderForm.resetFields();
        if (modalRef.current) {
          modalRef.current.destroy();
        }
      })
      .catch((e) => {
        return e;
      });
  };

  const handleCancel = () => {
    createRootFolderForm.resetFields();
    if (modalRef.current) {
      modalRef.current.destroy();
    }
  };

  return {
    createRootFolderAction: visible
      ? {
          key: menuItemKey,
          icon: <FolderAddOutlined />,
          label: 'Create Folder',
          onClick: (menuInfo) => {
            menuInfo.domEvent.preventDefault();
            menuInfo.domEvent.stopPropagation();
            modalRef.current = confirm({
              title: `Create New language root Folder!`,
              onOk: handleOk,
              onCancel: handleCancel,
              okText: 'Create Folder',
              content: (
                <Form
                  initialValues={{ code: '' }}
                  layout="vertical"
                  form={createRootFolderForm}
                  name="createRootFolderForm"
                >
                  <Form.Item
                    name="code"
                    label="Language"
                    valuePropName="value"
                    rules={[
                      {
                        required: true,
                        message: `Please select language!`,
                      },
                    ]}
                  >
                    <DebounceSelect
                      style={{ width: '100%' }}
                      placeholder="Select language"
                      fetchCallback={async (value) => {
                        try {
                          const response = await apolloGraphqlAppClient.query<
                            GetManagerLanguageListQuery,
                            { where: LanguageDtoFilterInput }
                          >({
                            query: GetManagerLanguageListDocument,
                            variables: {
                              where: {
                                rootFolderId: { eq: null },
                                ...(value
                                  ? {
                                      or: [
                                        { bcp47Code: { contains: value } },
                                        { code: { contains: value } },
                                        { title: { contains: value } },
                                      ],
                                    }
                                  : {}),
                              },
                            },
                          });
                          const {
                            data: { languages },
                          } = response;
                          return languages.map((lang) => ({
                            label: lang.title,
                            value: lang.code,
                          }));
                        } catch (e) {
                          return [];
                        }
                      }}
                    />
                  </Form.Item>
                </Form>
              ),
            });
          },
        }
      : null,
  };
};
