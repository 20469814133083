import { CommandMenuItem, CommandMenuItemProps } from '@remirror/react';
import { useActive, useCommands } from '@remirror/react-core';
import React, { FC, useCallback } from 'react';

import { IUseWNonEgwState, useWNonEgwState, WNonEgwAttributes, WNonEgwExtension } from '../../extensions';

export interface IToggleWNonEgwDropdownMenuItemProps
  extends Omit<CommandMenuItemProps, 'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'> {
  attrs: WNonEgwAttributes;
  state: IUseWNonEgwState;
}

export const ToggleWNonEgwDropdownMenuItem: FC<IToggleWNonEgwDropdownMenuItemProps> = (props) => {
  const { attrs, state, ...rest } = props;

  const handleSelect = useCallback(() => {
    // if (enabled) {
    //   console.log(enabled);
    state.handleSubmit(attrs);
    // }
  }, [state.handleSubmit, attrs]);

  return (
    <CommandMenuItem
      {...rest}
      commandName="toggleWNonEgw"
      active={state.active(attrs)}
      enabled={state.enabled}
      attrs={attrs}
      onSelect={handleSelect}
    />
  );
};
