/* eslint-disable curly */

import { NavItem } from 'epubjs';

export const CFI_PREFIX = 'epubcfi(';
export const CFI_POSTFIX_FIRST_SYMBOL = '!';

export const isValidEpubHref = (str) => !!str?.match(/.(x)?html/gm);

export const isHasXhtmlPath = (str) => !!str?.match(/xhtml\/.*.(x)?html/gm);

export const findSpineItemByEpubcifi = (epubBook, epubcifi) => {
  return epubBook?.spine?.spineItems?.find((item) => {
    return epubcifi.indexOf(CFI_PREFIX + item.cfiBase + CFI_POSTFIX_FIRST_SYMBOL) !== -1;
  });
};
export const getChapterElement = (body) => {
  return body.querySelector('.chapter');
};

export const getLastParaElementInSpineChapter = (spine) => {
  const chapter = getChapterElement(spine.document.body);

  if (chapter) {
    return chapter.children[chapter.children.length - 1];
  }

  return null;
};

export const isItemActive = (item, currHref) => {
  const { subitems } = item;
  const isItemFound = item.href === currHref;
  if (!subitems) {
    return isItemFound;
  }
  if (!subitems.length || isItemFound) {
    return isItemFound;
  }
  let result = false;
  for (let i = 0; i < subitems.length; i++) {
    result = isItemActive(subitems[i], currHref);
    if (result) {
      break;
    }
  }
  return result;
};

export const findChapterByTitle = (toc, title) => {
  if (!toc || !title) {
    return null;
  }

  const suitableTitle = title.replace('’', "'");
  return toc.find((c) => (c?.title || c?.label || '').replace('’', "'") === suitableTitle);
};

export const findChapterByParaId = (item, paraId) => {
  if (!item) {
    return null;
  }
  if (item.para_id === paraId) {
    return item;
  }
  const { chapters } = item;
  if (!chapters || !chapters.length) {
    return null;
  }
  let chapter = null;
  for (let i = 0; i < chapters.length; i++) {
    chapter = findChapterByParaId(chapters[i], paraId);
    if (chapter) {
      break;
    }
  }
  return chapter;
};

export const getItemsRecursive = (item: NavItem): NavItem[] => {
  if (!item.subitems || !item.subitems.length) {
    return [];
  }
  const { subitems } = item;
  const allItems: NavItem[] = [];
  for (let i = 0; i < subitems.length; i++) {
    const child = subitems[i];
    allItems.push(child);
    allItems.push(...getItemsRecursive(child));
  }
  return allItems;
};

export const createMapByKey = (arr: NavItem[], key: string): Record<string, NavItem> | null => {
  if (!arr || !arr.length) {
    return null;
  }
  const resultDic: Record<string, NavItem> = {};
  for (let i = 0; i < arr.length; i++) {
    const child = arr[i];
    const items = getItemsRecursive(child);
    resultDic[child[key]] = child;
    items.forEach((item) => {
      resultDic[item[key]] = item;
    });
  }
  return resultDic;
};

export const performSearch = async (query, book) => {
  const results = [];

  let item;

  for (let i = 0; i < book.spine.spineItems.length; i++) {
    item = book.spine.spineItems[i];

    // eslint-disable-next-line no-await-in-loop
    await item.load(book.load.bind(book));

    results.push(
      // @ts-ignore
      // eslint-disable-next-line no-loop-func
      ...item.find(query).map((r) => ({
        ...r,
        spineItemHref: item.href,
        spineItemIndex: item.index,
      })),
    );

    // eslint-disable-next-line no-await-in-loop
    await item.unload();
  }

  return results;
};
