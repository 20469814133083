import React, { forwardRef } from 'react';
import { Layout } from 'antd';
import { BasicProps } from 'antd/es/layout/layout';
import { IUseAppLayoutResult } from '../hooks';

export interface IContentLayoutProps extends BasicProps, React.RefAttributes<HTMLElement> {
  appLayout: IUseAppLayoutResult;
}

export const ContentLayoutComponent: React.FC<React.PropsWithChildren<IContentLayoutProps>> = ({
  appLayout,
  ...rest
}) => {
  return <Layout.Content {...rest} />;
};

export const ContentLayout: React.ForwardRefExoticComponent<IContentLayoutProps> = forwardRef((props, ref) => {
  return <ContentLayoutComponent {...props} ref={ref} />;
});
