import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CONSTANTS } from '../config';
import { SwitchRoutes } from './SwitchRoutes';

export const RootRouter = ({ ...rest }): React.ReactElement => {
  return (
    <BrowserRouter basename={CONSTANTS.APP_ROOT_URL_ENDPOINT}>
      <SwitchRoutes />
    </BrowserRouter>
  );
};
