import { Box, ClickAwayListener, Divider, Fade, Paper, Popper, PopperPlacementType, Typography } from '@mui/material';
import React, { ReactElement, ReactNode } from 'react';

import { PopoverProvider } from './PopoverProvider';

interface RichTooltipProps {
  content: ReactElement;
  children: ReactElement;
  open: boolean;
  onClose?: () => void;
  arrow?: boolean;
  placement?: PopperPlacementType;
  title?: ReactNode;
}

const classes = (theme) => {
  const color = theme.palette.background.paper;
  return {
    popoverRoot: {
      backgroundColor: color,
      marginTop: '0.5em',
      maxWidth: 500,
      // border: '1px solid gray',
    },
    content: {
      padding: theme.spacing(2),
    },
    // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js and https://github.com/mui-org/material-ui/blob/4f2a07e140c954b478a6670c009c23a59ec3e2d4/docs/src/pages/components/popper/ScrollPlayground.js
    popper: {
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-1em',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '0 100%',
        },
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-1em',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '100% 0',
        },
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '-1em',
        height: '1em',
        width: '0.71em',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '100% 100%',
        },
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: '-1em',
        height: '1em',
        width: '0.71em',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '0 0',
        },
      },
    },
    // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
    arrow: {
      position: 'absolute',
      width: '1em',
      height: '0.5em' /* = width / sqrt(2) = (length of the hypotenuse) */,
      boxSizing: 'border-box',
      color,
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: '100%',
        // boxShadow: theme.shadows[1],
        height: '100%',
        ...(theme.palette?.mode === 'dark'
          ? { backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))' }
          : {}),
        backgroundColor: 'currentColor',
        transform: 'rotate(45deg)',
      },
    },
  };
};

export const RichTooltip = ({
  placement = 'top',
  arrow = true,
  open,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose = () => {},
  content,
  children,
  title = 'RichTooltipTitle',
}: RichTooltipProps) => {
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  return (
    <>
      {React.cloneElement(children, { ...children.props, ref: setAnchorEl })}
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        sx={(theme) => {
          return classes(theme).popper;
        }}
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'window',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'window',
            },
          },
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={onClose}>
                <Paper
                  sx={(theme) => {
                    return classes(theme).popoverRoot;
                  }}
                >
                  {arrow ? (
                    <Box
                      component="span"
                      sx={(theme) => {
                        return classes(theme).arrow;
                      }}
                      ref={setArrowRef}
                    />
                  ) : null}
                  <Box>
                    <Typography sx={{ p: 1 }}>{title}</Typography>
                    <Divider />
                    <Box
                      sx={(theme) => {
                        return classes(theme).content;
                      }}
                    >
                      {content}
                    </Box>
                  </Box>
                </Paper>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};
