import React, { forwardRef } from 'react';
import { Descriptions, Flex, Typography } from 'antd';
import { usePublicationsManagerStoreContext } from './PublicationsManagerStoreProvider';
import { ManagerBreadcrumb } from './ManagerBreadcrumb';
import { ManagerFilters } from './ManagerFilters';
import { ManagerPublicationTypeIcon } from './ManagerPublicationTypeIcon';

export type IManagerHeaderComponent = React.PropsWithChildren;

export const ManagerHeaderComponent: React.FC<IManagerHeaderComponent> = (props) => {
  const { selectedFolder } = usePublicationsManagerStoreContext();
  return (
    <Flex justify="space-between" {...props} wrap="wrap" gap={5} style={{ width: '100%' }}>
      <Flex justify="start" wrap="nowrap" gap={5} style={{ flex: 'auto' }}>
        <ManagerPublicationTypeIcon type={selectedFolder?.type?.allowedTypes[0]} />
        <Typography.Text>{selectedFolder?.title || 'Unpublished'}</Typography.Text>
        <Descriptions
          column={1}
          style={{ marginLeft: 'auto' }}
          bordered
          labelStyle={{ padding: '0 2px', width: 10, lineHeight: 'unset' }}
          contentStyle={{
            padding: '0',
          }}
        >
          <Descriptions.Item className="managerHeader--breadcrumb" label=">" span={1}>
            <ManagerBreadcrumb />
          </Descriptions.Item>
        </Descriptions>
      </Flex>

      <Flex justify="start" wrap="wrap" gap={5}>
        <ManagerFilters />
      </Flex>
    </Flex>
  );
};

export const ManagerHeader: React.ForwardRefExoticComponent<
  IManagerHeaderComponent & React.RefAttributes<HTMLDivElement>
> = forwardRef((props, ref) => {
  return (
    <div ref={ref} style={{ width: '100%' }}>
      <ManagerHeaderComponent {...props} />
    </div>
  );
});
