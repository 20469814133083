import { App, Form, Input, InputNumber, ModalFuncProps, Space, Tag, Typography } from 'antd';
import React, { useRef } from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { EditOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';
import { useApolloClient } from '@apollo/client';
import { DebounceSelect, FlexListTemplate } from '../../../../../components';
import {
  GetAuthorListDocument,
  GetAuthorListQuery,
  GetAuthorListQueryVariables,
  ManagerPublicationListItemFragment,
  SetPublicationMetadataInput,
  useSetPublicationMetaDataMutation,
} from '../../../../../services/apolloGraphqlAppApiClient/models/types';
import { usePublicationListStoreContext } from '../PublicationListStoreProvider';
import { PublicationDescriptionEditor } from './PublicationDescriptionEditor';

export interface IUseSetPublicationMetaDataActionProps {
  publicationData: ManagerPublicationListItemFragment;
}

export interface IUseSetPublicationMetaDataActionResult {
  setPublicationMetaDataAction: ItemType;
}

export const useSetPublicationMetaDataAction = (
  props: IUseSetPublicationMetaDataActionProps,
): IUseSetPublicationMetaDataActionResult => {
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();
  const { publicationData } = props;
  const { refetch } = usePublicationListStoreContext();
  const apolloGraphqlAppClient = useApolloClient();

  const menuItemKey = `SetPublicationMetaDataAction-${publicationData.publicationId}`;
  const infoKey = uuidV4();
  const modalRef = useRef<ReturnType<typeof confirm>>();

  const visible = true;

  const [setPublicationMetaDataForm] = Form.useForm<SetPublicationMetadataInput>();

  const [setPublicationMetaDataAction] = useSetPublicationMetaDataMutation({
    onCompleted: (result) => {
      // refetch();
      info({
        placement: 'topRight',
        message: `SetPublicationMetaData status: true`,
        description: (
          <FlexListTemplate gap={5} alignItems="left" justifyContent="left" style={{ flexDirection: 'column' }}>
            {/* info */}
          </FlexListTemplate>
        ),
        key: infoKey,
        duration: 7,
      });
      refetch(
        result.setPublicationMetadata?.publicationDto?.order
          ? result.setPublicationMetadata.publicationDto.order - 1
          : undefined,
      );
    },
    onError: (e) => {
      return e;
    },
  });

  const handleOk: ModalFuncProps['onOk'] = () => {
    setPublicationMetaDataForm
      .validateFields()
      .then((values) => {
        return setPublicationMetaDataAction({
          variables: {
            input: { ...values },
          },
        });
      })
      .then((res) => {
        // setPublicationMetaDataForm.resetFields();
        if (modalRef.current) {
          modalRef.current.destroy();
        }
      })
      .catch((e) => {
        return e;
      });
  };

  const handleCancel = () => {
    setPublicationMetaDataForm.resetFields();
    if (modalRef.current) {
      modalRef.current.destroy();
    }
  };

  return {
    setPublicationMetaDataAction: visible
      ? {
          key: menuItemKey,
          icon: <EditOutlined />,
          label: 'Set Meta Data',
          onClick: (menuInfo) => {
            menuInfo.domEvent.preventDefault();
            menuInfo.domEvent.stopPropagation();
            modalRef.current = confirm({
              title: `Set Meta Data for "${publicationData.title}" Publication!`,
              width: '75%',
              onOk: handleOk,
              onCancel: handleCancel,
              okText: 'Set Meta Data',
              content: (
                <Form
                  initialValues={{
                    authorId: publicationData.authorId,
                    code: publicationData.code,
                    description: publicationData.description,
                    isbn: publicationData.isbn,
                    pageCount: publicationData.pageCount,
                    publicationId: publicationData.publicationId,
                    publicationYear: publicationData.publicationYear,
                    publisher: publicationData.publisher,
                    purchaseLink: publicationData.purchaseLink,
                    title: publicationData.title,
                  }}
                  layout="vertical"
                  form={setPublicationMetaDataForm}
                  name="setPublicationMetaDataForm"
                >
                  <Form.Item name="publicationId" label="Id" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="title"
                    label="Title"
                    valuePropName="value"
                    rules={[
                      {
                        required: true,
                        message: `Please input Title!`,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="description"
                    label="Description"
                    valuePropName="value"
                    rules={[
                      {
                        required: false,
                        message: `Please input Description!`,
                      },
                    ]}
                  >
                    <PublicationDescriptionEditor initialContent={publicationData.description} />
                  </Form.Item>
                  <Form.Item
                    name="authorId"
                    label="Author"
                    valuePropName="value"
                    rules={[
                      {
                        required: true,
                        message: `Please select author!`,
                      },
                    ]}
                  >
                    <DebounceSelect
                      style={{ width: '100%' }}
                      placeholder="Select author"
                      fetchCallback={async (value) => {
                        try {
                          const response = await apolloGraphqlAppClient.query<
                            GetAuthorListQuery,
                            GetAuthorListQueryVariables
                          >({
                            query: GetAuthorListDocument,
                            variables: {
                              where: {
                                ...(value
                                  ? {
                                      or: [
                                        { firstName: { contains: value } },
                                        { middleName: { contains: value } },
                                        { lastName: { contains: value } },
                                      ],
                                    }
                                  : {}),
                              },
                            },
                          });
                          const {
                            data: { authors },
                          } = response;
                          return (
                            authors?.items?.map((author) => ({
                              label: (
                                <Space wrap={false}>
                                  <Typography.Text>
                                    {author.firstName} {author.middleName} {author.lastName}
                                  </Typography.Text>
                                  {author.code && <Tag>{author.code}</Tag>}
                                  {/* `${author.code ? `[ ${author.code} ] - ` : ''}${author.firstName} ${author.middleName}{' '} */}
                                  {/* ${author.lastName}` */}
                                </Space>
                              ),
                              value: author.id,
                            })) || []
                          );
                        } catch (e) {
                          return [];
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="publisher"
                    label="Publisher"
                    valuePropName="value"
                    rules={[
                      {
                        required: true,
                        message: `Please select Publisher!`,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="purchaseLink"
                    label="Purchase Link"
                    valuePropName="value"
                    rules={[
                      {
                        required: true,
                        message: `Please input Purchase Link!`,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <FlexListTemplate justifyContent="space-between">
                    <Form.Item
                      name="code"
                      label="Code"
                      valuePropName="value"
                      rules={[
                        {
                          required: true,
                          message: `Please input code!`,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="isbn"
                      label="Isbn"
                      valuePropName="value"
                      rules={[
                        {
                          required: true,
                          message: `Please select ISBN!`,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="pageCount"
                      label="Page count"
                      valuePropName="value"
                      rules={[
                        {
                          required: true,
                          message: `Please input Page count!`,
                        },
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                    <Form.Item
                      name="publicationYear"
                      label="Publication Year"
                      valuePropName="value"
                      rules={[
                        {
                          required: true,
                          message: `Please input publication Year!`,
                        },
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                  </FlexListTemplate>
                </Form>
              ),
            });
          },
        }
      : null,
  };
};
