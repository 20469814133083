import React, { forwardRef } from 'react';
import { ConfigProvider, Layout } from 'antd';
import { BasicProps } from 'antd/es/layout/layout';
import { MenuTemplate } from '../../components';
import { MENUS_MAP } from '../../config';
import { useMobileDetect, useWindowDimensions } from '../../hooks';
import '../assets/styles/sider.scss';
import { AppThemeSwitcher, useAppThemeContext } from '../../themes';
import { ProfileSection } from './ProfileSection';
import { IUseAppLayoutResult } from '../hooks';

export interface ISiderLayoutProps extends BasicProps, React.RefAttributes<HTMLDivElement> {
  appLayout: IUseAppLayoutResult;
}

export const SiderLayoutComponent: React.FC<React.PropsWithChildren<ISiderLayoutProps>> = ({ appLayout, ...rest }) => {
  const { collapsed, manualCollapsed, setManualCollapsed, minSiderWidth, maxSiderWidth } = appLayout;
  const { isMobile } = useMobileDetect();
  const { width } = useWindowDimensions();
  const { themes } = useAppThemeContext();

  return (
    <ConfigProvider theme={themes.dark.antdTheme}>
      <Layout.Sider
        collapsedWidth={minSiderWidth}
        collapsed={collapsed}
        collapsible
        width={maxSiderWidth}
        onCollapse={(collapsed) => setManualCollapsed(collapsed)}
        className="ant-layout-sider"
        zeroWidthTriggerStyle={{
          display: 'none',
        }}
        style={{
          position: 'fixed',
          left: 0,
          top: 50,
          bottom: 0,
          zIndex: 109,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        {...rest}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <MenuTemplate
            className="ant-layout-sider--app-menu"
            style={{
              height: isMobile() || width <= 880 ? '85%' : '100%',
              overflow: 'auto',
            }}
            list={MENUS_MAP.SIDEBAR_MENU}
            theme="dark"
            mode="inline"
          />
          {(isMobile() || width <= 880) && (
            <div>
              <AppThemeSwitcher type="menu" />
              <ProfileSection type="menu" />
            </div>
          )}
        </div>
      </Layout.Sider>
    </ConfigProvider>
  );
};

export const SiderLayout: React.ForwardRefExoticComponent<ISiderLayoutProps> = forwardRef((props, ref) => {
  return <SiderLayoutComponent {...props} ref={ref} />;
});
