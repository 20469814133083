import React from 'react';
import { FloatButton, Layout } from 'antd';
import { Outlet, useOutletContext } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import { ContentLayout, FooterLayout, HeaderLayout, SiderLayout } from './components';
import { IUseAppLayoutResult, useAppLayout } from './hooks';

export function useAppLayoutContext() {
  return useOutletContext<IUseAppLayoutResult>();
}

export const AppLayout = () => {
  const { width: layoutWidth, height: layoutHeight, ref: layoutRef } = useResizeDetector();
  const { width: footerWidth, height: footerHeight, ref: footerRef } = useResizeDetector();
  const appLayout = useAppLayout({ footerHeight });
  // console.log({ layoutWidth, layoutHeight, layoutRef, footerWidth, footerHeight, footerRef });
  return (
    <Layout>
      <HeaderLayout appLayout={appLayout} />
      <SiderLayout appLayout={appLayout} />
      <Layout
        hasSider={false}
        ref={layoutRef}
        style={{
          position: 'relative',
          marginLeft: appLayout.margin?.marginLeft,
          top: appLayout.headerHeight,
          minHeight: window.innerHeight - appLayout.headerHeight,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
        }}
      >
        <ContentLayout
          appLayout={appLayout}
          style={
            {
              // minHeight: layoutHeight && footerHeight ? layoutHeight - footerHeight : 0,
            }
          }
        >
          <Outlet context={appLayout satisfies IUseAppLayoutResult} />
          <FloatButton.BackTop visibilityHeight={2} />
        </ContentLayout>
        <FooterLayout ref={footerRef} />
      </Layout>
    </Layout>
  );
};
