import { App, Form, Input, ModalFuncProps } from 'antd';
import React, { useRef } from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { EditOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';
import { FlexListTemplate } from '../../../../../components';
import {
  ManagerFolderTreeListItemFragment,
  RenameFolderInput,
  useRenameFolderMutation,
} from '../../../../../services/apolloGraphqlAppApiClient/models/types';
import { useFoldersTreeStoreContext } from '../FoldersTreeStoreProvider';

export interface IUseRenameFolderActionProps {
  folderData: ManagerFolderTreeListItemFragment;
}

export interface IUseRenameFolderActionResult {
  renameFolderAction: ItemType;
}

export const useRenameFolderAction = (props: IUseRenameFolderActionProps): IUseRenameFolderActionResult => {
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();
  const { folderData } = props;
  const { refetchFolders } = useFoldersTreeStoreContext();
  const menuItemKey = `RenameFolderAction-${folderData.id}`;
  const infoKey = uuidV4();
  const modalRef = useRef<ReturnType<typeof confirm>>();

  const visible = folderData.title !== 'Unpublished';

  const [renameFolderForm] = Form.useForm<RenameFolderInput>();

  const [renameFolder] = useRenameFolderMutation({
    onCompleted: async (result) => {
      refetchFolders();
      info({
        placement: 'topRight',
        message: `Rename Folder create status: true`,
        description: (
          <FlexListTemplate gap={5} alignItems="left" justifyContent="left" style={{ flexDirection: 'column' }}>
            {/* info */}
          </FlexListTemplate>
        ),
        key: infoKey,
        duration: 7,
      });
    },
    onError: (e) => {
      return e;
    },
  });

  const handleOk: ModalFuncProps['onOk'] = () => {
    renameFolderForm
      .validateFields()
      .then((values) => {
        return renameFolder({
          variables: {
            input: { ...values },
          },
        });
      })
      .then((res) => {
        // renameFolderForm.resetFields();
        if (modalRef.current) {
          modalRef.current.destroy();
        }
      })
      .catch((e) => {
        return e;
      });
  };

  const handleCancel = () => {
    renameFolderForm.resetFields();
    if (modalRef.current) {
      modalRef.current.destroy();
    }
  };

  return {
    renameFolderAction: visible
      ? {
          key: menuItemKey,
          icon: <EditOutlined />,
          label: 'Rename Folder',
          onClick: (menuInfo) => {
            menuInfo.domEvent.preventDefault();
            menuInfo.domEvent.stopPropagation();
            modalRef.current = confirm({
              title: `Rename ${folderData.title} Folder!`,
              onOk: handleOk,
              onCancel: handleCancel,
              okText: 'Rename Folder',
              content: (
                <Form
                  initialValues={{ id: folderData.id, title: folderData.title }}
                  layout="vertical"
                  form={renameFolderForm}
                  name="renameFolderForm"
                >
                  <Form.Item name="id" label="Id" hidden>
                    <Input type="textarea" />
                  </Form.Item>
                  <Form.Item
                    name="title"
                    label="Title"
                    rules={[
                      {
                        required: true,
                        message: `Please input folder title`,
                      },
                    ]}
                  >
                    <Input type="textarea" />
                  </Form.Item>
                </Form>
              ),
            });
          },
        }
      : null,
  };
};
