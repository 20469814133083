import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import React from 'react';
import { DevApiPage, TextEditorPage } from '../Dev/pages';
import { AppLayout } from '../layout';
import {
  HomePage,
  PublicationsManagerPage,
  ResultPage,
  PublicationCoverTypesManagerPage,
  PublicationEpubListManagerPage,
  PublicationPage,
  PublicationCoversManagerPage,
  PublicationChaptersReaderPage,
} from '../pages';

export const ROUTES_MAP: RouteObject[] = [
  // {
  //   path: '/auth',
  //   element: <AuthLayout />,
  //   children: [
  //     {
  //       path: 'login',
  //       element: <LoginPage />,
  //       // redirectPath: '/',
  //     },
  //     {
  //       path: '404',
  //       element: (
  //         <ResultPage
  //           {...{
  //             path: '/',
  //             status: '404',
  //             title: 'Page not found',
  //             subTitle: '404',
  //             // redirectTimer: 10,
  //           }}
  //         />
  //       ),
  //     },
  //     {
  //       path: '*',
  //       element: <Navigate to="404" replace />,
  //     },
  //   ],
  // },
  {
    element: <AppLayout />,
    children: [
      {
        id: 'Home',
        path: '/',
        // hidden: true,
        element: <HomePage />,
      },
      {
        id: 'CoverTypes',
        path: 'coverTypes',
        element: <PublicationCoverTypesManagerPage />,
      },
      {
        id: 'Publications',
        path: 'publications',
        // element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate to="manager" replace />,
          },
          {
            path: 'manager',
            // element: <Outlet />,
            children: [
              {
                path: '',
                element: <Navigate to="0" replace />,
              },
              {
                path: ':selectedFolderId',
                element: <PublicationsManagerPage />,
              },
            ],
          },
          {
            id: 'publicationId',
            path: ':publicationId',
            // element: <Outlet />,
            children: [
              {
                path: '',
                element: <PublicationPage />,
              },
              {
                path: 'covers',
                // element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <Navigate to="0" replace />,
                  },
                  {
                    path: ':typeId',
                    element: <PublicationCoversManagerPage />,
                  },
                ],
              },
              {
                path: 'epubs',
                // element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <Navigate to="0" replace />,
                  },
                  {
                    path: ':typeId',
                    element: <PublicationEpubListManagerPage />,
                  },
                ],
              },
              {
                path: 'chapters',
                // element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <PublicationChaptersReaderPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '404',
        element: (
          <ResultPage
            {...{
              path: '/',
              status: '404',
              title: 'Page not found',
              subTitle: '404',
              // redirectTimer: 10,
            }}
          />
        ),
      },
      {
        path: '403',
        element: (
          <ResultPage
            {...{
              // path: '/accounts/logout',
              status: '403',
              title: 'Access Denied',
              clearError: false,
            }}
          />
        ),
      },
      {
        path: '*',
        element: <Navigate to="/404" replace />,
      },
      ...(process.env.NODE_ENV !== 'production'
        ? [
            {
              path: 'dev',
              // element: <Outlet />,
              caseSensitive: true,
              children: [
                {
                  path: '',
                  element: <Navigate to="/dev/services" />,
                },
                {
                  path: 'services',
                  element: <DevApiPage />,
                },
                {
                  path: 'editor',
                  element: <TextEditorPage />,
                },
              ],
            },
          ]
        : []),
    ],
  },
];
