import { createContext, useContext, useMemo } from 'react';
import { IUsePublicationStoreResult, usePublicationStore } from './usePublicationStore';

export const PublicationStoreContext = createContext<IUsePublicationStoreResult | undefined>(undefined);
PublicationStoreContext.displayName = 'PublicationStoreContext';

export const usePublicationStoreContext = () => {
  const context = useContext(PublicationStoreContext);
  if (context === undefined) {
    throw new Error('usePublicationStoreContext must be used within PublicationStoreProvider');
  }
  return context;
};

export const PublicationStoreProvider = ({ children }) => {
  const publicationStore = usePublicationStore();

  const publicationStoreContextObject: IUsePublicationStoreResult = useMemo(() => publicationStore, [publicationStore]);

  return (
    <PublicationStoreContext.Provider value={publicationStoreContextObject}>
      {children}
    </PublicationStoreContext.Provider>
  );
};
