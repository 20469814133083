import React, { FC, useCallback } from 'react';
import { useActive, useCommands } from '@remirror/react-core';

import { CommandButton, ICommandButtonProps } from '../../../components';
import { UnorderedWListExtension } from '../UnorderedWListExtension';

export type IToggleUnorderedWListButtonProps = Omit<
  ICommandButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'
>;

export const ToggleUnorderedWListButton: FC<IToggleUnorderedWListButtonProps> = (props) => {
  const { toggleUnorderedWList } = useCommands<UnorderedWListExtension>();

  const handleSelect = useCallback(() => {
    if (toggleUnorderedWList.enabled()) {
      toggleUnorderedWList();
    }
  }, [toggleUnorderedWList]);

  const active = useActive<UnorderedWListExtension>().unorderedWList();
  const enabled = toggleUnorderedWList.enabled();

  return (
    <CommandButton
      {...props}
      commandName="toggleUnorderedWList"
      active={active}
      enabled={enabled}
      onSelect={handleSelect}
    />
  );
};
