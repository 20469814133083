import { createStyles } from 'antd-style';

export const usePublicationEpubListStyles = createStyles(({ token }) => ({
  list: {
    height: 600,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  item: {
    '.ant-ribbon-wrapper': {
      paddingTop: 22,
    },
  },
  itemCard: {
    '.ant-card-head': {
      all: 'unset',
      minHeight: 22,
    },
    '.ant-card-actions': {
      li: {
        margin: 0,
      },
    },
    '.ant-image-img': {
      borderRadius: 8,
    },
    '.ant-card-meta': {
      margin: 0,
    },
    '.ant-card-body': {
      padding: 3,
    },
  },
  itemBadge: {
    button: {
      padding: '0 !important',
      '.ant-btn-icon': {
        marginInlineEnd: '0 !important',
      },
    },
  },
  itemDescription: {
    '.ant-descriptions-view': {
      table: {
        // width: 'unset',
        // maxWidth: 200,
      },
    },
    '.ant-descriptions-item-label': {
      padding: '0 5px !important',
      fontSize: '0.75em',
      textOverflow: 'ellipsis',
      textWrap: 'nowrap',
      width: '100%',
      justifyContent: 'center',
    },
    '.ant-descriptions-header': {
      marginBottom: 2,
    },
    '.ant-descriptions-item-content': {
      adding: '0 5px !important',
      textOverflow: 'ellipsis',
      fontSize: '0.9em',
      textWrap: 'nowrap',
      width: '100%',
      justifyContent: 'center',
    },
    '.ant-descriptions-item': {
      padding: '0 !important',
      textOverflow: 'ellipsis',
      textWrap: 'nowrap',
    },
    '.epub-upload-date': {
      '&:hover': {
        cursor: 'help',
      },
    },
  },
  modalWrapper: {
    '.ant-modal-close': {
      display: 'none',
    },
  },
}));
