import { useActive, useAttrs, useCommands, useCurrentSelection, useExtensionEvent } from '@remirror/react';
import { useHelpers } from '@remirror/react-core';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { isNodeSelection } from 'remirror';

import { WNoteExtension } from '../WNoteExtension';

function useWNoteClick() {
  const [wNoteClickProps, setWNoteClickProps] = useState<any>();
  const [isEditing, setIsEditing] = useState(false);

  useExtensionEvent(
    WNoteExtension,
    'onViewClick',
    useCallback(
      (props) => {
        if (!isEditing && props?.nodeWithPosition?.node) {
          setIsEditing(true);
        }
        setWNoteClickProps(props);
      },
      [isEditing],
    ),
  );

  return { isEditing, wNoteClickProps, setIsEditing };
}

export function useWNoteState() {
  const { isEditing, wNoteClickProps, setIsEditing } = useWNoteClick();
  const { toggleWNote, removeWNote, focus } = useCommands();
  const { isSelectionEmpty } = useHelpers();
  const selection = useCurrentSelection();
  const { to, empty } = selection;

  const attrs = useAttrs<WNoteExtension>().wNote();

  const active = useActive<WNoteExtension>().wNote();
  const enabled =
    (isNodeSelection(selection) && selection?.node?.type?.name === 'wNote') ||
    (isSelectionEmpty() && toggleWNote.enabled());

  const handleRemove = useCallback(() => {
    setIsEditing(false);
    removeWNote();
    focus();
  }, [removeWNote]);

  // const updateReason = useUpdateReason();

  // useLayoutEffect(() => {
  //   if (!isEditing) {
  //     if (isNodeSelection(selection) && selection?.node?.type?.name === 'wNote') {
  //       setIsEditing(true);
  //     } else {
  //       setIsEditing(false);
  //     }
  //   }
  // }, [selection]);

  // useLayoutEffect(() => {
  //   if (!isEditing) {
  //     return;
  //   }
  //
  //   // if (updateReason.doc || updateReason.selection) {
  //   //   setIsEditing(false);
  //   // }
  // }, [isEditing, setIsEditing, updateReason.doc, updateReason.selection]);

  const handleSubmit = useCallback(
    (values) => {
      setIsEditing(false);
      toggleWNote({ attrs: { ...values } });
      focus();
    },
    [setIsEditing, wNoteClickProps, toggleWNote, attrs],
  );

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    focus();
  }, [setIsEditing]);

  // useEffect(() => {
  //   if (isEditing) {
  //     selectWNote();
  //   }
  // }, [isEditing]);

  const handleClickEdit = useCallback(() => {
    // if (empty) {
    //   selectWPage();
    // }
    setIsEditing(true);
  }, [empty, setIsEditing]);
  return useMemo(
    () => ({
      attrs,
      wNoteClickProps,
      handleClickEdit,
      isEditing,
      handleRemove,
      handleSubmit,
      handleCancel,
      active,
      enabled,
    }),
    [attrs, active, enabled, wNoteClickProps, isEditing, handleClickEdit, handleRemove, handleSubmit, handleCancel],
  );
}
