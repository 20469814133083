import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { FieldDataNode } from 'rc-tree/lib/interface';
import { TreeProps } from 'antd/lib';
import { DataNode, EventDataNode } from 'antd/lib/tree';
import { BasicDataNode } from 'antd/es/tree';

export interface TAntdTreePropsExpandOptionsType {
  expandedKeys: TreeProps['expandedKeys'];
  autoExpandParent: TreeProps['autoExpandParent'];

  [key: string]: any;
}

export interface IParseTreeDataOptions {
  idKey: string;
  titleKey: string;
  parentKey: string;
  childrenKey: string;
  childCountKey: string;
  selectable?: boolean;
}

export type IBaseAntdTreeFilterType = Record<string, any>;

export const parseTreeDataNode = <TreeDataNodeType extends BasicDataNode | DataNode = DataNode>(
  dataNode: TreeDataNodeType,
  options?: Partial<IParseTreeDataOptions>,
): TreeDataNodeType => {
  const ID_KEY = options?.idKey || 'id';
  const PARENT_KEY = options?.parentKey || 'parentId';
  const TITLE_KEY = options?.titleKey || 'title';
  const CHILDREN_KEY = options?.childrenKey || 'children';
  const CHILD_COUNT_KEY = options?.childCountKey || 'childCount';

  return {
    ...dataNode,
    key: dataNode[ID_KEY],
    parentKey: dataNode[PARENT_KEY],
    childCount: dataNode[CHILD_COUNT_KEY] || 0,
    title: dataNode[TITLE_KEY],
    isLeaf: !(dataNode[CHILD_COUNT_KEY] > 0),
    ...(options?.selectable !== undefined
      ? {
          selectable: options?.selectable,
        }
      : {}),
  };
};

const parseTreeData = <TreeNodeDataType extends BasicDataNode | DataNode = DataNode>(
  data: TreeNodeDataType[],
  options?: IParseTreeDataOptions,
) => {
  const ID_KEY = options?.idKey || 'key';
  const PARENT_KEY = options?.parentKey || 'parentKey';
  const CHILDREN_KEY = options?.childrenKey || 'children';
  const CHILD_COUNT_KEY = options?.childCountKey || 'childCount';
  const hashTable = Object.create(null);
  const dataTree = [];
  if (data) {
    data.forEach((aData) => {
      hashTable[aData[ID_KEY]] = {
        ...aData,
        ...(!aData[ID_KEY] || aData[CHILD_COUNT_KEY] > 0 ? { key: aData[ID_KEY] } : {}),
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...(!aData?.isLeaf
          ? {
              [`${CHILDREN_KEY}`]: [],
            }
          : {}),
      };
    });

    data.forEach((aData) => {
      if (aData[PARENT_KEY] && hashTable[aData[PARENT_KEY]]?.[CHILDREN_KEY]) {
        hashTable[aData[PARENT_KEY]][CHILDREN_KEY].push(hashTable[aData[ID_KEY]]);
      } else {
        // @ts-ignore
        dataTree.push(hashTable[aData[ID_KEY]]);
      }
    });
  }
  return dataTree;
};

export const flattenTreeData = <TreeNodeDataType extends BasicDataNode | DataNode = DataNode>(
  items: TreeNodeDataType[],
  options: IParseTreeDataOptions,
  depth = 0,
): TreeNodeDataType[] => {
  return items.reduce<TreeNodeDataType[]>((acc, item, index) => {
    const flattenedItem: TreeNodeDataType = {
      ...item,
      // parentId,
      // depth,
      // index,
      // isLast: items.length === index + 1,
      // parent: parent,
    };
    return [
      ...acc,
      flattenedItem,
      ...flattenTreeData<TreeNodeDataType>(item[options.childrenKey] ?? [], options, depth + 1),
    ];
  }, []);
};

export const getAntdTreeNodeParents = (
  id,
  node,
  options: Omit<IParseTreeDataOptions, 'parentKey' | 'childCountKey' | 'titleKey'>,
) => {
  const ID_KEY = options.idKey || 'id';
  const CHILDREN_KEY = options.childrenKey || 'children';
  if (node[ID_KEY] === id) {
    return [];
  }

  if (Array.isArray(node[CHILDREN_KEY])) {
    for (const treeNode of node[CHILDREN_KEY]) {
      const childResult = getAntdTreeNodeParents(id, treeNode, { idKey: ID_KEY, childrenKey: CHILDREN_KEY });

      if (Array.isArray(childResult)) {
        return [treeNode[ID_KEY]].concat(childResult);
      }
    }
  }
};

export type IUseAntdTreeDataProps<
  TreeNodeDataType extends BasicDataNode | DataNode = DataNode,
  FilterType = IBaseAntdTreeFilterType,
> = {
  data: TreeNodeDataType[];
  treeOptions: IParseTreeDataOptions;
  setSelectable?: (dataNode: TreeNodeDataType) => boolean;
  fetchData?: (
    callback: (data: TreeNodeDataType[]) => void,
    params: Record<string, any>,
    treeData?: TreeNodeDataType[],
  ) => Promise<void>;
  setLoadDataFetchProps?: (treeNode: EventDataNode<TreeNodeDataType>) => FilterType;
};

export interface IUseAntdTreeDataResult<TreeNodeDataType extends BasicDataNode | DataNode = DataNode> {
  loadData: TreeProps<TreeNodeDataType>['loadData'];
  treeData: TreeNodeDataType[];
  loadedKeys: TreeProps<TreeNodeDataType>['loadedKeys'];
  setLoadedKeys: React.Dispatch<React.SetStateAction<TreeProps['loadedKeys']>>;
  expandOptions: TAntdTreePropsExpandOptionsType;
  setExpandOptions: React.Dispatch<React.SetStateAction<TAntdTreePropsExpandOptionsType>>;
  onExpand: TreeProps<TreeNodeDataType>['onExpand'];
  toggleExpandNode: (key: string | number, collapsed?: boolean) => void;
}

const updateTreeData = <TreeNodeDataType extends BasicDataNode | DataNode = DataNode>(
  list: TreeNodeDataType[],
  key: React.Key,
  children: TreeNodeDataType[],
  options: IParseTreeDataOptions,
): TreeNodeDataType[] =>
  list.map((node) => {
    if (node[options.idKey] === key) {
      return {
        ...node,
        children,
      };
    }
    if (node[options.childrenKey]) {
      return {
        ...node,
        children: updateTreeData(node[options.childrenKey], key, children, options),
      };
    }
    return node;
  });

export const useAntdTreePropsData = <
  TreeNodeDataType extends BasicDataNode | DataNode = DataNode,
  FilterType = IBaseAntdTreeFilterType,
>(
  props: IUseAntdTreeDataProps<TreeNodeDataType, FilterType> = {
    data: [],
    treeOptions: {
      idKey: 'key',
      titleKey: 'title',
      parentKey: 'parentId',
      childrenKey: 'children',
      childCountKey: 'childCount',
    },
  },
): IUseAntdTreeDataResult<TreeNodeDataType> => {
  const { data, treeOptions, setSelectable, fetchData, setLoadDataFetchProps } = props;
  const [treeData, setTreeData] = useState<TreeNodeDataType[]>([]);
  const [loadedKeys, setLoadedKeys] = useState<TreeProps['loadedKeys']>([]);
  const [expandOptions, setExpandOptions] = useState<TAntdTreePropsExpandOptionsType>({
    expandedKeys: [],
    autoExpandParent: false,
  });

  const toggleExpandNode = (key, collapsed = true) => {
    setExpandOptions((prev) => {
      const outArr = [...prev.expandedKeys!];
      if (outArr.includes(key) && collapsed) {
        return {
          ...prev,
          expandedKeys: outArr.filter((e) => e !== key),
          autoExpandParent: true,
        };
      }
      if (outArr.includes(key) && !collapsed) {
        return {
          ...prev,
          expandedKeys: outArr,
          autoExpandParent: true,
        };
      }
      outArr.push(key);
      return {
        ...prev,
        expandedKeys: outArr,
        autoExpandParent: true,
      };
    });
  };

  const onExpand: IUseAntdTreeDataResult<TreeNodeDataType>['onExpand'] = (keys, node) => {
    setExpandOptions((prev) => {
      return {
        ...prev,
        expandedKeys: keys,
        autoExpandParent: false,
      };
    });
  };

  const loadData: IUseAntdTreeDataResult<TreeNodeDataType>['loadData'] = async (treeNode) => {
    if (setLoadDataFetchProps && fetchData && !treeNode.isLeaf) {
      const params = setLoadDataFetchProps(treeNode) || {};
      await fetchData((data) => setNewData(data, treeNode), params).then(() => {
        setLoadedKeys([...loadedKeys!, treeNode.key]);
      });
    }
  };

  // const resetData = async () => {
  //   if (fetchData) {
  //     await fetchData((data) => setNewData(data, undefined, true), {}).then(() => {
  //       setLoadedKeys([]);
  //     });
  //   }
  // };

  const setNewData = (data: TreeNodeDataType[], triggeredNode: EventDataNode<TreeNodeDataType>, resetData = false) => {
    setTreeData((origin) =>
      updateTreeData(
        origin,
        triggeredNode[treeOptions.idKey],
        data.map((dataNode) => {
          return parseTreeDataNode(dataNode, {
            ...treeOptions,
            ...(setSelectable
              ? {
                  selectable: setSelectable(dataNode),
                }
              : {}),
          });
        }),
        {
          ...treeOptions,
        },
      ),
    );
  };

  useEffect(() => {
    setTreeData(
      parseTreeData(
        data.map((dataNode) => {
          return parseTreeDataNode(dataNode, {
            ...treeOptions,
            ...(setSelectable
              ? {
                  selectable: setSelectable(dataNode),
                }
              : {}),
          });
        }),
      ),
    );
  }, [data]);

  return useMemo(
    () => ({
      treeData,
      loadedKeys,
      loadData,
      setLoadedKeys,
      expandOptions,
      setExpandOptions,
      onExpand,
      toggleExpandNode,
    }),
    [treeData, loadedKeys, expandOptions],
  );
};
