import React, { CSSProperties, useEffect, useState } from 'react';
import './assets/styles/index.css';

const setBodyCursorStyle = (cursorStyleData?: IUseGlobalCursorStyleProps) => {
  const { body } = document;
  if (cursorStyleData) {
    if (cursorStyleData.eventTrigger !== 'move') {
      body.classList.toggle('reset-all-cursors', cursorStyleData.eventTrigger === 'start');
    }
    if (cursorStyleData.cursorStyle) {
      body.style.setProperty('--reset-all-cursors--cursor', cursorStyleData.cursorStyle);
    }
    return;
  }
  if (!cursorStyleData) {
    body.style.setProperty('--reset-all-cursors--cursor', 'auto');
    body.classList.remove('reset-all-cursors');
  }
};

export interface IUseGlobalCursorStyleProps {
  cursorStyle: CSSProperties['cursor'];
  eventTrigger: 'start' | 'end' | 'move';
}

export interface IUseGlobalCursorStyleResult {
  setCursorStyleData: React.Dispatch<React.SetStateAction<IUseGlobalCursorStyleProps | undefined>>;
}
export const useGlobalCursorStyle = (props?: IUseGlobalCursorStyleProps): IUseGlobalCursorStyleResult => {
  const [cursorStyleData, setCursorStyleData] = useState<IUseGlobalCursorStyleProps | undefined>(undefined);

  useEffect(() => {
    setBodyCursorStyle(cursorStyleData);
  }, [cursorStyleData]);

  useEffect(() => {
    if (props) {
      setCursorStyleData(cursorStyleData);
    }
    return () => {
      setBodyCursorStyle();
    };
  }, [props]);

  return {
    setCursorStyleData,
  };
};
