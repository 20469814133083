import React, { useCallback, useRef } from 'react';
import { isFunction } from '@remirror/core';

export type IDebounceCallbackType<T> = NonNullable<T>;

// eslint-disable-next-line @typescript-eslint/ban-types
export const useDebounceCallback = <T extends (...args: any[]) => any>(
  callback: IDebounceCallbackType<T>,
  deps: [number, ...React.DependencyList],
) => {
  const [delay, ...rest] = deps;
  const timer = useRef<any>(null);

  return useCallback(
    (...args: Parameters<IDebounceCallbackType<T>>) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      if (typeof callback === 'function') {
        timer.current = setTimeout(() => {
          callback(...args);
        }, delay);
      }
    },
    [callback, ...rest, delay],
  );
};
