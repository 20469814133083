import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, Location, NavigateFunction, useParams, Params } from 'react-router-dom';

import qs from 'qs';
import { NavigateOptions } from 'react-router/dist/lib/context';
import { useDidMountEffect } from '../components';

export const historyObjectMap: (
  object: Record<string, any>,
  callback: (key: string, value: any) => any,
) => Record<string, any> = (object, callback) => {
  return Object.keys(object).reduce((result, key) => {
    // if (typeof value === undefined) {
    //   delete result[key];
    // }
    // if (value) {
    result[key] = callback(key, object[key]);
    // }
    return result;
  }, {});
};

export const getHistorySearchData = <F extends Record<string, any>>(location: Location): F => {
  const params =
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
      decoder: (str, defaultDecoder, charset, type) => {
        const strWithoutPlus = str.replace(/\+/g, ' ');
        if (charset === 'iso-8859-1') {
          // unescape never throws, no try...catch needed:
          return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
        }

        if (/^(\d+|\d*\.\d+)$/.test(str)) {
          return parseFloat(str);
        }

        const keywords = {
          true: true,
          false: false,
          null: null,
          undefined,
        };
        if (str in keywords) {
          return keywords[str];
        }

        // utf-8
        try {
          return decodeURIComponent(strWithoutPlus);
        } catch (e) {
          return strWithoutPlus;
        }
      },
      // strictNullHandling: true,
      // allowPrototypes: true,
    }) || {};
  return params as F;
};

export const getHistoryParams = (params: Readonly<Params<string>>): Record<string, any> => {
  return historyObjectMap(params, (key, value) => {
    if (/^(\d+|\d*\.\d+)$/.test(value)) {
      return parseFloat(value);
    }
    const keywords = {
      true: true,
      false: false,
      null: null,
      undefined,
    };
    if (value in keywords) {
      return keywords[value];
    }
    return value;
  });
};

export const setHistorySearchData = <F extends Record<string, any>>(
  historySearchProps: F,
  navigate: NavigateFunction,
) => {
  const paramsStringify = qs.stringify(historySearchProps, {
    addQueryPrefix: true,
    skipNulls: true,
    strictNullHandling: false,
  });
  navigate({ search: paramsStringify }, { replace: true });
};

export interface IUseHistorySearchPropsResult<F extends Record<string, any>> {
  historySearchProps: F;
  setHistorySearchProps: React.Dispatch<React.SetStateAction<F>>;
  historyParams?: Record<string, any>;
  setHistoryParams?: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  setParams?: (pathname: string, options?: NavigateOptions) => void;
}

export const useHistorySearchProps = <F extends Record<string, any>>(): IUseHistorySearchPropsResult<F> => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [historySearchProps, setHistorySearchProps] = useState<F>(() => getHistorySearchData(location));
  const [historyParams, setHistoryParams] = useState<Record<string, any>>(() => getHistoryParams(params));

  useEffect(() => {
    setHistorySearchData<F>(historySearchProps, navigate);
  }, [historySearchProps]);

  useEffect(() => {
    const newHistoryParams = getHistoryParams(params);
    if (historyParams !== newHistoryParams) {
      setHistoryParams(getHistoryParams(params));
    }
  }, [params]);

  const setParams = (pathname: string, options?: NavigateOptions) => {
    navigate({ search: location.search, pathname }, { replace: true, ...options });
  };

  return useMemo(
    () => ({
      historySearchProps,
      setHistorySearchProps,
      historyParams,
      setHistoryParams,
      setParams,
    }),
    [historySearchProps, historyParams],
  );
};
