import React, { forwardRef } from 'react';
import { Flex, theme, Typography } from 'antd';
import { usePublicationsManagerStoreContext } from './PublicationsManagerStoreProvider';

export type IManagerFooterComponent = React.PropsWithChildren;
export const ManagerFooterComponent: React.FC<IManagerFooterComponent> = (props) => {
  const { footerData } = usePublicationsManagerStoreContext();

  return (
    <Flex
      justify="space-between"
      wrap="wrap"
      style={{
        minHeight: 0,
        padding: 8,
      }}
    >
      <Flex justify="start" gap={5} wrap="nowrap" align="center" style={{ width: 'unset', overflow: 'hidden' }}>
        <Typography.Text ellipsis>Publications Count:</Typography.Text>
        <Typography.Text ellipsis>
          {footerData?.loadedPublicationCount === footerData?.filteredPublicationCount
            ? footerData?.filteredPublicationCount
            : `${footerData?.loadedPublicationCount} / ${footerData?.filteredPublicationCount}`}
        </Typography.Text>
      </Flex>
      {
        // @ts-ignore
        footerData?.loadedPublicationCount < footerData?.filteredPublicationCount && (
          <Typography.Text type="danger" ellipsis>
            Please scroll down publication list for load more.
          </Typography.Text>
        )
      }
    </Flex>
  );
};

export const ManagerFooter: React.ForwardRefExoticComponent<
  IManagerFooterComponent & React.RefAttributes<HTMLDivElement>
> = forwardRef((props, ref) => {
  const { token } = theme.useToken();
  return (
    <div
      ref={ref}
      style={{
        width: '100%',
        borderTop: `1px solid ${token.colorBorderSecondary}`,
      }}
    >
      <ManagerFooterComponent {...props} />
    </div>
  );
});
