import React, { forwardRef } from 'react';
import Icon from '@ant-design/icons';
import { IconProps } from '@ant-design/compatible/es/icon';
import { PUBLICATION_TYPE_ICONS } from '../../../../config';
import { PublicationType } from '../../../../services/apolloGraphqlAppApiClient/models/types';

export type TManagerPublicationTypeIconComponentProps = {
  type?: string;
  innerRef?: React.ForwardedRef<HTMLDivElement>;
} & Omit<IconProps, 'component'>;
export const ManagerPublicationTypeIconComponent = (props: TManagerPublicationTypeIconComponentProps) => {
  const { type = PublicationType.Book, innerRef, ...restProps } = props;
  const IconComponent = PUBLICATION_TYPE_ICONS[type]?.img;
  return <Icon component={IconComponent} ref={innerRef} {...restProps} />;
};

export const ManagerPublicationTypeIcon = forwardRef<HTMLDivElement, TManagerPublicationTypeIconComponentProps>(
  (props, ref) => <ManagerPublicationTypeIconComponent {...props} innerRef={ref} />,
);
