import React, { CSSProperties, useEffect, useState } from 'react';
import { Breadcrumb, BreadcrumbProps } from 'antd';
import { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import { usePublicationsManagerStoreContext } from './PublicationsManagerStoreProvider';
import { ManagerFolderTreeListItemFragment } from '../../../../services/apolloGraphqlAppApiClient/models/types';

const ItemRender: BreadcrumbProps['itemRender'] = (route, params, items, paths) => {
  const style: CSSProperties = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };

  return <span style={style}>{route.title}</span>;

  // const last = items.indexOf(route) === items.length - 1;
  // return last ? (
  //   <span style={style}>{route.title}</span>
  // ) : (
  //   <Button style={style} type="text" onClick={route.onClick}>
  //     {route.title}
  //   </Button>
  // );
};
export const ManagerBreadcrumb = (props) => {
  const { selectedFolder, setSelectedFolder } = usePublicationsManagerStoreContext();
  const [items, setItems] = useState<Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[]>([]);

  useEffect(() => {
    setItems([
      ...(selectedFolder && (selectedFolder as ManagerFolderTreeListItemFragment)?.parents?.length > 0
        ? (selectedFolder as ManagerFolderTreeListItemFragment).parents.map((folder) => ({
            path: `/${folder.title}`,
            title: folder.title,
            // onClick: (e) =>
            //   setSelectedFolder({
            //     key: wrapper.id,
            //     parentKey: wrapper.parentId,
            //     title: wrapper.title,
            //     childCount: wrapper.childFolderCount,
            //     isLeaf: !(wrapper.childFolderCount > 0),
            //     treeNodeData: {
            //       ...wrapper,
            //       parents: [
            //         // eslint-disable-next-line no-unsafe-optional-chaining
            //         ...(selectedFolder?.treeNodeData as ManagerFolderTreeListItemFragment)?.parents.filter(
            //           (p) => p.id !== wrapper.id,
            //         ),
            //       ],
            //     },
            //   }),
          }))
        : []),
      {
        path: `/${selectedFolder?.title}`,
        title: selectedFolder?.title,
      },
    ]);
  }, [selectedFolder]);

  return <Breadcrumb separator=">" className="foldersBreadcrumb" itemRender={ItemRender} items={items} />;
};
