import { useActive, useAttrs, useCommands, useCurrentSelection, useExtensionEvent } from '@remirror/react';
import { useHelpers } from '@remirror/react-core';
import { useCallback, useMemo, useState } from 'react';

import { W_LANG_PROPERTIES, WLangExtension } from '../WLangExtension';

function useWLangClick() {
  const [wLangClickHandlerProps, setWLangClickHandlerProps] = useState<any | undefined>();
  const [isEditing, setIsEditing] = useState(false);

  useExtensionEvent(
    WLangExtension,
    'onViewClick',
    useCallback(
      (props) => {
        if (!isEditing && props?.markWithPosition) {
          setIsEditing(true);
        }

        setWLangClickHandlerProps(props);
      },
      [isEditing],
    ),
  );

  return { isEditing, wLangClickHandlerProps, setIsEditing };
}

export function useWLangState() {
  const { toggleWLang, selectWLang, removeWLang, focus } = useCommands<WLangExtension>();
  const { isEditing, wLangClickHandlerProps, setIsEditing } = useWLangClick();
  const { to, empty } = useCurrentSelection();
  const { isSelectionEmpty } = useHelpers();

  const attrs = useAttrs<WLangExtension>().wLang();

  const { wLang: isActiveWLang } = useActive<WLangExtension>();

  const active = isActiveWLang();
  const enabled = (!isSelectionEmpty() && toggleWLang.enabled()) || isEditing;

  const options = {
    dirList: W_LANG_PROPERTIES.dirList.map((dir) => {
      const isActive = isActiveWLang({ dir });
      const disabled = !toggleWLang.enabled({
        dir,
      });
      return {
        value: dir,
        label: dir,
        title: dir,
        active: isActive,
        disabled,
      };
    }),
    langList: W_LANG_PROPERTIES.langList.map((lang) => {
      const isActive = isActiveWLang({ lang });
      const disabled = !toggleWLang.enabled({
        lang,
      });
      return {
        value: lang,
        label: lang,
        title: lang,
        active: isActive,
        disabled,
      };
    }),
  };

  const handleRemove = useCallback(() => {
    removeWLang();
    focus();
  }, [removeWLang]);

  // const updateReason = useUpdateReason();
  //
  // useLayoutEffect(() => {
  //   if (!isEditing) {
  //     return;
  //   }
  //
  //   if (updateReason.doc || updateReason.selection) {
  //     setIsEditing(false);
  //   }
  // }, [isEditing, setIsEditing, updateReason.doc, updateReason.selection]);

  const handleSubmit = useCallback(
    (values) => {
      setIsEditing(false);
      const range = wLangClickHandlerProps?.markWithPosition;
      if (range) {
        handleClickEdit();
      }
      toggleWLang({ ...values }, range);
      focus();
    },
    [setIsEditing, wLangClickHandlerProps, toggleWLang, attrs],
  );

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    focus();
  }, [setIsEditing]);

  const handleClickEdit = useCallback(() => {
    if (empty) {
      selectWLang();
    }
    setIsEditing(true);
  }, [selectWLang, empty, setIsEditing]);

  return useMemo(
    () => ({
      attrs,
      options,
      wLangClickHandlerProps,
      isEditing,
      handleRemove,
      handleSubmit,
      handleCancel,
      active,
      enabled,
      handleClickEdit,
    }),
    [
      attrs,
      active,
      options,
      enabled,
      wLangClickHandlerProps,
      handleClickEdit,
      isEditing,
      handleRemove,
      handleSubmit,
      handleCancel,
    ],
  );
}
