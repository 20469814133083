import { App, Button, ModalFuncProps, Spin } from 'antd';
import React from 'react';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';
import {
  ManagerPublicationEpubListItemFragment,
  SortEnumType,
  useDeletePublicationEpubMutation,
} from '../../../services/apolloGraphqlAppApiClient/models/types';
import { usePublicationEpubListManagerStore } from '../PublicationEpubListManagerStoreProvider';

export const DeletePublicationEpubActionButton = (record: ManagerPublicationEpubListItemFragment) => {
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();

  const { historyParams, getPublicationEpubListLazy } = usePublicationEpubListManagerStore();
  const infoKey = uuidV4();

  const [setPublicationMainEpub, { loading }] = useDeletePublicationEpubMutation({
    onCompleted: (result) => {
      getPublicationEpubListLazy({
        variables: {
          where: {
            type: { eq: historyParams?.typeId },
            publicationId: { eq: historyParams?.publicationId },
          },
          order: { createdAt: SortEnumType.Desc },
        },
      });
      info({
        placement: 'topRight',
        message: `Delete Epub status: true`,
        key: infoKey,
        duration: 7,
      });
    },
    onError: (e) => {
      return e;
    },
  });

  const handleOk: ModalFuncProps['onOk'] = () => {
    setPublicationMainEpub({
      variables: {
        input: { id: record.id },
      },
    });
  };

  return (
    <Button
      type="link"
      icon={<DeleteOutlined />}
      disabled={record.isMain}
      danger
      onClick={() => {
        confirm({
          title: `Are you sure to delete this epub`,
          icon: <ExclamationCircleOutlined />,
          okText: <Spin spinning={loading}>Delete</Spin>,
          onOk: handleOk,
          closable: true,
        });
      }}
    >
      Delete
    </Button>
  );
};
