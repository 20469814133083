import React from 'react';
import { Descriptions, Flex, Space, Tag, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { ManagerPublicationListItemFragment } from '../../../../services/apolloGraphqlAppApiClient/models/types';
import { PUBLICATION_PERMISSION_ICONS } from '../../../../config';

export type TPublicationListItemDescriptionRenderProps = {
  listItem: ManagerPublicationListItemFragment;
};
export const PublicationListItemDescriptionRender = (props: TPublicationListItemDescriptionRenderProps) => {
  const { listItem } = props;
  return (
    <Flex wrap="nowrap">
      <Tooltip placement="bottom" title={listItem.publicationId}>
        <Tag>{listItem.code}</Tag>
      </Tooltip>
      <Tag>{listItem.type}</Tag>
      <Tooltip placement="bottom" title={listItem.language.title}>
        <Tag>{listItem.languageCode}</Tag>
      </Tooltip>
      {/* {process.env.NODE_ENV !== 'production' && <Tag>Id: {listItem.publicationId}</Tag>} */}
      {process.env.NODE_ENV !== 'production' && <Tag>Order: {listItem.order}</Tag>}
      <Icon component={() => PUBLICATION_PERMISSION_ICONS[listItem.permission]} />
    </Flex>
  );
};
