import { App, Form, Input, ModalFuncProps } from 'antd';
import React, { useRef } from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { DeleteOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';
import { FlexListTemplate } from '../../../../../components';
import {
  DeleteFolderInput,
  ManagerFolderTreeListItemFragment,
  useDeleteFolderMutation,
} from '../../../../../services/apolloGraphqlAppApiClient/models/types';
import { useFoldersTreeStoreContext } from '../FoldersTreeStoreProvider';

export interface IUseDeleteFolderActionProps {
  folderData: ManagerFolderTreeListItemFragment;
}

export interface IUseDeleteFolderActionResult {
  deleteFolderAction: ItemType;
}

export const useDeleteFolderAction = (props: IUseDeleteFolderActionProps): IUseDeleteFolderActionResult => {
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();
  const { folderData } = props;
  const { refetchFolders } = useFoldersTreeStoreContext();
  const menuItemKey = `DeleteFolderAction-${folderData.id}`;
  const infoKey = uuidV4();
  const modalRef = useRef<ReturnType<typeof confirm>>();

  const visible =
    folderData.title !== 'Unpublished' && folderData.childPublicationCount === 0 && folderData.childFolderCount === 0;

  const [deleteFolderForm] = Form.useForm<DeleteFolderInput>();

  const [deleteFolder] = useDeleteFolderMutation({
    onCompleted: (result) => {
      refetchFolders();
      info({
        placement: 'topRight',
        message: `Delete Folder status: true`,
        description: (
          <FlexListTemplate gap={5} alignItems="left" justifyContent="left" style={{ flexDirection: 'column' }}>
            {/* info */}
          </FlexListTemplate>
        ),
        key: infoKey,
        duration: 7,
      });
    },
    onError: (e) => {
      return e;
    },
  });

  const handleOk: ModalFuncProps['onOk'] = () => {
    deleteFolderForm
      .validateFields()
      .then((values) => {
        return deleteFolder({
          variables: {
            input: { ...values },
          },
        });
      })
      .then((res) => {
        // deleteFolderForm.resetFields();
        if (modalRef.current) {
          modalRef.current.destroy();
        }
      })
      .catch((e) => {
        return e;
      });
  };

  const hadleCancel = () => {
    deleteFolderForm.resetFields();
    if (modalRef.current) {
      modalRef.current.destroy();
    }
  };

  return {
    deleteFolderAction: visible
      ? {
          key: menuItemKey,
          icon: <DeleteOutlined />,
          label: 'Delete Folder',
          onClick: (menuInfo) => {
            menuInfo.domEvent.preventDefault();
            menuInfo.domEvent.stopPropagation();
            modalRef.current = confirm({
              title: `Delete ${folderData.title} Folder!`,
              onOk: handleOk,
              onCancel: hadleCancel,
              okText: 'Delete Folder',
              content: (
                <Form<DeleteFolderInput>
                  initialValues={{ id: folderData.id }}
                  layout="vertical"
                  form={deleteFolderForm}
                  name="deleteFolderForm"
                >
                  <Form.Item name="id" label="Id" hidden>
                    <Input type="textarea" />
                  </Form.Item>
                </Form>
              ),
            });
          },
        }
      : null,
  };
};
