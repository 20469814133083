import React from 'react';
import { Button, Form, Input, InputNumber } from 'antd';
import { FlexListTemplate } from '../../../components';
import { useDebounce } from '../../../hooks';
import {
  TPublicationCoverTypesManagerFilter,
  usePublicationCoverTypesManagerStore,
} from '../PublicationCoverTypesManagerStoreProvider';

export const PublicationCoverTypesListFiltersForm = () => {
  const { historySearchProps, handlePageSearchPropsChange } = usePublicationCoverTypesManagerStore();

  const [publicationCoverTypeListFilterForm] = Form.useForm<TPublicationCoverTypesManagerFilter>();

  const handleSearch = useDebounce((values: TPublicationCoverTypesManagerFilter, cleanFilter = false) => {
    handlePageSearchPropsChange(
      { filter: values },
      {
        cleanFilter,
      },
    );
  }, 500);

  return (
    <Form
      name="CountryListFilterForm"
      form={publicationCoverTypeListFilterForm}
      initialValues={{ ...historySearchProps?.filter }}
      onValuesChange={(field, allFields) => {
        handleSearch(allFields);
      }}
    >
      <FlexListTemplate>
        <Form.Item name="code" style={{ margin: 0 }}>
          <Input placeholder="code" className="app-filter-form--item-field" />
        </Form.Item>
        <Form.Item name="coverCount" style={{ margin: 0 }}>
          <InputNumber placeholder="cover count" className="app-filter-form--item-field" />
        </Form.Item>
        <Form.Item name="minHeight" style={{ margin: 0 }}>
          <InputNumber placeholder="min height" className="app-filter-form--item-field" />
        </Form.Item>
        <Form.Item name="minWidth" style={{ margin: 0 }}>
          <InputNumber placeholder="min width" className="app-filter-form--item-field" />
        </Form.Item>
        <Form.Item name="description" style={{ margin: 0 }}>
          <Input placeholder="description" className="app-filter-form--item-field" allowClear />
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            type="link"
            onClick={(e) => {
              publicationCoverTypeListFilterForm.setFieldsValue({
                code: undefined,
                coverCount: undefined,
                description: undefined,
                minHeight: undefined,
                minWidth: undefined,
              });
              handleSearch({}, true);
            }}
          >
            Reset Filters
          </Button>
        </Form.Item>
      </FlexListTemplate>
    </Form>
  );
};
