import React, { CSSProperties, forwardRef, LegacyRef } from 'react';

export interface IFlexListTemplateProps {
  gap?: number | string;
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  flexWrap?: CSSProperties['flexWrap'];
  children?: React.ReactElement | React.ReactElement[] | React.ReactNode;
  style?: CSSProperties | undefined;
  innerRef?: React.ForwardedRef<HTMLDivElement>;
}

export const FlexListTemplateComponent: React.FC<IFlexListTemplateProps> = (props): React.ReactElement => {
  const {
    children,
    justifyContent = 'center',
    alignItems = 'center',
    flexWrap = 'wrap',
    gap = 16,
    style = undefined,
    innerRef,
  } = props;
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexWrap,
        gap,
        justifyContent,
        alignItems,
        ...style,
      }}
      ref={innerRef}
    >
      {children}
    </div>
  );
};

export const FlexListTemplate = forwardRef<HTMLDivElement, IFlexListTemplateProps>((props, ref) => (
  <FlexListTemplateComponent {...props} innerRef={ref} />
));
