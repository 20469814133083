import { useEffect, useState } from 'react';

const getCurrentTheme = () => window.matchMedia('(prefers-color-scheme: dark)').matches;
export const useSystemThemeDetector = (): boolean => {
  const [isSystemDarkModeTheme, setIsSystemDarkModeTheme] = useState(getCurrentTheme());
  const mqListener = (e) => {
    setIsSystemDarkModeTheme(e.matches);
  };

  useEffect(() => {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
    darkThemeMq.addEventListener('change', mqListener);
    return () => darkThemeMq.removeEventListener('change', mqListener);
  }, []);
  return isSystemDarkModeTheme;
};
