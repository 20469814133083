import React from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { FilePptOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { Button } from 'antd';
import { ManagerPublicationListItemFragment } from '../../../../../services/apolloGraphqlAppApiClient/models/types';

export interface IUseManagePublicationEpubsActionProps {
  publicationData: ManagerPublicationListItemFragment;
}

export interface IUseManagePublicationEpubsActionResult {
  managePublicationEpubsAction: ItemType;
}

export const useManagePublicationEpubsAction = (
  props: IUseManagePublicationEpubsActionProps,
): IUseManagePublicationEpubsActionResult => {
  const { publicationData } = props;

  const menuItemKey = `useManagePublicationEpubsAction-${publicationData.publicationId}`;

  const visible = true;

  return {
    managePublicationEpubsAction: visible
      ? {
          key: menuItemKey,
          style: {
            padding: 0,
          },
          label: (
            <NavLink to={`/publications/${publicationData.publicationId}/epubs`}>
              <Button
                type="text"
                icon={<FilePptOutlined style={{ fontSize: 12 }} />}
                style={{ padding: '5px 12px', borderRadius: 4, width: '100%', height: '100%' }}
              >
                Manage epubs
              </Button>
            </NavLink>
          ),
          onClick: (menuInfo) => {
            // menuInfo.domEvent.preventDefault();
            menuInfo.domEvent.stopPropagation();
          },
        }
      : null,
  };
};
