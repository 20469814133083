import React, {
  createContext,
  ElementType,
  FC,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { cx, deepMerge } from '@remirror/core';
import { createThemeVariables, CSSProperties, defaultRemirrorTheme, RemirrorThemeType, THEME } from '@remirror/theme';
import { darkTheme } from './darkTheme';
import { lightTheme } from './lightTheme';

import '../../assets/styles/index.scss';

const defaultThemes = {
  light: lightTheme,
  dark: darkTheme,
};

export interface EditorThemeProviderProps {
  children?: ReactNode | ReactNode[];
  customThemes?: { dark: UseThemeProps; light: UseThemeProps };
  themeMode?: 'dark' | 'light';
  className?: string;
}

const getCurrentTheme = (
  themeMode: 'dark' | 'light' | undefined,
  customThemes: { dark: UseThemeProps; light: UseThemeProps } | undefined,
) => {
  if (customThemes) {
    return themeMode === 'dark' ? customThemes.dark : customThemes.light;
  }
  return themeMode === 'dark' ? defaultThemes.dark : defaultThemes.light;
};

export const EditorThemeProvider: FC<EditorThemeProviderProps> = ({ children, customThemes, themeMode, className }) => {
  const [currentTheme, setCurrentTheme] = useState<UseThemeProps>(getCurrentTheme(themeMode, customThemes));

  useEffect(() => {
    setCurrentTheme(getCurrentTheme(themeMode, customThemes));
  }, [themeMode, customThemes]);

  const theme = useTheme(currentTheme);
  return (
    <ThemeProvider {...theme} themeMode={themeMode} className={className}>
      {children}
    </ThemeProvider>
  );
};

const ThemeContext = createContext<RemirrorThemeType>({});

export interface UseThemeProps {
  /**
   * The theme to customise the look and feel of your remirror editor.
   */
  theme?: RemirrorThemeType;
  /**
   * Any extra class names to add to the wrapper component.
   */
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string;
}

/**
 * Get the theme from the context and convert it to a style object which can be
 * attached to any element.
 *
 * The theme provided is deeply merged with the parent theme.
 */
export function useTheme(props: UseThemeProps = {}): {
  theme: RemirrorThemeType;
  style: CSSProperties;
  className?: string;
} {
  // Get theme from parent context.
  const parent = useContext(ThemeContext);
  const theme = useMemo(() => deepMerge(parent, props.theme ?? {}), [parent, props.theme]);
  const style = useMemo(() => createThemeVariables(theme).styles, [theme]);
  const className = cx(THEME, props.className);

  return useMemo(() => ({ style, className, theme }), [style, className, theme]);
}

export interface ThemeProviderProps extends UseThemeProps {
  /**
   * A custom component to use for the wrapper.
   *
   * @defaultValue 'div'
   */
  as?: ElementType<{ style?: CSSProperties; className?: string }>;

  children: ReactNode;
  themeMode?: 'dark' | 'light';
}

/**
 * This the `ThemeProvider`. Wrap your editor with it to customise the theming
 * of content within your editor.
 *
 * Please be aware that this wraps your component in an extra dom layer.
 */
export const ThemeProvider = (props: ThemeProviderProps): ReactElement<ThemeProviderProps> => {
  const { children, as: Component = 'div', themeMode = 'light', className: propClassName } = props;
  const { theme, style, className } = useTheme({
    theme: props.theme ?? defaultRemirrorTheme,
    className: propClassName,
  });

  const muiTheme = createTheme({
    palette: {
      mode: themeMode,
      primary: {
        main: theme.color?.primary ?? defaultRemirrorTheme.color.primary,
        dark: theme.color?.hover?.primary ?? defaultRemirrorTheme.color.hover.primary,
        contrastText: theme.color?.primaryText ?? defaultRemirrorTheme.color.primaryText,
      },
      secondary: {
        main: theme.color?.secondary ?? defaultRemirrorTheme.color.secondary,
        dark: theme.color?.hover?.secondary ?? defaultRemirrorTheme.color.hover.secondary,
        contrastText: theme.color?.secondaryText ?? defaultRemirrorTheme.color.secondaryText,
      },
    },
  });

  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeContext.Provider value={theme}>
        <Component style={style} className={className}>
          {children}
        </Component>
      </ThemeContext.Provider>
    </MuiThemeProvider>
  );
};
