import { App, Form } from 'antd';
import React, { useRef } from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { EditOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';

import {
  AddParagraphInput,
  PublicationChaptersReaderParagraphListItemFragment,
  useAddPublicationChaptersParagraphMutation,
} from '../../../services/apolloGraphqlAppApiClient/models/types';
import { PublicationParagraphEditor } from './PublicationParagraphEditor';
import { usePublicationChaptersReaderStore } from '../PublicationChaptersReaderStoreProvider';
import { IModalContentFormTemplateProps, ModalContentFormTemplate } from '../../../components';

export interface IUseAddPublicationChaptersParagraphBeforeActionProps {
  paragraphData: PublicationChaptersReaderParagraphListItemFragment;
}

export interface IUseAddPublicationChaptersParagraphBeforeActionResult {
  addPublicationChaptersParagraphBeforeAction: ItemType;
}

export const useAddPublicationChaptersParagraphBeforeAction = (
  props: IUseAddPublicationChaptersParagraphBeforeActionProps,
): IUseAddPublicationChaptersParagraphBeforeActionResult => {
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();
  const { paragraphData } = props;
  const { fetchParagraphsLazy, publicationChaptersReaderStore, historyParams } = usePublicationChaptersReaderStore();

  const menuItemKey = `AddPublicationChaptersParagraphBeforeAction-${paragraphData.paraId}`;
  const infoKey = uuidV4();
  const modalRef = useRef<ReturnType<typeof confirm>>();

  const visible = true;

  const [AddPublicationChaptersParagraphBeforeAction] = useAddPublicationChaptersParagraphMutation({
    onCompleted: (result) => {
      info({
        placement: 'topRight',
        message: `AddPublicationChaptersParagraphBefore status: true`,
        description: (
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={{ __html: result.addParagraph.paragraphDto?.content || '' }} />
        ),
        key: infoKey,
        duration: 7,
      });
      publicationChaptersReaderStore
        .refetch({ publicationId: historyParams?.publicationId })
        .then((res) => {
          const resultParagraphOrder = paragraphData.order;
          const nextOrder = resultParagraphOrder > 1 ? resultParagraphOrder - 1 : resultParagraphOrder;
          fetchParagraphsLazy({
            order: nextOrder,
          }).catch((e) => {
            console.log({ e });
          });
        })
        .catch((e) => {
          console.log('[fetchReaderParagraphError]', { e });
        });
    },
    onError: (e) => {
      return e;
    },
  });

  const handleOk: IModalContentFormTemplateProps<AddParagraphInput>['onFinish'] = async (values, form) => {
    const r = await AddPublicationChaptersParagraphBeforeAction({
      variables: {
        input: {
          before: paragraphData.paragraphId,
          content: values.content,
          publicationId: paragraphData.publicationId,
        },
      },
    });
    if (r.errors) {
      return Promise.reject(r.errors);
    }
    if (modalRef.current) {
      modalRef.current.destroy();
    }
    return r;
  };

  const handleCancel: IModalContentFormTemplateProps<AddParagraphInput>['onCancel'] = async (form) => {
    if (modalRef.current) {
      modalRef.current.destroy();
    }
    form.resetFields();
  };
  return {
    addPublicationChaptersParagraphBeforeAction: visible
      ? {
          key: menuItemKey,
          icon: <EditOutlined />,
          label: 'Add paragraph before',
          onClick: (menuInfo) => {
            menuInfo.domEvent.preventDefault();
            menuInfo.domEvent.stopPropagation();
            modalRef.current = confirm({
              title: `Add paragraph before: ${paragraphData.paraId}!`,
              width: '75%',
              footer: null,
              content: (
                <ModalContentFormTemplate<AddParagraphInput>
                  initialValues={{
                    content: '',
                  }}
                  layout="vertical"
                  name={`addPublicationChaptersParagraphBeforeForm-${paragraphData.paraId}`}
                  onFinish={handleOk}
                  onCancel={handleCancel}
                  buttonProps={{ submit: { label: 'Add paragraph' } }}
                >
                  <Form.Item
                    name="content"
                    valuePropName="value"
                    label="WEML Content Editor"
                    rules={[
                      {
                        required: false,
                        message: `Please input paragraph content!`,
                      },
                    ]}
                  >
                    <PublicationParagraphEditor initialContent="" />
                  </Form.Item>
                </ModalContentFormTemplate>
              ),
            });
          },
        }
      : null,
  };
};
