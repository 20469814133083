import React, { useCallback } from 'react';
import { Input, Space } from 'antd';
import { SearchProps } from 'antd/es/input';
import { usePublicationsManagerStoreContext } from './PublicationsManagerStoreProvider';

export const ManagerFilters = (props) => {
  const { historySearchProps, handlePageSearchPropsChange } = usePublicationsManagerStoreContext();

  const handleFoldersSearch = useCallback<Exclude<SearchProps['onSearch'], undefined>>(
    (value, e) => {
      handlePageSearchPropsChange({ filter: { folders: value || undefined } });
    },
    [handlePageSearchPropsChange],
  );

  const handlePublicationsSearch = useCallback<Exclude<SearchProps['onSearch'], undefined>>(
    (value, e) => {
      handlePageSearchPropsChange({ filter: { publications: value || undefined } });
    },
    [handlePageSearchPropsChange],
  );

  return (
    <Space wrap>
      <Input.Search
        defaultValue={historySearchProps?.filter?.folders}
        placeholder="Folder search"
        allowClear
        enterButton
        onSearch={handleFoldersSearch}
      />
      <Input.Search
        defaultValue={historySearchProps?.filter?.publications}
        placeholder="Publications search"
        allowClear
        enterButton
        onSearch={handlePublicationsSearch}
      />
    </Space>
  );
};
