import React, { FC, useCallback } from 'react';

import { useActive, useCommands } from '@remirror/react-core';
import { useAttrs } from '@remirror/react';
import { CommandPopoverButton, ICommandPopoverButtonProps } from '../../../components';
import { ToggleWImageForm } from './ToggleWImageForm';
import { extractWImageUrl, WImageAttributes, WImageExtension } from '../WImageExtension';
import { extractUrl } from '../../WLinkExtension';
import { WFigureExtension } from '../WFigureExtension';

export type IToggleWImageButtonProps = Omit<
  ICommandPopoverButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect' | 'popoverContent'
>;
export const ToggleWImageButton: FC<IToggleWImageButtonProps> = (props) => {
  const attrs = useAttrs<WImageExtension>().wImage() as WImageAttributes;

  const { insertWImage } = useCommands<WImageExtension>();

  const handleSubmit = useCallback(
    (values) => {
      console.log({ values });
      if (insertWImage.enabled()) {
        insertWImage(values);
      }
    },
    [insertWImage],
  );

  const active = useActive<WImageExtension>().wFigure();

  const enabled = insertWImage.enabled();

  const initialValues = {
    ...(attrs?.src ? { src: extractWImageUrl({ url: attrs.src }).url } : {}),
    ...(attrs?.alt ? { alt: attrs.alt } : {}),
  };

  // console.log({ attrs, initialValues });

  return (
    <CommandPopoverButton
      {...props}
      commandName="insertWImage"
      active={active}
      enabled
      // onSelect={handleClickEdit}
      popoverContent={
        <ToggleWImageForm
          initialOption={attrs?.src ? extractWImageUrl({ url: attrs.src })?.protocol : ''}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          // onRemove={active ? handleRemove : undefined}
        />
      }
    />
  );
};
