import React, { createContext, useContext, useMemo } from 'react';

export const PopoverContext = createContext<{ [key: string]: any } | undefined>(undefined);
PopoverContext.displayName = 'PopoverContext';

export const usePopoverContext = () => {
  const context = useContext(PopoverContext);
  if (context === undefined) {
    throw new Error('usePopoverContext must be used within PopoverProvider');
  }
  return context;
};

export interface IPopoverProvider {
  children?: React.ReactNode;
  context?: { [key: string]: any };
}

export const PopoverProvider = (props: IPopoverProvider) => {
  const { children, context: initialContext } = props;
  const context = useMemo(
    () => ({
      ...initialContext,
    }),
    [initialContext],
  );

  return <PopoverContext.Provider value={context}>{children}</PopoverContext.Provider>;
};
