import { ExtensionTag, includes } from '@remirror/core';
import { NodeType, ProsemirrorNode } from '@remirror/pm';

export function isList(type: NodeType): boolean {
  return !!type.spec.group?.includes(ExtensionTag.ListContainerNode);
}

export function isListItem(type: NodeType): boolean {
  return !!type.spec.group?.includes(ExtensionTag.ListItemNode);
}

export function isListNode(node: ProsemirrorNode): boolean {
  return isList(node.type);
}

export function isListItemNode(node: ProsemirrorNode): boolean {
  return isListItem(node.type);
}

export function getListType(value: string | null | undefined, validTypes: string[], defaultType: string): string {
  return includes(validTypes, value) ? value : defaultType;
}
