import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Space, Typography } from 'antd';
import CONSTANTS from '../../config/CONSTANTS';
import { IMAGES_MAP } from '../../config';
import { FlexListTemplate } from '../../components';
import { useMobileDetect } from '../../hooks';

export const TitleSection = (): React.ReactElement => {
  const { isDesktop } = useMobileDetect();
  return (
    <FlexListTemplate
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="start"
      style={{ height: '100%' }}
      // styles={{ item: { height: '80%', textOverflow: 'ellipsis' } }}
    >
      <Link to="/" style={{ height: '100%' }}>
        <FlexListTemplate
          flexWrap="nowrap"
          alignItems="center"
          justifyContent="start"
          style={{ height: '100%' }}
          gap={8}
          // styles={{ item: { height: '80%', textOverflow: 'ellipsis' } }}
        >
          <Image
            preview={false}
            alt="EGW"
            src={IMAGES_MAP.APP_LOGO.src}
            wrapperStyle={{
              height: '75%',
              width: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            style={{ height: '100%', width: 'auto' }}
          />
          <div
            style={{
              background: 'rgba(255, 255, 255, 0.2)',
              borderRadius: 6,
              height: '75%',
              width: 'auto',
              display: 'flex',
              gap: 8,
              flexDirection: 'column',
              flexWrap: 'nowrap',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0 5px',
              overflow: 'hidden',
            }}
          >
            <Typography.Text
              style={{
                fontFamily: 'monospace',
                fontSize: '1em',
                verticalAlign: 'middle',
                textAlign: 'center',
                lineHeight: 1,
              }}
            >
              {CONSTANTS.APP_TITLE}
            </Typography.Text>
          </div>
        </FlexListTemplate>
      </Link>
      {/* {isDesktop() && process.env.NODE_ENV !== 'production' && ( */}
      {/*  <Space */}
      {/*    wrap={false} */}
      {/*    align="center" */}
      {/*    style={{ height: 'inherit' }} */}
      {/*    styles={{ item: { height: '80%', textOverflow: 'ellipsis' } }} */}
      {/*  > */}
      {/*    {IMAGES_MAP.BANNERS_MAP.map((item) => ( */}
      {/*      <Image */}
      {/*        preview={false} */}
      {/*        key={item.alt} */}
      {/*        wrapperStyle={{ height: '100%' }} */}
      {/*        style={{ height: '100%', width: 'auto' }} */}
      {/*        {...item} */}
      {/*      /> */}
      {/*    ))} */}
      {/*  </Space> */}
      {/* )} */}
    </FlexListTemplate>
  );
};
