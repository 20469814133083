import { UseThemeProps } from '@remirror/react';
import { defaultRemirrorTheme } from 'remirror';

const primary = '#3c89e8';
const border = '#303030';
const background = '#141414';
const foreground = 'rgba(255,255,255,0.88)';

export const darkTheme: UseThemeProps = {
  theme: {
    ...defaultRemirrorTheme,
    color: {
      ...defaultRemirrorTheme.color,
      primary,
      outline: primary,
      border,
      background,
      foreground,
      primaryText: foreground,
      secondaryText: foreground,
      text: foreground,
      active: {
        ...defaultRemirrorTheme.color.active,
        primary,
        border,
        background,
        foreground,
        primaryText: foreground,
        secondaryText: foreground,
        text: foreground,
      },
      hover: {
        ...defaultRemirrorTheme.color.hover,
        primary,
        background,
        foreground,
        primaryText: foreground,
        secondaryText: foreground,
        text: foreground,
      },
    },
  },
};
