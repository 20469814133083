import { useActive, useAttrs, useCommands, useCurrentSelection, useExtensionEvent } from '@remirror/react';
import { useHelpers } from '@remirror/react-core';
import { useCallback, useMemo, useState } from 'react';
import { createMarkPositioner, ShortcutHandlerProps } from 'remirror/extensions';

import { WLinkExtension, WLinkViewClickHandlerProps } from '../WLinkExtension';

function useWLinkViewClick() {
  const [wLinkViewClickHandlerProps, setWLinkViewClickHandlerProps] = useState<
    WLinkViewClickHandlerProps | undefined
  >();
  const [isEditing, setIsEditing] = useState(false);

  useExtensionEvent(
    WLinkExtension,
    'onViewClick',
    useCallback(
      (props) => {
        if (!isEditing && props?.markWithPosition) {
          setIsEditing(true);
        }

        // @ts-ignore
        return setWLinkViewClickHandlerProps(props);
      },
      [isEditing],
    ),
  );

  return { wLinkViewClickHandlerProps, isEditing, setIsEditing };
}

export const useWLinkState = (): any => {
  const { isEditing, wLinkViewClickHandlerProps, setIsEditing } = useWLinkViewClick();
  const { to, empty } = useCurrentSelection();
  const { toggleWLink, removeWLink, selectWLink, focus } = useCommands();
  const { isSelectionEmpty } = useHelpers();

  const attrs = useAttrs().wLink();
  const active = useActive<WLinkExtension>().wLink();
  const enabled = (!isSelectionEmpty() && toggleWLink.enabled()) || isEditing;

  const handleRemove = useCallback(() => {
    removeWLink();
    focus();
  }, [removeWLink]);

  // const updateReason = useUpdateReason();
  //
  // useLayoutEffect(() => {
  //   if (!isEditing) {
  //     return;
  //   }
  //
  //   if (updateReason.doc || updateReason.selection) {
  //     setIsEditing(false);
  //   }
  // }, [isEditing, setIsEditing, updateReason.doc, updateReason.selection]);

  const handleSubmit = useCallback(
    (values) => {
      setIsEditing(false);
      const range = wLinkViewClickHandlerProps?.markWithPosition;
      if (range) {
        handleClickEdit();
      }
      if (values?.href) {
        toggleWLink({ ...values }, range);
      }
      focus();
    },
    [setIsEditing, wLinkViewClickHandlerProps, toggleWLink, attrs, to],
  );

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    focus();
  }, [setIsEditing]);

  const handleClickEdit = useCallback(() => {
    if (empty) {
      selectWLink();
    }
    setIsEditing(true);
  }, [selectWLink, empty, setIsEditing]);

  return useMemo(
    () => ({
      attrs,
      active,
      enabled,
      wLinkViewClickHandlerProps,
      isEditing,
      handleClickEdit,
      handleRemove,
      handleSubmit,
      handleCancel,
    }),
    [
      attrs,
      active,
      enabled,
      wLinkViewClickHandlerProps,
      isEditing,
      handleClickEdit,
      handleRemove,
      handleSubmit,
      handleCancel,
    ],
  );
};
