import { UseThemeProps } from '@remirror/react';
import { defaultRemirrorTheme } from 'remirror';

const primary = '#4096ff';
const border = '#d9d9d9';
const foreground = 'rgba(0,0,0,0.88)';
const background = '#fff';

export const lightTheme: UseThemeProps = {
  theme: {
    ...defaultRemirrorTheme,
    color: {
      ...defaultRemirrorTheme.color,
      primary,
      outline: primary,
      border,
      background,
      foreground,
      primaryText: foreground,
      secondaryText: foreground,
      text: foreground,
      active: {
        ...defaultRemirrorTheme.color.active,
        primary,
        border,
        background,
        foreground,
        primaryText: foreground,
        secondaryText: foreground,
        text: foreground,
      },
      hover: {
        ...defaultRemirrorTheme.color.hover,
        primary,
        background,
        foreground,
        primaryText: foreground,
        secondaryText: foreground,
        text: foreground,
      },
    },
  },
};
