import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  EditorState,
  extension,
  ExtensionPriority,
  ExtensionTag,
  findParentNodeOfType,
  Handler,
  isEqual,
  isTextSelection,
  keyBinding,
  KeyBindingProps,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
  NodeWithPosition,
  ProsemirrorAttributes,
  Static,
} from '@remirror/core';
import { findNodeAtSelection, isElementDomNode, toggleWrap } from 'remirror';
import { Fragment, Slice } from '@remirror/pm/model';
import { TextSelection } from '@remirror/pm/state';

export type WHeadingSkipProperty = string;
export type WHeadingLevelProperty = '1' | '2' | '3' | '4' | '5' | '6';

export interface WHeadingProperties {
  levelList: Static<WHeadingLevelProperty>[];
}

export interface WHeadingAttributes extends ProsemirrorAttributes {
  skip?: Static<WHeadingSkipProperty>;
  level: Static<WHeadingLevelProperty>;
}

export const W_HEADING_PROPERTIES: Static<WHeadingProperties> = {
  levelList: ['1', '2', '3', '4', '5', '6'],
};

const W_HEADING_ATTRIBUTES: Static<WHeadingAttributes> = {
  level: '1',
};

const TOGGLE_COMMAND_LABEL = 'Toggle wHeading';
const TOGGLE_COMMAND_DESCRIPTION = 'Toggle wHeading Attributes';
const TOGGLE_COMMAND_SHORTCUT = 'Ctrl-Shift-H';

const toggleWHeadingOptions: Remirror.CommandDecoratorOptions = {
  icon: 'heading',
  label: ({ t }) => t(TOGGLE_COMMAND_LABEL),
  description: ({ t }) => t(TOGGLE_COMMAND_DESCRIPTION),
  shortcut: TOGGLE_COMMAND_SHORTCUT,
};

const UPDATE_COMMAND_LABEL = 'Update wHeading';
const UPDATE_COMMAND_DESCRIPTION = 'Update wHeading Attributes';
const UPDATE_COMMAND_SHORTCUT = 'Ctrl-Shift-H';

const updateWHeadingOptions: Remirror.CommandDecoratorOptions = {
  icon: 'heading',
  label: ({ t }) => t(UPDATE_COMMAND_LABEL),
  description: ({ t }) => t(UPDATE_COMMAND_DESCRIPTION),
  shortcut: UPDATE_COMMAND_SHORTCUT,
};

export type WHeadingTypeProperty = 'footnote' | 'chapter-endnote' | 'book-endnote';

export interface WHeadingViewClickHandlerProps {
  event: MouseEvent;
  nodeWithPosition?: NodeWithPosition;
}

export interface WHeadingOptions {
  properties?: Static<WHeadingProperties>;
  attributes?: Static<WHeadingAttributes>;
  onViewClick?: Handler<(props: WHeadingViewClickHandlerProps) => boolean | undefined | void>;
}

@extension<WHeadingOptions>({
  defaultOptions: {
    properties: W_HEADING_PROPERTIES,
    attributes: W_HEADING_ATTRIBUTES,
  },
  handlerKeys: ['onViewClick'],
  staticKeys: ['properties', 'attributes'],
  defaultPriority: ExtensionPriority.Low,
})
export class WHeadingExtension extends NodeExtension<WHeadingOptions> {
  private lastGoodState?: EditorState = undefined;

  get name() {
    return 'wHeading' as const;
  }

  createTags() {
    return [ExtensionTag.WemlContainer, ExtensionTag.Block, ExtensionTag.FormattingNode];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      content: 'wTextBlock',
      defining: true,
      draggable: false,
      ...override,
      attrs: {
        ...extra.defaults(),
        skip: { default: this.options.attributes.skip },
        level: { default: this.options.attributes.level },
      },
      parseDOM: [
        {
          tag: 'w-heading',
          getAttrs: (node) => {
            return isElementDomNode(node)
              ? {
                  skip: node.getAttribute('skip') as string,
                  level: (node.getAttribute('level') as string) || this.options.attributes?.level,
                }
              : false;
          },
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (node) => {
        if (node?.attrs) {
          return [
            'w-heading',
            {
              ...(node.attrs?.skip ? { skip: node.attrs.skip.toString() } : {}),
              ...(node.attrs?.level ? { level: node.attrs.level.toString() } : {}),
            },
            0,
          ];
        }
        return ['w-heading', extra.dom(node), 0];
      },
    };
  }

  @command(toggleWHeadingOptions)
  toggleWHeading(attrs?: WHeadingAttributes): CommandFunction {
    // return updateNodeAttributes(this.type)(attrs, pos);
    return (props) => {
      const {
        state: { tr, selection, doc, schema },
        dispatch,
        view,
      } = props;
      // if (!isValidCalloutExtensionAttributes(attributes)) {
      //   throw new Error('Invalid attrs passed to the updateAttributes method');
      // }

      const parent = findParentNodeOfType({
        types: [this.type, schema.nodes.wPara, schema.nodes.wPageBlock],
        selection,
      });

      if (!parent || isEqual(attrs, parent.node.attrs)) {
        // Do nothing since the attrs are the same
        return toggleWrap(this.type, attrs)(props);
      }

      tr.setNodeMarkup(parent.pos, this.type, {
        ...parent.node.attrs,
        ...attrs,
      });

      if (dispatch) {
        dispatch(tr);
      }

      return true;
    };
  }

  @command(updateWHeadingOptions)
  updateWHeading(attrs?: WHeadingAttributes, pos?: number): CommandFunction {
    // return updateNodeAttributes(this.type)(attrs, pos);
    return ({ state: { tr, selection, doc, schema }, dispatch, view }) => {
      // if (!isValidCalloutExtensionAttributes(attributes)) {
      //   throw new Error('Invalid attrs passed to the updateAttributes method');
      // }

      const parent = findParentNodeOfType({
        types: [this.type, schema.nodes.wPara, schema.nodes.wPageBlock],
        selection: pos ? doc.resolve(pos) : selection,
      });
      console.log({ parent });

      if (!parent || isEqual(attrs, parent.node.attrs)) {
        // Do nothing since the attrs are the same
        return false;
      }

      tr.setNodeMarkup(parent.pos, this.type, {
        ...parent.node.attrs,
        ...attrs,
      });

      if (dispatch) {
        dispatch(tr);
      }

      return true;
    };
  }

  @keyBinding({ shortcut: 'Enter' })
  handleEnterKey({ dispatch, tr, state }: KeyBindingProps): boolean {
    if (!(isTextSelection(tr.selection) && tr.selection.empty)) {
      return false;
    }

    const { nodeBefore, nodeAfter, parent } = tr.selection.$from;

    if (!nodeBefore?.isText || !parent.type.isTextblock) {
      return false;
    }

    if (!nodeAfter?.isText || !parent.type.isTextblock) {
      const pos = tr.selection.$from.after();
      const end = pos + 1;
      // +1 to account for the extra pos a node takes up

      if (dispatch) {
        const slice = new Slice(Fragment.from(state.schema.nodes.wTextBlock.create()), 0, 1);
        tr.replace(pos, pos, slice);

        // Set the selection to within the callout
        tr.setSelection(TextSelection.near(tr.doc.resolve(pos + 1)));
        tr.scrollIntoView();
        dispatch(tr);
      }

      return true;
    }

    const { text, nodeSize } = nodeBefore;
    const { textContent } = parent;

    if (!text) {
      return false;
    }

    const regex = /^:::([A-Za-z]*)?$/;
    const matchesNodeBefore = text.match(regex);
    const matchesParent = textContent.match(regex);

    if (!matchesNodeBefore || !matchesParent) {
      return false;
    }

    const pos = tr.selection.$from.before();
    const end = pos + nodeSize + 1;
    // +1 to account for the extra pos a node takes up

    if (dispatch) {
      const slice = new Slice(Fragment.from(this.type.create({ type: 'wTextBlock' })), 0, 1);
      tr.replace(pos, end, slice);

      // Set the selection to within the callout
      tr.setSelection(TextSelection.near(tr.doc.resolve(pos + 1)));
      dispatch(tr);
    }

    return true;
  }

  @keyBinding({ shortcut: 'Backspace' })
  handleBackspace({ dispatch, tr }: KeyBindingProps): boolean {
    // Aims to stop merging callouts when deleting content in between

    // If the selection is not empty return false and let other extension
    // (ie: BaseKeymapExtension) to do the deleting operation.
    if (!tr.selection.empty) {
      return false;
    }

    const { $from } = tr.selection;

    // If not at the start of current node, no joining will happen
    if ($from.parentOffset !== 0) {
      return false;
    }

    // if ($from.depth === 0) {
    //   return false;
    // }

    const previousPosition = $from.before($from.depth) - 1;

    // If nothing above to join with
    if (previousPosition < 1) {
      return false;
    }

    const previousPos = tr.doc.resolve(previousPosition);

    // If resolving previous position fails, bail out
    if (!previousPos?.parent) {
      return false;
    }

    const previousNode = previousPos.parent;
    const { node, pos } = findNodeAtSelection(tr.selection);

    // If previous node is a callout, cut current node's content into it
    if (node.type !== this.type && previousNode.type === this.type) {
      const { content, nodeSize } = node;
      tr.delete(pos, pos + nodeSize);
      tr.setSelection(TextSelection.near(tr.doc.resolve(previousPosition - 1)));
      tr.insert(previousPosition - 1, content);

      if (dispatch) {
        dispatch(tr);
      }

      return true;
    }

    return false;
  }

  @keyBinding({ shortcut: TOGGLE_COMMAND_SHORTCUT, command: 'toggleWHeading' })
  shortcut(props: KeyBindingProps): boolean {
    return this.toggleWHeading()(props);
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wHeading: WHeadingExtension;
    }
  }
}
