import React from 'react';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';
import CONSTANTS from '../config/CONSTANTS';

const removeCodeFromLocation = (): void => {
  const [base, search] = window.location.href.split('?');
  if (!search) {
    return;
  }
  const newSearch = search
    .split('&')
    .map((param) => param.split('='))
    .filter(([key]) => !['code', 'state'].includes(key))
    .map((keyAndVal) => keyAndVal.join('='))
    .join('&');
  window.history.replaceState(window.history.state, 'null', base + (newSearch.length ? `?${newSearch}` : ''));
};

export const oidcConfig: AuthProviderProps = {
  authority: CONSTANTS.APP_API_SESSION_ACCESS_URL,
  client_id: CONSTANTS.APP_API_CLIENT_ID,
  redirect_uri: window.location.origin ?? CONSTANTS.APP_ROOT_URL,
  scope: 'openid offline_access roles',
  // userStore: new WebStorageStateStore({
  //   store: localStorage,
  // }),
  post_logout_redirect_uri: '/',
  // loadUserInfo: true,
  // mergeClaims: true,
  // filterProtocolClaims: false,
  automaticSilentRenew: true,
  monitorSession: true,
  onSigninCallback: async (user) => {
    if (!user) {
      return;
    }
    if (user?.state) {
      const { state } = user;
      // window.history.replaceState(
      //   {},
      //   document.title,
      //   (
      //     state as {
      //       preAuthLocation: Location;
      //     }
      //   )?.preAuthLocation.pathname,
      // );
      if (window.location.href !== (state as { preAuthLocation: Location })?.preAuthLocation.href) {
        window.location.replace((state as { preAuthLocation: Location })?.preAuthLocation.href);
      }
    }
  },
};
export const AppAuthenticationServiceProvider = ({ children }) => {
  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>;
};
