import React from 'react';
import { Tabs } from 'antd';
import { createStyles } from 'antd-style';
import { PublicationCoverTypeCoversList } from './Components';
import {
  PublicationCoversManagerStoreProvider,
  usePublicationCoversManagerStore,
} from './PublicationCoversManagerStoreProvider';
import { UploadPublicationCoverActionButton } from './Components/PublicationCoverActions';
import { useAppLayoutContext } from '../../layout';
import { PublicationPageHoc } from '../PublicationPageHoc';

const usePublicationCoverTypesTabsStyles = createStyles(({ token }) => ({
  publicationCoverTypesTabs: {
    height: '100%',
    '.tabs-extra-button': {
      marginRight: 16,
    },
    '.ant-tabs-content-holder': {
      height: 'calc(100% - 54px)',
    },
    '.ant-tabs-content, .ant-tabs-tabpane': {
      height: '100%',
    },
    '.ant-row-rtl.tabs-extra-demo-button': {
      marginRight: 0,
      marginLeft: 16,
    },
  },
}));
export const PublicationCoversManagerPageComponent = (props) => {
  const { setParams, historyParams, publicationCoverTypes, publicationCoversManagerStore } =
    usePublicationCoversManagerStore();
  const { styles } = usePublicationCoverTypesTabsStyles();
  const { minPageContentHeight } = useAppLayoutContext();
  return (
    <Tabs
      className={styles.publicationCoverTypesTabs}
      style={{ height: minPageContentHeight || 600 }}
      tabBarExtraContent={{
        ...(historyParams?.typeId ? { left: <UploadPublicationCoverActionButton /> } : {}),
      }}
      activeKey={historyParams?.typeId}
      onChange={(activeKey) => {
        if (setParams) {
          setParams(`/publications/${historyParams?.publicationId}/covers/${activeKey}`, { replace: true });
        }
        // setActiveCoverType(publicationCoverTypes.data?.coverTypes?.items?.find((i) => i.code === activeKey));
      }}
      items={publicationCoverTypes.data?.coverTypes?.items?.map((coverType, i) => {
        return {
          label: `${coverType.code}`,
          key: coverType.code,
          children: <PublicationCoverTypeCoversList {...coverType} />,
        };
      })}
      destroyInactiveTabPane
    />
  );
};

export const PublicationCoversManagerPage = (props) => {
  return (
    <PublicationPageHoc title="covers manager">
      <PublicationCoversManagerStoreProvider>
        <PublicationCoversManagerPageComponent />
      </PublicationCoversManagerStoreProvider>
    </PublicationPageHoc>
  );
};
