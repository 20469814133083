import React, { FC } from 'react';

import { useWSentState, WSentExtension, WSentIcon } from '../../extensions';
import { CommandButton, ICommandButtonProps } from './CommandButton';

export type IToggleWSentButtonProps = Omit<
  ICommandButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'
>;
export const ToggleWSentButton: FC<IToggleWSentButtonProps> = (props) => {
  const { enabled, active, handleSubmit, attrs } = useWSentState();

  return (
    <CommandButton
      {...props}
      commandName="toggleWSent"
      active={active}
      enabled={enabled}
      onSelect={handleSubmit}
      icon={<WSentIcon />}
    />
  );
};
