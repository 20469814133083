import { App, ModalFuncProps } from 'antd';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { FlexListTemplate } from '../../../../../components';
import {
  SetPublicationOrderInput,
  ManagerFolderTreeListItemFragment,
  useSetPublicationOrderMutation,
} from '../../../../../services/apolloGraphqlAppApiClient/models/types';
import { TManagerPublicationListItem, usePublicationListStore } from '../usePublicationListStore';
import { usePublicationListStoreContext } from '../PublicationListStoreProvider';

export interface IUseSetPublicationOrderActionProps {
  folderData: ManagerFolderTreeListItemFragment;
}

export interface IUseSetPublicationOrderActionResult {
  setPublicationOrderAction: (...args: [SetPublicationOrderInput, ISetPublicationOrderActionOptions]) => void;
}

export type ISetPublicationOrderActionOptions = {
  dragTitle: TManagerPublicationListItem['title'];
  dropTitle: TManagerPublicationListItem['title'];
  position: 'before' | 'after';
};

export const useSetPublicationOrderAction = (): IUseSetPublicationOrderActionResult => {
  const { refetch } = usePublicationListStoreContext();
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();
  const infoKey = uuidV4();

  const [setPublicationOrder] = useSetPublicationOrderMutation({
    onCompleted: (result) => {
      info({
        placement: 'topRight',
        message: `Set Publication Order status: true`,
        description: (
          <FlexListTemplate gap={5} alignItems="left" justifyContent="left" style={{ flexDirection: 'column' }}>
            {/* info */}
          </FlexListTemplate>
        ),
        key: infoKey,
        duration: 7,
      });
      refetch(
        result.setPublicationOrder.publicationDto?.order
          ? result.setPublicationOrder.publicationDto.order - 1
          : undefined,
      );
    },
    onError: (e) => {
      return e;
    },
  });

  const handleOk: ModalFuncProps['onOk'] = async (values: SetPublicationOrderInput) => {
    await setPublicationOrder({
      variables: {
        input: { ...values },
      },
    });
  };

  const setPublicationOrderAction: IUseSetPublicationOrderActionResult['setPublicationOrderAction'] = (
    values,
    options,
  ) => {
    const { dragTitle, dropTitle, position } = options;
    confirm({
      title: `Set ${dragTitle} Publication order!`,
      onOk: () => handleOk(values),
      okText: 'Set Order',
      content: `Are you sure you want to sort "${dragTitle}" ${position} "${dropTitle}"`,
    });
  };

  return {
    setPublicationOrderAction,
  };
};
