import React, { useEffect } from 'react';
import { Select, SelectProps, Spin } from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { useDebounce, useDidMountEffect } from '../hooks';

export interface IDebounceSelectProps<ValueType = any, OptionType extends DefaultOptionType = DefaultOptionType>
  extends SelectProps<ValueType, OptionType> {
  fetchCallback: (value: string) => Promise<OptionType[]>;
  debounceTimeout?: number;
}

// export type DebounceSelectValueType<ValueType = any> = ValueType;
//

export const DebounceSelect = <ValueType = any, OptionType extends DefaultOptionType = DefaultOptionType>(
  props: IDebounceSelectProps<ValueType, OptionType>,
) => {
  const { fetchCallback, debounceTimeout = 800, ...restProps } = props;

  const [fetching, setFetching] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<OptionType[]>([]);

  const handleSearch = useDebounce<SelectProps<ValueType, OptionType>['onSearch']>((value) => {
    setOptions([]);
    setFetching(true);
    fetchCallback(value).then((newOptions) => {
      setOptions(newOptions);
      setFetching(false);
    });
  }, debounceTimeout);

  useEffect(() => {
    handleSearch('');
  }, []);

  return (
    <Select<ValueType, OptionType>
      filterOption={false}
      showSearch
      onSearch={handleSearch}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...restProps}
      options={options}
    />
  );
};
