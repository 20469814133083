import React, { CSSProperties, FC, ReactNode } from 'react';

export interface IEditorSubFieldProps {
  label?: string;
  children?: ReactNode;
  style?: CSSProperties;
}

export const EditorSubField: FC<IEditorSubFieldProps> = (props) => {
  const { label = '', children, style } = props;
  return (
    <fieldset
      style={{
        borderWidth: 0,
        padding: 0,
        margin: '0.5em 0',
        position: 'relative',
        ...style,
      }}
    >
      <legend
        contentEditable={false}
        style={{
          textIndent: 'unset',
          padding: '0 0.5rem',
          fontWeight: 400,
          fontSize: '1rem',
          // display: block,
          transformOrigin: 'top left',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          opacity: 0.5,
          textOverflow: 'ellipsis',
          maxWidth: 'calc(133% - 32px)',
          position: 'absolute',
          left: 0,
          top: 0,
          transform: 'translate(14px, -9px) scale(0.75)',
          transition:
            'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
          // zIndex: 1,
          pointerEvents: 'auto',
          userSelect: 'none',
        }}
      >
        {label}
      </legend>
      {children}
    </fieldset>
  );
};
