import React, { useState } from 'react';
import { TActiveDragItem } from './PublicationsManagerDndProvider';

export interface IUsePublicationsManagerDndStoreResult {
  draggedFolder?: any;
  setDraggedFolder?: React.Dispatch<React.SetStateAction<any>>;
  activeDragItem: TActiveDragItem;
  setActiveDragItem: React.Dispatch<React.SetStateAction<TActiveDragItem>>;
}

export const usePublicationsManagerDndStore = (): IUsePublicationsManagerDndStoreResult => {
  const [activeDragItem, setActiveDragItem] = useState<TActiveDragItem>(null);
  return { activeDragItem, setActiveDragItem };
};
