import React, { createContext, CSSProperties, useContext, useMemo, useState } from 'react';
import { DndContext, DndContextProps, DragEndEvent, DragOverlay, Modifier, rectIntersection } from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { getEventCoordinates } from '@dnd-kit/utilities';
import { App, ModalFuncProps } from 'antd';
import { PublicationListItemTitleRender, TManagerPublicationListItem } from '../../Publications';
import { useGlobalCursorStyle } from '../../../../../hooks';
import { ManagerFolderTreeNodeType } from '../../Folders';
import {
  IUsePublicationsManagerDndStoreResult,
  usePublicationsManagerDndStore,
} from './usePublicationsManagerDndStore';
import { usePublicationsManagerStoreContext } from '../PublicationsManagerStoreProvider';
import { useDndPublicationActions } from '../../Publications/PublicationActions';

export const PublicationsManagerDndStoreContext = createContext<IUsePublicationsManagerDndStoreResult | undefined>(
  undefined,
);
PublicationsManagerDndStoreContext.displayName = 'PublicationsManagerDndStoreContext';

export const usePublicationsManagerDndStoreContext = () => {
  const context = useContext(PublicationsManagerDndStoreContext);
  if (context === undefined) {
    throw new Error('usePublicationsManagerDndContext must be used within PublicationsManagerDndProvider');
  }
  return context;
};

export type TDndPublicationType = TManagerPublicationListItem;
export type TDndFolderType = ManagerFolderTreeNodeType;

export type TActiveDragItem = {
  publication: TDndPublicationType;
} | null;

export const parseModalContent = (event: DragEndEvent): Partial<ModalFuncProps> => {
  const { active, over } = event;
  const dragTitle = active?.data?.current?.publication?.title;
  const dropTitle = over?.data?.current?.publication?.title || over?.data?.current?.folder?.title;
  const eventType = over?.data?.current?.publication ? 'sort' : 'move';
  const eventPreposition = over?.data?.current?.publication ? 'after' : 'in to';
  return {
    title: `${eventType} Publication Confirm!`.toUpperCase(),
    content: `Are you sure you want to ${eventType} "${dragTitle}" ${eventPreposition} "${dropTitle}"`,
  };
};

export const PublicationsManagerDndProviderComponent = (props) => {
  const { children } = props;
  const { modal, notification } = App.useApp();
  const { activeDragItem, setActiveDragItem } = usePublicationsManagerDndStoreContext();
  const { selectedFolder } = usePublicationsManagerStoreContext();

  const { setCursorStyleData } = useGlobalCursorStyle();

  const { movePublicationAction, setPublicationOrderAction, unpublishPublicationAction, publishPublicationAction } =
    useDndPublicationActions();

  const handleDragStart: DndContextProps['onDragStart'] = (event) => {
    const { active } = event;
    setCursorStyleData({ eventTrigger: 'start', cursorStyle: 'grabbing' });
    setActiveDragItem((active?.data?.current as TActiveDragItem) || null);
  };
  const handleDragEnd: DndContextProps['onDragEnd'] = (event) => {
    const { active, over } = event;
    console.log('[Drag End Event]', { event });
    setCursorStyleData(undefined);

    if (over) {
      if (
        (selectedFolder?.id === 0 && !over?.data?.current?.folder) ||
        selectedFolder?.id === over?.data?.current?.folder?.id
      ) {
        setActiveDragItem(null);
        return;
      }

      if (active?.id !== over?.id) {
        if (
          over?.data?.current?.folder?.isLeaf === false ||
          over?.data?.current?.folder?.type?.allowedTypes.includes(activeDragItem?.publication.type) === false
        ) {
          setActiveDragItem(null);
          return;
        }

        if (activeDragItem?.publication && over?.data?.current?.folder) {
          if (activeDragItem?.publication?.folderId !== null && over?.data?.current?.folder?.id === 0) {
            unpublishPublicationAction(
              {
                publicationId: activeDragItem?.publication?.publicationId as number,
              },
              { dragTitle: activeDragItem?.publication?.title },
            );
            setActiveDragItem(null);
            return;
          }
          if (activeDragItem?.publication?.folderId === null && over?.data?.current?.folder?.title !== 'Unpublished') {
            publishPublicationAction(
              {
                folderId: over?.data?.current?.folder?.id,
                publicationId: activeDragItem?.publication?.publicationId as number,
              },
              {
                dragTitle: activeDragItem?.publication?.title,
                dropTitle: over?.data?.current?.folder?.title,
              },
            );
            setActiveDragItem(null);
            return;
          }
          if (
            activeDragItem?.publication?.folderId !== null &&
            activeDragItem?.publication?.folderId !== undefined &&
            over?.data?.current?.folder?.title !== 'Unpublished'
          ) {
            movePublicationAction(
              {
                folderId: over?.data?.current?.folder?.id,
                publicationId: activeDragItem?.publication?.publicationId as number,
              },
              {
                dragTitle: activeDragItem?.publication?.title,
                dropTitle: over?.data?.current?.folder?.title,
              },
            );
            setActiveDragItem(null);
            return;
          }
        }
        if (
          activeDragItem?.publication &&
          over.data.current?.publication &&
          activeDragItem?.publication !== over.data.current?.publication.publicationId
        ) {
          setPublicationOrderAction(
            {
              order:
                activeDragItem?.publication.order < over.data.current.publication.order
                  ? over.data.current.publication.order
                  : over.data.current.publication.order - 1,
              publicationId: activeDragItem?.publication.publicationId,
            },
            {
              dragTitle: activeDragItem?.publication.title,
              dropTitle: over?.data?.current?.publication?.title,
              position: activeDragItem?.publication.order < over.data.current.publication.order ? 'after' : 'before',
            },
          );
          setActiveDragItem(null);
          return;
        }
      }
      setActiveDragItem(null);
    }
    setActiveDragItem(null);
  };
  const handleDragOver: DndContextProps['onDragOver'] = (event) => {
    const { active, over } = event;
    // setBodyCursorStyle('start', over?.data.current?.wrapper?.isLeaf ? 'grabbing' : 'no-drop');
    if (over?.id === `folder-${over?.data?.current?.id}`) {
      // console.log({ active, over });
    }
  };

  const handleDragMove: DndContextProps['onDragMove'] = (event) => {
    // console.log({ event });
    const { active, over } = event;
    let cursorStyle: CSSProperties['cursor'] = 'grabbing';
    if (!over) {
      cursorStyle = 'not-allowed';
    }
    if (over?.id === 'publications' && selectedFolder?.id === 0) {
      cursorStyle = 'no-drop';
    }
    if (over?.data.current?.folder?.isLeaf === false) {
      cursorStyle = 'progress';
    }
    if (over?.data?.current?.folder?.type?.allowedTypes.includes(active.data.current?.publication?.type) === false) {
      cursorStyle = 'no-drop';
    }
    setCursorStyleData({ eventTrigger: 'move', cursorStyle });
  };

  const snapCenterToCursorCustom: Modifier = ({ activatorEvent, draggingNodeRect, transform }) => {
    if (draggingNodeRect && activatorEvent) {
      const activatorCoordinates = getEventCoordinates(activatorEvent);

      if (!activatorCoordinates) {
        return transform;
      }

      const offsetX = activatorCoordinates.x - draggingNodeRect.left;
      const offsetY = activatorCoordinates.y - draggingNodeRect.top;
      // console.log({ transform });

      return {
        ...transform,
        x: transform.x + offsetX + 5,
        y: transform.y + offsetY - draggingNodeRect.height / 2,
      };
    }

    return transform;
  };

  return (
    <DndContext
      collisionDetection={rectIntersection}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragMove={handleDragMove}
      modifiers={[snapCenterToCursorCustom, restrictToWindowEdges]}
    >
      {children}
      <DragOverlay
        wrapperElement="div"
        dropAnimation={null}
        style={{
          position: 'absolute',
          width: 'fit-content',
          height: 'fit-content',
          cursor: 'inherit !important',
        }}
      >
        {activeDragItem?.publication ? (
          <PublicationListItemTitleRender isEllipsis record={activeDragItem.publication} withActions={false} />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export const PublicationsManagerDndProvider = ({ children }) => {
  const publicationsManagerDndStore = usePublicationsManagerDndStore();

  const publicationsManagerDndStoreContextObject: IUsePublicationsManagerDndStoreResult = useMemo(
    () => publicationsManagerDndStore,
    [publicationsManagerDndStore],
  );

  return (
    <PublicationsManagerDndStoreContext.Provider value={publicationsManagerDndStoreContextObject}>
      <PublicationsManagerDndProviderComponent>{children}</PublicationsManagerDndProviderComponent>
    </PublicationsManagerDndStoreContext.Provider>
  );
};
