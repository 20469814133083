import { Box } from '@mui/material';
import React, { FC, useState } from 'react';

import { useWEntityState, W_ENTITY_PROPERTIES } from '../../extensions';
import { ToggleWEntityForm } from '../forms';
import { CommandPopoverButton, ICommandPopoverButtonProps } from './index';

export type IToggleWEntityButtonProps = Omit<
  ICommandPopoverButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'popoverContent' | 'onSelect'
>;

export const ToggleWEntityButton: FC<IToggleWEntityButtonProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const [activeOption, setActiveOption] = useState(null);

  const {
    wEntityClickHandlerProps,
    attrs,
    active,
    enabled,
    isEditing,
    handleClickEdit,
    handleRemove,
    handleSubmit,
    handleCancel,
  } = useWEntityState();

  const initialValues = { ...attrs };

  const options = W_ENTITY_PROPERTIES.types.map((type) => {
    const isActive = active && type === attrs?.type;
    const disabled = !enabled && type === attrs?.type;
    return {
      value: type,
      active: isActive,
      disabled,
    };
  });

  return (
    <CommandPopoverButton
      {...props}
      commandName="toggleWEntity"
      active={active}
      enabled={enabled}
      icon={<Box sx={{ lineHeight: 'normal' }}>{options.find((opt) => opt.active)?.value || 'entity'}</Box>}
      popoverContent={
        <ToggleWEntityForm
          initialValues={active ? initialValues : undefined}
          onSubmit={handleSubmit}
          onRemove={isEditing && wEntityClickHandlerProps?.markWithPosition ? handleRemove : undefined}
        />
      }
    />
  );
};
