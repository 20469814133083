import { CommandsExtension } from '@remirror/core';
import { useCommands, useEditorState } from '@remirror/react-core';
import React, { FC, useCallback } from 'react';

import { CommandButton, ICommandButtonProps } from './CommandButton';

export type ITogglePasteButtonProps = Omit<
  ICommandButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'
>;

export const TogglePasteButton: FC<ITogglePasteButtonProps> = (props) => {
  const { paste } = useCommands<CommandsExtension>();
  // Force component update on state change
  useEditorState();

  const handleSelect = useCallback(() => {
    if (paste.enabled()) {
      paste();
    }
  }, [paste]);

  const enabled = paste.enabled();

  return <CommandButton {...props} commandName="paste" active={false} enabled={enabled} onSelect={handleSelect} />;
};
