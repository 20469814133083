import { IUseMovePublicationActionResult, useMovePublicationAction } from './useMovePublicationAction';
import { IUsePublishPublicationActionResult, usePublishPublicationAction } from './usePublishPublicationAction';
import { IUseUnpublishPublicationActionResult, useUnpublishPublicationAction } from './useUnpublishPublicationAction';
import { IUseSetPublicationOrderActionResult, useSetPublicationOrderAction } from './useSetPublicationOrderAction';

export type IUseFolderActionsResult = IUsePublishPublicationActionResult &
  IUseMovePublicationActionResult &
  IUseUnpublishPublicationActionResult &
  IUseSetPublicationOrderActionResult;

export const useDndPublicationActions = (): IUseFolderActionsResult => {
  const { movePublicationAction } = useMovePublicationAction();
  const { unpublishPublicationAction } = useUnpublishPublicationAction();
  const { publishPublicationAction } = usePublishPublicationAction();
  const { setPublicationOrderAction } = useSetPublicationOrderAction();

  return {
    movePublicationAction,
    unpublishPublicationAction,
    publishPublicationAction,
    setPublicationOrderAction,
  };
};
