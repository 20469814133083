import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { FileImageOutlined, FilePptOutlined } from '@ant-design/icons';
import { PublicationStoreProvider } from './PublicationStoreProvider';
import { PublicationPageHoc } from '../PublicationPageHoc';

export const PublicationPage = (props) => {
  const { publicationId } = useParams();
  const navigate = useNavigate();
  return (
    <PublicationPageHoc title="main page">
      <PublicationStoreProvider>
        <Button
          type="text"
          icon={<FileImageOutlined />}
          onClick={() => {
            navigate(`/publications/${publicationId}/covers`);
          }}
        >
          Manage covers
        </Button>
        <Button
          type="text"
          icon={<FilePptOutlined />}
          onClick={() => {
            navigate(`/publications/${publicationId}/epubs`);
          }}
        >
          Manage epubs
        </Button>
        <Button
          type="text"
          icon={<FilePptOutlined />}
          onClick={() => {
            navigate(`/publications/${publicationId}/chapters`);
          }}
        >
          Chapters reader
        </Button>
      </PublicationStoreProvider>
    </PublicationPageHoc>
  );
};
