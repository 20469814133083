import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  EditorState,
  EditorView,
  extension,
  ExtensionPriority,
  ExtensionTag,
  findParentNodeOfType,
  Handler,
  isElementDomNode,
  isEqual,
  isTextSelection,
  keyBinding,
  KeyBindingProps,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
  NodeWithPosition,
  ProsemirrorAttributes,
  Static,
} from '@remirror/core';
import { findNodeAtSelection, InputRule, toggleWrap } from 'remirror';
import { Fragment, Slice } from '@remirror/pm/model';
import { TextSelection } from '@remirror/pm/state';

export type WParaRoleProperty =
  | 'address'
  | 'addressee'
  | 'author'
  | 'date'
  | 'place'
  | 'introduction'
  | 'letterhead'
  | 'salutation'
  | 'signature-date'
  | 'signature-line'
  | 'signature-source'
  | 'bible-text'
  | 'devotional-text'
  | 'poem-source'
  | 'publication-info'
  | 'title';

export type WParaAlignProperty = 'left' | 'center' | 'right';
export type WParaSkipProperty = string;
export type WParaIndentProperty = string;

export interface WParaProperties {
  roleList: Static<WParaRoleProperty>[];
  alignList: Static<WParaAlignProperty>[];
}

export type WParaAttributes = ProsemirrorAttributes<{
  skip?: Static<WParaSkipProperty>;
  indent?: Static<WParaIndentProperty>;
  role?: Static<WParaRoleProperty>;
  align?: Static<WParaAlignProperty>;
}>;

export const W_PARA_PROPERTIES: Static<WParaProperties> = {
  roleList: [
    'address',
    'addressee',
    'author',
    'date',
    'place',
    'introduction',
    'letterhead',
    'salutation',
    'signature-date',
    'signature-line',
    'signature-source',
    'bible-text',
    'devotional-text',
    'poem-source',
    'publication-info',
    'title',
  ],
  alignList: ['left', 'center', 'right'],
};

const W_PARA_ATTRIBUTES: Static<WParaAttributes> = {
  indent: '1',
};

const TOGGLE_COMMAND_LABEL = 'Toggle wPara';
const TOGGLE_COMMAND_DESCRIPTION = 'Create wPara Attributes';
const TOGGLE_COMMAND_SHORTCUT = 'Ctrl-Shift-P';

const toggleWParaOptions: Remirror.CommandDecoratorOptions = {
  icon: 'paragraph',
  label: ({ t }) => t(TOGGLE_COMMAND_LABEL),
  description: ({ t }) => t(TOGGLE_COMMAND_DESCRIPTION),
  shortcut: TOGGLE_COMMAND_SHORTCUT,
};

export interface WParaOptions {
  properties?: Static<WParaProperties>;
  attributes?: Static<WParaAttributes>;
}

@extension<WParaOptions>({
  defaultOptions: {
    properties: W_PARA_PROPERTIES,
    attributes: W_PARA_ATTRIBUTES,
  },
  staticKeys: ['properties', 'attributes'],
  defaultPriority: ExtensionPriority.Medium,
})
export class WParaExtension extends NodeExtension<WParaOptions> {
  private lastGoodState?: EditorState = undefined;

  get name() {
    return 'wPara' as const;
  }

  createTags() {
    return [ExtensionTag.Block, ExtensionTag.FormattingNode, ExtensionTag.WemlContainer];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      content: 'wemlBlock',
      draggable: false,
      ...override,
      attrs: {
        ...extra.defaults(),
        skip: { default: this.options.attributes?.skip },
        indent: { default: this.options.attributes?.indent },
        role: { default: this.options.attributes?.role },
        align: { default: this.options.attributes?.align },
      },
      parseDOM: [
        {
          tag: 'w-para',
          getAttrs: (node) => {
            return isElementDomNode(node)
              ? {
                  skip: node.getAttribute('skip') as string,
                  indent: (node.getAttribute('indent') as string) || this.options.attributes?.indent,
                  role: node.getAttribute('role') as string,
                  align: node.getAttribute('align') as string,
                }
              : false;
          },
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (node) => {
        if (node?.attrs) {
          return [
            'w-para',
            {
              ...(node.attrs?.skip ? { skip: node.attrs.skip.toString() } : {}),
              ...(node.attrs?.indent ? { indent: node.attrs.indent.toString() } : {}),
              ...(node.attrs?.role ? { role: node.attrs.role.toString() } : {}),
              ...(node.attrs?.align ? { align: node.attrs.align.toString() } : {}),
            },
            0,
          ];
        }
        return ['w-para', extra.dom(node), 0];
      },
    };
  }

  @command(toggleWParaOptions)
  toggleWPara(attrs?: WParaAttributes): CommandFunction {
    // return updateNodeAttributes(this.type)(attrs, pos);
    return (props) => {
      // if (!isValidCalloutExtensionAttributes(attributes)) {
      //   throw new Error('Invalid attrs passed to the updateAttributes method');
      // }
      const {
        state: { tr, selection, schema, doc },
        dispatch,
      } = props;

      const parent = findParentNodeOfType({
        types: [this.type, schema.nodes.wHeading, schema.nodes.wPageBlock],
        selection,
      });

      if (!parent || isEqual(attrs, parent.node.attrs)) {
        // Do nothing since the attrs are the same
        // return false;
        return toggleWrap(this.type, attrs)(props);
      }

      tr.setNodeMarkup(parent.pos, this.type, {
        ...parent.node.attrs,
        ...attrs,
      });

      if (dispatch) {
        dispatch(tr);
      }

      return true;
    };
  }

  // @command(toggleWParaOptions)
  // updateWPara(attrs?: WParaAttributes, pos?: number): CommandFunction {
  //   // return updateNodeAttributes(this.type)(attrs, pos);
  //   return ({ state: { tr, selection, doc }, dispatch }) => {
  //     // if (!isValidCalloutExtensionAttributes(attributes)) {
  //     //   throw new Error('Invalid attrs passed to the updateAttributes method');
  //     // }
  //
  //     const parent = findParentNodeOfType({
  //       types: this.type,
  //       selection: pos ? doc.resolve(pos) : selection,
  //     });
  //
  //     if (!parent || isEqual(attrs, parent.node.attrs)) {
  //       // Do nothing since the attrs are the same
  //       return false;
  //     }
  //
  //     tr.setNodeMarkup(parent.pos, this.type, {
  //       ...parent.node.attrs,
  //       ...attrs,
  //     });
  //
  //     if (dispatch) {
  //       dispatch(tr);
  //     }
  //
  //     return true;
  //   };
  // }

  @keyBinding({ shortcut: 'Enter' })
  handleEnterKey({ dispatch, tr, state }: KeyBindingProps): boolean {
    if (!(isTextSelection(tr.selection) && tr.selection.empty)) {
      return false;
    }

    const { nodeBefore, nodeAfter, parent } = tr.selection.$from;

    if (!nodeBefore?.isText || !parent.type.isTextblock) {
      return false;
    }

    if (!nodeAfter?.isText || !parent.type.isTextblock) {
      const pos = tr.selection.$from.after();
      const end = pos + 1;
      // +1 to account for the extra pos a node takes up

      if (dispatch) {
        const slice = new Slice(Fragment.from(state.schema.nodes.wTextBlock.create()), 0, 1);
        tr.replace(pos, pos, slice);

        // Set the selection to within the callout
        tr.setSelection(TextSelection.near(tr.doc.resolve(pos + 1)));
        tr.scrollIntoView();
        dispatch(tr);
      }

      return true;
    }

    const regex = /^:::([A-Za-z]*)?$/;
    const { text, nodeSize } = nodeBefore;
    const { textContent } = parent;

    if (!text) {
      return false;
    }

    const matchesNodeBefore = text.match(regex);
    const matchesParent = textContent.match(regex);

    if (!matchesNodeBefore || !matchesParent) {
      return false;
    }

    const pos = tr.selection.$from.before();
    const end = pos + nodeSize + 1;
    // +1 to account for the extra pos a node takes up

    if (dispatch) {
      const slice = new Slice(Fragment.from(this.type.create({ type: 'wTextBlock' })), 0, 1);
      tr.replace(pos, end, slice);

      // Set the selection to within the callout
      tr.setSelection(TextSelection.near(tr.doc.resolve(pos + 1)));
      dispatch(tr);
    }

    return true;
  }

  @keyBinding({ shortcut: 'Backspace' })
  handleBackspace({ dispatch, tr }: KeyBindingProps): boolean {
    // Aims to stop merging callouts when deleting content in between

    // If the selection is not empty return false and let other extension
    // (ie: BaseKeymapExtension) to do the deleting operation.
    if (!tr.selection.empty) {
      return false;
    }

    const { $from } = tr.selection;

    // If not at the start of current node, no joining will happen
    if ($from.parentOffset !== 0) {
      return false;
    }

    // if ($from.depth === 0) {
    //   return false;
    // }

    const previousPosition = $from.before($from.depth) - 1;

    // If nothing above to join with
    if (previousPosition < 1) {
      return false;
    }

    const previousPos = tr.doc.resolve(previousPosition);

    // If resolving previous position fails, bail out
    if (!previousPos?.parent) {
      return false;
    }

    const previousNode = previousPos.parent;
    const { node, pos } = findNodeAtSelection(tr.selection);

    // If previous node is a callout, cut current node's content into it
    if (node.type !== this.type && previousNode.type === this.type) {
      const { content, nodeSize } = node;
      tr.delete(pos, pos + nodeSize);
      tr.setSelection(TextSelection.near(tr.doc.resolve(previousPosition - 1)));
      tr.insert(previousPosition - 1, content);

      if (dispatch) {
        dispatch(tr);
      }

      return true;
    }

    return false;
  }

  @keyBinding({ shortcut: TOGGLE_COMMAND_SHORTCUT, command: 'toggleWPara' })
  shortcut(props: KeyBindingProps): boolean {
    return this.toggleWPara()(props);
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wPara: WParaExtension;
    }
  }
}
