import { useCallback, useEffect, useRef, useState } from 'react';
import { useFoldersTreeStoreContext } from '../FoldersTreeStoreProvider';

// eslint-disable-next-line @typescript-eslint/ban-types
export const useDebounceExpandFolder = <T extends Function = (...args) => void | undefined>(
  callback: T,
  delay: number,
) => {
  const timerRef = useRef<any>(null);
  const triggerRef = useRef<any>(null);

  return useCallback(
    (...args) => {
      const nextArgs = [...args];
      if (triggerRef.current !== nextArgs[0] && timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (triggerRef.current !== nextArgs[0]) {
        timerRef.current = setTimeout(() => {
          callback(...nextArgs);
        }, delay);
      }
      if (triggerRef.current !== nextArgs[0]) {
        // eslint-disable-next-line prefer-destructuring
        triggerRef.current = nextArgs[0];
      }
    },
    [delay, triggerRef],
  );
};
