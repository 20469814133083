import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Card } from 'antd';

import { useResizeDetector } from 'react-resize-detector';
import { EpubReaderView } from './EpubReaderView';
import { EpubReaderStoreProvider } from './EpubReaderStoreProvider';
import { EpubReaderToc } from './EpubReaderToc';
import { EpubReaderHeader } from './EpubReaderHeader';
import { EpubReaderActionsProvider } from './EpubReaderActionsProvider';

export interface IEpubReaderProps {
  url: string;
  height?: CSSProperties['height'];
  extra?: React.ReactNode;
}

const Component: React.FC<IEpubReaderProps> = (props) => {
  const { height = 600, url, extra } = props;
  const { width: readerWrapperWidth, height: readerWrapperHeight, ref: readerWrapperRef } = useResizeDetector();
  const { width: headerWrapperWidth, height: headerWrapperHeight, ref: headerWrapperRef } = useResizeDetector();

  const readerContentStyle: React.CSSProperties = {
    position: 'relative',
    overflow: 'hidden',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'start',
    justifyContent: 'start',
    width: '100%',
  };

  return (
    <Card
      style={{ height }}
      headStyle={{ minHeight: 0, padding: 8 }}
      bodyStyle={{
        ...(headerWrapperHeight ? { height: `calc(100% - ${headerWrapperHeight}px - 16px)` } : {}),
        padding: 0,
      }}
      title={
        <div ref={headerWrapperRef}>
          <EpubReaderHeader url={url} extra={extra} />
        </div>
      }
    >
      <div
        ref={readerWrapperRef}
        className="EpubReaderWrapper"
        style={{
          height: '100%',
        }}
      >
        <div
          style={{
            ...readerContentStyle,
            height: readerWrapperHeight,
          }}
          className="EpubReaderConetent"
        >
          <EpubReaderToc url={url} height={readerWrapperHeight} />
          <EpubReaderView url={url} height={readerWrapperHeight} />
        </div>
      </div>
    </Card>
  );
};

export const EpubReader: React.FC<IEpubReaderProps> = (props) => {
  return (
    <EpubReaderStoreProvider>
      <EpubReaderActionsProvider>
        <Component {...props} />
      </EpubReaderActionsProvider>
    </EpubReaderStoreProvider>
  );
};
