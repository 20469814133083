import React from 'react';
import { Layout, Spin } from 'antd';
import { SpinProps } from 'antd/es/spin';

export const SpinnerLayout: React.FC<SpinProps> = (props) => {
  return (
    <Layout>
      <Layout.Content
        style={{
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin {...props} />
      </Layout.Content>
    </Layout>
  );
};
