import { ItemType } from 'antd/es/menu/hooks/useItems';
import { PublicationChaptersReaderParagraphListItemFragment } from '../../../services/apolloGraphqlAppApiClient/models/types';
import { useSetPublicationChaptersParagraphContentAction } from './useSetPublicationChaptersParagraphContentAction';
import { useAddPublicationChaptersParagraphBeforeAction } from './useAddPublicationChaptersParagraphBeforeAction';
import { useAddPublicationChaptersEndParagraphAction } from './useAddPublicationChaptersEndParagraphAction';
import { useDeletePublicationChaptersParagraphAction } from './useDeletePublicationChaptersParagraphAction';

export interface IUseContextPublicationParagraphActionsProps {
  paragraphData: PublicationChaptersReaderParagraphListItemFragment;
}

export type IUseContextPublicationParagraphActionsResult = ItemType[];

export const useContextPublicationChaptersParagraphActions = (
  props: IUseContextPublicationParagraphActionsProps,
): IUseContextPublicationParagraphActionsResult => {
  const { paragraphData } = props;
  const { setPublicationChaptersParagraphContentAction } = useSetPublicationChaptersParagraphContentAction({
    paragraphData,
  });
  const { addPublicationChaptersParagraphBeforeAction } = useAddPublicationChaptersParagraphBeforeAction({
    paragraphData,
  });
  const { addPublicationChaptersEndParagraphAction } = useAddPublicationChaptersEndParagraphAction({
    paragraphData,
  });
  const { deletePublicationChaptersParagraphAction } = useDeletePublicationChaptersParagraphAction({
    paragraphData,
  });

  return [
    {
      key: 'paraId',
      label: `ParaId: ${paragraphData.paraId}`,
      type: 'group',
    },
    setPublicationChaptersParagraphContentAction,
    addPublicationChaptersParagraphBeforeAction,
    addPublicationChaptersEndParagraphAction,
    deletePublicationChaptersParagraphAction,
  ];
};
