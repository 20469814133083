import { useCommands, useHelpers } from '@remirror/react-core';
import React, { FC, useCallback } from 'react';
import { HistoryExtension } from 'remirror/extensions';

import { CommandButton, ICommandButtonProps } from './CommandButton';

export type IToggleUndoButtonProps = Omit<
  ICommandButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'
>;

export const ToggleUndoButton: FC<IToggleUndoButtonProps> = (props) => {
  const { undo } = useCommands<HistoryExtension>();
  const { undoDepth } = useHelpers<HistoryExtension>(true);

  const handleSelect = useCallback(() => {
    if (undo.enabled()) {
      undo();
    }
  }, [undo]);

  const enabled = undoDepth() > 0;

  return <CommandButton {...props} commandName="undo" active={false} enabled={enabled} onSelect={handleSelect} />;
};
