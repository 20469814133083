import { useEffect, useState } from 'react';

export interface IUseSiderSwipeEffectProps {
  minSwipeStartWidth: number;
  maxSwipeStartWidth: number;
}

export interface IUseSiderSwipeEffectResult {
  isSiderSwiped: boolean;
  setIsSiderSwiped: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useSiderSwipeEffect = (props: IUseSiderSwipeEffectProps): IUseSiderSwipeEffectResult => {
  const { minSwipeStartWidth, maxSwipeStartWidth } = props;
  const [isSiderSwiped, setIsSiderSwiped] = useState<boolean>(false);

  useEffect(() => {
    let touchStartX = 0;
    let touchEndX = 0;

    const checkDirection = (): void => {
      if (touchStartX < maxSwipeStartWidth && touchEndX < touchStartX) {
        setIsSiderSwiped(false);
      }
      if (touchStartX < minSwipeStartWidth + 50 && touchEndX > touchStartX) {
        setIsSiderSwiped(true);
      }
    };

    const handleSwipedStart = (e) => {
      touchStartX = e.changedTouches[0].clientX;
    };

    const handleSwipedEnd = (e) => {
      touchEndX = e.changedTouches[0].clientX;
      checkDirection();
    };

    window.addEventListener('touchstart', handleSwipedStart);
    window.addEventListener('touchend', handleSwipedEnd);
    return () => {
      window.removeEventListener('touchstart', handleSwipedStart);
      window.removeEventListener('touchend', handleSwipedEnd);
    };
  }, []);

  return { isSiderSwiped, setIsSiderSwiped };
};
