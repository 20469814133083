import React, { useEffect, useState } from 'react';
import {
  GetManagerSelectedFolderQueryHookResult,
  ManagerPublicationListItemFragment,
  useGetManagerSelectedFolderQuery,
} from '../../../../services/apolloGraphqlAppApiClient/models/types';
import { ManagerFolderTreeNodeType, unpublishedFolder } from '../index';
import { IUsePageSearchPropsResult, usePageSearchProps } from '../../../../hooks';

export type TManagerPublicationListItem = ManagerPublicationListItemFragment;
export type ManagerSelectedFolderType = ManagerFolderTreeNodeType;
export type TManagerSelectedPublication = TManagerPublicationListItem | null;

export interface IManagerFooterData {
  foldersCount?: number;
  selectedFolderPublicationCount?: number;
  filteredPublicationCount?: number;
  loadedPublicationCount?: number;
  foldersCountRecursive?: number;
  publicationCountRecursive?: number;
}

export interface IPublicationsManagerFilters {
  folders?: string;
  publications?: string;
}

export interface IUsePublicationsManagerStoreResult extends IUsePageSearchPropsResult<IPublicationsManagerFilters> {
  selectedFolder: ManagerSelectedFolderType;
  setSelectedFolder: React.Dispatch<React.SetStateAction<ManagerSelectedFolderType>>;
  footerData: IManagerFooterData;
  setFooterData: React.Dispatch<React.SetStateAction<IManagerFooterData>>;
  selectedFolderStore: GetManagerSelectedFolderQueryHookResult;
}

const getSelectedFolderId = (id?: ManagerSelectedFolderType['id']): ManagerSelectedFolderType['id'] => {
  return id || 0;
};

export const usePublicationsManagerStore = (): IUsePublicationsManagerStoreResult => {
  const { historySearchProps, handlePageSearchPropsChange, ...rest } =
    usePageSearchProps<IPublicationsManagerFilters>(false);
  const [selectedFolder, setSelectedFolder] = useState<ManagerSelectedFolderType>(null!);
  const [footerData, setFooterData] = useState<IManagerFooterData>({});

  useEffect(() => {
    if (typeof rest?.historyParams?.selectedFolderId !== 'number') {
      if (rest?.setParams) {
        rest.setParams(`/publications/manager/0`);
      }
    }
  }, [rest?.historyParams?.selectedFolderId]);

  const selectedFolderStore: GetManagerSelectedFolderQueryHookResult = useGetManagerSelectedFolderQuery({
    variables: {
      folderId: typeof rest?.historyParams?.selectedFolderId === 'number' ? rest.historyParams.selectedFolderId : 0,
    },
    onCompleted: (newData) => {
      setSelectedFolder(() => {
        if (newData?.folder === null) {
          return unpublishedFolder;
        }
        return {
          ...newData.folder,
          selectable: newData.folder?.childFolderCount === 0,
        } as ManagerSelectedFolderType;
      });
    },
  });

  return {
    selectedFolder,
    setSelectedFolder,
    footerData,
    setFooterData,
    historySearchProps,
    handlePageSearchPropsChange,
    selectedFolderStore,
    ...rest,
  };
};
