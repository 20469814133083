import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionTag,
  FromToProps,
  GetMarkRange,
  getTextSelection,
  Handler,
  isAllSelection,
  isMarkActive,
  isSelectionEmpty,
  isTextSelection,
  MarkExtension,
  MarkExtensionSpec,
  MarkSpecOverride,
  PrimitiveSelection,
  ProsemirrorAttributes,
  removeMark,
  Static,
  updateMark,
} from '@remirror/core';
import { CreateEventHandlers } from '@remirror/extension-events';

export type WNonEgwTypeProperty = 'appendix' | 'comment' | 'foreword' | 'intro' | 'note' | 'preface' | 'text';

export interface WNonEgwProperties {
  typeList: Static<WNonEgwTypeProperty>[];
}

const CREATE_COMMAND_LABEL = 'Toggle WNonEgw';
const CREATE_COMMAND_DESCRIPTION = 'Insert WNonEgw Attributes';

export const toggleWNonEgwOptions: Remirror.CommandDecoratorOptions = {
  // label: ({ t }) => t(CREATE_COMMAND_LABEL),
  // description: ({ t }) => t(CREATE_COMMAND_DESCRIPTION),
  // icon: ({ attrs }) => (<>{attrs?.type ?? 'text'}</>) as unknown as CoreIcon,
  label: ({ t, attrs }) => t((attrs?.type as string) || CREATE_COMMAND_LABEL),
};

export type WNonEgwAttributes = ProsemirrorAttributes<{
  type: Static<WNonEgwTypeProperty>;
}>;

export const W_N0N_EGW_PROPERTIES: Static<WNonEgwProperties> = {
  typeList: ['appendix', 'comment', 'foreword', 'intro', 'note', 'preface', 'text'],
};

const W_N0N_EGW_ATTRIBUTES: Static<WNonEgwAttributes> = {
  type: 'text',
};

export interface WNonEgwClickData extends GetMarkRange, WNonEgwAttributes {}

export interface WNonEgwViewClickHandlerProps {
  event: MouseEvent;
  markWithPosition?: WNonEgwClickData;
}

export interface WNonEgwOptions {
  properties?: Static<WNonEgwProperties>;
  attributes?: Static<WNonEgwAttributes>;
  onViewClick?: Handler<(props: WNonEgwViewClickHandlerProps) => boolean | undefined | void>;
}

@extension<WNonEgwOptions>({
  defaultOptions: {
    properties: W_N0N_EGW_PROPERTIES,
    attributes: W_N0N_EGW_ATTRIBUTES,
  },
  handlerKeys: ['onViewClick'],
  staticKeys: ['properties', 'attributes'],
})
export class WNonEgwExtension extends MarkExtension<WNonEgwOptions> {
  get name() {
    return 'wNonEgw' as const;
  }

  createTags() {
    return [ExtensionTag.FormattingMark, ExtensionTag.FontStyle];
  }

  createMarkSpec(extra: ApplySchemaAttributes, override: MarkSpecOverride): MarkExtensionSpec {
    return {
      ...override,
      attrs: {
        ...extra.defaults(),
        type: { default: this.options.attributes.type },
      },
      parseDOM: [
        ...this.options.properties.typeList.map((type) => ({
          tag: `w-non-egw[type=${type}]`,
          getAttrs: (element: string | Node) => ({
            ...extra.parse(element),
            type,
          }),
        })),
        ...(override.parseDOM ?? []),
      ],
      toDOM: (mark) => {
        const {
          properties: { typeList },
        } = this.options;

        if (mark?.attrs?.type) {
          return ['w-non-egw', { type: mark.attrs.type.toString() }, 0];
        }

        return ['w-non-egw', extra.dom(mark), 0];
      },
    };
  }

  createEventHandlers(): CreateEventHandlers {
    return {
      clickMark: (event, clickState) => {
        const markRange = clickState.getMark(this.type);

        if (!markRange) {
          return this.options.onViewClick({ event });
        }

        const attrs = markRange.mark.attrs as WNonEgwAttributes;
        const markWithPosition: WNonEgwClickData = { ...attrs, ...markRange };

        if (!markWithPosition || markWithPosition.mark.type !== this.type) {
          return this.options.onViewClick({ event });
        }

        return this.options.onViewClick({ event, markWithPosition });
      },
    };
  }

  @command(toggleWNonEgwOptions)
  toggleWNonEgw(attrs?: WNonEgwAttributes, range?: FromToProps): CommandFunction {
    return (props) => {
      const { tr } = props;
      const selectionIsValid =
        (isTextSelection(tr.selection) && !isSelectionEmpty(tr.selection)) ||
        isAllSelection(tr.selection) ||
        isMarkActive({ trState: tr, type: this.type });

      if (!selectionIsValid && !range) {
        return false;
      }

      tr.setMeta(this.name, {
        command: 'toggleWNonEgw',
        attrs,
        range,
      });

      return updateMark({ type: this.type, attrs, range })(props);
    };
  }

  @command()
  setWNonEgw(selection?: PrimitiveSelection): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc);
      dispatch?.(tr.addMark(from, to, this.type.create()));
      return true;
    };
  }

  @command()
  removeWNonEgw(range?: FromToProps): CommandFunction {
    return (props) => {
      const { tr } = props;
      if (!isMarkActive({ trState: tr, type: this.type, ...range })) {
        return false;
      }
      return removeMark({ type: this.type, expand: true, range })(props);
    };
  }

  @command()
  selectWNonEgw(): CommandFunction {
    return this.store.commands.selectMark.original(this.type);
  }
}

interface WNonEgwCommandOptions {
  attrs?: ProsemirrorAttributes;
  selection?: PrimitiveSelection;
  preserveAttrs?: boolean;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wNonEgw: WNonEgwExtension;
    }
  }
}
