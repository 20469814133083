import { Image, List, theme } from 'antd';
import React, { forwardRef, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useNavigate } from 'react-router-dom';
import { createStyles } from 'antd-style';
import { PublicationListItemTitleRender } from './PublicationListItemTitleRender';
import { CONSTANTS } from '../../../../config';
import { ManagerPublicationListItemFragment } from '../../../../services/apolloGraphqlAppApiClient/models/types';
import { PublicationListItemDescriptionRender } from './PublicationListItemDescriptionRender';
import { PreventOnClickParentsEvent } from '../../../../components';

export type TPublicationListItemProps = {
  listItem: ManagerPublicationListItemFragment;
  innerRef?: React.ForwardedRef<HTMLDivElement>;
  style?: React.CSSProperties;
  withActions?: boolean;
};

const usePublicationListItemStyles = createStyles((props) => {
  return {
    listItemMeta: {
      '.ant-list-item-meta-content': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
      },
    },
  };
});

export const PublicationListItemComponent = (props: TPublicationListItemProps) => {
  const { withActions, listItem, innerRef, style } = props;
  const [isHover, setIsHover] = useState<boolean>(false);
  const { token } = theme.useToken();
  const { styles } = usePublicationListItemStyles();
  const navigate = useNavigate();

  const {
    isSorting,
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
    isOver,
  } = useSortable({
    id: listItem.folderId !== null ? listItem.order : listItem.publicationId,
    data: { publication: listItem },
  });

  const listItemStyle: React.CSSProperties = {
    ...style,
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.3 : 1,
    padding: 5,
    // ...(isOver && !isDragging && overIndex - activeIndex !== 1
    //   ? { borderBlockStart: `2px solid ${token.colorPrimary}` }
    //   : {}),
    position: 'absolute',
    cursor: 'pointer',
    ...(isHover ? { backgroundColor: token.colorBgTextHover } : {}),
    userSelect: 'none',
  };

  return (
    <List.Item
      style={listItemStyle}
      ref={setNodeRef}
      {...attributes}
      onClick={() => {
        navigate(`/publications/${listItem.publicationId}`);
      }}
      onMouseEnter={() => setIsHover(!isSorting)}
      onMouseLeave={() => setIsHover(false)}
    >
      <List.Item.Meta
        className={styles.listItemMeta}
        avatar={
          <PreventOnClickParentsEvent>
            <Image
              height={50}
              width={34}
              // src={GET_PUBLICATION_COVER_SRC(listItem.publicationId)}
              src={listItem?.webCover?.uri}
              alt={listItem.publicationId.toString()}
              fallback={CONSTANTS.NOT_FOUND_IMG_EGW}
            />
          </PreventOnClickParentsEvent>
        }
        title={
          <PublicationListItemTitleRender
            propRef={setActivatorNodeRef}
            {...listeners}
            record={listItem}
            style={{ touchAction: 'none', cursor: 'grab' }}
            withActions={withActions}
          />
        }
        description={<PublicationListItemDescriptionRender listItem={listItem} />}
      />
    </List.Item>
  );
};

export const PublicationListItem = forwardRef<HTMLDivElement, TPublicationListItemProps>((props, ref) => (
  <PublicationListItemComponent {...props} innerRef={ref} />
));
