import { App, Button, Form, Input, ModalFuncProps } from 'antd';
import React, { useRef } from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { EditOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';

import {
  PublicationChaptersReaderParagraphListItemFragment,
  SetParagraphContentInput,
  useSetPublicationChaptersParagraphContentMutation,
} from '../../../services/apolloGraphqlAppApiClient/models/types';
import { PublicationParagraphEditor } from './PublicationParagraphEditor';
import { IModalContentFormTemplateProps, IModalFormTemplateProps, ModalContentFormTemplate } from '../../../components';
import { usePublicationChaptersReaderStore } from '../PublicationChaptersReaderStoreProvider';

export interface IUseSetPublicationChaptersParagraphContentActionProps {
  paragraphData: PublicationChaptersReaderParagraphListItemFragment;
}

export interface IUseSetPublicationChaptersParagraphContentActionResult {
  setPublicationChaptersParagraphContentAction: ItemType;
}

export const useSetPublicationChaptersParagraphContentAction = (
  props: IUseSetPublicationChaptersParagraphContentActionProps,
): IUseSetPublicationChaptersParagraphContentActionResult => {
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();
  const { paragraphData } = props;
  const { fetchParagraphsLazy, publicationChaptersReaderStore, historyParams } = usePublicationChaptersReaderStore();

  const menuItemKey = `SetPublicationChaptersParagraphContentAction-${paragraphData.paraId}`;
  const infoKey = uuidV4();
  const modalRef = useRef<ReturnType<typeof confirm>>();

  const visible = true;

  const [SetPublicationChaptersParagraphContentAction, { loading }] = useSetPublicationChaptersParagraphContentMutation(
    {
      onCompleted: (result) => {
        info({
          placement: 'topRight',
          message: `SetPublicationChaptersParagraphContent status: true`,
          description: (
            // eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={{ __html: result.setParagraphContent.paragraphDto?.content || '' }} />
          ),
          key: infoKey,
          duration: 7,
        });
        publicationChaptersReaderStore
          .refetch({ publicationId: historyParams?.publicationId })
          .then((res) => {
            const resultParagraphOrder = paragraphData.order;
            const nextOrder = resultParagraphOrder > 1 ? resultParagraphOrder - 1 : resultParagraphOrder;
            fetchParagraphsLazy({
              order: nextOrder,
            }).catch((e) => {
              console.log({ e });
            });
          })
          .catch((e) => {
            console.log('[fetchReaderParagraphError]', { e });
          });
      },
      onError: (e) => {
        return e;
      },
    },
  );

  const handleOk: IModalContentFormTemplateProps<SetParagraphContentInput>['onFinish'] = async (values, form) => {
    const r = await SetPublicationChaptersParagraphContentAction({
      variables: {
        input: { paraId: values.paraId, content: values.content },
      },
    });
    if (r.errors) {
      return Promise.reject(r.errors);
    }
    if (modalRef.current) {
      modalRef.current.destroy();
    }
    return r;
  };

  const handleCancel: IModalContentFormTemplateProps<SetParagraphContentInput>['onCancel'] = async (form) => {
    if (modalRef.current) {
      modalRef.current.destroy();
    }
    form.resetFields();
  };

  return {
    setPublicationChaptersParagraphContentAction: visible
      ? {
          key: menuItemKey,
          icon: <EditOutlined />,
          label: 'Edit content',
          onClick: (menuInfo) => {
            menuInfo.domEvent.preventDefault();
            menuInfo.domEvent.stopPropagation();
            modalRef.current = confirm({
              title: `Edit content for paragraph: ${paragraphData.paraId}!`,
              width: '75%',
              footer: null,
              content: (
                <ModalContentFormTemplate<SetParagraphContentInput>
                  initialValues={{
                    paraId: paragraphData.paraId,
                    content: paragraphData.content,
                  }}
                  layout="vertical"
                  name={`setPublicationChaptersParagraphContentForm-${paragraphData.paraId}`}
                  onFinish={handleOk}
                  onCancel={handleCancel}
                  buttonProps={{ submit: { label: 'Set content' } }}
                >
                  <Form.Item name="paraId" label="Id" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="content"
                    valuePropName="value"
                    label="WEML Content Editor"
                    rules={[
                      {
                        required: false,
                        message: `Please input paragraph content!`,
                      },
                    ]}
                  >
                    <PublicationParagraphEditor
                      initialContent={paragraphData.content}
                      headingLevel={paragraphData.headingLevel}
                    />
                  </Form.Item>
                </ModalContentFormTemplate>
              ),
            });
          },
        }
      : null,
  };
};
