import React, { FC, useCallback } from 'react';
import { useAttrs } from '@remirror/react';
import { useActive, useCommands } from '@remirror/react-core';
import { CommandPopoverButton, ICommandPopoverButtonProps } from '../../../components';
import { WTextBlockAttributes, WTextBlockExtension } from '../../index';
import { ToggleWTextBlockForm } from './index';

export type IToggleWTextBlockButtonProps = Omit<
  ICommandPopoverButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'popoverContent' | 'onSelect'
>;

export const ToggleWTextBlockButton: FC<IToggleWTextBlockButtonProps> = (props) => {
  const { toggleWTextBlock } = useCommands<WTextBlockExtension>();
  const attrs = useAttrs<WTextBlockExtension>(true).wTextBlock() as WTextBlockAttributes;

  const active = useActive<WTextBlockExtension>().wTextBlock();
  const enabled = toggleWTextBlock.enabled();

  const handleSubmit = useCallback(
    (values) => {
      console.log({ values });
      if (toggleWTextBlock.enabled()) {
        if (active) {
          return toggleWTextBlock(values);
        }
        toggleWTextBlock(values);
      }
    },
    [toggleWTextBlock, active],
  );

  // const handleRemove = useCallback(() => {
  //   if (toggleWTextBlock.enabled()) {
  //     toggleWTextBlock();
  //   }
  // }, [toggleWTextBlock]);

  return (
    <CommandPopoverButton
      {...props}
      commandName="toggleWTextBlock"
      active={active}
      enabled={enabled}
      popoverContent={
        <ToggleWTextBlockForm
          // onRemove={active ? handleRemove : undefined}
          initialValues={
            attrs
              ? {
                  ...(attrs?.type ? { type: attrs?.type } : {}),
                }
              : {}
          }
          onSubmit={handleSubmit}
        />
      }
    />
  );
};
