import React, { JSX } from 'react';
import { DollarOutlined, EyeInvisibleOutlined, EyeOutlined, LoginOutlined } from '@ant-design/icons';
import {
  PublicationDto,
  PublicationPermissionEnum,
  PublicationType,
} from '../services/apolloGraphqlAppApiClient/models/types';
import { ReactComponent as Bible } from '../assets/images/egw_font/bible.svg';
import { ReactComponent as BookOpen } from '../assets/images/egw_font/book-open.svg';
import { ReactComponent as Commentaries } from '../assets/images/egw_font/commentaries.svg';
import { ReactComponent as DevotionalReadings } from '../assets/images/egw_font/devotional-readings.svg';
import { ReactComponent as Dictionary } from '../assets/images/egw_font/dictionary.svg';
import { ReactComponent as Manuscript } from '../assets/images/egw_font/manuscript.svg';
import { ReactComponent as Periodicals } from '../assets/images/egw_font/periodicals.svg';
import { ReactComponent as SdaScriptureIndex } from '../assets/images/egw_font/sda-scripture-Index.svg';
import { ReactComponent as TopicalIndex } from '../assets/images/egw_font/topical-index.svg';
import { ReactComponent } from '*.svg';

export type IPublicationTypeIcons = {
  [key in PublicationType]: { i: JSX.Element; img: typeof ReactComponent };
};

export type IPublicationPermissionIcons = {
  [key in PublicationPermissionEnum]: JSX.Element;
};

export const PUBLICATION_TYPE_ICONS: IPublicationTypeIcons = {
  [PublicationType.Bible]: { i: <i className="icon-bible" />, img: Bible },
  [PublicationType.BibleCommentary]: {
    i: <i className="icon-commentaries" />,
    img: Commentaries,
  },
  [PublicationType.Book]: { i: <i className="icon-book-open" />, img: BookOpen },
  [PublicationType.Devotional]: {
    i: <i className="icon-devotional-readings" />,
    img: DevotionalReadings,
  },
  [PublicationType.Dictionary]: {
    i: <i className="icon-dictionary" />,
    img: Dictionary,
  },
  [PublicationType.Manuscript]: {
    i: <i className="icon-manuscript" />,
    img: Manuscript,
  },
  [PublicationType.PeriodicalNoPageBreak]: {
    i: <i className="icon-periodicals" />,
    img: Periodicals,
  },
  [PublicationType.PeriodicalPageBreak]: {
    i: <i className="icon-periodicals" />,
    img: Periodicals,
  },
  [PublicationType.ScriptureIndex]: {
    i: <i className="icon-sda-scripture-Index" />,
    img: SdaScriptureIndex,
  },
  [PublicationType.TopicalIndex]: {
    i: <i className="icon-topical-index" />,
    img: TopicalIndex,
  },
};

export const PUBLICATION_PERMISSION_ICONS: IPublicationPermissionIcons = {
  [PublicationPermissionEnum.Public]: <EyeOutlined />,
  [PublicationPermissionEnum.AuthenticatedOnly]: <LoginOutlined />,
  [PublicationPermissionEnum.Hidden]: <EyeInvisibleOutlined />,
  [PublicationPermissionEnum.PurchasedOnly]: <DollarOutlined />,
};

export const GET_PUBLICATION_COVER_SRC = (publicationId: PublicationDto['publicationId'], size = 'k'): string => {
  return `https://media2.egwwritings.org/covers/${publicationId}_${size}.jpg`;
};
