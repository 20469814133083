import { PopperPlacementType } from '@mui/material';
import React, { ReactElement, ReactNode, useState } from 'react';

import { PopoverProvider } from './PopoverProvider';
import { RichTooltip } from './RichTooltip';

interface ClickableRichTooltipProps {
  content: ReactElement | (() => ReactElement);
  children: ReactElement;
  disabled?: boolean;
  arrow?: boolean;
  placement?: PopperPlacementType;
  title?: ReactNode;
  isOpen?: boolean;
}

export const ClickableRichTooltip = ({
  placement,
  arrow,
  content,
  children,
  disabled = false,
  isOpen,
  title,
}: ClickableRichTooltipProps) => {
  const [open, setOpen] = useState(false);

  if (disabled) {
    return React.cloneElement(children, { ...children.props, disabled: true });
  }

  const existingOnClick = children.props.onClick;
  const newOnClick = (e: MouseEvent) => {
    setOpen(!open);
    existingOnClick && existingOnClick(e);
  };

  const contentNode =
    typeof content === 'function'
      ? content()
      : React.cloneElement(content, {
          ...content.props,
          open,
          setOpen,
        });

  return (
    <PopoverProvider context={{ open, setOpen }}>
      <RichTooltip
        title={title}
        open={isOpen !== undefined ? isOpen && open : open}
        onClose={() => setOpen(false)}
        arrow={arrow}
        placement={placement}
        content={contentNode}
      >
        {React.cloneElement(children, { ...children.props, onClick: newOnClick })}
      </RichTooltip>
    </PopoverProvider>
  );
};
