import React from 'react';
import { Dropdown, DropDownProps } from 'antd';
import { ManagerPublicationListItemFragment } from '../../../../services/apolloGraphqlAppApiClient/models/types';
import { useContextPublicationActions } from './PublicationActions';
import { PreventOnClickParentsEvent } from '../../../../components';

export type TPublicationListItemContextActionsRenderProps = {
  publicationData: ManagerPublicationListItemFragment;
} & DropDownProps;
export const PublicationListItemContextActionsRender = (props: TPublicationListItemContextActionsRenderProps) => {
  const { publicationData, forceRender = true, destroyPopupOnHide = true, children, ...restProps } = props;
  const publicationContextActions = useContextPublicationActions({ publicationData });
  return (
    <Dropdown
      menu={{
        items: publicationContextActions,
      }}
      trigger={['contextMenu']}
      forceRender={false}
      destroyPopupOnHide
      {...restProps}
    >
      {children}
    </Dropdown>
  );
};
