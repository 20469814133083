import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { BasicDataNode } from 'antd/es/tree';
import { ApolloQueryResult } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';
import { IUsePageSearchPropsResult, useDidMountEffect, usePageSearchProps } from '../../hooks';
import {
  GetPublicationChaptersReaderDataQueryHookResult,
  GetPublicationChaptersReaderParagraphListLazyQueryHookResult,
  GetPublicationChaptersReaderParagraphListQuery,
  GetPublicationChaptersReaderParagraphListQueryVariables,
  PublicationChaptersReaderChapterListItemFragment,
  PublicationChaptersReaderDataFragment,
  PublicationChaptersReaderParagraphListItemFragment,
  useGetPublicationChaptersReaderDataQuery,
  useGetPublicationChaptersReaderParagraphListLazyQuery,
} from '../../services/apolloGraphqlAppApiClient/models/types';

export type ChaptersTreeNodeType = BasicDataNode &
  PublicationChaptersReaderChapterListItemFragment & {
    children?: ChaptersTreeNodeType[];
    paraCount: number | 'lastChapter';
    parents?: PublicationChaptersReaderChapterListItemFragment['order'][];
  };

export type TPublicationsChaptersReaderStorePagination = {
  skip?: GetPublicationChaptersReaderParagraphListQueryVariables['skip'];
  take?: GetPublicationChaptersReaderParagraphListQueryVariables['take'];
};

export interface TPublicationsChaptersReaderStoreFilters {
  activeChapterOrder?: number;
  firstViewParagraphOrder?: number;
}

export interface IUsePublicationChaptersReaderStoreResult
  extends Omit<
    IUsePageSearchPropsResult<TPublicationsChaptersReaderStoreFilters>,
    'setHistorySearchProps' | 'antdPagination' | 'setAntdPagination'
  > {
  // chapterParagraphListStore: GetPublicationChaptersReaderParagraphListQueryHookResult;
  getParagraphListLazy: GetPublicationChaptersReaderParagraphListLazyQueryHookResult[0];
  chapterParagraphListStore: GetPublicationChaptersReaderParagraphListLazyQueryHookResult[1];
  chaptersTree: ChaptersTreeNodeType[];
  setChaptersTree: React.Dispatch<React.SetStateAction<ChaptersTreeNodeType[]>>;
  publicationChaptersReaderStore: GetPublicationChaptersReaderDataQueryHookResult;
  activeChapterOrder: ChaptersTreeNodeType['order'];
  setActiveChapterOrder: React.Dispatch<React.SetStateAction<ChaptersTreeNodeType['order']>>;
  scrollToItem?: number;
  setScrollToItem: React.Dispatch<React.SetStateAction<number | undefined>>;
  fetchParagraphsLazy: ({
    order,
    skip,
    take,
    isRefetch,
  }: {
    order?: number;
    skip?: number;
    take?: number;
    isRefetch?: boolean;
  }) => Promise<
    | QueryResult<
        GetPublicationChaptersReaderParagraphListQuery,
        GetPublicationChaptersReaderParagraphListQueryVariables
      >
    | ApolloQueryResult<GetPublicationChaptersReaderParagraphListQuery>
  >;
}

export const PublicationChaptersReaderStoreContext = createContext<
  IUsePublicationChaptersReaderStoreResult | undefined
>(undefined);
PublicationChaptersReaderStoreContext.displayName = 'PublicationChaptersReaderStoreContext';

export const usePublicationChaptersReaderStore = () => {
  const context = useContext(PublicationChaptersReaderStoreContext);
  if (context === undefined) {
    throw new Error('usePublicationChaptersReaderStore must be used within PublicationChaptersReaderStoreProvider');
  }
  return context;
};

export const parseChaptersTree = (
  initialData: ChaptersTreeNodeType[],
  initialLevel: number,
): ChaptersTreeNodeType[] => {
  const toc = (data: ChaptersTreeNodeType[], level: number): ChaptersTreeNodeType[] => {
    if (!data || !data?.length) {
      return [];
    }
    const result: ChaptersTreeNodeType[] = [];
    const accumulator: ChaptersTreeNodeType[] = [];
    let lastChunk: ChaptersTreeNodeType | null = null;
    for (let i = 0; i < data.length; i++) {
      const chap = data[i];
      if (chap) {
        if (chap.level === level) {
          if (lastChunk != null) {
            const resultItem = lastChunk;
            resultItem.children = toc(
              accumulator.map((i) => {
                i.parents = [...(i?.parents ? i.parents : []), ...(resultItem?.order ? [resultItem.order] : [])];
                return i;
              }),
              level + 1,
            );
            result.push(resultItem);
            accumulator.length = 0;
          }
          lastChunk = chap;
        } else {
          accumulator.push(chap);
        }
      }
    }
    const resultItem = lastChunk;
    if (resultItem) {
      resultItem.children = toc(
        accumulator.map((i) => {
          i.parents = [...(i?.parents ? i.parents : []), ...(resultItem?.order ? [resultItem.order] : [])];
          return i;
        }),
        level + 1,
      );
      result.push(resultItem);
    }
    return result;
  };
  return toc(initialData, initialLevel);
};

export const getParaPosition = (
  chapters: PublicationChaptersReaderDataFragment['chapters'],
  paragraph: PublicationChaptersReaderParagraphListItemFragment,
): PublicationChaptersReaderChapterListItemFragment | undefined => {
  let lastChapter: PublicationChaptersReaderChapterListItemFragment | undefined;
  chapters?.every((item) => {
    if (item.order === paragraph.order) {
      lastChapter = item;
      return false;
    }
    if (item.order < paragraph.order) {
      lastChapter = item;
      return true;
    }
    return false;
  });
  return lastChapter;
};

export const PublicationChaptersReaderStoreProvider = ({ children }) => {
  const { historyParams, handlePageSearchPropsChange, historySearchProps, setParams } =
    usePageSearchProps<TPublicationsChaptersReaderStoreFilters>(false);
  const [activeChapterOrder, setActiveChapterOrder] = useState<ChaptersTreeNodeType['order']>(
    historySearchProps?.filter?.activeChapterOrder || 1,
  );
  const [scrollToItem, setScrollToItem] = useState<number | undefined>();
  const [chaptersTree, setChaptersTree] = useState<ChaptersTreeNodeType[]>([]);

  useDidMountEffect(() => {
    handlePageSearchPropsChange({ filter: { activeChapterOrder } });
  }, [activeChapterOrder]);

  const publicationChaptersReaderStore: GetPublicationChaptersReaderDataQueryHookResult =
    useGetPublicationChaptersReaderDataQuery({
      variables: { publicationId: historyParams?.publicationId },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (newData) => {
        if (newData.publication?.chapters && newData.publication?.chapters?.length > 0) {
          const paraCountData: ChaptersTreeNodeType[] = newData.publication?.chapters.map((item, i, arr) => {
            return {
              ...item,
              paraCount: arr.length === i + 1 ? 'lastChapter' : arr[i + 1].order - item.order,
            };
          });
          setChaptersTree([paraCountData[0], ...parseChaptersTree(paraCountData, 2)]);
        }
      },
    });

  const [
    getParagraphListLazy,
    chapterParagraphListStore,
  ]: GetPublicationChaptersReaderParagraphListLazyQueryHookResult =
    useGetPublicationChaptersReaderParagraphListLazyQuery({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const fetchParagraphsLazy = async ({
    order,
    skip,
    take,
    isRefetch,
  }: {
    order?: number;
    skip?: number;
    take?: number;
    isRefetch?: boolean;
  }) => {
    if (isRefetch || !chapterParagraphListStore?.fetchMore) {
      return chapterParagraphListStore.refetch({
        publicationId: historyParams?.publicationId,
        order: { gte: order || activeChapterOrder || 1 },
        take: take || 20,
        skip: skip || 0,
      });
    }
    return chapterParagraphListStore.fetchMore({
      variables: {
        publicationId: historyParams?.publicationId,
        order: { gte: order || activeChapterOrder || 0 },
        take: take || 20,
        skip: skip || 0,
      },
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousQueryResult;
        const newItems: PublicationChaptersReaderParagraphListItemFragment[] = [
          ...(fetchMoreResult?.paragraphs?.items ? fetchMoreResult.paragraphs.items : []),
          ...(previousQueryResult?.paragraphs?.items ? previousQueryResult.paragraphs.items : []),
        ];
        return {
          ...previousQueryResult,
          paragraphs: {
            ...fetchMoreResult.paragraphs,
            items: newItems
              .filter((el, i) => newItems.findIndex((obj, j) => obj?.paragraphId === el?.paragraphId) === i)
              .sort((a, b) => {
                return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
              }),
          },
        } as GetPublicationChaptersReaderParagraphListQuery;
      },
    });
  };

  useEffect(() => {
    if (publicationChaptersReaderStore.data?.publication?.chapters && !chapterParagraphListStore.data?.paragraphs) {
      const lastChapterOrder = publicationChaptersReaderStore.data?.publication?.chapters?.slice(-1)[0].order;
      const nextOrder =
        typeof lastChapterOrder === 'number' && activeChapterOrder - lastChapterOrder > 20
          ? activeChapterOrder > 1
            ? activeChapterOrder - 1
            : activeChapterOrder
          : activeChapterOrder - 18;
      const nextTake = 20;
      getParagraphListLazy({
        variables: {
          publicationId: historyParams?.publicationId,
          order: { gte: nextOrder },
          take: nextTake,
          skip: 0,
        },
      })
        .then(() => {
          setScrollToItem(activeChapterOrder);
        })
        .catch((e) => {
          console.log({ e });
        });
    }
  }, [publicationChaptersReaderStore]);

  const publicationChaptersReaderStoreContextObject: IUsePublicationChaptersReaderStoreResult = useMemo(
    () => ({
      publicationChaptersReaderStore,
      activeChapterOrder,
      setActiveChapterOrder,
      historyParams,
      historySearchProps,
      handlePageSearchPropsChange,
      chapterParagraphListStore,
      getParagraphListLazy,
      chaptersTree,
      setChaptersTree,
      fetchParagraphsLazy,
      scrollToItem,
      setScrollToItem,
    }),
    [
      publicationChaptersReaderStore,
      activeChapterOrder,
      chapterParagraphListStore,
      chaptersTree,
      historyParams,
      historySearchProps,
      scrollToItem,
    ],
  );

  return (
    <PublicationChaptersReaderStoreContext.Provider value={publicationChaptersReaderStoreContextObject}>
      {children}
    </PublicationChaptersReaderStoreContext.Provider>
  );
};
