import { App, ModalFuncProps } from 'antd';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { FlexListTemplate } from '../../../../../components';
import {
  usePublishPublicationMutation,
  PublishPublicationInput,
} from '../../../../../services/apolloGraphqlAppApiClient/models/types';
import { ManagerFolderTreeNodeType } from '../../Folders';
import { TManagerPublicationListItem } from '../usePublicationListStore';
import { usePublicationListStoreContext } from '../PublicationListStoreProvider';

export interface IUsePublishPublicationActionResult {
  publishPublicationAction: (...args: [PublishPublicationInput, IPublishPublicationActionOptions]) => void;
}

export type IPublishPublicationActionOptions = {
  dragTitle: TManagerPublicationListItem['title'];
  dropTitle: ManagerFolderTreeNodeType['title'];
};

export const usePublishPublicationAction = (): IUsePublishPublicationActionResult => {
  const { refetch } = usePublicationListStoreContext();
  const {
    modal: { confirm },
    notification: { info },
  } = App.useApp();

  const [publishPublication] = usePublishPublicationMutation({
    onCompleted: (result) => {
      info({
        placement: 'topRight',
        message: `Publish publication status: true`,
        description: (
          <FlexListTemplate gap={5} alignItems="left" justifyContent="left" style={{ flexDirection: 'column' }}>
            ¦{/* info */}
          </FlexListTemplate>
        ),
        key: uuidV4(),
        duration: 7,
      });
      refetch(
        result.publishPublication.publicationDto?.order
          ? result.publishPublication.publicationDto.order - 1
          : undefined,
      );
    },
    onError: (e) => {
      return e;
    },
  });

  const handleOk: ModalFuncProps['onOk'] = async (values: PublishPublicationInput) => {
    await publishPublication({
      variables: {
        input: { ...values },
      },
    });
  };

  const publishPublicationAction: IUsePublishPublicationActionResult['publishPublicationAction'] = (
    values,
    options,
  ) => {
    const { dragTitle, dropTitle } = options;
    confirm({
      title: `Publish ${dragTitle} Publication!`,
      onOk: () => handleOk(values),
      okText: 'Publish',
      content: `Are you sure you want to publish "${dragTitle}" in "${dropTitle}"`,
    });
  };

  return {
    publishPublicationAction,
  };
};
