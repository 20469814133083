import React, { FC } from 'react';

import { ToggleWAnchorForm } from './ToggleWAnchorForm';
import { CommandPopoverButton, ICommandPopoverButtonProps } from '../../../components';
import { useWAnchorState } from '../hooks';
import { WAnchorIcon } from './WAnchorIcon';

export type IToggleWAnchorButtonProps = Omit<
  ICommandPopoverButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect' | 'popoverContent'
>;
export const ToggleWAnchorButton: FC<IToggleWAnchorButtonProps> = (props) => {
  const { attrs, active, enabled, handleRemove, handleSubmit, isEditing } = useWAnchorState();

  return (
    <CommandPopoverButton
      {...props}
      commandName="toggleWAnchor"
      active={active}
      enabled={enabled}
      popoverContent={
        <ToggleWAnchorForm onRemove={active ? handleRemove : undefined} initialValues={attrs} onSubmit={handleSubmit} />
      }
      icon={<WAnchorIcon />}
    />
  );
};
