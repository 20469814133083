import React from 'react';
import { ReadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ManagerPublicationEpubListItemFragment } from '../../../services/apolloGraphqlAppApiClient/models/types';
import { ModalTemplate } from '../../../components';
import { EpubReader } from '../Components';

export interface IPreviewPublicationEpubActionButtonProps {
  record: ManagerPublicationEpubListItemFragment;
  setEpubPreviewState: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      url: ManagerPublicationEpubListItemFragment['uri'];
    }>
  >;
}

export const PreviewPublicationEpubActionButton: React.FC<IPreviewPublicationEpubActionButtonProps> = (props) => {
  const { record, setEpubPreviewState } = props;
  return (
    <Button
      icon={<ReadOutlined style={{ fontSize: 120 }} />}
      type="link"
      style={{ width: '100%', height: 'auto' }}
      onClick={() => {
        setEpubPreviewState({
          open: true,
          url: record.uri,
        });
      }}
    />
  );
};
