import { App, Form, Input, Upload, UploadProps } from 'antd';
import React from 'react';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';
import { createStyles } from 'antd-style';
import {
  SortEnumType,
  UploadCoverInput,
  useUploadPublicationCoverMutation,
} from '../../../../services/apolloGraphqlAppApiClient/models/types';
import { FlexListTemplate, IModalFormTemplateProps, ModalFormTemplate } from '../../../../components';
import { usePublicationCoversManagerStore } from '../../PublicationCoversManagerStoreProvider';

const getAspectRatio = (width: number, height: number): string => {
  return (width / height).toFixed(3);
};

const useUploadPublicationCoverActionStyles = createStyles(({ token }) => ({
  uploadDescription: {
    // width: 100,
    '.ant-descriptions-view': {
      table: {
        width: 'unset',
        maxWidth: 200,
      },
    },
    '.ant-descriptions-item-label': {
      padding: '0 2px !important',
      // fontSize: '0.75em',
      textOverflow: 'ellipsis',
      textWrap: 'nowrap',
    },
    '.ant-descriptions-header': {
      marginBottom: 2,
    },
    '.ant-descriptions-item-content': {
      padding: '0 2px !important',
      textOverflow: 'ellipsis',
      // fontSize: '0.9em',
      textWrap: 'nowrap',
      width: 'fit-content',
    },
    '.ant-descriptions-item': {
      padding: '0 !important',
      textOverflow: 'ellipsis',
      textWrap: 'nowrap',
    },
  },
}));

export const UploadPublicationCoverActionButton = () => {
  const {
    notification: { info },
  } = App.useApp();
  const { historyParams, publicationCoverTypes } = usePublicationCoversManagerStore();

  const { styles } = useUploadPublicationCoverActionStyles();
  const activeCoverType = publicationCoverTypes.data?.coverTypes?.items?.find(
    (item) => item.code === historyParams?.typeId,
  );

  const { getPublicationCoversLazy } = usePublicationCoversManagerStore();
  const infoKey = uuidV4();
  const [UploadPublicationCover] = useUploadPublicationCoverMutation({
    onCompleted: (result) => {
      getPublicationCoversLazy({
        variables: {
          where: {
            typeId: { eq: historyParams?.typeId },
            publicationId: { eq: historyParams?.publicationId },
          },
          order: { createdAt: SortEnumType.Desc },
        },
      });
      info({
        placement: 'topRight',
        message: `Upload ${result.uploadCover.coverDto?.format} cover status: true`,
        key: infoKey,
        duration: 7,
      });
    },
    onError: (e) => {
      return e;
    },
  });

  const handleFinish: IModalFormTemplateProps<UploadCoverInput>['onFinish'] = async (values, form) => {
    const r = await UploadPublicationCover({
      variables: {
        input: {
          id: uuidV4(),
          publicationId: historyParams?.publicationId as unknown as number,
          type: historyParams?.typeId,
          file: values.file.originFileObj,
        },
      },
    });
    if (r.errors) {
      return Promise.reject(r.errors);
    }
    // form.resetFields();
    return r;
  };

  const dummyRequest: UploadProps['customRequest'] = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess && onSuccess('ok');
    }, 0);
  };

  return (
    <ModalFormTemplate<UploadCoverInput>
      title="Upload new cover"
      okText="Upload"
      onFinish={handleFinish}
      triggerProps={{
        icon: <UploadOutlined />,
        // type: 'primary',
        children: 'Upload New',
        className: 'tabs-extra-button',
      }}
      formProps={{
        initialValues: { publicationId: historyParams?.publicationId, type: historyParams?.typeId },
        layout: 'vertical',
        name: `uploadPublicationCoverForm-${historyParams?.typeId}`,
      }}
    >
      <Form.Item
        name="file"
        getValueFromEvent={(e: any) => {
          return e?.file;
        }}
        valuePropName="file"
        rules={[
          {
            required: true,
          },
          {
            validator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!value) {
                  reject('File is required');
                }
                const isJpgOrPng = value?.type === 'image/jpeg' || value?.type === 'image/png';
                if (!isJpgOrPng) {
                  reject('Upload image is not valid file format');
                }
                const reader = new FileReader();
                reader.readAsDataURL(value?.originFileObj);
                reader.addEventListener('load', (event) => {
                  const _loadedImageUrl = event.target?.result;
                  const image = document.createElement('img');
                  image.src = _loadedImageUrl as string;

                  image.addEventListener('load', () => {
                    const { width, height } = image;
                    if (activeCoverType) {
                      const coverTypeImgParams: { width: number; height: number; aspectRatio: string } = {
                        width: activeCoverType.minWidth,
                        height: activeCoverType.minHeight,
                        aspectRatio: getAspectRatio(activeCoverType.minWidth, activeCoverType.minHeight),
                      };

                      const uploadImgParams: { width: number; height: number; aspectRatio: string } = {
                        width,
                        height,
                        aspectRatio: getAspectRatio(width, height),
                      };
                      const isValidImgSize =
                        uploadImgParams.width >= coverTypeImgParams.width &&
                        uploadImgParams.height >= coverTypeImgParams.height &&
                        uploadImgParams.aspectRatio === coverTypeImgParams.aspectRatio;
                      if (uploadImgParams.width < coverTypeImgParams.width) {
                        return reject('Upload image width is not walid on this cover type');
                      }
                      if (uploadImgParams.height < coverTypeImgParams.height) {
                        return reject('Upload image height is not walid on this cover type');
                      }
                      if (uploadImgParams.aspectRatio !== coverTypeImgParams.aspectRatio) {
                        return reject('Upload image aspect ratio is not walid on this cover type');
                      }
                      return resolve('Success');
                    }
                    return reject('Cover Type');
                  });
                });
              });
            },
          },
        ]}
      >
        <Upload.Dragger
          listType="picture"
          accept="image/png, image/jpeg"
          multiple={false}
          maxCount={1}
          customRequest={dummyRequest}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined style={{ fontSize: 32 }} />
          </p>
          <p className="ant-upload-text">Click or drag file to this area for upload</p>
          <p className="ant-upload-hint">Supported single jpeg/png file with specs:</p>
          <FlexListTemplate alignItems="start" gap="1em">
            <FlexListTemplate
              style={{ flexDirection: 'column', width: 'unset', gap: 5, justifyContent: 'end', alignItems: 'end' }}
            >
              <FlexListTemplate style={{ width: 'auto' }} flexWrap="nowrap" gap={5} justifyContent="end">
                <div style={{ opacity: 0.45 }}>Type:</div>
                <div>{activeCoverType?.code}</div>
              </FlexListTemplate>
              <FlexListTemplate style={{ width: 'auto' }} flexWrap="nowrap" gap={5} justifyContent="end">
                <div style={{ opacity: 0.45 }}>Aspect Ratio:</div>
                <div>{activeCoverType && getAspectRatio(activeCoverType.minWidth, activeCoverType.minHeight)}</div>
              </FlexListTemplate>
            </FlexListTemplate>
            <FlexListTemplate
              style={{ flexDirection: 'column', width: 'unset', gap: 5, justifyContent: 'end', alignItems: 'end' }}
            >
              <FlexListTemplate style={{ width: 'auto' }} flexWrap="nowrap" gap={5}>
                <div style={{ opacity: 0.45 }}>Min Width:</div>
                <div>{activeCoverType?.minWidth} px.</div>
              </FlexListTemplate>
              <FlexListTemplate style={{ width: 'auto' }} flexWrap="nowrap" gap={5}>
                <div style={{ opacity: 0.45 }}>Min Height:</div>
                <div>{activeCoverType?.minHeight} px.</div>
              </FlexListTemplate>
            </FlexListTemplate>
          </FlexListTemplate>
        </Upload.Dragger>
      </Form.Item>
      <FlexListTemplate justifyContent="space-between" alignItems="start">
        <Form.Item name="type" label="Type" hidden>
          <Input disabled />
        </Form.Item>
        <Form.Item name="publicationId" label="PublicationId" hidden>
          <Input disabled />
        </Form.Item>
      </FlexListTemplate>
    </ModalFormTemplate>
  );
};
