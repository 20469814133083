import { createContext, useContext, useEffect, useMemo } from 'react';
import { LazyQueryExecFunction, QueryResult } from '@apollo/client/react/types/types';
import { inspect } from 'util';
import { IUsePageSearchPropsResult, usePageSearchProps } from '../../hooks';
import {
  GetManagerPublicationCoverListLazyQueryHookResult,
  GetManagerPublicationCoverListQuery,
  GetManagerPublicationCoverListQueryVariables,
  GetManagerPublicationCoverTypesQueryHookResult,
  SortEnumType,
  useGetManagerPublicationCoverListLazyQuery,
  useGetManagerPublicationCoverTypesQuery,
} from '../../services/apolloGraphqlAppApiClient/models/types';

export interface IUsePublicationCoversManagerStoreResult
  extends Omit<IUsePageSearchPropsResult, 'setHistorySearchProps' | 'antdPagination' | 'setAntdPagination'> {
  publicationCoversManagerStore: QueryResult<
    GetManagerPublicationCoverListQuery,
    GetManagerPublicationCoverListQueryVariables
  >;
  getPublicationCoversLazy: LazyQueryExecFunction<
    GetManagerPublicationCoverListQuery,
    GetManagerPublicationCoverListQueryVariables
  >;
  publicationCoverTypes: GetManagerPublicationCoverTypesQueryHookResult;
}

export const PublicationCoversManagerStoreContext = createContext<IUsePublicationCoversManagerStoreResult | undefined>(
  undefined,
);
PublicationCoversManagerStoreContext.displayName = 'PublicationCoversManagerStoreContext';

export const usePublicationCoversManagerStore = () => {
  const context = useContext(PublicationCoversManagerStoreContext);
  if (context === undefined) {
    throw new Error('usePublicationCoversManagerStore must be used within PublicationCoversManagerStoreProvider');
  }
  return context;
};

export const PublicationCoversManagerStoreProvider = ({ children }) => {
  const { historyParams, handlePageSearchPropsChange, historySearchProps, setParams } = usePageSearchProps(false);

  const publicationCoverTypes: GetManagerPublicationCoverTypesQueryHookResult = useGetManagerPublicationCoverTypesQuery(
    {
      variables: {},
      onCompleted: (newData) => {
        const baseType =
          newData?.coverTypes?.items?.find((i) => i.code === historyParams?.typeId) || newData?.coverTypes?.items?.[0];
        if (setParams && baseType) {
          setParams(`/publications/${historyParams?.publicationId}/covers/${baseType.code}`, { replace: true });
        }
      },
    },
  );

  const [getPublicationCoversLazy, publicationCoversManagerStore]: GetManagerPublicationCoverListLazyQueryHookResult =
    useGetManagerPublicationCoverListLazyQuery({
      // variables: {
      //   where: {
      //     typeId: { eq: historyParams?.typeId },
      //     publicationId: { eq: historyParams?.publicationId },
      //   },
      //   order: { createdAt: SortEnumType.Desc },
      // },
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (historyParams?.typeId) {
      getPublicationCoversLazy({
        variables: {
          where: {
            typeId: { eq: historyParams?.typeId },
            publicationId: { eq: historyParams?.publicationId },
          },
          order: { createdAt: SortEnumType.Desc },
        },
      });
    }
  }, [historyParams?.typeId]);

  const publicationCoversManagerStoreContextObject: IUsePublicationCoversManagerStoreResult = useMemo(
    () => ({
      publicationCoversManagerStore,
      publicationCoverTypes,
      setParams,
      getPublicationCoversLazy,
      historyParams,
      historySearchProps,
      handlePageSearchPropsChange,
    }),
    [publicationCoversManagerStore, publicationCoverTypes, historyParams, historySearchProps],
  );

  return (
    <PublicationCoversManagerStoreContext.Provider value={publicationCoversManagerStoreContextObject}>
      {children}
    </PublicationCoversManagerStoreContext.Provider>
  );
};
