import React from 'react';
import { AppThemeProvider } from './themes';
import { RootRouter } from './routes';
import { AppServicesProvider } from './services';

const App = (): JSX.Element => {
  return (
    <AppThemeProvider>
      <AppServicesProvider>
        <RootRouter />
      </AppServicesProvider>
    </AppThemeProvider>
  );
};

export default App;
