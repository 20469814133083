import React from 'react';
import { List, Spin } from 'antd';
import DragVirtualList, { Props as DragVirtualListProps } from 'react-tiny-virtual-list';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useResizeDetector } from 'react-resize-detector';
import { usePublicationListStoreContext } from './PublicationListStoreProvider';
import { usePublicationsManagerStoreContext } from '../Manager';
import { PublicationListItem } from './PublicationListItem';
import { usePublicationsManagerDndStoreContext } from '../Manager/PublicationsManagerDndStore';

const Component = (props) => {
  const { selectedFolder } = usePublicationsManagerStoreContext();
  const { activeDragItem } = usePublicationsManagerDndStoreContext();

  const { scrollToIndex, publicationsStore, fetchMore, fetchRest } = usePublicationListStoreContext();
  const { width: listWrapperWidth, height: listWrapperHeight, ref: listWrapperRef } = useResizeDetector();

  const handleDragVirtualListScroll: DragVirtualListProps['onScroll'] = (offset, event) => {
    // console.log({ offset });
    // @ts-ignore
    // eslint-disable-next-line no-unsafe-optional-chaining
    if (event?.currentTarget?.scrollHeight - event?.currentTarget?.scrollTop === listWrapperHeight) {
      fetchMore();
    }
  };

  return (
    <div
      ref={listWrapperRef}
      style={{
        height: '100%',
      }}
    >
      <Spin tip="Loading..." spinning={publicationsStore.loading} style={{ height: listWrapperHeight }}>
        <SortableContext
          items={
            publicationsStore.data?.publications?.items?.map((i, index) => ({
              id: i.folderId !== null ? i.order : i.publicationId,
            })) || []
          }
          strategy={verticalListSortingStrategy}
          disabled={{
            draggable: false,
            droppable: selectedFolder?.id === 0,
          }}
        >
          <List
            style={{
              height: listWrapperHeight || 400,
            }}
          >
            <DragVirtualList
              style={{ overflow: 'hidden auto' }}
              onScroll={handleDragVirtualListScroll}
              width="100%"
              height={listWrapperHeight || 400}
              itemCount={publicationsStore.data?.publications?.items?.length || 0}
              itemSize={61}
              overscanCount={5}
              scrollToIndex={scrollToIndex}
              stickyIndices={
                activeDragItem?.publication && selectedFolder.title !== 'Unpublished'
                  ? [activeDragItem.publication.order - 1]
                  : undefined
              }
              renderItem={({ index, style }) => {
                const item = publicationsStore.data?.publications?.items?.[index];
                if (item) {
                  return <PublicationListItem key={`pubListItem-${index}`} listItem={item} style={style} />;
                }
                return undefined;
              }}
            />
          </List>
        </SortableContext>
      </Spin>
    </div>
  );
};
export const PublicationList = (props) => {
  return (
    // <PublicationListStoreProvider>
    <Component {...props} />
    // </PublicationListStoreProvider>
  );
};
