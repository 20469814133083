import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionTag,
  getTextSelection,
  keyBinding,
  KeyBindingProps,
  MarkExtension,
  MarkExtensionSpec,
  MarkSpecOverride,
  PrimitiveSelection,
  ProsemirrorAttributes,
  Static,
  toggleMark,
} from '@remirror/core';
import { CoreIcon } from '@remirror/icons';
import React from 'react';
import { InputRule, markInputRule } from 'remirror';

import { WFormatTypeProperty } from './_interface';

const TOGGLE_COMMAND_LABEL = 'Toggle AllCaps';
const TOGGLE_COMMAND_SHORTCUT = 'Mod-A';

const toggleWFormatAllCapsOptions: Remirror.CommandDecoratorOptions = {
  label: ({ t }) => t(TOGGLE_COMMAND_LABEL),
  shortcut: TOGGLE_COMMAND_SHORTCUT,
};

export type WFormatAllCapsExtensionAttributes = ProsemirrorAttributes<{
  type?: WFormatTypeProperty;
}>;
export interface WFormatAllCapsOptions {
  type?: Static<WFormatTypeProperty>;
  defaultType?: Static<WFormatTypeProperty>;
}

@extension<WFormatAllCapsOptions>({
  defaultOptions: {
    type: undefined,
    defaultType: 'all-caps',
  },
  staticKeys: ['defaultType', 'type'],
})
export class WFormatAllCapsExtension extends MarkExtension<WFormatAllCapsOptions> {
  get name() {
    return 'wFormatAllCaps' as const;
  }

  createTags() {
    return [ExtensionTag.FormattingMark, ExtensionTag.FontStyle];
  }

  createMarkSpec(extra: ApplySchemaAttributes, override: MarkSpecOverride): MarkExtensionSpec {
    return {
      ...override,
      attrs: {
        ...extra.defaults(),
        type: { default: this.options.defaultType },
      },
      parseDOM: [
        {
          tag: 'w-format[type=all-caps]',
          getAttrs: (element: string | Node) => ({
            ...extra.parse(element),
            type: 'all-caps',
          }),
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (mark) => {
        const { type } = this.options;
        return ['w-format', { type: mark.attrs.type.toString() }, 0];
      },
    };
  }

  createInputRules(): InputRule[] {
    return [
      markInputRule({
        regexp: /(?:\*\*|__)([^*_]+)(?:\*\*|__)$/,
        type: this.type,
        ignoreWhitespace: true,
      }),
    ];
  }

  @command(toggleWFormatAllCapsOptions)
  toggleWFormatAllCaps(attrs?: WFormatAllCapsExtensionAttributes, selection?: PrimitiveSelection): CommandFunction {
    return toggleMark({ type: this.type, selection, attrs: { ...attrs, type: 'all-caps' } });
  }

  /**
   * Set the bold formatting for the provided range.
   */
  @command()
  setWFormatAllCaps(selection?: PrimitiveSelection): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc);
      dispatch?.(tr.addMark(from, to, this.type.create()));

      return true;
    };
  }

  /**
   * Remove the bold formatting from the provided range.
   */
  @command()
  removeWFormatAllCaps(selection?: PrimitiveSelection): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc);

      if (!tr.doc.rangeHasMark(from, to, this.type)) {
        return false;
      }

      dispatch?.(tr.removeMark(from, to, this.type));

      return true;
    };
  }

  @keyBinding({ shortcut: 'Mod-A', command: 'toggleWFormatAllCaps' })
  shortcut(props: KeyBindingProps): boolean {
    return this.toggleWFormatAllCaps()(props);
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wFormatAllCapsItalic: WFormatAllCapsExtension;
    }
  }
}
