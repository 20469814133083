import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionTag,
  getTextSelection,
  Handler,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
  NodeWithPosition,
  PrimitiveSelection,
  ProsemirrorAttributes,
  Static,
} from '@remirror/core';
import { CreateEventHandlers } from '@remirror/extension-events';
import { CoreIcon } from '@remirror/icons';
import { TextSelection } from '@remirror/pm/state';
import { isElementDomNode } from 'remirror';

import './assets/styles/view_w-anchor.css';

export type WAnchorIdProperty = string;

const CREATE_COMMAND_LABEL = 'Toggle wAnchor';
const CREATE_COMMAND_DESCRIPTION = 'Insert wAnchor Attributes';
const CREATE_COMMAND_SHORTCUT = 'Mod-P';

const toggleWAnchorOptions: Remirror.CommandDecoratorOptions = {
  label: ({ t }) => t(CREATE_COMMAND_LABEL),
  description: ({ t }) => t(CREATE_COMMAND_DESCRIPTION),
  // shortcut: CREATE_COMMAND_SHORTCUT,
};

export interface WAnchorProperties {
  id: Static<WAnchorIdProperty>;
}

export interface WAnchorAttributes {
  id: Static<WAnchorIdProperty>;
}

export const W_ANCHOR_PROPERTIES: Static<WAnchorProperties> = {
  id: '',
};

const W_ANCHOR_ATTRIBUTES: Static<WAnchorAttributes> = {
  id: '',
};

export interface WAnchorViewClickHandlerProps {
  event: MouseEvent;
  nodeWithPosition?: NodeWithPosition;
}

export interface WAnchorOptions {
  properties?: Static<WAnchorProperties>;
  attributes?: Static<WAnchorAttributes>;
  onViewClick?: Handler<(props: WAnchorViewClickHandlerProps) => boolean | undefined | void>;
}

@extension<WAnchorOptions>({
  defaultOptions: {
    properties: W_ANCHOR_PROPERTIES,
    attributes: W_ANCHOR_ATTRIBUTES,
  },
  handlerKeys: ['onViewClick'],
  staticKeys: ['properties', 'attributes'],
})
export class WAnchorExtension extends NodeExtension<WAnchorOptions> {
  // ReactComponent: ComponentType<NodeViewComponentProps> = WAnchorView;

  get name() {
    return 'wAnchor' as const;
  }

  createTags() {
    return [ExtensionTag.InlineNode, ExtensionTag.Behavior];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      inline: true,
      draggable: true,
      selectable: true,
      content: 'inline*',
      atom: true,
      isolating: true,
      ...override,
      attrs: {
        ...extra.defaults(),
        id: { default: this.options.attributes.id },
      },
      parseDOM: [
        {
          tag: 'w-anchor[id]',
          getAttrs: (node) => {
            return isElementDomNode(node)
              ? {
                  ...extra.parse(node),
                  id: node.getAttribute('id') as string,
                }
              : false;
          },
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (mark) => {
        return ['w-anchor', { id: mark.attrs.id.toString() }];
      },
    };
  }

  // createKeymap(): KeyBindings {
  //   const command = chainCommands(convertCommand(exitCode), () => {
  //     this.store.commands.insertWAnchor();
  //     return true;
  //   });
  //
  //   return {
  //     'Mod-P': command,
  //     'Shift-P': command,
  //   };
  // }

  createEventHandlers(): CreateEventHandlers {
    return {
      click: (event, clickState) => {
        // Check if this is a direct click which must be the case for atom
        // nodes.
        // event.preventDefault();
        // event.stopPropagation();
        // this.selectWAnchor();
        if (!clickState.direct) {
          return;
        }

        const nodeWithPosition = clickState.getNode(this.type);

        if (!nodeWithPosition || nodeWithPosition.node.type !== this.type) {
          return this.options.onViewClick({ event });
        }

        return this.options.onViewClick({ event, nodeWithPosition });
      },
      // mousedown: (event) => {
      //   const { tr } = this.store.view.state;
      //   // @ts-ignore
      //   if (event?.target?.pmViewDesc) {
      //     // @ts-ignore
      //     // const { node, posAfter, posAtStart, posBefore, size } = event.target.pmViewDesc;
      //     // console.log({ tr, event, node, posAfter, posAtStart, posBefore, size });
      //     // if (node && posAfter && posAtStart && node.type === this.type) {
      //     // this.store.views.dispatch?.(
      //     //   // @ts-ignore
      //     //   tr.setSelection(TextSelection.create(tr.doc, posAtStart, posAfter))?.scrollIntoView(),
      //     // );
      //     // }
      //   }
      // },
    };
  }

  /**
   * Inserts a `<w-page number="X"></w-page>` tag into the editor.
   */
  @command(toggleWAnchorOptions)
  toggleWAnchor(attrs?: ProsemirrorAttributes, selection?: PrimitiveSelection): CommandFunction {
    return (props) => {
      const { tr, dispatch, view } = props;
      const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc);
      const node = this.type.create(attrs);

      // @ts-ignore
      dispatch?.(
        tr
          .replaceRangeWith(from, to, node)
          .setSelection(TextSelection.create(tr.doc, from + 1))
          .scrollIntoView(),
      );

      return true;
    };
  }

  @command()
  removeWAnchor(): CommandFunction {
    return (props) => {
      const { tr, dispatch, state } = props;

      const { from, to } = tr.selection;

      // @ts-ignore
      if (tr.selection?.node.type === this.type) {
        if (dispatch) {
          dispatch(tr.delete(from, to).scrollIntoView());
        }
        return true;
      }
      return false;
    };
  }

  // @command()
  // selectWAnchor(): CommandFunction {
  //   return !isNodeActive({
  //     state: tr,
  //     type: this.type,
  //   });
  //   // return this.store.commands.selectNode(this.type);
  // }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wAnchor: WAnchorExtension;
    }
  }
}
