import React, { useEffect, useState } from 'react';
import { ItemType } from 'antd/lib/breadcrumb/Breadcrumb';
import { Link, useLocation } from 'react-router-dom';

export const useBreadcrumbTree = (
  customRoutes?: ItemType[],
): {
  routes: ItemType[];
} => {
  const [routes, setRoutes] = useState<ItemType[]>(customRoutes || []);
  const { pathname } = useLocation();

  const currentRoute = () => {
    const rootRoute = {
      // path: '/',
      breadcrumbName: 'Home',
      title: <Link to="/">Home</Link>,
    };
    if (pathname !== '/') {
      return pathname.split('/').map((item, i, arr) => {
        if ([''].includes(item)) {
          return rootRoute;
        }

        const path = pathname.split(`/${arr[i + 1]}`)[0];
        const breadcrumbName = item[0].toUpperCase() + item.slice(1);
        const title = <Link to={path}>{breadcrumbName}</Link>;
        if (arr.length - 1 === i) {
          return {
            breadcrumbName,
            title: breadcrumbName,
          };
        }
        return {
          // path,
          breadcrumbName,
          title,
        };
      });
    }
    return [rootRoute];
  };

  useEffect(() => {
    setRoutes([...(customRoutes ?? currentRoute())]);
  }, [customRoutes, pathname]);

  return { routes };
};
