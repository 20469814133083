import {
  ApplySchemaAttributes,
  findChildren,
  findNodeAtSelection,
  getActiveNode,
  isNodeSelection,
  NodeExtensionSpec,
  NodeSpecOverride,
} from 'remirror';
import {
  command,
  CommandFunction,
  CreateExtensionPlugin,
  EditorView,
  extension,
  ExtensionPriority,
  ExtensionTag,
  isTextSelection,
  keyBinding,
  KeyBindingProps,
  NodeExtension,
  ProsemirrorNode,
  Transaction,
} from '@remirror/core';

import { type EditorState, NodeSelection, TextSelection } from '@remirror/pm/state';
import { Decoration, DecorationSet } from '@remirror/pm/view';
import { Fragment, Slice } from '@remirror/pm/model';
import { WFigcaptionExtension } from './WFigcaptionExtension';
import { WImageExtension } from './WImageExtension';
import './assets/styles/view_w-image.scss';

const TOGGLE_COMMAND_LABEL = 'Toggle wFigure';
const TOGGLE_COMMAND_DESCRIPTION = 'Create wImage Attributes';

const toggleWFigureOptions: Remirror.CommandDecoratorOptions = {
  icon: 'imageLine',
  label: ({ t }) => t(TOGGLE_COMMAND_LABEL),
  description: ({ t }) => t(TOGGLE_COMMAND_DESCRIPTION),
};

export interface WFigureExtensionOptions {
  captionPlaceholder?: string;
  emptyCaptionClass?: string;
}

@extension<WFigureExtensionOptions>({
  defaultOptions: {
    emptyCaptionClass: 'remirror-decoration',
    captionPlaceholder: 'Add Caption...',
  },
})
export class WFigureExtension extends NodeExtension<WFigureExtensionOptions> {
  get name() {
    return 'wFigure' as const;
  }

  createTags() {
    return [ExtensionTag.WemlBlock, ExtensionTag.FormattingNode];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      content: 'wImage wFigcaption{0,1}',
      group: 'block',
      selectable: true,
      defining: true,
      ...override,
      attrs: extra.defaults(),
      parseDOM: [{ tag: 'figure' }],
      toDOM: (node) => ['figure', 0],
    };
  }

  createExtensions() {
    return [
      new WImageExtension({
        nodeOverride: {
          inline: false,
          // atom: true,
          selectable: true,
          group: 'wFigure',
        },
      }),
      new WFigcaptionExtension({ priority: ExtensionPriority.Low }),
    ];
  }

  @command()
  toggleWFigure(pos?: number): CommandFunction {
    return (props) => {
      const { tr, dispatch, state } = props;

      // if (pos > = 0) {
      //     const
      // }
      //     console.log({pos, sel: new NodeSelection(state.tr.doc.resolve(pos - 2))});
      // // state.tr.setSelection(TextSelection.create(state.tr.doc, pos_ - 2, pos_));
      // dispatch(tr.setSelection(new NodeSelection(state.tr.doc.resolve(pos_ - 2))).scrollIntoView());

      const activeNode = getActiveNode({ state: tr, type: this.type });
      //
      // if (activeNode?.node.type.name === 'wFigure' && activeNode?.node.lastChild?.type.name === 'wImage') {
      //   console.log({ toggle: props, tr, activeNode });
      //   const { $from } = tr.selection;
      //   const wFigcaptionNode = state.schema.nodes.wFigcaption.create(
      //     null,
      //     state.schema.nodes.wTextBlock.create(null, null),
      //   );
      //   const { start, end } = activeNode;
      //
      //   if (dispatch) {
      //     tr.replaceWith(start + 1, start + 1, wFigcaptionNode);
      //     dispatch(
      //       tr
      //         // .insertText('t', end + wFigcaptionNode.nodeSize - 4)
      //         .setSelection(
      //           TextSelection.create(
      //             tr.doc,
      //             start + wFigcaptionNode.nodeSize + 1 - 2,
      //             start + wFigcaptionNode.nodeSize + 1 - 2,
      //           ),
      //         )
      //         .scrollIntoView(),
      //     );
      //   }
      //   return true;
      // }

      if (!isNodeSelection(tr.selection)) {
        console.log({ toggle: props, tr, activeNode });
        return false;
      }

      if (activeNode?.node.lastChild?.type.name === 'wFigcaption') {
        return this.removeCaption()(props);
      }

      return this.addCaption()(props);
    };
  }

  @command()
  addCaption(text = ''): CommandFunction {
    return ({ tr, dispatch, state }) => {
      if (!isNodeSelection(tr.selection)) {
        console.log({ stage: 1, tr, dispatch });
        return false;
      }

      const { node, $from } = tr.selection;

      if (!this.isCaptionCompatibleNode(node)) {
        return false;
      }

      const activeNode = getActiveNode({ state: tr, type: this.type });

      // If already a caption, append text to existing caption text
      if (activeNode?.node?.type.name === 'wFigcaption') {
        const { node, pos } = activeNode;
        tr.setSelection(TextSelection.create(tr.doc, pos + node.nodeSize - 3));
        dispatch?.(tr.insertText(text));
        return true;
      }

      if (activeNode?.node.type.name === 'wFigure' && activeNode?.node.lastChild?.type.name === 'wImage') {
        console.log({ node, activeNode });
        const { $from } = tr.selection;
        const wFigcaptionNode = state.schema.nodes.wFigcaption.create(
          null,
          state.schema.nodes.wTextBlock.create(null, null),
        );
        const { start, end, pos } = activeNode;
        if (dispatch) {
          tr.replaceWith(
            pos + 1 + activeNode.node.lastChild.nodeSize,
            pos + 1 + activeNode.node.lastChild.nodeSize,
            wFigcaptionNode,
          );
          dispatch(
            tr
              .setSelection(
                TextSelection.create(
                  tr.doc,
                  pos + 1 + wFigcaptionNode.nodeSize - 2,
                  pos + 1 + wFigcaptionNode.nodeSize - 2,
                ),
              )
              .scrollIntoView(),
          );
        }
        return true;
      }

      // If not captioned, wrap selected node and append caption text
      const captionNode = this.type.create(null, node);
      const slice = new Slice(Fragment.from(captionNode), 0, 1);
      const { pos } = $from;
      if (dispatch) {
        tr.replace(pos, pos + 1, slice);
        tr.setSelection(TextSelection.create(tr.doc, pos + captionNode.nodeSize + 1));
        dispatch(tr.insertText(text));
      }

      return true;
    };
  }

  @command()
  removeCaption(): CommandFunction {
    return ({ tr, dispatch }) => {
      const activeNode = getActiveNode({ state: tr, type: this.type });

      if (!activeNode) {
        return false;
      }

      const { node, pos, end } = activeNode;
      const captionCompatibleNode = findChildren({
        node,
        predicate: ({ node }) => this.isCaptionCompatibleNode(node),
      })[0];

      if (!captionCompatibleNode) {
        return false;
      }

      const slice = new Slice(Fragment.from(captionCompatibleNode.node), 0, 0);
      dispatch?.(tr.replace(pos, end, slice));
      return true;
    };
  }

  /**
   * Handle the backspace key when deleting caption text.
   */
  @keyBinding({ shortcut: 'Backspace' })
  handleBackspace(props: KeyBindingProps): boolean {
    const { tr, dispatch } = props;
    const activeNode = getActiveNode({ state: tr, type: this.type });
    // If not in a caption context return false and let other extension
    // (ie: BaseKeymapExtension) do the deleting operation.
    if (!activeNode) {
      return false;
    }
    // If deleting the captionCompatible node itself, remove everything
    // Else we are deleting the caption text
    if (isNodeSelection(tr.selection) && this.isCaptionCompatibleNode(tr.selection.node)) {
      const { pos, end } = activeNode;
      dispatch?.(tr.replaceRange(pos, end, Slice.empty));
      return true;
    }
    if (!(isTextSelection(tr.selection) && tr.selection.empty)) {
      return false;
    }

    // If deleting in middle of caption text, resume default behaviour
    if (tr.selection.$from.parentOffset !== 0) {
      return false;
    }

    const captionTextNode = findNodeAtSelection(tr.selection);

    // If deleting from start of caption text, remove image and lift caption
    if (captionTextNode?.node.content.size > 0) {
      const { pos, end } = activeNode;
      dispatch?.(tr.replaceRangeWith(pos, end, captionTextNode.node));
      return true;
    }

    // Lift captionable node
    return this.removeCaption()(props);
  }

  /**
   * Handle the Enter key within in the caption text
   */
  @keyBinding({ shortcut: 'Enter' })
  handleEnter({ tr, dispatch }: KeyBindingProps): boolean {
    const activeNode = getActiveNode({ state: tr, type: this.type });

    // If not in a caption context return false and let other extension
    // (ie: BaseKeymapExtension) do the deleting operation.
    if (!activeNode) {
      return false;
    }

    if (!(isTextSelection(tr.selection) && tr.selection.empty)) {
      return false;
    }

    const captionTextNode = findNodeAtSelection(tr.selection);

    if (!captionTextNode) {
      return false;
    }

    if (dispatch) {
      const { node, end } = captionTextNode;
      const { textOffset, pos, nodeAfter } = tr.selection.$from;
      tr.insert(activeNode.end, nodeAfter ? node.cut(textOffset) : node.type.create());
      tr.setSelection(TextSelection.create(tr.doc, pos, end));
      dispatch(tr.deleteSelection());
    }

    return true;
  }

  createPlugin(): CreateExtensionPlugin {
    return {
      state: {
        init: (_, state): WFigurePluginState => ({
          ...this.options,
          empty: true,
        }),
        apply: (tr, pluginState: WFigurePluginState, _, state): WFigurePluginState => {
          return applyState({ pluginState, tr, extension: this, state });
        },
      },
      props: {
        // handleTextInput: (view, _from, _to, text) => {
        //   const { state, dispatch } = view;
        //   return this.addCaption(text)({ state, dispatch, tr: state.tr });
        // },
        decorations: (state) => {
          return createDecorationSet({ state, extension: this });
        },
        handleDOMEvents: {
          mousedown: (view, e) => {
            const { state, dispatch } = view;
            // @ts-ignore
            if (
              e?.target &&
              // @ts-ignore
              e.target?.nodeName === 'FIGCAPTION' &&
              // @ts-ignore
              e.target?.contentEditable === 'false'
            ) {
              // @ts-ignore
              const pos_ = view.posAtDOM(e.target);
              console.log({ pos_, e, sel: new NodeSelection(state.tr.doc.resolve(pos_ - 2)) });
              // state.tr.setSelection(TextSelection.create(state.tr.doc, pos_ - 2, pos_));
              view.dispatch(state.tr.setSelection(new NodeSelection(state.tr.doc.resolve(pos_ - 2))));
              return this.toggleWFigure(pos_)({
                state: view.state,
                dispatch: view.dispatch,
                tr: view.state.tr,
              });
            }
            return false;
          },
        },
      },
    };
  }

  private isCaptionCompatibleNode(node: ProsemirrorNode): boolean {
    const captionCompatibleNodes = ['wImage', 'wFigure'];
    return captionCompatibleNodes.includes(node.type.name);
  }
}

export interface WFigurePluginState extends Required<WFigureExtensionOptions> {
  empty: boolean;
}

interface SharedProps {
  /**
   * A reference to the extension
   */
  extension: WFigureExtension;
  /**
   * The editor state
   */
  state: EditorState;
}

interface ApplyStateProps extends SharedProps {
  /**
   * The plugin state passed through to apply
   */
  pluginState: WFigurePluginState;

  /**
   * A state transaction
   */
  tr: Transaction;
}

interface GetDescriptionWidgetProps extends SharedProps {
  pos: number;
}

/**
 * Apply state for managing the created placeholder plugin.
 *
 * @param props
 */
function applyState(props: ApplyStateProps) {
  const { pluginState, extension, tr, state } = props;

  if (!tr.docChanged) {
    return pluginState;
  }

  return { ...extension.options, empty: true };
}

type DOMNode = InstanceType<typeof window.Node>;

type WidgetConstructor = (
  props: GetDescriptionWidgetProps,
) => (view: EditorView, getPos: () => number | undefined) => DOMNode;

const getDecorationWidget: WidgetConstructor = (props) => (view, getPos) => {
  const { extension, pos } = props;
  const { emptyCaptionClass, captionPlaceholder } = extension.options;
  const { state, dispatch } = view;
  const widget = document.createElement('figcaption');
  // widget.addEventListener('mousedown', (e) => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   // extension.addCaption()({ state, dispatch, tr: state.tr, view });
  //   const wrap_cap = new Slice(
  //     Fragment.from(state.schema.nodes.wFigcaption.create(null, state.schema.nodes.wTextBlock.create())),
  //     0,
  //     0,
  //   );
  //   // console.log({ wrap_cap, pos, act: getActiveNode({ state: state.tr, type: extension.type }) });
  //   state.tr.setMeta(extension.pluginKey, { remove: {} });
  //   state.tr.replace(pos, pos, wrap_cap);
  //   dispatch(state.tr);
  //   const newPos = state.doc.resolve(pos);
  //   dispatch(state.tr.setSelection(TextSelection.between(newPos, newPos)).scrollIntoView());
  // });
  widget.className = emptyCaptionClass;
  widget.setAttribute('data-placeholder', captionPlaceholder);
  return widget;
};

/**
 * Creates a decoration set from the passed through state.
 *
 * @param props - see [[`SharedProps`]]
 */
function createDecorationSet(props: SharedProps) {
  const { extension, state } = props;
  const { empty } = extension.pluginKey.getState(state) as WFigurePluginState;
  const { emptyCaptionClass, captionPlaceholder } = extension.options;

  if (!empty) {
    return;
  }

  const decorations: Decoration[] = [];

  state.doc.descendants((node, pos) => {
    // console.log({ node, pos });
    if (node.type.name === 'wFigure' && node.lastChild?.type.name !== 'wFigcaption') {
      // // console.log({ node });
      // const widget = document.createElement('figcaption');
      // widget.addEventListener('mousedown', (e) => {
      //   e.stopPropagation();
      //   e.preventDefault();
      //   console.log({ e, extension, node, state });
      //   // extension.addCaption();
      //   const wrap_cap = new Slice(Fragment.from(state.schema.nodes.wFigcaption.create()), 0, 0);
      //   state.tr.setMeta(extension.pluginKey, { remove: {} });
      //   state.tr.replace(pos, pos, wrap_cap);
      //   // window.view.dispatch(state.tr);
      //   // const newPos = state.doc.resolve(pos);
      //   // const newSel = new TextSelection.between(newPos, newPos);
      //   // window.view.dispatch(state.tr.setSelection(newSel).scrollIntoView());
      // });
      // widget.className = emptyCaptionClass;
      // widget.setAttribute('data-placeholder', captionPlaceholder);
      const widget = getDecorationWidget({ ...props, pos: pos + 2 });
      const decoration = Decoration.widget(pos + 2, widget);
      decorations.push(decoration);
    }
  });

  return DecorationSet.create(state.doc, decorations);
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wFigure: WFigureExtension;
    }
  }
}
