import React from 'react';
import { Space } from 'antd';
import { ColumnType } from 'antd/es/table';
import { ManagerPublicationCoverTypeListItemFragment } from '../../../../services/apolloGraphqlAppApiClient/models/types';
import { DeletePublicationCoverTypeActionButton } from './DeletePublicationCoverTypeActionButton';
import { UpdatePublicationCoverTypeActionButton } from './UpdatePublicationCoverTypeActionButton';

export const PublicationCoverTypesListItemActionsRender: ColumnType<ManagerPublicationCoverTypeListItemFragment>['render'] =
  (value, record, index) => {
    return (
      <Space>
        <UpdatePublicationCoverTypeActionButton {...record} />
        <DeletePublicationCoverTypeActionButton {...record} />
      </Space>
    );
  };
