import React, { FC } from 'react';

import { useWLangState } from '../../extensions';
import { ToggleWLangForm } from '../forms';
import { CommandPopoverButton, ICommandPopoverButtonProps } from './CommandPopoverButton';

export type IToggleWLangButtonProps = Omit<
  ICommandPopoverButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'popoverContent' | 'onSelect'
>;

export const ToggleWLangButton: FC<IToggleWLangButtonProps> = (props) => {
  const { attrs, active, enabled, wLangClickHandlerProps, isEditing, handleRemove, handleSubmit } = useWLangState();

  return (
    <CommandPopoverButton
      {...props}
      commandName="toggleWLang"
      active={active}
      enabled={enabled}
      popoverContent={
        <ToggleWLangForm onSubmit={handleSubmit} initialValues={attrs} onRemove={active ? handleRemove : undefined} />
      }
    />
  );
};
