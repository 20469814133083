import React from 'react';
import { Dropdown, DropDownProps } from 'antd';
import { ManagerFolderTreeListItemFragment } from '../../../../services/apolloGraphqlAppApiClient/models/types';
import { useFolderActions } from './FolderActions';

export type TFoldersTreeItemContextActionsRenderProps = {
  folderData: ManagerFolderTreeListItemFragment;
} & DropDownProps;
export const FoldersTreeItemContextActionsRender = (props: TFoldersTreeItemContextActionsRenderProps) => {
  const { folderData, forceRender = true, destroyPopupOnHide = true, ...restProps } = props;
  const foldersActions = useFolderActions({ folderData });
  return (
    <Dropdown
      menu={{
        items: foldersActions,
      }}
      trigger={['contextMenu']}
      forceRender={false}
      destroyPopupOnHide
      {...restProps}
    />
  );
};
