import { useAuth } from 'react-oidc-context';
import React, { createContext, useContext, useMemo } from 'react';
import { axiosApiAppClient as AxiosApiAppClient } from './axiosAppApiClient';
import CONSTANTS from '../config/CONSTANTS';

export const AxiosAppApiClientContext = createContext<AxiosApiAppClient | undefined>(undefined);
AxiosAppApiClientContext.displayName = 'AxiosAppApiClientContext';

export const useAxiosAppApiClient = () => {
  const context = useContext(AxiosAppApiClientContext);
  if (context === undefined) {
    throw new Error('useAxiosAppApiClient must be used within AxiosAppApiClientProvider');
  }
  return context;
};

export const AxiosAppApiClientProvider = ({ children }) => {
  const { user } = useAuth();

  const client = useMemo(
    () =>
      new AxiosApiAppClient({
        BASE: CONSTANTS.APP_API_URL,
        TOKEN: user?.access_token,
      }),
    [user],
  );

  return <AxiosAppApiClientContext.Provider value={client}>{children}</AxiosAppApiClientContext.Provider>;
};
