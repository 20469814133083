import React from 'react';
import { Tabs } from 'antd';
import { createStyles } from 'antd-style';
import { GenerateNewPublicationEpubActionButton } from './PublicationEpubActions';
import { PublicationEpubList } from './PublicationEpubList';
import {
  PublicationEpubListManagerStoreProvider,
  usePublicationEpubListManagerStore,
} from './PublicationEpubListManagerStoreProvider';
import { useAppLayoutContext } from '../../layout';
import { PublicationPageHoc } from '../PublicationPageHoc';

const usePublicationEpubTypesTabsStyles = createStyles(({ token }) => ({
  publicationEpubTypesTabs: {
    '.tabs-extra-button': {
      marginRight: 16,
    },
    '.ant-tabs-content-holder': {
      height: 'calc(100%-75px)',
    },
    '.ant-tabs-content, .ant-tabs-tabpane': {
      height: '100%',
    },
    '.ant-row-rtl.tabs-extra-demo-button': {
      marginRight: 0,
      marginLeft: 16,
    },
  },
}));
export const PublicationEpubsManagerPageComponent = (props) => {
  const { setParams, historyParams, publicationEpubTypes } = usePublicationEpubListManagerStore();
  const { styles } = usePublicationEpubTypesTabsStyles();
  const { minPageContentHeight } = useAppLayoutContext();

  return (
    <Tabs
      className={styles.publicationEpubTypesTabs}
      style={{ height: minPageContentHeight || 600 }}
      tabBarExtraContent={{
        ...(historyParams?.typeId ? { left: <GenerateNewPublicationEpubActionButton /> } : {}),
      }}
      activeKey={historyParams?.typeId}
      onChange={(activeKey) => {
        if (setParams) {
          setParams(`/publications/${historyParams?.publicationId}/epubs/${activeKey}`, { replace: true });
        }
        // setActiveEpubType(publicationEpubTypes.find((i) => i === activeKey)!);
      }}
      items={publicationEpubTypes.map((epubType, i) => {
        return {
          label: `${epubType}`,
          key: epubType,
          children: <PublicationEpubList typeId={epubType} publicationId={historyParams?.publicationId} />,
        };
      })}
      destroyInactiveTabPane
    />
  );
};

export const PublicationEpubListManagerPage = (props) => {
  return (
    <PublicationPageHoc title="epubs manager">
      <PublicationEpubListManagerStoreProvider>
        <PublicationEpubsManagerPageComponent />
      </PublicationEpubListManagerStoreProvider>
    </PublicationPageHoc>
  );
};
