import React, { useCallback, useEffect, useState } from 'react';
import { Button, Flex, Progress, Slider, SliderSingleProps } from 'antd';
import Icon, {
  DownloadOutlined,
  FileTextOutlined,
  LeftOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ReadOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { ButtonProps } from 'antd/lib/button/button';
import { useEpubReaderStore } from './EpubReaderStoreProvider';
import { FlexListTemplate } from '../../../../components';
import { ReactComponent as EpubIcon } from '../../../../assets/images/egw_font/epub.svg';
import { findSpineItemByEpubcifi, getChapterElement, getLastParaElementInSpineChapter } from './EpubUtils';
import { inRect, parseDOMRectToObject } from './dom';
import { IEpubReaderProps } from './EpubReader';
import { ILocationData, useEpubReaderActions } from './EpubReaderActionsProvider';

export type IEpubReaderFooterProps = IEpubReaderProps;

const LocationData: React.FC<ILocationData> = (props) => {
  const { percentage, totalPage, currentPage, chapterName } = props;

  return (
    <Flex vertical align="center" style={{ width: '100%' }}>
      <div>{`${currentPage} / ${totalPage} - ${percentage}%`}</div>
      <div style={{ maxWidth: 200, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        {chapterName}
      </div>
    </Flex>
  );
};

const Formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value) => {
  const { locationData, handleSlideChange } = useEpubReaderActions();
  const { percentage, totalPage, currentPage, chapterName } = locationData;
  return (
    <LocationData chapterName={chapterName} currentPage={currentPage} percentage={percentage} totalPage={totalPage} />
  );
};

const Component: React.FC<IEpubReaderFooterProps> = (props) => {
  const { locationData, handleSlideChange } = useEpubReaderActions();
  const { bookRef } = useEpubReaderStore();
  const { percentage } = locationData;
  const [sliderValue, setSliderValue] = useState<number>(percentage || 0);

  const onChange: NonNullable<SliderSingleProps['onChange']> = useCallback(
    (newValue) => {
      setSliderValue(newValue);
    },
    [percentage],
  );

  useEffect(() => {
    if (sliderValue !== percentage) {
      setSliderValue(percentage);
    }
  }, [percentage]);

  return (
    <Slider
      disabled={!bookRef.current?.state.isLoaded}
      onChange={onChange}
      onAfterChange={handleSlideChange}
      value={sliderValue}
      keyboard={false}
      tooltip={{
        formatter: Formatter,
      }}
    />
  );
};

export const EpubReaderFooter: React.FC<IEpubReaderFooterProps> = (props) => {
  return <Component {...props} />;
};
