import { Box, BoxProps, Container } from '@mui/material';
import React, { CSSProperties, FC, ReactNode } from 'react';

export interface CommandButtonGroupProps extends Omit<BoxProps, 'children'> {
  children: ReactNode | ReactNode[];
}

export const CommandButtonGroup: FC<CommandButtonGroupProps> = React.memo((props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '1px',
        width: 'fit-content',
        '&:not(style)+:not(style)': {
          marginLeft: 0,
        },
        // bgcolor: 'background',
        color: 'inherit',
      }}
      {...props}
    />
  );
});
