import { createContext, useContext, useMemo } from 'react';
import { historyObjectMap, IUsePageSearchPropsResult, usePageSearchProps } from '../../hooks';
import {
  CoverTypeDtoSortInput,
  GetManagerPublicationCoverTypesQueryHookResult,
  Scalars,
  useGetManagerPublicationCoverTypesQuery,
} from '../../services/apolloGraphqlAppApiClient/models/types';

export type TPublicationCoverTypesManagerFilter = {
  code?: string;
  coverCount?: number;
  description?: string;
  minHeight?: number;
  minWidth?: number;
};
export type TPublicationCoverTypesManagerSort = Array<CoverTypeDtoSortInput>;
export type TPublicationCoverTypesManagerPagination = {
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export interface IUsePublicationCoverTypesManagerStoreResult
  extends Omit<
    IUsePageSearchPropsResult<
      TPublicationCoverTypesManagerFilter,
      TPublicationCoverTypesManagerSort,
      TPublicationCoverTypesManagerPagination
    >,
    'setHistorySearchProps'
  > {
  publicationCoverTypesManagerStore: GetManagerPublicationCoverTypesQueryHookResult;
  refetchCoverTypes: () => void;
}

export const PublicationCoverTypesManagerStoreContext = createContext<
  IUsePublicationCoverTypesManagerStoreResult | undefined
>(undefined);
PublicationCoverTypesManagerStoreContext.displayName = 'PublicationCoverTypesManagerStoreContext';

export const usePublicationCoverTypesManagerStore = () => {
  const context = useContext(PublicationCoverTypesManagerStoreContext);
  if (context === undefined) {
    throw new Error(
      'usePublicationCoverTypesManagerStore must be used within PublicationCoverTypesManagerStoreProvider',
    );
  }
  return context;
};

export const PublicationCoverTypesManagerStoreProvider = ({ children }) => {
  const { historySearchProps, handlePageSearchPropsChange, setAntdPagination, antdPagination } = usePageSearchProps<
    TPublicationCoverTypesManagerFilter,
    TPublicationCoverTypesManagerSort,
    TPublicationCoverTypesManagerPagination
  >();

  const publicationCoverTypesManagerStore: GetManagerPublicationCoverTypesQueryHookResult =
    useGetManagerPublicationCoverTypesQuery({
      variables: {
        where: historySearchProps?.filter
          ? historyObjectMap(historySearchProps.filter, (key, value) => {
              if (!value) {
                return undefined;
              }
              if (['minHeight', 'minWidth', 'coverCount'].includes(key)) {
                return { eq: value };
              }
              return { contains: value };
            })
          : {},
        ...historySearchProps.pagination,
      },
    });
  const refetchCoverTypes = () => {
    publicationCoverTypesManagerStore.refetch({
      where: historySearchProps?.filter
        ? historyObjectMap(historySearchProps.filter, (key, value) => {
            if (!value) {
              return undefined;
            }
            if (['minHeight', 'minWidth', 'coverCount'].includes(key)) {
              return { eq: value };
            }
            return { contains: value };
          })
        : {},
      ...historySearchProps.pagination,
    });
  };

  const publicationCoverTypesManagerStoreContextObject: IUsePublicationCoverTypesManagerStoreResult = useMemo(
    () => ({
      publicationCoverTypesManagerStore,
      handlePageSearchPropsChange,
      historySearchProps,
      refetchCoverTypes,
      setAntdPagination,
      antdPagination,
    }),
    [publicationCoverTypesManagerStore, handlePageSearchPropsChange, historySearchProps, antdPagination],
  );

  return (
    <PublicationCoverTypesManagerStoreContext.Provider value={publicationCoverTypesManagerStoreContextObject}>
      {children}
    </PublicationCoverTypesManagerStoreContext.Provider>
  );
};
