import { useActive, useCommands } from '@remirror/react';
import React, { FC, useCallback } from 'react';
import { CommandButton, ICommandButtonProps } from '../../../components';
import { WFormatSubscriptExtension } from '../WFormatSubscriptExtension';

export type IToggleWFormatSubscriptButtonProps = Omit<
  ICommandButtonProps,
  'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'
>;

export const ToggleWFormatSubscriptButton: FC<IToggleWFormatSubscriptButtonProps> = (props) => {
  const type = 'subscript';
  const { toggleWFormatSubscript: command, focus } = useCommands<WFormatSubscriptExtension>();
  const active = useActive<WFormatSubscriptExtension>().wFormatSubscript({
    type,
  });
  const enabled = command.enabled({ type });

  const handleClick = useCallback(() => {
    if (enabled) {
      command({ type });
      focus();
    }
  }, [command]);

  return (
    <CommandButton
      {...props}
      commandName="toggleWFormatSubscript"
      active={active}
      enabled={enabled}
      onSelect={handleClick}
    />
  );
};
