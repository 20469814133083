import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionTag,
  getTextSelection,
  keyBinding,
  KeyBindingProps,
  MarkExtension,
  MarkExtensionSpec,
  MarkSpecOverride,
  NamedShortcut,
  PrimitiveSelection,
  ProsemirrorAttributes,
  Static,
  toggleMark,
} from '@remirror/core';
import { CoreIcon } from '@remirror/icons';
import React from 'react';
import { InputRule, markInputRule } from 'remirror';

import { WFormatTypeProperty } from './_interface';

const CREATE_COMMAND_LABEL = 'Toggle Italic';

const toggleWFormatItalicOptions: Remirror.CommandDecoratorOptions = {
  icon: 'italic',
  label: ({ t }) => t(CREATE_COMMAND_LABEL),
  shortcut: NamedShortcut.Italic,
};

export type WFormatItalicExtensionAttributes = ProsemirrorAttributes<{
  type?: WFormatTypeProperty;
}>;
export interface WFormatItalicOptions {
  /**
   * Require set the mark type property for this extension.
   */
  type?: Static<WFormatTypeProperty>;
  defaultType?: Static<WFormatTypeProperty>;
}

@extension<WFormatItalicOptions>({
  defaultOptions: {
    type: undefined,
    defaultType: undefined,
  },
  staticKeys: ['defaultType', 'type'],
})
export class WFormatItalicExtension extends MarkExtension<WFormatItalicOptions> {
  get name() {
    return 'wFormatItalic' as const;
  }

  createTags() {
    return [ExtensionTag.FormattingMark, ExtensionTag.FontStyle];
  }

  createMarkSpec(extra: ApplySchemaAttributes, override: MarkSpecOverride): MarkExtensionSpec {
    return {
      ...override,
      attrs: {
        ...extra.defaults(),
        type: { default: this.options.defaultType },
      },
      parseDOM: [
        {
          tag: `w-format[type=italic]`,
          getAttrs: (element: string | Node) => ({
            ...extra.parse(element),
            type: 'italic',
          }),
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (mark) => {
        const { type } = this.options;
        return ['w-format', { type: mark.attrs.type.toString() }, 0];
      },
    };
  }

  createInputRules(): InputRule[] {
    return [
      markInputRule({
        regexp: /(?:\*\*|__)([^*_]+)(?:\*\*|__)$/,
        type: this.type,
        ignoreWhitespace: true,
      }),
    ];
  }

  @command(toggleWFormatItalicOptions)
  toggleWFormatItalic(attrs?: WFormatItalicExtensionAttributes, selection?: PrimitiveSelection): CommandFunction {
    return toggleMark({ type: this.type, selection, attrs: { ...attrs, type: 'italic' } });
  }

  @command()
  setWFormatItalic(selection?: PrimitiveSelection): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc);
      dispatch?.(tr.addMark(from, to, this.type.create()));

      return true;
    };
  }

  @command()
  removeWFormatItalic(selection?: PrimitiveSelection): CommandFunction {
    return ({ tr, dispatch }) => {
      const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc);

      if (!tr.doc.rangeHasMark(from, to, this.type)) {
        return false;
      }

      dispatch?.(tr.removeMark(from, to, this.type));

      return true;
    };
  }

  @keyBinding({ shortcut: NamedShortcut.Italic, command: 'toggleWFormatItalic' })
  shortcut(props: KeyBindingProps): boolean {
    return this.toggleWFormatItalic()(props);
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      wFormatItalic: WFormatItalicExtension;
    }
  }
}
