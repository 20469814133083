import { ImgHTMLAttributes } from 'react';
import pray4uk from '../assets/images/pray4uk.jpg';
import appLogo from '../assets/images/app-logo.svg';

const APP_LOGO: ImgHTMLAttributes<any> = {
  src: appLogo,
  alt: 'EGW TD UI',
  title: 'EGW TD UI',
};

const IMAGES_MAP: ImgHTMLAttributes<any>[] = [
  {
    src: pray4uk,
    alt: 'Pray4Ukraine',
    title: 'Pray4Ukraine',
  },
];

export default { APP_LOGO, BANNERS_MAP: IMAGES_MAP };
